import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'call-success-page',
  templateUrl: './call-success-page.component.html'
})
export class CallSuccessPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
