import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  public currentLang: string = '';
  public mainTitle: { ka: string, en: string } = {ka: 'კონფიდენციალურობის პოლიტიკა', en: 'PRIVACY POLICY'}
  public mainDesc: { ka: string, en: string } = {ka: 'წინამდებარე ვებ-გვერდის გამოყენება რეგულირდება სს „თიბისი ბანკის” (საიდენტიფიკაციო ნომერი: 204854595, მისამართი: თბილისი, კ. მარჯანიშვილის ქ. #7) მიერ დადგენილი წესებითა და პირობებით.', en: 'JSC "TBC Bank" is a commercial bank licensed under the Georgian legislation. Identification number: 204854595. Legal address: Marjanishvili str. №7, Chugureti region, Tbilisi, Georgia.'}
  public whoWeAre: { ka: string, en: string } = {ka: 'ვინ ვართ', en: 'Who we are'}
  public whoWeAreDesc: { ka: string[], en: string[] } = {
    ka: ['სს „თიბისი ბანკი“ არის საქართველოს კანონმდებლობის თანახმად ლიცენზირებული კომერციული ბანკი.', 'საიდენთიფიკაციო ნომერი: 204854595', 'იურიდიული მისამართი: საქართველო, თბილისი, ჩუღურეთის რაიონი, მარჯანიშვილის ქ. №7'],
    en: ['JSC "TBC Bank" is a commercial bank licensed under the Georgian legislation. ', 'Identification number: 204854595', 'Legal address: Marjanishvili str. №7, Chugureti region, Tbilisi, Georgia.'],
  }
  public howWeUseYourInformationTitle: { ka: string, en: string } = {
    ka: 'როგორ ვიყენებთ თქვენს პერსონალურ ინფორმაციას',
    en: 'How we use your personal information'
  }
  public howWeUseYourInformationTitleDesc: { ka: string, en: string } = {
    ka: 'წინამდებარე შეტყობინება ეხება პერსონალურ მონაცემებზე არასანქცირებულ წვდომასთან დაკავშირებით სს „თიბისი\n' +
        '            ბანკში“ (შემდგომში ბანკი) არსებულ დაცვის მექანიზმებს, რომელთა საშუალებითაც ბანკი იცავს თქვენს პერსონალურ\n' +
        '            მონაცემებს. ეს უკანასკნელი მოიცავს ინფორმაციას, რომელსაც ბანკი მოიპოვებს თქვენთვის მომსახურების გაწევის\n' +
        '            პერიოდში, რომელიც საქართველოს კანონმდებლობის შესაბამისად, შემდგომში ასევე გამოიყენება პირდაპირი მარკეტინგის\n' +
        '            მიზნებისთვის. შეტყობინების მიზანია, მოგაწოდოთ ინფორმაცია პერსონალური ინფორმაციის დამუშავებისას ჩვენი\n' +
        '            მუშაობის პრინციპებისა და თქვენი სამართლებრივი დაცვის მექანიზმებთან დაკავშირებით.',
    en: 'This notice refers to the security tools available at TBC Bank (hereinafter referred to as the Bank) against unsanctioned access to personal information. It covers the data which the Bank obtains when having you as a customer, which is also used for direct marketing purposes in line with the legislation of Georgia. The notice explains the principles we follow while processing your personal data and how the law protects you.'
  }
  public privacyPromiseTitle: { ka: string, en: string } = {
    ka: 'პერსონალურ მონაცემთა კონფიდენციალურობის დაცვის დაპირება\n',
    en: 'Privacy promise'
  }

  public promiseSubTitle: { ka: string, en: string } = {
    ka: 'ჩვენ, სს „თიბისი ბანკის“ გუნდი, გპირდებით, რომ:',
    en: 'We, the TBC Bank group, promise:'
  }
  public privacyPromiseTitleDesc: { ka: string[], en: string[] } = {
    ka: ['ვიზრუნებთ თქვენი პერსონალური მონაცემების უსაფრთხოებასა და დაცვაზე;', 'არ გამოვიყენებთ თქვენს მონაცემებს არამართლზომიერად;', 'ნებისმიერ დროს მოგაწვდით სრულ და ამომწურავ ინფორმაციას თქვენი პერსონალური მონაცემების დამუშავებასთან დაკავშირებით.'],
    en: ['To keep your data safe and private;', 'Not to use your data unlawfully;', 'To provide you with complete and exhaustive information with respect to the processing of your personal information.']
  }
  public howLawProtectsTitle: { ka: string, en: string } = {
    ka: 'როგორ გიცავთ კანონი',
    en: 'How the law protects you'
  }
  public howLawProtectsTitle1: { ka: string, en: string } = {
    ka: 'კანონმდებლობის თანახმად, თქვენ უფლება გაქვთ, მოგვთხოვოთ ინფორმაცია თქვენი მონაცემების დამუშავების თაობაზე. თქვენ გაქვთ უფლება მიიღოთ შემდეგი ინფორმაცია:',
    en: 'As well as our Privacy Promise, your privacy is protected by the Law of Georgia on the Protection of Personal Data. Pursuant to the law, you are authorized to request of us the information with regard to the use of your personal data. You can receive the following information:'
  }
  public howLawProtectsTitle2: { ka: string, en: string } = {
    ka: 'პერსონალურ მონაცემთა კონფიდენციალურობის დაცვის დაპირების მსგავსად, აღნიშნულ მონაცემებს იცავს პერსონალურ მონაცემთა დაცვის შესახებ საქართველოს კანონი.',
    en: ''
  }
  public howLawProtectsDesc: { ka: string[], en: string[] } = {
    ka: ['რომელი მონაცემები მუშავდება თქვენს შესახებ;', 'მონაცემთა დამუშავების მიზანი;', 'მონაცემთა დამუშავების სამართლებრივი საფუძველი;', 'რა გზით შეგროვდა თქვენი მონაცემები;', 'მონაცემთა გაცემის საფუძველი და მიზანი.'],
    en: ['Which data are being processed with regard to you;', 'What is the purpose of data processing;', 'Legal basis for the data processing;', 'How the data were processed;', 'Who the data was transferred to;', 'Data issuance ground and purpose.']
  }
  public howLawProtectsSubDesc: { ka: string[], en: string[] } = {
    ka: ['თქვენ შეგიძლიათ მოითხოვოთ ჩვენ მიერ დამუშავებული ინფორმაციის ასლი. კანონმდებლობის შესაბამისად თქვენ გაქვთ\n' +
    '            უფლება მოითხოვოთ თქვენი პირადი მონაცემების გასწორება, განახლება, დამატება, დაბლოკვა, წაშლა ან განადგურება თუ\n' +
    '            ისინი არასრულია, არაზუსტია, არ არის განახლებული ან თუ მათი შეგროვება და დამუშავება განხორციელდა კანონის\n' +
    '            საწინააღმდეგოდ. ჩვენ ვმოქმედებთ საქართველოს კანონმდებლობის შესაბამისად, რამაც შესაძლოა ხელი შეგვიშალოს\n' +
    '            დაუყოვნებლივ წავშალოთ თქვენი პირადი მონაცემები. ასეთი ვალდებულებები შესაძლოა გამომდინარეობდეს ფულის\n' +
    '            გათეთრების წინააღმდეგ ბრძოლის, საგადასახადო კანონმდებლობის, კომერციული ბანკების საქმიანობისა და\n' +
    '            მომხმარებელთა უფლებების დაცვის კანონმდებლობიდან.', 'აღნიშნული ინფორმაციის თქვენთვის მოწოდების ვალდებულება ბანკს წარმოეშვება თქვენი მოთხოვნის შემთხვევაში.\n' +
    '            პერსონალური მონაცემების გამოყენების უფლება გვაქვს მხოლოდ იმ შემთხვევაში, როცა ამისათვის კონკრეტული\n' +
    '            სახელშეკრულებო ან/და კანონისმიერი საფუძველი გაგვაჩნია.', 'ბანკს აქვს თქვენი ინფორმაციის გამოყენების კანონიერი საფუძველი, რაც გულისხმობს ბიზნესთან დაკავშირებული ან/და\n' +
    '            კომერციული მიზნის არსებობას. მნიშვნელოვანია, რომ მოცემულ შემთხვევაშიც, ბანკის მიერ ინფორმაციის დამუშავება არ\n' +
    '            არის მიმართული თქვენი ინტერესების შელახვისაკენ.', 'ქვემოთ მოცემულია მიზნები და სამართლებრივი საფუძვლები, რომლის ფონზეც ვამუშავებთ თქვენს პერსონალურ\n' +
    '            ინფორმაციას. ყოველი კონკრეტული შემთხვევისთვის, ჩვენ წარმოგიდგენთ თქვენი ინფორმაციის გამოყენების მიზნებს და\n' +
    '            ჩვენს სამართლებრივ საფუძვლებს:'],
    en: ['You can request a copy of the information processed by us. Under the law, you are authorized to require adjustment, update, addition, blockage, deletion or destruction of your personal data if they appear to be incomplete, incorrect, out-of-date or if the process of information gathering and processing is carried out illegally. We observe the requirements of the Georgian legislation, which may prevent us from an immediate deletion of your personal data. Such obligations may be stemming from the laws on antimoney laundering, tax, activities of commercial banks and consumer rights protection.', 'The Bank shall be obliged to supply this information if requested by you. We are authorized to use the personal data only in case specific contractual and/or relevant legal basis exist.',]
  }

  public personalInformationGroupsTitle: { ka: string, en: string } = {
    ka: 'პერსონალური ინფორმაციის ჯგუფები',
    en: 'Personal Information Groups'
  }

  public personalInformationGroupsDesc: { ka: string, en: string } = {
    ka: 'ჩვენ ვიყენებთ სხვადასხვა სახის პერსონალურ ინფორმაციას და ვაჯგუფებთ მას შემდეგნაირად:',
    en: 'We use various types of personal information and classify them as follows:'
  }



  public tableOneTitles: { ka: string[], en: string[] } = {
    ka: ['რისთვის ვიყენებთ თქვენს პერსონალურ ინფორმაციას', 'ჩვენი მიზანი', 'ჩვენი სამართლებრივი საფუძველი'],
    en: ['What do we use your personal information for', 'Our goal', 'Our legal basis']
  }
  public tableOne =
      {
        ka: [
          [
            [
              'თქვენთან ურთიერთობისთვის;',
              'თქვენი მოთხოვნების დასაკმაყოფილებლად, თანამშრომლობის ახალი გზების შესამუშავებლად და ჩვენი ბიზნესის განსავითარებლად;',
              'თქვენი მოთხოვნების დასაკმაყოფილებლად',
              'მარკეტინგული აქტივობების შესამუშავებლად და განსახორციელებლად;',
              'იმის შესასწავლად, თუ როგორ იყენებენ კლიენტები ჩვენს პროდუქტებსა და მომსახურებას;',
              'ჩვენს პროდუქტებსა და მომსახურებასთან დაკავშირებით რჩევებისა თუ რეკომენდაციების მისაღებად;',
              'ჩვენი ბრენდების, პროდუქტებისა და მომსახურების განსავითარებლად/სამართავად;',
              'ჩვენი პროდუქტებისა და მომსახურების მისაწოდებლად;',
              'კლიენტების გადახდების განსახორციელებლად და სამართავად;',
              'კლიენტის ანგარიშებზე საკომისიოებისა და პროცენტის სამართავად;',
              'სახაზინო და საინვესტიციო პროდუქტების და მომსახურების სამართავად და მისაწოდებლად',
            ],
            [
              'მონაცემების განახლება, თქვენთვის საინტერესო პროდუქტებისა და მომსახურების იდენტიფიცირება და ამ ინფორმაციის თქვენთვის მოწოდება;',
              'პროდუქტებისა და მომსახურების განვითარება;',
              'ახალი პროდუქტებისა თუ მომსახურების შეთავაზებისთვის კლიენტთა ფოკუს ჯგუფების განსაზღვრა;',
              'ჩვენი კანონიერი მოვალეობების ეფექტიანად შესრულება.',
              'ჩვენი კანონიერი მოვალეობებისა და სახელშეკრულებო ვალდებულებების ეფექტიანად შესრულება;',
              'ჩვენთვის რელევანტურ რეგულაციებთან შესაბამისობის უზრუნველყოფა'
            ],
            [
              'თქვენი თანხმობა;',
              'სახელშეკრულებო ვალდებულებათა შესრულება;',
              'ჩვენი კანონიერი ინტერესები;',
              'ჩვენი კანონიერი მოვალეობა.'
            ],
          ],

          [
            [
              'ფინანსური დანაშაულის გამოსავლენად, გამოსაძიებლად, ანგარიშგებისა და პრევენციისათვის;',
              'ჩვენი და ჩვენი კლიენტების რისკების სამართავად;',
              'ჩვენთვის რელევანტური კანონებისა და რეგულაციების შესასრულებლად;',
              'საჩივრებზე რეაგირების მოსახდენად და მათი მოგვარების გზების მოსაძიებლად.'
            ],
            [
              'ფინანსური დანაშაულის წინააღმდეგ ჩვენი სამოქმედო სტრატეგიების შემუშავება და დახვეწა, ასევე ამ თვალსაზრისით კანონიერი მოვალეობების შესრულება'
            ],
            [
              'თქვენი თანხმობა;',
              'სახელშეკრულებო ვალდებულებათა შესრულება;',
              'ჩვენი კანონიერი ინტერესები;',
              'ჩვენი კანონიერი მოვალეობა.'
            ],
          ],
          [
            [
              'ჩვენი ბიზნესის ეფექტიანად და მართებულად წარსამართავად, რაც მოიცაბს ჩვენი ფინანსური პოზიციის, ბიზნეს შესაძლებლობის, დაგეგმვის, კომუნიკაციების, კორპორატიული მმართველობისა და აუდიტის მართვა.',
            ],
            [
              'ჩვენთვის რელევანტურ რეგულაციებთან შესაბამისობის უზრუნველყოფა;',
              'ჩვენი კანონიერი მოვალეობების და სახელშეკრულებო ვალდებულებების ეფექტიანად შესრულება.'
            ],
            [
              'ჩვენი კანონიერი ინტერესები;',
              'ჩვენი კანონიერი მოვალეობა.'
            ],
          ],
          [
            [
              'ხელშეკრულებებით ნაკისრი უფლებების-ვალდებულებების განხორციელება.'
            ],
            [
              'სახელშეკრულებო ვალდებულებების შესრულება'
            ],
            [
              'რელევანტურ რეგულაციებთან შესაბამისობის უზრუნველყოფა;',
              'ჩვენი კანონიერი მოვალეობების და სახელშეკრულებო ვალდებულებების ეფექტიანად შესრულება.'
            ],
          ]
        ],
        en: [
          [
            [
              'To manage our relationship with you',
              'To meet your needs, develop new ways of cooperation and grow our business.',
              'To develop and carry out marketing activities.',
              'To study how our customers use products and services from us;',
              'To receive advice or guidance about our products and services.',
              'To develop/manage our brands, products and services.',
              'To deliver our products and services;',
              "To make and manage Clients' payments;",
              "To manage fees and interest due on client's accounts;",
              'To manage and deliver the products from Treasury and investment products',

            ],
            [
              'To update data, to identify the products and services interesting for you and to supply you with relevant information;',
              'To develop products and services;',
              'To define the focus client groups for the purpose of offering new products and services;',
              'To efficiently fulfill legal obligations.',
              'To efficiently execute our legal and contractual obligations;',
              'To ensure compliance with relevant regulations',
            ],
            [
              'Your consent;',
              'Fulfillment of contractual obligations;',
              'Our legitimate interests;',
              'Our legal obligation.',
            ],
          ],

          [
            [
              'To detect, investigate, report and prevent financial crime;',
              'To manage the risks of ours and those of our clients;',
              'To fulfill relevant laws and regulations;',
              'To respond to claims and find the ways of resolution.',
            ],
            [
              'To develop and improve our action strategies against financial crime; also, to fulfill our legal duties in this regard.'
            ],
            [
              'Your consent;',
              'Fulfillment of contractual obligations;',
              'Our legitimate interests;',
              'Our legal obligation',
            ],
          ],
          [
            [
              'To efficiently and properly run the business, covering the management of our financial position, business opportunities, planning, communications, corporate governance and audit management.',
            ],
            [
              'To ensure compliance with relevant regulations',
              'To efficiently execute our legal and contractual obligations;',
            ],
            [
              'Our legitimate interests;',
              'Our legal obligation',
            ],
          ],
          [
            [
              'To execute contractual rights and obligations'
            ],
            [
              'To meet contractual obligations'
            ],
            [
              'To ensure compliance with relevant regulations',
              'To efficiently execute our legal and contractual obligations;',
            ],
          ]
        ],
      }

  public tableTwoTitles: { ka: string[], en: string[] } = {
    ka: ['პერსონალური ინფორმაციის ტიპები', 'აღწერილობა'],
    en: ['Type of personal information', 'Description']
  }
  public tableTwo =
      {
        ka: [
          [
            'ფინანსური',
            'თქვენი მოთხოვნების დასაკმაყოფილებლად, თანამშრომლობის ახალი გზების შესამუშავებლად და ჩვენი ბიზნესის განსავითარებლად;'
          ],
          [
            'საკონტაქტო ინფორმაცია',
            'საცხოვრებელი მისამართი და საკონტაქტო ინფორმაცია'
          ],
          [
            'სოციო-დემოგრაფიული',
            'დეტალური ინფორმაცია თქვენი სამუშაო ადგილის ან პროფესიის შესახებ; აგრეთვე ინფორმაცია თქვენი ეროვნების, განათლების, სოციალური თუ ეკონომიკური კატეგორიისადმი კუთვნილების შესახებ'
          ],
          [
            'ტრანზაქციასთან დაკავშირებული',
            'დეტალური ინფორმაცია ბანკში თქვენს ანგარიშზე და თქვენი ანგარიშიდან წარმოებული გადახდების შესახებ'
          ],
          [
            'სახელშეკრულებო',
            'დეტალური ინფორმაცია ჩვენ მიერ თქვენთვის მიწოდებული პროდუქტებისა და გაწეული მომსახურების შესახებ'
          ],
          [
            'ლოკაციური',
            'მონაცემები თქვენს ადგილსამყოფელთან დაკავშირებით, რომელიც შესაძლოა ბანკმა მოიპოვოს თქვენი მობილური ტელეფონის დახმარებით, კომპიუტერის ინტერნეტში ჩართვის მისამართიდან, ან იმ ობიექტის ადგილმდეგარეობიდან, სადაც თქვენი ბარათის საშუალებით ახორციელებთ საბანკო ოპერაციებს'
          ],
          [
            'ქცევითი',
            'დეტალური ინფორმაცია იმის შესახებ, თუ როგორ იყენებთ ჩვენს პროდუქტებს და მომსახურებას'
          ],
          [
            'ტექნიკური',
            'ინფორმაცია თქვენ მიერ გამოყენებული მოწყობილობებისა და ტექნოლოგიის შესახებ'
          ],
          [
            'საკომუნიკაციო',
            'ინფორმაცია, რომელსაც ბანკი მოიპოვებს თქვენს შესახებ წერილებიდან, ელექტრონული წერილებიდან კლიენტსა და ბანკს შორის განხორციელებული სხვა ნებისმიერი საკომუნიკაციო საშუალებით.'
          ],
          [
            'სოციალური ურთიერთობები',
            'თქვენი ოჯახის წევრები.'
          ],
          [
            'დოკუმენტური მონაცემები',
            'სხვადასხვა ფორმატის დოკუმენტებსა და მათ ასლებში ასახული ინფორმაცია თქვენს შესახებ. ასეთ დოკუმენტებს მიეკუთვნება თქვენი პასპორტი, პირადობის დამადასტურებელი საბუთი, დაბადების მოწმობა, ავტომობილის მართვის მოწმობა და სხვა პიროვნების მაიდენტიფიცირებელი დოკუმენტი.'
          ]
        ],
        en: [
          [
            'Financial',
            'Your financial position, status and history'
          ],
          [
            'Contact details',
            'Where you live and how to contact you'
          ],
          [
            'Socio-demographic',
            'Details on your job or profession; also, information on your nationality, education, social or income grouping'
          ],
          [
            'Transactional',
            'Details on payments to and from your account'
          ],
          [
            'Contractual',
            'Details on products and services provided to you by us'
          ],
          [
            'Locational',
            'Details on your location which the Bank may obtain from your mobile phone, the address where you connect your computer to the internet, or a shop where you buy something with your card.'
          ],
          [
            'Behavioral',
            'Details on how you use our products and services.'
          ],
          [
            'Technical',
            'Details on the devices and technology your use.'
          ],
          [
            'Communications',
            'Details which the Bank obtains about you from letters, e- mails and conversations between the client and the Bank via any means of communication.'
          ],
          [
            'Social relations',
            'Details on your family members.'
          ],
          [
            'Documentary data',
            'Details about you recorded in various types of documents and the copies thereof. To such documents belong: passport, ID card, birth certificate, driving license and other identification documents.'
          ]
        ],
      }

  public tableTwoLastCol = {
    ka: {
      title: 'განსაკუთრებული კატეგორიის მონაცემები',
      desc: 'განიხილავს, როგორც განსაკუთრებული ტიპის ინფორმაციას. ამ ტიპის მონაცემთა დამუშავება შესაძლებელია მხოლოდ თქვენი წერილობითი თანხმობით ან იმ შემთხვევაში, თუ ასეთი დამუშავება ემსახურება კანონით მკაცრად განსაზღვრულ მიზანს.',
      descBold: 'ასეთ მონაცემებს მიეკუთვნება :',
      list: [
        'რასობრივი თუ ეთნიკური კუთვნილების შესახებ ინფორმაცია',
        'პოლიტიკური შეხედულებები',
        'რელიგიური თუ ფილოსოფიური მწრამსი',
        'ინფორმაცია პროფესიული კავშირის წევრობის შესახებ',
        'გენეტიკური და ბიომეტრიული მონაცემები',
        'მონაცემები ჯანმრთელობის მდგომარეობასა და სქესობრივ ცხოვრებასთან დაკავშირებით',
        'ინფორმაცია ნასამართლობის, ადმინისტრაციული პატიმრობის, პირისთვის აღკვეთის ღონისძიების შეფარდების, პირთან საპროცესო შეთანხმების დადების, განრიდების, დანაშაულის მსხვერპლად აღიარების ან დაზარალებულად ცნობის შესახებ'
      ]
    },
    en: {
      title: 'Special types of data',
      desc: 'The Privacy Law of Georgia and other regulations treat some types of personal data as special. The latter can only be processed only under your written consent or in case it serves the purpose strictly defined by the law.',
      descBold: 'To such data belong :',
      list: [
        'Information on racial or ethnic origin',
        'Political beliefs',
        'Religious or philosophical beliefs',
        'Trade Union membership',
        'Genetic and bio-metric data',
        'Health data including gender',
        'Information on conviction, administrative detention, preventive measure, plea-bargaining, diversion, recognition as aggrieved or victim of crime'
      ]
    }
  };

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.pipe(takeUntil(this.destroy$))
        .subscribe(lang => {
          this.currentLang = lang.lang;
        })
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
