import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderProductFormService } from '../services/order-product-form.service';

@Injectable()
export class OrderProductFormGuard implements CanActivate {

  constructor(private router: Router, private orderProductFormService: OrderProductFormService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (next.params.product !== "pos" && next.params.product !== "ecom") {
      this.router.navigate(['404']);
    }

    if (!next.firstChild) {
      this.router.navigate([state.url + "/" + this.orderProductFormService.getProductStepData2(next.params.product)[0].route]);
    }

    return true;
  }

}
