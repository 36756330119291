import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { PosTableService } from '../pos-table.service';

@Component({
  selector: 'app-pos-product-table',
  templateUrl: './pos-product-table.component.html',
  styleUrls: ['./pos-product-table.component.scss'],
  animations: [
    trigger('tableCell', [
      transition('* <=> *', [
        animate(250, keyframes([
          style({ opacity: 0.3, offset: 0 }),
          style({ opacity: 0.7, offset: 0.6 }),
          style({ opacity: 1, offset: 1 })
        ]))
      ])
    ]),
  ]
})
export class PosProductTableComponent implements OnInit {
  @ViewChild('scrollable', {static: true}) scrollable: ElementRef;
  headers = [];
  fields = [];
  selectedTitle = 1;
  selectedHeader = 'POS_STANDARD';
  currentIndex = 0;
  isMobile: boolean = window.innerWidth <= 768;
  public toggleAnimation: boolean;

  constructor(private tableService: PosTableService) {
    this.headers = tableService.headers;
    this.fields = tableService.fields;
  }

  ngOnInit(): void { }

  expandTable(field) {
    if (field.showExpand) {
      field.show = !field.show;
    }
  }

  setTable(header, i) {
    this.selectedHeader = header;
    this.currentIndex = i;
    this.toggleAnimation = !this.toggleAnimation;
  }

  disableLeft = true;
  disableRight = false;

  scrollLeft() {
    this.sideScroll(this.scrollable.nativeElement, 'left', 7, 150, 5);

  }

  scrollRight() {
    this.sideScroll(this.scrollable.nativeElement, 'right', 7, 150, 5);

  }

  checkScrollWidth() {
    let ne = this.scrollable.nativeElement;
    this.disableRight = ne.scrollWidth - ne.clientWidth - ne.scrollLeft === 0;
    this.disableLeft = ne.scrollLeft === 0;
  }

  sideScroll(element: any,direction: 'left' | 'right',speed: number,distance: number,step: number){
    let scrollAmount = 0;
    let slideTimer = setInterval(() => {
      if(direction == 'left'){
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if(scrollAmount >= distance){
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
}
