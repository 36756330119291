import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from "@angular/forms";
import { debounceTime, switchMap } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { WorkingSpheres } from "../../enums/working-spheres";
import { ProductReceiveChannels } from "../../enums/product-receive-channels";
import { cardTypes } from "../../enums/ecom-card-types";
import { EcomCurrencies } from "../../enums/ecom-currencies";
import { Constants } from "../../../../../common/constants";
import { ValidatorPatterns } from "../../../../shared/validators/validator-patterns.validator";
import { SaveFormService } from "../../../services/save-form.service";

@Component({
  selector: "ecom-working-sphere",
  templateUrl: "./ecom-working-sphere.component.html",
  styleUrls: ["./ecom-working-sphere.component.scss"],
})
export class WorkingSphereComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public workingSpheres = WorkingSpheres;
  public merhcantProductReceiveChannels = ProductReceiveChannels;
  public chosenSphere: number;
  public cardTypes = cardTypes;
  public ecomCurrencies = EcomCurrencies;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() formFields;
  @Input() section;
  @Input() displayLoader: boolean;
  @Input() eComApplicationId;
  @Input() ecomType: number;
  @Output() sphereChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();

  @Input() set serverErrorData(serverErrors) {
    if (serverErrors) {
      if (serverErrors.length && this.formGroup) {
        serverErrors.forEach((serverError) => {
          let fieldName: string = serverError.fieldName;
          const props = ["trade", "service", "microFinanceCompany", "tourism"];

          props.forEach((prop) => {
            const toReplace = prop + ".";
            fieldName = fieldName.replace(toReplace, "");
            fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
          });

          const formField = this.getFormProperty(fieldName);
          if (formField) {
            formField.setErrors(serverError.serverError);
          }
        });
      }
    }
  }

  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Output() formSent: EventEmitter<any> = new EventEmitter();
  @ViewChild('captchaRef') reCaptcha;

  constructor(private formBuilder: FormBuilder, private saveFormService: SaveFormService) { }

  ngOnInit(): void {
    this.buildForm();

    this.formGroup.valueChanges
      .pipe(debounceTime(300), switchMap(() => {
        this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, nextStep: false });
        return of(null)
      })).subscribe()
    this.saveFormService.patchFormValue(this.formGroup, this.section);
    // console.log(this.ecomType);
  }

  cardTypeChanged(): void {
    if (this.cardType.value === 2) {
      this.additionalCurrency.addValidators(Validators.required);
    } else {
      this.additionalCurrency.removeValidators(Validators.required);
    }
    this.additionalCurrency.updateValueAndValidity();
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      website: new FormControl(this.formFields.website.value, [
        Validators.required,
        Validators.pattern(new RegExp(ValidatorPatterns.RegexPatterns["web-address"]))
      ]),
      shopify: new FormControl(this.formFields && this.formFields.ecomType ? this.formFields.ecomType.value === 3 : false),
      fieldOfActivityDescription: new FormControl(this.formFields.fieldOfActivityDescription.value, [Validators.required, Validators.maxLength(3000)]),
      cardType: new FormControl(this.formFields.cardType.value, [Validators.required]),
      additionalCurrency: new FormControl(this.formFields.additionalCurrency.value),
      estimatedMonthlyTurnover: new FormControl(this.formFields.estimatedMonthlyTurnover.value, [
        Validators.required,
        Validators.max(2147483647),
        Validators.pattern(new RegExp(Constants.RegexPatterns["number"]))
      ]),
      productPriceRange: new FormControl(this.formFields.productPriceRange.value, [
        Validators.required,
        Validators.max(2147483647),
        Validators.pattern(new RegExp(Constants.RegexPatterns["number-range"]))
      ]),
      applePay: new FormControl(this.formFields.applePay.value),
      googlePay: new FormControl(this.formFields.googlePay.value),
      installmentPayment: new FormControl(this.formFields.installmentPayment.value),
      payWithInternetBank: new FormControl(this.formFields.payWithInternetBank.value),
      payByQROrByMobileBank: new FormControl(this.formFields.payByQROrByMobileBank.value),
      payWithLoyalPoints: new FormControl(this.formFields.payWithLoyalPoints.value),
      BNPL: new FormControl(this.formFields.BNPL.value),
    });
  }

  public getFormProperty(propertyName: string): AbstractControl {
    return this.formGroup.get(propertyName);
  }

  public get websiteKeycode() {
    let keyCode = this.formFields.website.keyCode;
    if (this.ecomType === 4) {
      keyCode += '_PBL';
    }
    return keyCode;
  }


  public sendForm() {
    if (this.formGroup.status === 'VALID') {
      this.reCaptcha.execute();
    }
    // console.log(this.formGroup.value);
  }

  resolved(captchaResponse: string) {
    this.formSent.emit({ recaptchaToken: captchaResponse, ...this.formGroup.value });
    this.reCaptcha.reset();
  }

  public get website(): AbstractControl {
    return this.formGroup.get("website");
  }

  public get shopify(): AbstractControl {
    return this.formGroup.get('shopify')
  }

  public get fieldOfActivityDescription(): AbstractControl {
    return this.formGroup.get("fieldOfActivityDescription");
  }

  public get sourceOfProduction(): AbstractControl {
    return this.formGroup.get("sourceOfProduction");
  }

  public get productionDescription(): AbstractControl {
    return this.formGroup.get("productionDescription");
  }

  public get cardType(): AbstractControl {
    return this.formGroup.get("cardType");
  }

  public get additionalCurrency(): AbstractControl {
    return this.formGroup.get("additionalCurrency");
  }

  public get estimatedMonthlyTurnover(): AbstractControl {
    return this.formGroup.get("estimatedMonthlyTurnover");
  }

  public get productPriceRange(): AbstractControl {
    return this.formGroup.get("productPriceRange");
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
