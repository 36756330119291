import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-landing',
  templateUrl: './portal-landing.component.html',
  styleUrls: ['./portal-landing.component.scss']
})
export class PortalLandingComponent implements OnInit {
  public directory = {
    productType: 'space',
    productId: 'default'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
