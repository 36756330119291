<div class="control-panel">
    <div class="control-panel__details generic-spacer-bottom">
        <h2 class="control-panel__details--title caps">{{data[directory.productType][directory.productId].title |
            translate}}</h2>
        <p class="control-panel__details--description">
            {{data[directory.productType][directory.productId].description | translate}}
        </p>
        <!-- <a href="{{data[directory.productType][directory.productId].address}}" target="_blank"
            style="width: fit-content;">
            <tbc-button [showArrow]="true"
                [text]="data[directory.productType][directory.productId].buttonText | translate">
            </tbc-button>
        </a> -->
        <div class="offer_list">
            <div class="list"><span><img src="./assets/list/check.716c6635b16b4574.svg.svg" alt="list_icon"></span>{{ 'SHOPIFY_LIST_1' | translate }}</div>
            <div class="list"><span><img src="./assets/list/check.716c6635b16b4574.svg.svg" alt="list_icon"></span>{{ 'SHOPIFY_LIST_2' | translate }}</div>
            <div class="list"><span><img src="./assets/list/check.716c6635b16b4574.svg.svg" alt="list_icon"></span>{{ 'SHOPIFY_LIST_3' | translate }}</div>
        </div>
    </div>
    <div class="control-panel__image">
        <img class="img" src="assets/images/product-details/panel-component.svg" [alt]="data[directory.productType][directory.productId].title | translate">
    </div>
</div>