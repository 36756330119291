<generic-container divider="true" [titleKey]="pageTitle" textAlign="left">
    <div class="container-fluid">
        <div class="row item-row">
            <div [ngClass]="i % 2 !== 0 && 'pr-0 pl-md-5'"
                *ngFor="let function of currentData; let i = index; let last = last"
                class="col-12 col-sm-6 pl-0 item-wrapper">
                <div class="wrapper d-flex">
                    <div class="function-item position-relative">
                        <img src="{{function.img}}" class="position-absolute" [ngStyle]="{
                            'width': function.width && function.width + 'px',
                            'margin-top': function.top && function.top + 'px',
                            'margin-left': function.left && function.left + 'px'}" [alt]="function.title | translate">
                        <h3 class="font-weight-bold">
                            {{function.title | translate}}
                        </h3>
                        <p class="mb-0">
                            {{function.description | translate}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</generic-container>