<div class="container-fluid pl-0 pr-0 header-wrapper">
    <div class="navbar wrap" [ngClass]="{'nav-bd-bottom': show}">
        <div class="d-flex navbar-brand-wrapper justify-content-between">
            <a class="navbar-brand" routerLink="/" (click)="close()">
                <img class="d-block" src="../../../assets/images/logo/tbc-logo-color.svg" alt="tbc-logo">
            </a>
        </div>

        <ng-container *ngIf="!isMobile">
            <div class="d-flex deskotp-menu-items">
                <div class="w-100 justify-content-between align-items-center row caps ml-0 mr-0">
                    <ul class="navbar-nav">
                        <li class="nav-item" (click)="close()">
                            <a class="nav-link font-weight-bold px-2 px-xl-3" [routerLink]="['/pos']"
                               routerLinkActive="header-active">{{'HEADER_POS_TERMINALS_TITLE'
                                | translate}}</a>
                        </li>
                        <li class="nav-item" (click)="close()">
                            <a class="nav-link font-weight-bold px-2 px-xl-3" [routerLink]="['/ecom']"
                               routerLinkActive="header-active">{{'E_COMMERCE' | translate}}</a>
                        </li>
                        <li class="nav-item position-relative" (click)="close()">
                            <a class="nav-link font-weight-bold px-xl-3" [routerLink]="'space'"
                               routerLinkActive="header-active">
                                <div class="new-label position-absolute">
                                    {{'NEW' | translate}}
                                </div>
                                {{'HEADER_SPACE' | translate}}
                            </a>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item nav-button mr-xl-3">
							<tbc-button *ngIf="(showLoginButton$ | async) === false" (click)="initLogin()" position="right" [showLoader]="displayLoader"
										text="{{ 'LOGIN' | translate }}">
							</tbc-button>
							<tbc-button *ngIf="(showLoginButton$ | async) === true" class="dashboard_btn" (click)="returnToDashboard()" position="right"
										text="{{ 'RETURN_TO_DASHBOARD' | translate }}">
							</tbc-button>
                        </li>
                        <li class="nav-item" *ngIf="!isMobile">
                            <lang-custom-dropdown
                                    (onChange)="changeLang($event)"
                                    [oppositeLanguage]="oppositeLanguage">
                            </lang-custom-dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <div class="navbar-toggler" (click)="toggle()" [ngClass]="{ open: show }">
                <div class="icon-bar"></div>
                <div class="icon-bar"></div>
                <div class="icon-bar"></div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isMobile && show">
        <div class="navbar-menu-items-wrapper" [ngClass]="{'expanded': show}" @openClose>
            <div class="w-100 justify-content-between d-flex flex-column caps ml-0 mr-0">
                <ul class="navbar-nav">
                    <li class="nav-item" (click)="close()">
                        <a class="nav-link font-weight-bold" [routerLink]="['/pos']"
                           routerLinkActive="header-active">{{'HEADER_POS_TERMINALS_TITLE'
                            | translate}}</a>
                    </li>
                    <li class="nav-item" (click)="close()">
                        <a class="nav-link font-weight-bold" [routerLink]="['/ecom']"
                           routerLinkActive="header-active">{{'E_COMMERCE' | translate}}</a>
                    </li>
                    <li class="nav-item position-relative" (click)="close()">
                        <a class="nav-link font-weight-bold" [routerLink]="'space'"
                           routerLinkActive="header-active">
                            <div class="new-label position-absolute">
                                {{'NEW' | translate}}
                            </div>
                            {{'HEADER_SPACE' | translate}}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="isMobile">
                        <lang-custom-dropdown
                                (onChange)="changeLang($event)"
                                [dropdown]="false"
                                [oppositeLanguage]="oppositeLanguage">
                        </lang-custom-dropdown>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <div class="top-line"></div>
                    <li class="nav-item nav-button mr-xl-3">
						<tbc-button *ngIf="(showLoginButton$ | async) === false" class="dashboard_btn" (click)="initLogin()" position="right" [maxWidth]="'100%'" [showLoader]="displayLoader"
									text="{{ 'LOGIN' | translate }}">
						</tbc-button>
						<tbc-button *ngIf="(showLoginButton$ | async) === true" class="dashboard_btn" (click)="returnToDashboard()" position="right" [maxWidth]="'100%'"
									text="{{ 'RETURN_TO_DASHBOARD' | translate }}">
						</tbc-button>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
</div>
