import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators';
import { GenericResponse } from 'src/ng-app/common/entities/generic-response';
import { Router } from '@angular/router';
import { AppConfiguraitonService } from '../../services/app-configuration.service';

export function customTranslateLoaderFactory(http: HttpClient, router: Router, appConfigurationService: AppConfiguraitonService) {
    return new CustomTranslateHttpLoader(http, router, appConfigurationService);
}

class CustomTranslateHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient, private router: Router, private appConfigurationService: AppConfiguraitonService) { }

    public getTranslation(lang: string): Observable<any> {
        return new Observable(observer => {
            this.http.get<GenericResponse<any>>(`${this.appConfigurationService.baseAddress}/Localization/translations?code=${lang}`, {observe: 'response'})
                .pipe(timeout(this.appConfigurationService.timoutDuration * 1000))
                .pipe(tap(res => {
                    if (!res?.body?.isSuccess) {
                        this.navigateToMaintenance(observer, res?.body?.data);
                    }
                }))
                .pipe(map(data => this.transformTranslates(data?.body)))
                .subscribe(response => {
                        observer.next(response);
                    }, _ => {
                        this.navigateToMaintenance(observer);
                    }
                );
        });
    }

    private navigateToMaintenance(observer, response = {}): void {
        observer.next(response);
        this.router.navigate(["maintenance"]);
    }

    private transformTranslates(response: GenericResponse<Array<{ code: string, value: string }>>): any {
        var result = {};

        if (!response.data || !response.data.length) {
            return result;
        }

        response.data.forEach(item => {
            result[item.code] = item.value;
        });

        return result;
    }
}

