import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'product-control-panel',
  templateUrl: './product-control-panel.component.html',
  styleUrls: ['./product-control-panel.component.scss']
})
export class ProductControlPanelComponent implements OnInit {

  @Input() directory: { productType: string, productId: string };

  public data = {
    ecom: {
      tbc: {
        title: "TBC_CHECKOUT_CONTROL_PANEL_TITLE",
        description: "TBC_CHECKOUT_CONTROL_PANEL_DESCRIPTION",
        buttonText: "TBC_CHECKOUT_CONTROL_PANEL_BUTTON_TEXT",
        address: 'https://ecom.tbcpayments.ge/Account/TbcLogin'
      },
      link: {
        title: "TBC_LINK_CONTROL_PANEL_TITLE",
        description: "TBC_LINK_CONTROL_PANEL_DESCRIPTION",
        buttonText: "TBC_LINK_CONTROL_PANEL_BUTTON_TEXT",
        address: 'https://ecom.tbcpayments.ge/Account/TbcLogin'
      },

      geopay: {
        title: "GEOPAY_CONTROL_PANEL_TITLE",
        description: "GEOPAY_CONTROL_PANEL_DESCRIPTION",
        buttonText: "GEOPAY_CONTROL_PANEL_BUTTON_TEXT",
        address: 'https://ecom.tbcpayments.ge/Account/TbcLogin'
      },
    }
  }

  constructor() {
  }

  ngOnInit(): void {

  }

}
