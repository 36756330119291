import { Component, HostListener } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { map, takeWhile, tap } from 'rxjs/operators';
import { SessionTimeoutService } from './session-timeout.service';
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss'],
})
export class SessionTimeoutComponent {
  public id: string = 'timeout-modal';
  public initCount: number = 59;
  public count: number;

  restartDelay = 30 * 1000;
  lastRestartTime: number;
  private timeoutInterval: Subscription = new Subscription();

  constructor(
    private sessionTimeoutService: SessionTimeoutService,
    private authService: AuthenticationService
  ) {
    this.sessionTimeoutService.initTimeout();
    this.sessionTimeoutService.userInactive.subscribe(() => {
      if ((Date.now() - this.lastRestartTime) > (this.sessionTimeoutService.timeoutLimit + this.initCount + this.restartDelay)) {
        this.count = 0;
        this.logout();
      } else {
        this.setTimer();
      }
    });
  }

  @HostListener('window:mousemove', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('touchstart', ['$event'])
  userIsActive() {
    if (this.lastRestartTime >= Date.now() - this.restartDelay) {
      return;
    }
    this.sessionTimeoutService.restartTimeout();
    this.lastRestartTime = Date.now();
  }

  public setTimer(): void {
    this.count = this.initCount;
    this.timeoutInterval = interval(1000)
      .pipe(
        map(() => --this.count),
        takeWhile((time) => !!time),
        tap((time) => {
          if (time === 1) {
            this.logout();
          }
        })
      ).subscribe();
  }

  public logout() {
    this.authService.removeUserTokens();
    this.timeIsOut = true;
  }

  public get timeIsOut() {
    return this.sessionTimeoutService.timeIsOut;
  }

  public set timeIsOut(bool: boolean) {
    this.sessionTimeoutService.timeIsOut = bool;
  }
}
