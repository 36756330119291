export const FormStepPosWithFunctions = {
    1: 'Initialize',
    2: 'Terminal'
};

export const FormStepPosWithoutFunctions = FormStepPosWithFunctions;

export const FormForTPos = {
    1: 'TPos',
    2: 'Finalize'
}

export const FormStepGeopay = {
    1: 'Initialize',
    2: 'Company',
    3: 'BusinessActivity'
};

export const FormStepEcom = {
    1: 'Initialize',
    2: 'Payment',
};