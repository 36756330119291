import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDetailsComponent } from './product-details.component';
import { ProductHeaderComponent } from './product-header/product-header.component';
import { ProductCostComponent } from './product-cost/product-cost.component';
import { ProductFunctionsComponent } from './product-functions/product-functions.component';
import { ProductFaqComponent } from './product-faq/product-faq.component';
import { ProductContactComponent } from './product-contact/product-contact.component';
import { ProductStorageComponent } from './product-storage/product-storage.component';
import { ProductHowToComponent } from './product-how-to/product-how-to.component';
import { SharedHeaderModule } from '../shared/shared-header/shared-header.module';
import { GenericContainerModule } from '../shared/generic-container/generic-container.module';
import { SharedModule } from '../../shared/shared.module';
import { FaqModule } from '../faq/faq.module';
import { ContactInfoModule } from '../shared/contact-info/contact-info.module';
import { ProductPartnersComponent } from './product-partners/product-partners.component';
import { TbcButtonModule } from '../shared/tbc-button/tbc-button.module';
import { ProductControlPanelComponent } from './product-control-panel/product-control-panel.component';
import { EcomTbcWebsiteComponent } from './ecom-tbc-website/ecom-tbc-website.component';
import { TooltipModule } from '../shared/tooltip/tooltip.module';
import { LottieModule } from 'ngx-lottie';
import {ProductVideosComponent} from "./product-videos/product-videos.component";
import {CustomSwiperModule} from "../shared/custom-swiper/custom-swiper.module";
import {SwiperModule} from "swiper/angular";
import {VideoModule} from "../shared/video/video.module";
import { ProductDownloadComponent } from './product-download/product-download.component';
import { ShopifyBannerComponent } from './shopify-banner/shopify-banner.component';
import {ProductEnrollComponent} from "./product-enroll/product-enroll.component";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    ProductFaqComponent,
    ProductCostComponent,
    ProductHowToComponent,
    ProductHeaderComponent,
    ProductContactComponent,
    ProductDetailsComponent,
    ProductStorageComponent,
    ProductFunctionsComponent,
    ProductPartnersComponent,
    ProductControlPanelComponent,
    EcomTbcWebsiteComponent,
    ProductVideosComponent,
    ProductDownloadComponent,
    ShopifyBannerComponent,
    ProductEnrollComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedHeaderModule,
    GenericContainerModule,
    FaqModule,
    ContactInfoModule,
    TbcButtonModule,
    TooltipModule,
    LottieModule.forRoot({player: playerFactory}),
    CustomSwiperModule,
    SwiperModule,
    VideoModule,
  ],
  exports: [
    ProductHeaderComponent,
    ProductHowToComponent,
    ProductFunctionsComponent,
    ProductFaqComponent,
    ProductStorageComponent
  ]
})
export class ProductDetailsModule { }
