import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';

@Component({
  selector: 'home-payments',
  templateUrl: './home-payments.component.html',
  styleUrls: ['./home-payments.component.scss']
})
export class HomePaymentsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('paymentsSectionImageWrapper') imageWrapper: ElementRef;
  @ViewChild('paymentsSectionImageCol') imageCol: ElementRef;
  public displayLoader: boolean = false;
  // private destroy$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient,
              private appConfig: AppConfiguraitonService
  ) { }

  ngAfterViewInit(): void {
    // this.bpObserver.observe(['(min-width: 768px)'])
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe((state: BreakpointState) => {
    //       setTimeout(() => {
    //         this.positionImg(state.matches)
    //       }, 200)
    //     })
  }


  ngOnDestroy(): void {
    // this.destroy$.next();
    // this.destroy$.complete();
  }

  // positionImg(state) {
  //   let spaceToRight = 0;
  //   if (state) {
  //     const colPos = this.imageCol.nativeElement.getBoundingClientRect().x;
  //     const imgWidth = this.imageWrapper.nativeElement.getBoundingClientRect().width;
  //     spaceToRight = Math.round(colPos - (imgWidth * 0.823));
  //   }
  //   this.imageWrapper.nativeElement.style.transform = `translateX(${spaceToRight}px)`;
  // }

  initLogin() {
    this.displayLoader = true;
    setTimeout(() => {
      try {
        (<any>window).gtag('event', 'event', {
          eventCategory: 'MainWeb',
          eventAction: 'Landing_login_down',
        });
        (<any>window).fbq('track', 'Landing_login_down')
      } catch (e) {
        console.error('Tracker Error', e)
      }
    })
    window.location.href = `${this.appConfig.portalBaseAddress}/gateway/SSO/init-session`;

    // this.http.post(`${this.appConfig.portalBaseAddress}/Account/InitLogin`, null)
    //   .subscribe((res: { redirectUrl: string }) => {
    //     window.location.href = res.redirectUrl;
    //   }, () => {
    //     this.displayLoader = false;
    //   });
  }
}
