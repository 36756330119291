/**
 * @description sets http cookie, that is acceccable by JavaScript
 * @param name of the cookie
 * @param value of the cookie
 * @param timoutInMinutes expiration time from set time until `timoutInMinutes` expires
 * @param domain
 * @returns void
 */
export function setCookie(name: string, value: string, timoutInMinutes: number = null, domain = null): void {
    var expires: string = "";

    if (timoutInMinutes) {
        var date = new Date();
        date.setTime(date.getTime() + (timoutInMinutes * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = domain ? name + "=" + value + expires + ";" + 'domain='+ domain + "; path=/" : name + "=" + value + expires + "; path=/"

    // document.cookie = name + "=" + value + expires + "; path=/";
}

/**
 * @description get http cookie
 * @param name of the cookie
 * @returns value of cookie as `string` if exists, otherwise empty string `""`
 */
export function getCookie(name: string): string {
    if (document.cookie.length > 0) {
        var cookieStart = document.cookie.indexOf(name + "=");

        if (cookieStart != -1) {
            cookieStart = cookieStart + name.length + 1;

            var cookieEnd = document.cookie.indexOf(";", cookieStart);

            if (cookieEnd == -1) {
                cookieEnd = document.cookie.length;
            }

            return unescape(document.cookie.substring(cookieStart, cookieEnd));
        }
    }

    return "";
}

/**
 * @description deletes http cookie
 * @param name of the cookie
 * @returns void
 */
export function deleteCookie(name: string): void {
    document.cookie = name + '=; Max-Age=-99999999;path=/';
}

export function getDifferenceBetweenDatesInMinutes(date1: Date, date2: Date): number {
    return Math.round((<any>date2 - <any>date1) / 1000);
}
