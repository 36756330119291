export const WorkingSpheres = {
    1: 'MERCHANT_SPHERE',
    2: 'SERVICE_SPHERE',
    3: 'TOURISM_SPHERE',
    6: 'CHARITY_SPHERE'
}

export const WorkingSpheresFieldname = {
    1: 'trade',
    2: 'service',
    3: 'tourism',
    6: 'charity'
}

export const GeopayWorkingSpheres = {
    1: 'TOURISM_SPHERE',
    2: 'HOTEL_SPHERE',
    3: 'AUTO_SPHERE'
}

export const WorkingSpheresFieldnameGeopay = {
    1: 'tourism',
    2: 'hotel',
    3: 'carRental'
}