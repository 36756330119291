<div class="background-wrapper-white">
	<generic-container textAlign="center" titleKey="BEST_OFFERS" bold="first">
		<div class="wrap offers container-fluid">
			<div class="row row1">
				<div class="col col-md-6 col-12">
					<div class="container-fluid">
						<div class="row">
							<div class="image-wrapper">
								<img src="../../../../assets/images/home/offers/persons.svg" [alt]="'TBC_CARD' | translate" />
							</div>
							<div class="col">
								<h3 class="col-header-text h2">
									{{'TBC_CARD' | translate}}
								</h3>
								<p class="col-content-text mb-0">
									{{'TBC_CARD_DESCRIPTION' | translate}}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col col-md-6 col-12">
					<div class="container-fluid">
						<div class="row">
							<div class="image-wrapper">
								<img src="../../../../assets/images/home/offers/card.svg" [alt]="'REDUCED_CHARGE' | translate"/>
							</div>
							<div class="col">
								<h3 class="col-header-text h2">
									{{'REDUCED_CHARGE' | translate}}
								</h3>
								<p class="col-content-text mb-0">
									{{'REDUCED_CHARGE_DESCRIPTION' | translate}}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col col-md-6 col-12">
					<div class="container-fluid">
						<div class="row">
							<div class="image-wrapper">
								<img src="../../../../assets/images/home/offers/stopwatch.svg" [alt]="'FAST_TRANSFER' | translate"/>
							</div>
							<div class="col">
								<h3 class="col-header-text h2">
									{{'FAST_TRANSFER' | translate}}
								</h3>
								<p class="col-content-text mb-0">
									{{'FAST_TRANSFER_DESCRIPTION' | translate}}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col col-md-6 col-12 mb-0">
					<div class="container-fluid">
						<div class="row">
							<div class="image-wrapper">
								<img src="../../../../assets/images/home/offers/shield.svg" loading="lazy" [alt]="'SAFE_SYSTEM' | translate"/>
							</div>
							<div class="col">
								<h3 class="col-header-text h2">
									{{'SAFE_SYSTEM' | translate}}
								</h3>
								<p class="col-content-text mb-0">
									{{'SAFE_SYSTEM_DESCRIPTION' | translate}}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</generic-container>
</div>