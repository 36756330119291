import { Component, OnInit } from '@angular/core';
import { SupportPageService } from './services/support-page.service';
import { GenericResponseData } from '../../common/entities/generic-response-data';
import { FAQResponse } from '../faq/models/faq-response';
import {finalize, map, tap} from 'rxjs/operators';
import {AppConfiguraitonService} from "../../services/app-configuration.service";
import {LabelId} from "../../common/enums/label-id.enum";

@Component({
  selector: 'support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss']
})
export class SupportPageComponent implements OnInit {
  public faqData: GenericResponseData<Array<FAQResponse>>;
  public videosData: any[];
  public videos: any[] = [1, 2, 3, 4];
  public showButtonLoader: boolean = false;
  public defaultFaqTitleKey: string = 'FAQ_HEADER';
  public faqTitleKey: string = this.defaultFaqTitleKey;

  constructor(
      private supportPageService: SupportPageService,
      private appConfigurationService: AppConfiguraitonService
  ) { }

  ngOnInit() {
    this.getFaqs();
    this.getVideos();
  }

  public searchFAQs(key): void {
    this.showButtonLoader = true;
    this.supportPageService.searchFAQs(key).pipe(
      finalize(() => this.showButtonLoader = false)
    ).subscribe(result => {
      this.faqData = result.isSuccess ? result.data : null;
      this.faqTitleKey = null;
    });
  }

  // public getFaqs(): void {
  //   this.supportPageService.getFAQs().subscribe(response => {
  //     response.isSuccess && (
  //       this.faqTitleKey = this.defaultFaqTitleKey,
  //       this.faqData = response.data
  //     )
  //   });
  // }

  public getFaqs(): void {
    this.supportPageService.getFAQs().subscribe(response => {
      if (response.isSuccess) {
        this.faqTitleKey = this.defaultFaqTitleKey;
        this.faqData = response.data;
      }
    });
  }


  public getVideos(labelId: LabelId = undefined): void {
    this.supportPageService.getVideos(labelId)
        .pipe(map(r => {
          return r.videos.map(video => {
            video.path = this.appConfigurationService.baseAddress + video.path
            return video;
          })
        }))
        .subscribe((response: any[]) => {
          this.videosData = response;
            if(this.videosData.length) {
              while (this.videosData.length < 3) {
                this.videosData.push({empty: true})
              }
            }
        })
  }

}
