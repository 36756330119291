<form [formGroup]="formGroup" (submit)="sendForm()">
    <div class="fields-container">
        <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
            <mat-label>{{formFields.count.keyCode | translate}}</mat-label>
            <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering formControlName="count">
                <mat-option [value]="1">{{posQuantity[1]}}</mat-option>
                <mat-option [value]="2">{{posQuantity[2]}}</mat-option>
                <mat-option [value]="3">{{posQuantity[3]}}</mat-option>
                <mat-option [value]="4">{{posQuantity[4]}}</mat-option>
            </mat-select>
            <mat-error *ngIf="count.errors?.required
                && (count.dirty || count.touched)">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="count.errors?.serverError
                && (count.dirty || count.touched)">
                {{count.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
            <mat-label>{{formFields.language.keyCode | translate}}</mat-label>
            <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering formControlName="language">
                <mat-option [value]="1">{{menuLanguages[1] | translate}}</mat-option>
                <mat-option [value]="2">{{menuLanguages[2] | translate}}</mat-option>
                <mat-option [value]="3">{{menuLanguages[3] | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="language.errors?.required
                && (language.dirty || language.touched)">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="language.errors?.serverError
                && (language.dirty || language.touched)">
                {{language.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input
                    type="text"
                    maxlength="5"
                    (keydown)="caretPosition($event)"
                    matInput
                    #dayTimeInput
                    placeholder="{{formFields.dayCloseTime.keyCode | translate}}"
                    formControlName="dayCloseTime">
            <mat-error *ngIf="dayCloseTime.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="!dayCloseTime.errors?.required && ((dayCloseTime.errors?.pattern
                && (dayCloseTime.dirty || dayCloseTime.touched)) || timeError)">
                {{'INCORECT_TIME_FORMAT' | translate}}</mat-error>
            <mat-error *ngIf="dayCloseTime.errors?.serverError
                && (dayCloseTime.dirty || dayCloseTime.touched)">
                {{dayCloseTime.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.nameInLatin.keyCode | translate}}" formControlName="nameInLatin">
            <mat-error *ngIf="nameInLatin.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="nameInLatin.errors?.pattern
                && (nameInLatin.dirty || nameInLatin.touched)">
                {{'ERROR_COMPANY_NAME_EN' | translate}}</mat-error>
            <mat-error *ngIf="nameInLatin.errors?.serverError
                && (nameInLatin.dirty || nameInLatin.touched)">
                {{nameInLatin.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.nameOnReceipt.keyCode | translate}}"
                formControlName="nameOnReceipt">
            <mat-hint>
                {{'IN_ENGLISH_OR_GEORGIAN' | translate}}
            </mat-hint>
            <mat-error *ngIf="nameOnReceipt.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="nameOnReceipt.errors?.pattern
                && (nameOnReceipt.dirty || nameOnReceipt.touched)">
                {{'ERROR_COMPANY_RECEIPT_PATTERN' | translate}}</mat-error>
            <mat-error *ngIf="nameOnReceipt.errors?.serverError
                && (nameOnReceipt.dirty || nameOnReceipt.touched)">
                {{nameOnReceipt.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="posType === 1 || posType === 5" formGroupName="posReportingDetails">
            <p class="heading font-weight-bold mb-3 mt-5">
                {{'CHOOSE_ADDITIONAL_FUNCTIONS' | translate}}
            </p>
            <div class="fields-container row mx-0" style="margin-bottom: 3rem;">
                <mat-checkbox formControlName="thankYou" class="custom-form-check position-relative mb-3 mr-sm-auto">
                    {{formFields.posReportingDetails.thankYou.keyCode | translate}}
                    <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.posReportingDetails.thankYou.keyCode | translate">
                    </tbcds-tooltip>
                </mat-checkbox>
                <mat-checkbox formControlName="payWithLoyalPoints" class="custom-form-check position-relative mb-3">
                    {{formFields.posReportingDetails.payWithLoyalPoints.keyCode | translate}}
                    <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.posReportingDetails.payWithLoyalPoints.keyCode | translate">
                    </tbcds-tooltip>
                </mat-checkbox>
                <mat-checkbox formControlName="preauthorization"
                              class="custom-form-check position-relative mb-3 mr-sm-auto">
                    {{formFields.posReportingDetails.preauthorization.keyCode | translate}}
                    <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.posReportingDetails.preauthorization.keyCode | translate">
                    </tbcds-tooltip>
                </mat-checkbox>
                <mat-checkbox formControlName="refund" class="custom-form-check position-relative mb-3">
                    {{formFields.posReportingDetails.refund.keyCode | translate}}
                    <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.posReportingDetails.refund.keyCode | translate">
                    </tbcds-tooltip>
                </mat-checkbox>
                <mat-checkbox *ngIf="posType == 1" formControlName="dcc" class="custom-form-check position-relative mb-3 mb-sm-0 mr-sm-auto">
                    {{formFields.posReportingDetails.dcc.keyCode | translate}}
                    <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.posReportingDetails.dcc.keyCode | translate">
                    </tbcds-tooltip>
                </mat-checkbox>
            </div>
        </ng-container>
    </div>
    <div class="button-wrap d-flex justify-content-between flex-row-reverse" style="margin-top: 2rem;">
        <tbc-button position="right" class="form-button-width" [maxWidth]="200" [disabled]="false"
                    [showLoader]="displayLoader" [showArrow]="true" text="{{'SEND' | translate}}">
        </tbc-button>
        <tbc-button class="mr-3 form-button-width" [maxWidth]="200" position="left" (click)="goBackEmit()"
                    appearance="outline" type="button" text="{{'GO_BACK' | translate}}">
        </tbc-button>
    </div>
</form>

<re-captcha #captchaRef='reCaptcha' size="invisible" (resolved)="$event && resolved($event)"
            siteKey="6Lf95tMZAAAAAJmvvrZX3Mm1dE3EDq7zAXEt4ymV">
</re-captcha>