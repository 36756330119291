<div class="cookie-policy">
    <div class="cookie-image-container">
        <img class="cookie-image" src="assets/images/cookie.svg" alt="cookies">
        <div class="align-items-center cookie-button-container cookie-btn-2" (click)="agreeCookie()">
            <img class="mr-2 cookie-arrow" src="assets/images/arrow-right.svg" alt="arrow-right">
            <span class="cookie-button">{{'COOKIE_AGREE' | translate}}</span>
        </div>
    </div>
    <span class="cookie-text" (click)="refresh()">
            {{ "COOKIE_MAIN_TEXT" | translate }}
            <a class="cookie-see-more" 
            [href]="'assets/statics/product-details/AddUser_Guide_' + (currentLang == 'ka' ? 'GEO' : 'ENG') + '.pdf'" 
            target="_blank">{{ "COOKIE_SEE_MORE" | translate }}</a>
        </span>
    <div class="align-items-center cookie-button-container cookie-btn-1 ml-auto" (click)="agreeCookie()">
        <img class="mr-2 cookie-arrow" src="assets/images/arrow-right.svg" alt="arrow-right">
        <span class="cookie-button">{{'COOKIE_AGREE' | translate}}</span>
    </div>
</div>
