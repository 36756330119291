<shared-header [showCurves]="true" [detailsPage]="true">

    <div class="wrap tos-header-wrapper container-fluid h-100">
        <div class="row h-100">
            <div class="col mt-auto">
                <h1 class="caps font-weight-medium mb-3">
                    {{ mainTitle['ka'] }}
                </h1>
                <p>
                    {{ mainDesc['ka'] }}
                </p>
            </div>
        </div>
    </div>
</shared-header>

<div class="wrap container-fluid tos-sections">
    <section>
        <h6>{{ whoWeAre['ka'] }}</h6>
        <p *ngFor="let whoWeAreDesc of whoWeAreDesc['ka']">
            {{ whoWeAreDesc }}
        </p>
    </section>

    <section>
        <h6>
            {{ howWeUseYourInformationTitle['ka'] }}
        </h6>
        <p>
            {{ howWeUseYourInformationTitleDesc['ka'] }}
        </p>
    </section>

    <section>
        <h6>
            {{ privacyPromiseTitle['ka'] }}
        </h6>
        <p class="font-weight-medium">
            {{ promiseSubTitle['ka'] }}
        </p>
        <ul>
            <li *ngFor="let promiseDesc of privacyPromiseTitleDesc['ka']">
                {{ promiseDesc }}
            </li>
        </ul>
    </section>

    <section>
        <h6>
            {{ howLawProtectsTitle['ka'] }}
        </h6>
        <p>
            {{ howLawProtectsTitle1['ka'] }}
        </p>
        <p class="font-weight-medium">
            {{ howLawProtectsTitle2['ka'] }}
        </p>
        <ul class="check">
            <li *ngFor="let howLawProtectsDesc of howLawProtectsDesc['ka']">
                {{ howLawProtectsDesc }}
            </li>
        </ul>

        <ng-container *ngFor="let subDesc of howLawProtectsSubDesc['ka']; last as isLast">
            <p [ngClass]="{'font-weight-bold': isLast}"> {{ subDesc }} </p>
        </ng-container>

        <section class="custom-table table-first d-flex">
            <div class="table-padding-left"></div>

            <table>
                <caption></caption>
                <tr class="text-left text-md-center">
                    <th *ngFor="let title of tableOneTitles['ka']">
                        {{ title }}
                    </th>
                </tr>
                <ng-container>
                    <tr *ngFor="let contentRow of tableOne['ka']">
                        <td *ngFor="let contentCell of contentRow">
                            <ul class="table-list">
                                <li *ngFor="let listEl of contentCell">
                                    {{listEl}}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </ng-container>
            </table>

            <div class="table-padding-right"></div>
        </section>
    </section>

    <section>
        <h6>
            {{ personalInformationGroupsTitle['ka'] }}
        </h6>
        <p class="font-weight-bold">
            {{ personalInformationGroupsDesc['ka'] }}
        </p>

        <section class="custom-table table-second desktop">
            <table>
                <caption></caption>
                <tr class="text-center">
                    <th *ngFor="let title of tableTwoTitles['ka']">
                        {{ title }}
                    </th>
                </tr>
                <tr *ngFor="let contentRow of tableTwo['ka']">
                    <td>
                        <p class="m-0 title">
                            {{contentRow[0]}}
                        </p>
                    </td>
                    <td>
                        {{contentRow[1]}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <p class="m-0 title">
                            {{tableTwoLastCol['ka']?.title}}
                        </p>
                    </td>
                    <td>
                        {{tableTwoLastCol['ka']?.desc}}
                        <br>
                        <span class="font-weight-medium">
                            {{tableTwoLastCol['ka']?.descBold}}
                        </span>
                        <br>
                        <br>
                        <ul class="table-list ml-1">
                            <li *ngFor="let listEl of tableTwoLastCol['ka']?.list">
                                {{listEl}}
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </section>

        <section class="custom-table table-second mobile">
            <table>
                <caption></caption>
                <tr>
                    <th>
                        პერსონალური ინფორმაციის ტიპები და აღწერილობა
                    </th>
                </tr>
                <tr *ngFor="let contentRow of tableTwo['ka']">
                    <td>
                        <p class="title">
                            {{contentRow[0]}}
                        </p>
                        {{contentRow[1]}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <p class="title">
                            {{tableTwoLastCol['ka']?.title}}
                        </p>
                        {{tableTwoLastCol['ka']?.desc}}
                        <br>
                        <span class="font-weight-medium">
                            {{tableTwoLastCol['ka']?.descBold}}
                        </span>
                        <br>
                        <br>
                        <ul class="table-list ml-1">
                            <li *ngFor="let listEl of tableTwoLastCol['ka']?.list">
                                {{listEl}}
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </section>
    </section>

    <section>
        <h6>
            რა წყაროებიდან მოვიპოვებთ პერსონალურ ინფორმაციას
        </h6>
        <p>
            თქვენს შესახებ პერსონალური ინფორმაციის მოპოვება შეგვიძლია ბანკისთვის თქვენ მიერ მოწოდებული და ქვემოთ
            ჩამოთვლილი წყაროებიდან.
        </p>
        <p class="font-weight-medium">
            თქვენგან მონაცემებს ვიღებთ შემდეგ შემთხვევებში:
        </p>
        <ul>
            <li>
                როცა მოგვმართავთ ჩვენი პროდუქტებისა თუ მომსახურების მისაღებად;
            </li>
            <li>
                როცა კომუნიკაცია ხორციელდება ტელეფონის საშუალებით ან უშუალოდ ფილიალში ვიზიტის დროს;
            </li>
            <li>
                როცა აგზავნით წერილებს როგორც მატერიალური, ისე ელექტრონული ფორმით.
            </li>
            <li>
                თქვენს მიერ განხორციელებული საბანკო ოპერაციებიდან.
            </li>
        </ul>
    </section>

    <section>
        <h6>
            „მზა ჩანაწერები“ (Cookies)
        </h6>
        <p>
            ჩვენ ვიყენებთ Cookies-ს და ვაკვირდებით ჩვენს ვებ-გვერდზე მომხამრებლების ქცევას რათა დავრწმუნდეთ, რომ
            გთავაზობთ საუკეთესო გამოცდილებას ჩვენს ვებ-გვერდზე ყოფნისას და შევძლოთ მუდმივად გავაუმჯობესოთ მომსახურების
            ხარისხი.
        </p>
        <p class="font-weight-medium">
            ჩვენს ვებ-გვერდზე ვაგროვებთ ინფორმაციას cookies-ების, მომხმარებლების ვებ-გვერდზე ნავიგაციის და ქცევის
            შესახებ, კერძოდ:
        </p>
        <ul class="check">
            <li>
                IP მისამართი, მოწყობილობის ტიპი, საოპერაციო სისტემა და ბრაუზერი საიდანაც ხდება ვებ-გვერდზე შემოსვლა.
            </li>
            <li>
                ვაგროვებთ ინფორმაციას ჩვენს ვებ-გვერდზე გახსნილი გვერდების, სესიის ხანგრძლივობის და სესიის სხვადასხვა
                პარამეტრების შესახებ.
            </li>
            <li>
                ინფორმაცია იმ ქმედებების შესახებ რომელიც ჩვენს ვებ-გვერდზე მოხდა: ფორმების შევსება, ვებ-გვერდის
                ინტერაქციულ ელემენტების გამოყენება და ა.შ.
            </li>
            <li>
                ვებ-გვერდზე ველების შევსების პროცესი, დრო და ფორმა
            </li>
        </ul>
    </section>

    <section>
        <h6>
            მოგროვებულ ინფორმაციას ვიყენებთ:
        </h6>
        <ul class="check">
            <li>
                ვებ-გვერდის სტატისტიკის საწარმოებლად პროცესების, ველების და ვებ-გვერდის დიზაინის ოპტიმიზაციის მიზნით.
            </li>
            <li>
                ვებ-გვერდის მომხმარებლების დაცვისა და თაღლითური ქმედებების პრევენციის მიზნით.
            </li>
            <li>
                ფულის გათეთრების, ტერორიზმის დაფინანსებისა და სხვა დანაშაულებრივი ღონისძიებების გამოვლენისა და თავიდან
                აცილების მიზნით;
            </li>
            <li>
                ვებ-გვერდზე მომხმარებელთა ნაკადის კონტროლისა და მარკეტინგული კამპანიების ეფექტურობის შესაფასებლად.
            </li>
            <li>
                მოპოვებული ინფორმაცია საშუალებას გვაძლევს განვახორციელოთ ვებ-გვერდის და მისი კომპონენტების ადაპტირება
                მომხმარებლის საჭიროებების მიხედვით.
            </li>
            <li>
                ასევე cookies ფაილებს ვიყენებთ იმის დასადგენად თუ როგორ ხვდებიან მომხმარებლები ჩვენს ვებ-გვერდზე და
                Google Analytics-ის რეპორტების საწარმოებლად.
            </li>
        </ul>

        <p>
            ვებ-გვერდის გამოყენების დაწყებამდე, თქვენ გაქვთ საშუალება დაეთანხმოთ cookies და მომხმარებელთა ქცევაზე
            დაკვირვების შესახებ შეტყობინებას „თანხმობა“ ღილაკზე დაჭერით. იმ შემთხვევაში თუ არ დაეთანმხებით „ Cookie
            პოლიტიკა “-ს, თუმცა გააგრძელებთ ვებ-გვერდის გამოყენებას, აღნიშნული ქმედება მაინც ჩაითვლება თქვენს თანხმობად
            ზემოდ აღნიშნულ ქმედებაზე
        </p>
        <p>
            ჩვენ არ ვიყენებთ cookie ფაილებს და მომხმარებლის ქცევაზე დაკვირვების შედეგად მიღებულ მონაცემებს პირადი
            ინფორმაციის შეგროვების მიზნით. თუ გსურთ შეზღუდოთ cookie ფაილები ან დაბლოკოთ ისინი ჩვენს ვებ-გვერდზე, ამის
            განხორციელება შეგიძლიათ თქვენი ბრაუზერის პარამეტრების შეცვლით. ამასთან, გთხოვთ გაითვალისწინოთ რომ ჩვენს მიერ
            შემოთავაზებული ზოგიერთი სერვისი არ იფუნქციონირებს თუ დაბლოკავთ ან წაშლით cookie-სფაილებს.
        </p>
    </section>

    <section>
        <h6>
            მესამე პირებისგან მიღებული ინფორმაცია
        </h6>
        <p>
            ჩვენ არ ვიყენებთ cookie ფაილებს და მომხმარებლის ქცევაზე დაკვირვების შედეგად მიღებულ მონაცემებს პირადი
            ინფორმაციის შეგროვების მიზნით. თუ გსურთ შეზღუდოთ cookie ფაილები ან დაბლოკოთ ისინი ჩვენს ვებ-გვერდზე, ამის
            განხორციელება შეგიძლიათ თქვენი ბრაუზერის პარამეტრების შეცვლით. ამასთან, გთხოვთ გაითვალისწინოთ რომ ჩვენს მიერ
            შემოთავაზებული ზოგიერთი სერვისი არ იფუნქციონირებს თუ დაბლოკავთ ან წაშლით cookie-სფაილებს.
        </p>
    </section>

    <section>
        <h6>
            ვის ვუზიარებთ თქვენს პერსონალურ მონაცემებს
        </h6>
        <p class="font-weight-medium">
            თქვენი პერსონალური მონაცემების გაზიარება შეიძლება დაგვჭირდეს საქართველოს კანონმდებლობით განსაზღვრულ
            შემთხვევებში ან სხვა ორგანიზაციებთანაც, რომლებმაც თქვენს მიერ შერჩეული პროდუქტი თუ მომსახურება უნდა
            მოგაწოდონ, მაგალითად:
        </p>
        <ul>
            <li>
                თუ თქვენ გაქვთ ჩვენი კომპანიის სადებეტო, საკრედიტო და ანგარიშსწორების ბარათები, დეტალურ ინფორმაციას
                თქვენი ტრანზაქციის შესახებ მივაწვდით იმ კომპანიებს, რომლებიც გვეხმარებიან ამ მომსახურების მიწოდებაში
                (როგორებიცაა მაგალითად, Visa და MasterCard).
            </li>
            <li>
                თუ თქვენ დაზღვევას ჩვენი მეშვეობით ახორციელებთ, ჩვენ შესაძლოა თქვენი პერსონალური თუ ბიზნეს მონაცემები
                გადავცეთ სადაზღვევო კომპანიას და ნებისმიერ გადამზღვევს.
            </li>
            <li>
                ჩვენ ასევე შესაძლოა გავაზიაროთ თქვენი პერსონალური მონაცემები იმ შემთხვევაში, თუ ბანკის სტრუქტურა
                მომავალში შეიცვლება, მაგალითად: ჩვენ შესაძლოა გავყიდოთ, გადავცეთ, ან მოვახდინოთ ბიზნესის, ან აქტივების
                ნაწილის შერწყმა.
            </li>
            <li>
                რომელიმე ზემოხსენებული პროცესის განხორციელების შემთხვევაში, თქვენი ინფორმაცია შესაძლოა გავუზიაროთ სხვა
                მხარეებს. თუმცა, ასეთი ინფორმაციის გაზიარებამდე ხსენებულმა მხარეებმა სავალდებულო წესით უნდა განაცხადონ
                თანხმობა, შეინახონ თქვენი მონაცემები უსაფრთხოდ და კონფიდენციალურად.
            </li>
            <li>
                ჩვენი ჯგუფის სტრუქტურის შეცვლის შემთხვევაში, სხვა მხარეებმა შესაძლოა თქვენი მონაცემები გამოიყენონ მხოლოდ
                ისე და იმ ფარგლებში, როგორც ეს წინამდებარე დოკუმენტით და კანონმდებლობით არის რეგულირებული.
            </li>
        </ul>
    </section>

    <section>
        <h6>
            როგორ ვიყენებთ თქვენს ინფორმაციას ავტომატური გადაწყვეტილებების მიღების პროცესში
        </h6>
        <p>
            ზოგჯერ ავტომატური გადაწყვეტილებების მისაღებად ვიყენებთ ჩვენს ხელთ არსებულ ან ისეთ პერსონალურ მონაცემებს,
            რომელთა სხვა პირებისგან მიღების უფლებამოსილებაც გაგვაჩნია კანონმდებლობით ან თქვენთან გაფორმებული
            ხელშეკრულებიდან გამომდინარე. აღნიშნული ჩვენ გვეხმარება იმის უზრუნველყოფაში, რომ ჩვენი გადაწყვეტილებები იყოს
            სწრაფი, სამართლიანი და ეფექტური. ამგვარი ავტომატური გადაწყვეტილებები გავლენას ახდენს პროდუქტებისა და
            მომსახურების ხარისხზე რომლებსაც ჩვენ გთავაზობთ ახლა ან შემოგთავაზებთ მომავალში.
        </p>
    </section>

    <section>
        <h6 style="color: #273441;">
            ქვემოთ მოცემულია ჩვენს მიერ მიღებული ავტომატური გადაწყვეტილებების ტიპები :
        </h6>
    </section>

    <section>
        <h6>
            ფასწარმოქმნა
        </h6>
        <p>
            ჩვენ ვიღებთ გადაწყვეტილებას ზოგიერთი პროდუქტისა და მომსახურების ფასთან დაკავშირებით ჩვენს ხელთ არსებული
            ინფორმაციის საფუძველზე.
        </p>
    </section>

    <section>
        <h6>
            პროდუქტებისა და მომსახურების კლიენტების საჭიროებებზე მორგება
        </h6>
        <p>
            ჩვენ ვახდენთ ჩვენი კლიენტების სეგმენტიზაციას ჯგუფებად. ასეთ ჯგუფებს კლიენტთა სეგმენტებს ვუწოდებთ, რომელთაც
            თქვენი საჭიროებების შესასწავლად ვიყენებთ და მიღებული ცოდნის საფუძველზე, ვცდილობთ თქვენთვის სასურველი და
            ხელსაყრელი გადაწყვეტილებების მიღებას. ეს პროცესი ჩვენ გვეხმარება კლიენტთა სხვადასხვა სეგმენტისათვის
            რელევანტური პროდუქტებისა და მომსახურებების შექმნასა და მათთან ურთიერთობების დარეგულირებაში.
        </p>
    </section>

    <section>
        <h6>
            თაღლითობის გამოვლენა
        </h6>
        <p>
            თქვენი პერსონალური მონაცემები გვეხმარება, დავადგინოთ, შესაძლოა თუ არა თქვენი ანგარიშები გამოყენებულ იქნას
            თაღლითობის თუ ფულის გათეთრების მიზნებისათვის. ჩვენ მიერ თაღლითობის რისკის დაფიქსირების შემთხვევაში, უფლებას
            ვიტოვებთ, თქვენივე უსაფრთხოებისთვის დროებით შევაჩეროთ ტრანზაქციები საეჭვო ანგარიშებზე ან უარი განვაცხდოთ
            შესაბამისი მომსახურების გაწევაზე.
        </p>
    </section>

    <section>
        <h6>
            ანგარიშების გახსნა
        </h6>
        <p>
            თუ თქვენ გადაწყვეტთ ჩვენთან ანგარიშის გახსნას, ჩვენ, ჩვენს ხელთ არსებული ინფორმაციის საფუძველზე შევამოწმებთ
            კონკრეტული პროდუქტის ან/და მომსახურების რელევანტურობას თქვენთან მიმართებაში. ასევე უზრუნველვყოფთ იმას, რომ
            თქვენ აკმაყოფილებდეთ ანგარიშის გასახსნელად საჭირო პირობებს.
        </p>

        <p class="font-weight-medium">
            ამ ინფორმაციას გამოვიყენებთ, რათა:
        </p>
        <p>
            ჩვენ უფლებას ვიტოვებთ, კანონით მკაცრად განსაზღვრულ შემთხვევებში სამართალდამცავ ორგანოებს მივცეთ წვდომა
            თქვენს პირად მონაცემებზე სამსახურეობრივი უფლებამოსილების შესრულების, მათ შორის დანაშაულის დადგენის,
            გამოძიების და აღკვეთის მიზნით.
        </p>
        <ul>
            <li>
                დავადასტუროთ თქვენ მიერ მოწოდებული ინფორმაციის სისწორე
            </li>
            <li>
                ხელი შევუწყოთ ფინანსური დანაშაულის დადგენასა და აღკვეთას
            </li>
        </ul>
        <p>
            თუ არ ისურვებთ პირადი ინფორმაციის გაცემას, შესაძლოა, შეფერხდეს ან შეუძლებელი გახდეს ჩვენ მიერ თქვენს წინაშე
            ნაკისრი ვალდებულებების შესრულება, მათ შორის ანგარიშის მომსახურება ან შესაბამისი პროცედურები.
        </p>
    </section>

    <section>
        <h6>
            მარკეტინგი
        </h6>
        <p>
            ჩვენ შეგვიძლია გამოვიყენოთ თქვენი პირადი მონაცემები თქვენთვის კონკრეტული პროდუქტების და შეთავაზებების
            გასაცნობად.
        </p>
        <p>
            ჩვენ წვდომა გვაქვს თქვენს პირად მონაცემებზე იმ ფარგლებში, რასაც თავად გვიზიარებთ, ან ჩვენ ვაგროვებთ
            თქვენთვის მომსახურების გაწევის პროცესში ჩვენს ხელთ არსებული საშუალებებით
        </p>
        <p>
            ჩვენ შევისწავლით თქვენს მონაცემებს, რათა გავიგოთ, რა გჭირდებათ, რა გაინტერესებთ, ან რა სურვილები შეიძლება
            გქონდეთ. ასე ვწყვეტთ, რომელი პროდუქტი, მომსახურება თუ შეთავაზება შეიძლება იყოს თქვენთვის შესაფერისი.
        </p>
        <p>
            თქვენ უფლება გაქვთ ნებისმიერ დროს დაგვიკავშირდეთ და გვთხოვოთ მარკეტინგული შეტყობინებების გამოგზავნის
            შეწყვეტა. ჩვენ პატივს ვცემთ თქვენს სურვილებს და ასეთი შეტყობინების მიღებისთანავე შევწყვეტთ თქვენი პირადი
            მონაცემების გამოყენებას პირდაპირი მარკეტინგის მიზნებისათვის.
        </p>
        <p>
            ჩვენთვის მნიშვნელოვანია თქვენი უსაფრთხოება და ინტერესები, რის გამოც თქვენ ნებისმიერ შემთხვევაში მიიღებთ
            შეტყობინებებს თქვენთვის შემოთავაზებულ პროდუქტებსა და მომსახურებების პირობებში ბანკის მიერ განხორციელებული
            ცვლილებების შესახებ.
        </p>
    </section>

    <section>
        <h6>
            რამდენ ხანს ვინახავთ პერსონალურ ინფორმაციას?
        </h6>
        <p class="font-weight-medium">
            თქვენს პირად მონაცემებს ვინახავთ თქვენი მომსახურების მთელი პერიოდის განმავლობაში; აგრეთვე, მომსახურების
            დასრულების შემდეგ 15 წლამდე ვადით შემდეგი მიზეზების გამო:
        </p>
        <ul>
            <li>
                კითხვებსა და საჩივრებზე პასუხის გასაცემად
            </li>
            <li>
                იმის დასამტკიცებლად, რომ ჩვენ სამართლიანად მოგექეცით
            </li>
            <li>
                შესაბამისი რეგულაციების/წესების დაცვით აღრიცხვის წარმოების მიზნით
            </li>
        </ul>
        <p>
            ჩვენ შეგვიძლია შევინახოთ თქვენი პირადი ინფორმაცია 15 წელზე მეტი ხნით, თუ მისი წაშლის შესაძლებლობა არ გვაქვს
            სამართლებრივი ან ნორმატიული მიზეზების გამო.
        </p>
        <p>
            თუ ფიქრობთ, რომ ჩვენთან შენახული თქვენი ნებისმიერი მონაცემი არასწორი ან არასრულია, გთხოვთ დაგვიკავშირდით
            თქვენი ეჭვების დასაფიქსირებლად. ჩვენ ყველა გონივრულ ზომას მივიღებთ, რომ შევამოწმოთ და გამოვასწოროთ ეს
            ხარვეზი.
        </p>
        <p>
            დაგვიკავშირდით, თუ არ ეთანხმებით ჩვენ მიერ თქვენი მონაცემების გამოყენებას, თუ გაქვთ შეკითხვები ან თუ გნებავთ
            თქვენი მონაცემების გაუქმება ან გამოყენების შეზღუდვა.
        </p>
    </section>
</div>