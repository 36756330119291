import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AppConfiguraitonService {
    private _baseAddress: string;
    private _portalBaseAddress: string;
    private _authBaseAddress: string;
    private _timoutDuration: number;

    constructor(private httpClient: HttpClient) { }

    public get baseAddress(): string {
        return this._baseAddress;
    }

    public get portalBaseAddress(): string {
        return this._portalBaseAddress;
    }

    public get authBaseAddress(): string {
        return this._authBaseAddress;
    }

    public get timoutDuration(): number {
        return this._timoutDuration;
    }

    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.httpClient.get("/app-settings.json", {
                headers: {
                    'Cache-Control': 'no-cache',
                    "Pragma": 'no-cache'
                }
            })
                .subscribe((response: any) => {
                    this._baseAddress = response.EndPointBaseAddress;
                    this._timoutDuration = response.TimeoutDuration;
                    this._portalBaseAddress = response.PortalBaseAddress;
                    const authBase = response.AuthBaseAddress;
                    authBase && (this._authBaseAddress = authBase);

                    resolve();
                });
        })
    }

    public getHostName(): string {
        return window.location.hostname.includes('tbcbank.ge') ? 'tbcbank.ge' : 'tbcpayments.ge';
    }
}

