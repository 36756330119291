<div class="wrapper d-flex h-100">
    <div class="left">
        <img src="../../../../../assets/images/call.svg" [alt]="'CALL_CONTACT_US_TITLE' | translate">
    </div>
    <div class="right d-flex flex-column col px-0">
        <h3 class="font-weight-bold">{{'CALL_CONTACT_US_TITLE' | translate}}</h3>
        <p class=" mb-2 mb-md-4">{{'CALL_CONTACT_US_DESC' | translate}}</p>
        <div class="go-to mt-auto">
            <a class="link font-weight-medium" [routerLink]="route || '/call/support/0'">
                {{'CALL_CONTACT_US_LINK' | translate}}
            </a>
        </div>
    </div>
</div>