import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@tbc-common/dialog';
import { HomeComponent } from './home.component';
import { SharedModule } from '../../shared/shared.module';
import { HomeCategoriesComponent } from './home-categories/home-categories.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { HomeExperienceComponent } from './home-experience/home-experience.component';
import { HomeOffersComponent } from './home-offers/home-offers.component';
import { HomeSuccessStoriesComponent } from './home-success-stories/home-success-stories.component';
import { GenericContainerModule } from '../shared/generic-container/generic-container.module';
import { UserStoryService } from './services/user-story.service';
import { HomeUserStoryComponent } from './home-success-stories/home-user-story.component/home-user-story.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LocalizationService } from '../../services/localization.service';
import { TbcButtonModule } from '../shared/tbc-button/tbc-button.module';
import { HomePaymentsComponent } from './home-payments/home-payments.component';
import { LottieModule } from 'ngx-lottie';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    declarations: [
        HomeComponent,
        HomeCategoriesComponent,
        HomeBannerComponent,
        HomeExperienceComponent,
        HomeOffersComponent,
        HomeSuccessStoriesComponent,
        HomeUserStoryComponent,
        HomePaymentsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        GenericContainerModule,
        DialogModule,
        MatDialogModule,
        TbcButtonModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
    exports: [
        HomeComponent
    ],
    providers: [
        UserStoryService,
        LocalizationService
    ]
})
export class HomeModule { }
