<section class="call-text">
    <h1 class="caps font-weight-bold text-center mb-4" style="color: #F00" *ngIf="validationSummary">
        {{validationSummary | translate}}</h1>
    <h1 class="caps font-weight-bold text-center mb-4" *ngIf="!validationSummary">
        {{'WE_WILL_CALL_BACK' | translate}}</h1>
    <p class="call-desc font-weight-medium mb-4">
        {{'CALL_ME_DESC' | translate}}
    </p>
</section>
<section class="mb-4">
    <form [formGroup]="formGroup" (submit)="submit()">
        <div class="fields-container">
            <div *ngIf="fromMymarket">
                <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
                    <mat-label>{{'COMPANY_TYPE' | translate}}</mat-label>
                    <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering
                        (selectionChange)="companyTypeChosen($event.value)" formControlName="type">
                        <mat-option [value]="1">{{companyTypes[1] | translate}}</mat-option>
                        <mat-option [value]="2">{{companyTypes[2] | translate}}</mat-option>
                        <mat-option [value]="3">{{companyTypes[3] | translate}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="type.errors?.required 
                    && (type.dirty || type.touched)">
                        {{'ERROR_REQUIRED' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="identityNumberKeyCode" class="tbcds-input" hideRequiredMarker>
                    <input matInput placeholder="{{identityNumberKeyCode | translate}}"
                        formControlName="identityNumber">
                    <mat-error *ngIf="identityNumber.errors?.required
                        && !identityNumber.errors?.NumbersOnly">
                        {{'ERROR_REQUIRED' | translate}}</mat-error>
                    <mat-error *ngIf="identityNumber.errors?.invalidCharacters
                        && (identityNumber.dirty || identityNumber.touched)">
                        {{'ERROR_INVALID_CHARACTERS' | translate}}</mat-error>
                    <mat-error *ngIf="identityNumber.errors?.minlength
                        && !identityNumber.errors?.invalidCharacters">
                        {{"MIN_LENGTH_ID_NUMBER_5" | translate}}</mat-error>
                    <mat-error *ngIf="identityNumber.errors?.maxlength
                        && !identityNumber.errors?.invalidCharacters">
                        {{"MAX_LENGTH_ID_NUMBER_50" | translate}}</mat-error>


                    <mat-error *ngIf="identityNumber.errors?.NumbersOnly
                        && (identityNumber.dirty || identityNumber.touched)">
                        {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
                    <mat-error *ngIf="identityNumber.errors?.Length
                        && (identityNumber.dirty || identityNumber.touched)
                        && !identityNumber.errors?.NumbersOnly">
                        {{identityNumberErrorCode | translate}}</mat-error>

                    <mat-error *ngIf="identityNumber.errors?.serverError
                        && (identityNumber.dirty || identityNumber.touched)">
                        {{identityNumber.errors?.serverError | translate}}</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{'FIRST_LAST_NAME' | translate}}" formControlName="name" >
                <mat-error *ngIf="name.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="name.errors?.pattern
                    && (name.dirty || name.touched)">
                    {{'INCORRECT_FORMAT' | translate}}</mat-error>
                <mat-error *ngIf="name.errors?.serverError
                    && (name.dirty || name.touched)">
                    {{name.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{'PHONE' | translate}}" formControlName="phoneNumber">
                <mat-error *ngIf="phoneNumber.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="phoneNumber.errors?.maxlength">
                    {{'PHONE_NUMBER_MAX_LENGTH_25' | translate}}</mat-error>
                <mat-error *ngIf="phoneNumber.errors?.phoneNumberInvalid && phoneNumber.value.length > 0 && !phoneNumber.errors?.maxlength">
                    {{'ERROR_PHONE_NUMBER' | translate}}</mat-error>
                <mat-error *ngIf="phoneNumber.errors?.serverError
                    && (phoneNumber.dirty || phoneNumber.touched)">
                    {{phoneNumber.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="button-wrap d-flex justify-content-between flex-row-reverse"
            style="margin-top: calc(1rem + .4375em);">
            <tbc-button [showLoader]="formSubmitted" [showArrow]="true" [disabled]="false"
                [maxWidth]="true" text="{{'SEND' | translate}}">
            </tbc-button>
        </div>
    </form>
</section>
<p class="call-desc font-weight-medium text-center m-0">
    {{'YOUR_INFO_WILL_BE_SECURE' | translate}}
</p>
<re-captcha #captchaRef='reCaptcha' size="invisible" (resolved)="$event && resolvedRecaptcha($event)"
            siteKey="6Lf95tMZAAAAAJmvvrZX3Mm1dE3EDq7zAXEt4ymV">
</re-captcha>