import {Injectable} from "@angular/core";
import {CookieService} from "./cookie.service";
import {AppConfiguraitonService} from "./app-configuration.service";

@Injectable()
export class ActiveTabService {
    public interval;
    private domain = this.configurationService.getHostName();

    constructor(
        private cookie: CookieService,
        private configurationService: AppConfiguraitonService
    ) {
    }

    setTabAsActive() {
        if (this.cookie.getCookie('active-tab')) {
            this.cookie.deleteCookie('active-tab', this.domain)
        }
        this.cookie.setCookie('active-tab', 'offering', 1, this.domain)
    }

    removeTab() {
        this.cookie.deleteCookie('active-tab', this.domain)
    }

    isTabActive(): boolean {
        return this.cookie.getCookie('active-tab') === 'offering'
    }

    checkTabs(): void {
        this.interval = setInterval(() => {
            let activeTab = this.cookie.getCookie('active-tab')
            if (!activeTab) {
                this.cookie.setCookie('active-tab', 'offering', 1, this.domain)
            }
        }, 3000)
    }
}
