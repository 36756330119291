import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericContainerComponent } from './generic-container.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [GenericContainerComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    GenericContainerComponent
  ]
})
export class GenericContainerModule { }
