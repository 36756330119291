import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: 'product-storage',
	templateUrl: './product-storage.component.html',
	styleUrls: ['./product-storage.component.scss']
})
export class ProductStorageComponent implements OnChanges, OnDestroy {
	@Input() directory: { productType: string, productId: string };
	public currentData;
	public currentLang: string;
	public destroy$: Subject<void> = new Subject<void>();

	public expandStorage: boolean = false;

	public data = {
		space: {
			default: {
				data: [
					{
						title: 'SPACE_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/AddUser_Guide.pdf',
						downloadUrlGeo: '/assets/statics/product-details/AddUser_Guide.pdf'
					},
					{
						title: 'SPACE_STORAGE_TITLE_3',
						downloadUrlEng: '/assets/statics/product-details/PermissionManagement_Guide.pdf',
						downloadUrlGeo: '/assets/statics/product-details/PermissionManagement_Guide.pdf'
					},
					{
						title: 'SPACE_STORAGE_TITLE_2',
						downloadUrlEng: '/assets/statics/product-details/TransactionsExport_Guide.pdf',
						downloadUrlGeo: '/assets/statics/product-details/TransactionsExport_Guide.pdf'
					}
				]
			}
		},
		pos: {
			standard: {
				data: [
					{
						title: 'POS_STANDARD_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/ENG_pos-service-rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_pos-service-rules.pdf'
					},
					{
						title: 'POS_STANDARD_STORAGE_TITLE_2',
						downloadUrlEng: 'https://www.tbcbank.ge/web/documents/10184/0/terminal+booklet+2018%2C31%2C01.pdf/11dad67d-10a1-4e04-a633-58435f8dad39',
						downloadUrlGeo: 'https://www.tbcbank.ge/web/documents/10184/0/terminal+booklet+2018%2C31%2C01.pdf/11dad67d-10a1-4e04-a633-58435f8dad39'
					},
					{
						title: 'POS_STANDARD_STORAGE_TITLE_3',
						downloadUrlEng: '/assets/statics/product-details/Ingenico_IWL250.pdf',
						downloadUrlGeo: '/assets/statics/product-details/Ingenico_IWL250.pdf'
					},
					{
						title: 'POS_STANDARD_STORAGE_TITLE_4',
						downloadUrlEng: '/assets/statics/product-details/Ingenico_Move2500.pdf',
						downloadUrlGeo: '/assets/statics/product-details/Ingenico_Move2500.pdf'
					},
					{
						title: 'POS_STANDARD_STORAGE_TITLE_5',
						downloadUrlEng: '/assets/statics/product-details/VeriFone_VX680_standard.pdf',
						downloadUrlGeo: '/assets/statics/product-details/VeriFone_VX680_standard.pdf'
					}
				]
			},
			integrated: {
				data: [
					{
						title: 'POS_INTEGRATED_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/ENG_pos-service-rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_pos-service-rules.pdf',
					}
				]
			},
			cashier: {
				data: [
					{
						title: 'POS_CASHIER_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/pos-service-rules-cashier.pdf',
						downloadUrlGeo: '/assets/statics/product-details/pos-service-rules-cashier.pdf',
					},
					{
						title: 'POS_CASHIER_STORAGE_TITLE_2',
						downloadUrlEng: '/assets/statics/product-details/pos-cashier-manual.pdf',
						downloadUrlGeo: '/assets/statics/product-details/pos-cashier-manual.pdf'
					},
					{
						title: 'POS_CASHIER_STORAGE_TITLE_3',
						downloadUrlEng: '/assets/statics/product-details/Ingenico_Move_2500.pdf',
						downloadUrlGeo: '/assets/statics/product-details/Ingenico_Move_2500.pdf'
					},
					{
						title: 'POS_CASHIER_STORAGE_TITLE_4',
						downloadUrlEng: '/assets/statics/product-details/PAX_S920.pdf',
						downloadUrlGeo: '/assets/statics/product-details/PAX_S920.pdf'
					},
					{
						title: 'POS_CASHIER_STORAGE_TITLE_5',
						downloadUrlEng: '/assets/statics/product-details/Verifone_v240m.pdf',
						downloadUrlGeo: '/assets/statics/product-details/Verifone_v240m.pdf'
					},
					{
						title: 'POS_CASHIER_STORAGE_TITLE_6',
						downloadUrlEng: '/assets/statics/product-details/Verifone_VX680.pdf',
						downloadUrlGeo: '/assets/statics/product-details/Verifone_VX680.pdf'
					},
				]
			},
			self: {
				data: [
					{
						title: 'POS_SELF_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/ENG_pos-service-rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_pos-service-rules.pdf'
					}
				]
			},
			android: {
				data: [
					{
						title: 'POS_ANDROID_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/ENG_pos-service-rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_pos-service-rules.pdf'
					},
					{
						title: 'POS_ANDROID_STORAGE_TITLE_2',
						downloadUrlEng: '/assets/statics/product-details/Sunmi_P2.pdf',
						downloadUrlGeo: '/assets/statics/product-details/Sunmi_P2.pdf'
					}
				]
			},
			qrhoreca: {
				data: []
			},
			tpos: {
				data: [
					{
						title: 'POS_TPOS_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/tpos_triplet.pdf',
						downloadUrlGeo: '/assets/statics/product-details/tpos_triplet.pdf'
					},
					{
						title: 'POS_TPOS_STORAGE_TITLE_2',
						downloadUrlEng: '/assets/statics/product-details/ENG_pos-service-rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_pos-service-rules.pdf'
					}
				]
			}
		},
		ecom: {
			link: {
				data: [
					{
						title: 'ECOM_LINK_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/ENG_ecom_service_rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_ecom_service_rules.pdf'
					},
					{
						title: 'ECOM_LINK_STORAGE_TITLE_2',
						downloadUrlEng: '/assets/statics/product-details/ecom_pay_by_link_manual.pdf',
						downloadUrlGeo: '/assets/statics/product-details/ecom_pay_by_link_manual.pdf',
					},
					{
						title: 'ECOM_LINK_STORAGE_TITLE_3',
						downloadUrlEng: '/assets/statics/product-details/ENG_change_ecom_service_rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_change_ecom_service_rules.pdf',
					}
				]
			},
			geopay: {
				data: [
					{
						title: 'ECOM_GEOPAY_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/ENG_ecom_service_rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_ecom_service_rules.pdf',
					},
					{
						title: 'ECOM_GEOPAY_STORAGE_TITLE_5',
						downloadUrlEng: '/assets/statics/product-details/ENG_change_ecom_service_rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_change_ecom_service_rules.pdf',
					}
				]
			},
			tbc: {
				data: [
					{
						title: 'ECOM_TPAY_STORAGE_TITLE_1',
						downloadUrlEng: '/assets/statics/product-details/ENG_ecom_service_rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_ecom_service_rules.pdf',
					},
					{
						title: 'ECOM_TPAY_STORAGE_TITLE_2',
						downloadUrlEng: '/assets/statics/product-details/TBC-E-commerce-Cs-cart-ENG.pdf',
						downloadUrlGeo: '/assets/statics/product-details/TBC-E-commerce-Cs-cart.pdf',
					},
					{
						title: 'ECOM_TPAY_STORAGE_TITLE_7',
						downloadUrlEng: '/assets/statics/product-details/ENG_change_ecom_service_rules.pdf',
						downloadUrlGeo: '/assets/statics/product-details/GEO_change_ecom_service_rules.pdf',
					},
					{
						title: 'ECOM_TPAY_STORAGE_TITLE_3',
						downloadUrlEng: '/assets/statics/product-details/TBC-E-commerce-Merchant-API-ENG.pdf',
						downloadUrlGeo: '/assets/statics/product-details/TBC-E-commerce-Merchant-API.pdf',
					},
					{
						title: 'ECOM_TPAY_STORAGE_TITLE_4',
						downloadUrlEng: '/assets/statics/product-details/TBC-E-commerce-WooCommerce-ENG.pdf',
						downloadUrlGeo: '/assets/statics/product-details/TBC-E-commerce-WooCommerce.pdf',
					},
					{
						title: 'ECOM_TPAY_STORAGE_TITLE_5',
						downloadUrlEng: '/assets/statics/product-details/TBC-E-commerce-B2C.GE-ENG.pdf',
						downloadUrlGeo: '/assets/statics/product-details/TBC-E-commerce-B2C.GE.pdf',
					},
					{
						title: 'ECOM_TPAY_STORAGE_TITLE_6',
						downloadUrlEng: '/assets/statics/product-details/TBC-E-commerce-kOVZY.pdf',
						downloadUrlGeo: '/assets/statics/product-details/TBC-E-commerce-kOVZY.pdf',
					},

				]
			}
		}
	}

	constructor(private translateService: TranslateService) {
		this.currentLang = this.translateService.currentLang;
		this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(lang => this.currentLang = lang.lang)
	}

	ngOnChanges(): void {
		this.currentData = this.data[this.directory.productType][this.directory.productId]?.data;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public triggerExpand(): void {
		this.expandStorage = !this.expandStorage;
	}
}
