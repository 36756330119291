import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'generic-container',
	templateUrl: './generic-container.component.html',
	styleUrls: ['./generic-container.component.scss']
})
export class GenericContainerComponent {
	@Input() title: string;
	@Input() titleKey: string;
	@Input() divider: boolean;
	@Input() noSpacer: boolean;
	@Input() textAlign: boolean;
	@Input() bold: 'first' | 'last';

	// public boldTitle: string[] = [];

	// constructor(private translateService: TranslateService) { }

	// ngOnChanges(): void {
	// 	if (this.titleKey) {
	// 		this.translateService.get(this.titleKey)
	// 			.subscribe(title => {
	// 				const composedTitle = title.split(' ');
	// 				const firstWord = composedTitle.shift();
	// 				const lastWord = composedTitle.pop();
	// 				const midContent = composedTitle.join(' ');
	// 				this.boldTitle.push(`<span class='font-weight-medium'>${firstWord ? firstWord : ''}</span> ${midContent ? midContent : ''} ${lastWord ? lastWord : ''}`);
	// 				this.boldTitle.push(`${firstWord ? firstWord : ''} ${midContent ? midContent : ''} <span class='font-weight-medium'>${lastWord ? lastWord : ''}</span>`);
	// 				this.boldTitle.push(`${firstWord ? firstWord : ''} ${midContent ? midContent : ''} ${lastWord ? lastWord : ''}`);
	// 			})
	// 	} else {
	// 		this.boldTitle = [];
	// 	}
	// }
}
