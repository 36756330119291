<div class="background-wrapper-white">
    <generic-container textAlign="center" bold="last" titleKey='ALL_KINDS_OF_BUSINESSES'>
        <div class="wrap container-fluid p-0">

            <div class="row" style="margin-bottom: 100px">
                <div class="col-md-5 col-12 p-0">
                    <div class="category-image pos">
                        <img src="../../../../assets/images/home/categories/POS.webp"
                            style="width: 100%;display:block" [alt]="'HOME_CATEGORIES_POS_TERMINALS_TITLE' | translate" />
                    </div>
                </div>
                <div class="col-md-7 col-12 vertical-center top">
                    <div class="category-text">
                        <h3 class="font-weight-bold mb-4 h2">{{'HOME_CATEGORIES_POS_TERMINALS_TITLE' | translate}}</h3>
                        <p class="mb-5">
                            {{'POS_TERMINALS_DESCRIPTION' | translate}}
                        </p>
                        <tbc-button routerLink="/pos" text="{{'FIND_MORE' | translate}}"></tbc-button>
                    </div>
                </div>
            </div>

            <div class="wrap row flex-row-reverse" style="margin-bottom: 100px">
                <div class="col-md-5 col-12 p-0">
                    <div class="category-image pl-xl-0 pl-4">
                        <div class="bg-wrapper">
                            <img src="../../../../assets/images/home/categories/bg.webp" [alt]="'EL_COMMERCE' | translate">
                        </div>
                        <div class="image-wrapper">
                            <img src="../../../../assets/images/home/categories/dots-matrix.webp" class="matrix" [alt]="'EL_COMMERCE' | translate">
                            <img src="../../../../assets/images/home/categories/laptop.webp" class="laptop" [alt]="'EL_COMMERCE' | translate">
                        </div>
                    </div>
                </div>
                <div class="col-md-7 col-12 vertical-center p-0">
                    <div class="mr-md-5 pr-md-5 category-text">
                        <h3 class="font-weight-bold mb-4 h2">{{'EL_COMMERCE' | translate}}</h3>
                        <p class="mb-5">
                            {{'EL_COMMERCE_DESCRIPTION' | translate}}
                        </p>
                        <tbc-button routerLink="/ecom" text="{{'FIND_MORE' | translate}}"></tbc-button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5 col-12 p-0">
                    <div class="category-image pos">
                        <img src="../../../../assets/images/home/categories/tpay.webp"
                            style="display:block" [alt]="'HOME_CATEGORIES_TPAY_TITLE' | translate"/>
                    </div>
                </div>
                <div class="col-md-7 col-12 vertical-center top">
                    <div class="category-text">
                        <h3 class="font-weight-bold mb-4 h2">{{'HOME_CATEGORIES_TPAY_TITLE' | translate}}</h3>
                        <p class="mb-5">
                            {{'HOME_CATEGORIES_TPAY_DESCRIPTION' | translate}}
                        </p>
                        <a target="_blank" href="https://tbcpay.ge/for-business">
                            <tbc-button text="{{'FIND_MORE' | translate}}"></tbc-button>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </generic-container>
</div>