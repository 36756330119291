import { FormForTPos } from '../enums/form-steps';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderProductFormService } from '../../services/order-product-form.service';
import {
  FormStepEcom,
  FormStepPosWithFunctions,
  FormStepPosWithoutFunctions,
  FormStepGeopay
} from '../enums/form-steps';
import { WorkingSpheresFieldname, WorkingSpheresFieldnameGeopay } from '../enums/working-spheres';
import { FilesService } from "../../../shared/file-uploader/services/files.service";
import { Observable } from "rxjs";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-steps-container',
  templateUrl: './steps-container.component.html',
  styleUrls: ['./steps-container.component.scss']
})
export class StepsContainerComponent implements OnInit {
  public formSteps;
  public currentStep;
  public formTitle;
  public product;
  public productChild;
  public formFieldData;
  public formSubmitted: boolean = false;
  public validationSummary: string;
  public formStepSubmit;
  public isGeoPay: boolean;
  public orderLeadId: string = null;
  public serverErrorData: any[] = [];
  private _response: any;
  private _stepToGo: number;
  private _section: string;
  @ViewChild('captchaRef') reCaptcha;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private orderProductFormService: OrderProductFormService,
    private filesService: FilesService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe(params => {
      if (params.product && params.id) {
        this.product = params.product;
        this.productChild = +params.id;
        ({ stepData: this.formSteps, formData: this.formFieldData }
          = this.orderProductFormService.getFormData(this.product, this.productChild));
        this.isGeoPay = (this.product === 'ecom' && this.productChild === 2);
        this.filesService.progressInfos = [];
        this.stepChanged(1);
      }

      this.activatedRoute.params
        .subscribe(params => {
          if (params.step) {
            this.currentStep = +params.step;
            this.formTitle = this.formSteps[this.currentStep - 1].formTitle;
            this.changeDetectorRef.detectChanges();
          }
        });
    });
  }


  public stepChanged(step: number): void {
    this.router.navigate([this.formSteps[step - 1].route],
      { relativeTo: this.activatedRoute.parent, replaceUrl: true });
  }

  public stepperStepChanged(step: number): void {
    if (this.formSteps[step - 1].valid) {
      if (this.formStepSubmit !== this.currentStep) {
        this.formStepSubmit = {
          currentStep: this.currentStep,
          stepToGo: step
        };
        this.changeDetectorRef.detectChanges();
      }
    }

    if (this.product === "pos" && this.currentStep === 5 || this.currentStep > step) {
      this.stepChanged(step);
    }

    if (this.product === "ecom" && this.currentStep === 6 || this.currentStep > step) {
      this.stepChanged(step);
    }
  }

  onSphereChange({ formFields, section }: any) {
    Object.keys(formFields).forEach(formField => {
      this.formFieldData[section][formField].value = formFields[formField];
    });
  }

  public nextStep({ formFields, section, stepToGo, nextStep }: any): void {
    this._section = section;
    Object.keys(formFields).forEach(formField => {
      this.formFieldData[section][formField].value = formFields[formField];
    });

    let formValues = JSON.parse(sessionStorage.getItem('formValues')) || {}
    formValues[section] = formFields
    sessionStorage.setItem('formValues', JSON.stringify(formValues))
    let lead = { ...formFields };

    if (this.product === 'pos') {
      lead = this.checkPosFields(lead, formFields);
    } else if (this.product === 'ecom') {
      this.isGeoPay ? lead = this.checkGeopayFields(lead) :
        lead = this.checkEcomFields(lead);
    }

    if (!nextStep) return;
    this.sendStep(lead, stepToGo);
  }


  public sendFormFinal(data): void {
    if (this.product === 'pos') {
      data = this.productChild !== 6 && this.productChild !== 8 ? { ...data, posApplicationId: this.orderLeadId } : { ...data }
      this.sendStep(data);
    } else if (this.product === 'ecom') {
      let lead = this.checkEcomFields({ applicationId: this.orderLeadId, ...data });
      if (this.isGeoPay) {
        lead = this.checkGeopayFields({ ...data });
      }
      this.sendStep(lead);
    }
  }


  public sendStep(lead: any, stepToGo?: number): void {
    this.formSubmitted = true;
    this.validationSummary = null;
    this.serverErrorData = [];

    let formLead = null;

    try {
      if (this.product === 'pos') {
        if (this.productChild === 6) {
          formLead = this.sendTPosLead(lead);
        } else if (this.productChild === 8) {
          formLead = this.sendTQrHorecaLead(lead);
        } else {
          formLead = this.sendPosLead(lead);
        }
      } else if (this.product === 'ecom') {
        this.isGeoPay ? formLead = this.sendGeopayLead(lead) : formLead = this.sendEcomLead(lead);
      }
      formLead?.subscribe(response => {
        this.handleLeadResponse(response, stepToGo);
      }, () => {
        this.errorReceived();
      });
    }
    catch {
      this.errorReceived();
    }
  }

  public sendEcomLead(ecomLead: any) {
    return this.orderProductFormService.sendEcomLead(FormStepEcom[this.currentStep], ecomLead);
  }

  public sendGeopayLead(formLead: any) {
    return this.orderProductFormService.sendGeopayLead(FormStepGeopay[this.currentStep], formLead);
  }

  public sendPosLead(posLead: any) {
    const steps = (this.productChild === 1 || this.productChild === 5) ? FormStepPosWithFunctions : FormStepPosWithoutFunctions;
    return this.orderProductFormService.sendPosLead(steps[this.currentStep], posLead);
  }

  public sendTPosLead(tPosLead): Observable<any> {
    return this.orderProductFormService.sendTPosLead(FormForTPos[this.currentStep], tPosLead);
  }

  public sendTQrHorecaLead(tPosLead): Observable<any> {
    return this.orderProductFormService.sendTPosLead('Horeca', tPosLead);
  }

  public recaptchaResolved(captchaResponse: string): void {
    let lead;
    if (this.product === 'pos') {
      lead = { posApplicationId: this.orderLeadId, recaptchaToken: captchaResponse };
      let posRequest: Observable<any>;
      if (this.productChild === 6) {
        posRequest = this.orderProductFormService.TPosFinalize('Finalize', lead)
      } else if(this.productChild === 8) {
        posRequest = this.orderProductFormService.QrHorecaFinalize('Finalize', lead)
      } else {
        posRequest = this.orderProductFormService.sendPosLead('Finalize', lead)
      }
      posRequest.subscribe(res => {
        if (res.isSuccess) {
          this._handleSuccess(res, this._stepToGo);
        } else {
          this._handleError(res);
        }
      })
    } else if (this.product === 'ecom') {
      lead = { applicationId: this.orderLeadId, recaptchaToken: captchaResponse };
      let callback: Observable<any>;
      if (this.productChild === 2) {
        callback = this.orderProductFormService.sendGeopayLead('Finalize', lead);
      } else {
        callback = this.orderProductFormService.sendEcomLead('Finalize', lead)
      }
      callback.subscribe(res => {
        if (res.isSuccess) {
          this._handleSuccess(this._response, this._stepToGo);
            sessionStorage.setItem('formValues', null)

        } else {
          this._handleError(res);
        }
      })
    }
    this.reCaptcha.reset();
  }

  public handleLeadResponse(response, stepToGo?: number) {
    this._response = response;
    this._stepToGo = stepToGo;
    if (response.isSuccess || response?.id) {
      if (response?.id) {
        this.orderLeadId = response.id;
      }

      if (this.formSteps.length - 1 === this.currentStep) {
        if(!location.hostname.includes('tbcbank')) {
          this.reCaptcha.execute();
        } else {
          this.recaptchaResolved("");
        }
      } else {
        this._handleSuccess(response, stepToGo);
      }
    } else {
      this._handleError(response);
    }
  }

  private _handleSuccess(response, stepToGo?: number): void {
    this.formStepSubmit = null;
    this.stepChanged(stepToGo || this.currentStep + 1);
    this.formSteps[this.currentStep].valid = this.formSteps[this.currentStep - 1].valid = true;
    this.formSubmitted = false;
    response.data && response.data.id && (this.orderLeadId = response.data.id);
    this.changeDetectorRef.detectChanges();
  }

  private _handleError(response): void {
    if (response.errors) {
      for (var propName in response.errors) {
        this.serverErrorData.push(
          {
            fieldName: propName,
            serverError: { serverError: response.errors[propName] ? response.errors[propName][0] : 'FIELD_IS_INVALID' }
          }
        );
      }

      this.serverErrorData = this.serverErrorData.slice();
      this.formSubmitted = false;
      this.changeDetectorRef.detectChanges();
    } else {
      this.errorReceived();
    }
  }

  public errorReceived(): void {
    this.validationSummary = "GENERAL_ERROR";
    this.formSubmitted = false;
    this.changeDetectorRef.detectChanges();
  }

  public checkPosFields(posLead: any, formFields: any): any {
    if (this.currentStep === 1) {
      posLead[`posType`] = this.productChild;
      posLead[`posApplicationId`] = this.orderLeadId;
    } else if (this.currentStep === 2) {
      posLead = {
        posApplicationId: this.orderLeadId,
        director: {
          fullName: formFields[`companyDirectorFullName`],
          identityNumber: formFields[`companyDirectorIdentityNumber`],
          phoneNumber: formFields[`companyDirectorPhoneNumber`]
        },
        contact: {
          fullname: formFields[`contactPersonFullname`],
          phoneNumber: formFields[`contactPersonPhoneNumber`]
        },
        recipient: {
          fullName: formFields[`recipientFullName`],
          phoneNumber: formFields[`recipientPhone`],
          identityNumber: formFields[`recipientIdentityNumber`]
        }
      }
    } else {
      posLead[`posApplicationId`] = this.orderLeadId;
    }

    return posLead;
  }

  public checkEcomFields(ecomLead: any): any {
    if (this.currentStep === 1) {
      ecomLead = {
        eComApplicationId: this.orderLeadId,
        eComType: this.productChild,
        ...ecomLead
      }
    } else if (this.currentStep === 3) {
      ecomLead = {
        eComApplicationId: this.orderLeadId,
        [WorkingSpheresFieldname[ecomLead.fieldOfActivity]]: ecomLead
      }
      if (ecomLead.additionalCurrency) {
        ecomLead = { ...ecomLead, additionalCurrency: [...ecomLead.additionalCurrency].reduce((acc, curr) => acc + curr) }
      }

      if(!this.isGeoPay) {
        if (ecomLead.fieldOfActivityDescription !== undefined) {
          ecomLead['fieldOfActivityDescription'] = ecomLead.fieldOfActivityDescription;
        }
        delete ecomLead[WorkingSpheresFieldname[ecomLead.fieldOfActivity]].fieldOfActivityDescription;
      }
    } else if (this.currentStep === 4) {
      ecomLead[`eComApplicationId`] = this.orderLeadId;
    }

    return ecomLead;
  }

  public checkGeopayFields(formLead: any): any {
    if (this.currentStep === 1) {
      formLead = {
        geopayApplicationId: this.orderLeadId,
        ...formLead
      }
    } else if (this.currentStep === 3) {
      formLead = {
        geopayApplicationId: this.orderLeadId,
        fieldOfActivity: formLead.fieldOfActivity,
        recaptchaToken: formLead.recaptchaToken,
        [WorkingSpheresFieldnameGeopay[formLead.fieldOfActivity]]: formLead
      }

      delete formLead[WorkingSpheresFieldnameGeopay[formLead.fieldOfActivity]].fieldOfActivity;
      delete formLead[WorkingSpheresFieldnameGeopay[formLead.fieldOfActivity]].recaptchaToken;
    } else {
      formLead[`geopayApplicationId`] = this.orderLeadId;
    }

    return formLead;
  }

}
