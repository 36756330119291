<generic-container divider="true" titleKey="HOW_TO_BUY" textAlign="left">
    <div class="container-fluid above">
        <div class="row">
            <div class="col-12 col-md-4 pr-0 pl-0 mb-4 mb-md-0">
                <!-- <online-shopping
                    [ngStyle]="productId === 'tpos' ? {'pointer-events': 'none', 'user-select': 'none', 'opacity': '0.5'} : {}"
                        [link]="productId === 'tpos' ? undefined : orderUrl"></online-shopping> -->
                <online-shopping
                        [link]="orderUrl"></online-shopping>
            </div>
            <div class="col-12 col-md-4 pr-0 pl-0 mb-4 mb-md-0 pl-md-5">
                <contact-us></contact-us>
            </div>
            <div class="col-12 col-md-4 pr-0 pl-0 pl-md-5">
                <call [route]="callUrl"></call>
            </div>
        </div>
    </div>
</generic-container>