import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Constants } from '../../../common/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { CallRequestService } from '../services/call-request.service';
import { CompanyTypes } from '../../order-product/request-form/enums/company-types';
import { NumbersOnlyAndLength, PhoneNumberValidator } from '../../shared/validators/number-only.validator'
import { NumbersLettersAndDash } from "../../shared/validators/letters-and-numbers.validator";


@Component({
  selector: 'call-form',
  templateUrl: './call-form.component.html',
  styleUrls: ['./call-form.component.scss']
})
export class CallFormComponent implements OnInit {
  public companyTypes = CompanyTypes;

  public formGroup: UntypedFormGroup;
  public formSubmitted: boolean;
  public validationSummary: string;

  public fromMymarket: boolean;
  public leadType: number;

  public identityNumberErrorCode: string;
  public identityNumberKeyCode: string;
  public mymarketUid: string;
  @ViewChild('captchaRef') reCaptcha;

  public idData = {
    "person": {
      keyCode: 'PERSONAL_ID',
      errorCode: 'QUANTITY_11'
    },
    "company": {
      keyCode: 'IDENTIFICATION_CODE',
      errorCode: 'QUANTITY_9'
    },
    "else": {
      keyCode: 'IDENTIFICATION_CODE',
      errorCode: 'QUANTITY_9_11'
    }
  };

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private callRequestService: CallRequestService
  ) {
  }

  ngOnInit(): void {
    this.formGroup = this.buildForm();
    const params = this.activatedRoute.snapshot.params;
    this.setType(params);
    if (params.product === "mypos") {
      this.fromMymarket = true;
      this.addMymarketFields();
      this.getMymarketUid();
    }
  }

  private getMymarketUid() {
    this.callRequestService
      .getMymarketUid(document.referrer)
      .subscribe(response => {
        if (response.isSuccess) {
          this.mymarketUid = response.data.uId;
        } else {
          this.errorReceived();
        }
      }, () => {
        this.errorReceived();
      });
  }

  public companyTypeChosen(option: number) {
    let idDataAccessor = '';

    this.identityNumber.patchValue('');
    this.identityNumber.markAsUntouched();

    if (option === 1) {
      this.identityNumber.setValidators(
        [
          NumbersOnlyAndLength([9, 11]),
          Validators.required
        ]
      );
      idDataAccessor = 'person';
    } else if (option === 2) {
      this.identityNumber.setValidators([
        NumbersOnlyAndLength([9]),
        Validators.required
      ]);
      idDataAccessor = 'company';
    } else if (option === 3) {
      this.identityNumber.setValidators([
        NumbersLettersAndDash(),
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.required
      ]);
      idDataAccessor = 'else';
    }

    this.identityNumberErrorCode = this.idData[idDataAccessor].errorCode;
    this.identityNumberKeyCode = this.idData[idDataAccessor].keyCode;
  }

  public submit() {
    if (this.formGroup.status === 'VALID') {
      this.formSubmitted = true;
      this.validationSummary = null;
      this.reCaptcha.execute();
    }
  }

  resolvedRecaptcha(captchaResponse: string) {
    this.fromMymarket ? this.submitMymarket(captchaResponse) :
      this.submitRegular(captchaResponse);
    this.reCaptcha.reset();
  }

  public submitRegular(captchaResponse: string) {
    this.callRequestService.sendCallLead(
      { ...this.formGroup.value, type: this.leadType, recaptchaToken: captchaResponse }
    ).subscribe(response => {
      if (response.isSuccess) {
        this.router.navigate(['success'], { relativeTo: this.activatedRoute.parent });
        this.formSubmitted = false;
      } else {
        this.errorReceived();
      }
    }, () => {
      this.errorReceived();
    });
  }

  public submitMymarket(captchaResponse: string) {
    this.callRequestService.sendMymarkeCallLead(
      { ...this.formGroup.value, uId: this.mymarketUid, recaptchaToken: captchaResponse }
    ).subscribe(response => {
      if (response.isSuccess) {
        this.router.navigate(['success'], { relativeTo: this.activatedRoute.parent });
        this.formSubmitted = false;
      } else {
        this.errorReceived();
      }
    }, () => {
      this.errorReceived();
    });
  }

  public errorReceived(): void {
    this.validationSummary = "GENERAL_ERROR";
    this.formSubmitted = false;
  }

  private setType(params): void {
    let type = 0;

    switch (params.product) {
      case "ecom":
        type += 100;
        break;
      case "pos":
        type += 200;
        break;
    }

    params.id && (type += +params.id);

    if (params.id === '5') {
      type = 206;
    }

    if (params.product === "space") {
      type = 205;
    }

    if (params.id === '6') {
      type = 207;
    }

    this.leadType = type;
  }

  private addMymarketFields() {
    this.formGroup.addControl('type', new UntypedFormControl('', [
      Validators.required]));
    this.formGroup.addControl('identityNumber', new UntypedFormControl('', [
      Validators.required
    ]));
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: new UntypedFormControl('', [
        Validators.pattern(new RegExp(Constants.RegexPatterns["multiple-words"])),
        Validators.required,
      ]),
      phoneNumber: new UntypedFormControl('', [
        PhoneNumberValidator(),
        Validators.required
      ])
    });
  }

  public get type(): AbstractControl {
    return this.formGroup.get("type");
  }

  public get identityNumber(): AbstractControl {
    return this.formGroup.get("identityNumber");
  }

  public get name(): AbstractControl {
    return this.formGroup.get("name");
  }

  public get phoneNumber(): AbstractControl {
    return this.formGroup.get("phoneNumber");
  }

}
