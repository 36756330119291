import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../faq/services/faq.service';
import { FAQResponse } from '../../faq/models/faq-response';
import { LabelId } from '../../../common/enums/label-id.enum';

@Component({
	selector: 'pos-faq',
	templateUrl: './pos-faq.component.html'
})
export class PosFaqComponent implements OnInit {
	public faqs: Array<FAQResponse>;

	constructor(private faqService: FaqService) { }

	ngOnInit() {
		this.getFaqs();
	}

	private getFaqs(): void {
		this.faqService.getFAQs(LabelId.Pos)
			.subscribe(response => {
				if (response.isSuccess) {
					this.faqs = response.data;
				}
			})
	}
}
