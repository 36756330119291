import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyTosComponent } from './privacy-tos.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RouterModule, Router } from '@angular/router';
import { privacyTOSRoutes } from './routing/privacy-tos.routing'
import { SharedHeaderModule } from '../shared/shared-header/shared-header.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { customTranslateLoaderFactory } from '../../core/loaders/translate.loader';
import { AppConfiguraitonService } from '../../services/app-configuration.service';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    PrivacyTosComponent,
    TosComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(privacyTOSRoutes),
    SharedHeaderModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoaderFactory,
        deps: [HttpClient, Router, AppConfiguraitonService]
      }
    }),
  ]
})
export class PrivacyTosModule { }
