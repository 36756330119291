import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosComponent } from './pos.component';
import { PosHeaderComponent } from './pos-header/pos-header.component';
import { PosProductsComponent } from './pos-products/pos-products.component';
import { SharedModule } from '../../shared/shared.module';
import { PosCostComponent } from './pos-cost/pos-cost.component';
import { PaymentProductCostModule } from '../shared/payment-product-cost/payment-product-cost.module';
import { PosContactComponent } from './pos-contact/pos-contact.component';
import { PosFaqComponent } from './pos-faq/pos-faq.component';
import { FaqModule } from '../faq/faq.module';
import { ContactInfoModule } from '../shared/contact-info/contact-info.module';
import { SharedHeaderModule } from '../shared/shared-header/shared-header.module';
import { ProductInfoModule } from '../shared/product-info/product-info.module';
import { GenericContainerModule } from '../shared/generic-container/generic-container.module';
import { ProductInfoService } from '../shared/product-info/services/product-info.service';
import { PosProductTableComponent } from './pos-cost/pos-product-table/pos-product-table.component';
import { PosTableService } from './pos-cost/pos-table.service'
import {PosVideosComponent} from "./pos-videos/pos-videos.component";
import {CustomSwiperModule} from "../shared/custom-swiper/custom-swiper.module";
import {SwiperModule} from "swiper/angular";
import {VideoModule} from "../shared/video/video.module";

@NgModule({
	declarations: [
		PosComponent,
		PosHeaderComponent,
		PosProductsComponent,
		PosCostComponent,
		PosContactComponent,
		PosFaqComponent,
		PosProductTableComponent,
		PosVideosComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		PaymentProductCostModule,
		FaqModule,
		ContactInfoModule,
		SharedHeaderModule,
		ProductInfoModule,
		GenericContainerModule,
		CustomSwiperModule,
		SwiperModule,
		VideoModule
	],
	providers: [
		ProductInfoService,
		PosTableService
	]
})
export class PosModule { }
