<ng-container *ngIf="dropdown; else buttons">
    <div class="lang-custom-dropdown" [ngClass]="{'shown': show}" (click)="showDropdown()" #langDropdown>
        <div class="thumbnail" [ngClass]="{'expanded': show}">
            <span>{{languageOptions[oppositeLanguage === 'ka' ? 'en' : 'ka'].value}}</span>
        </div>
        <div class="body" #itemsBody>
            <div class="items">
                <div class="item" [ngClass]="{'active': oppositeLanguage !== languageOptions.ka.key}"
                     (click)="onChange.emit(languageOptions.ka.key)">
                    <span>{{languageOptions.ka.value}}</span>
                </div>
                <div class="item" [ngClass]="{'active': oppositeLanguage !== languageOptions.en.key}"
                     (click)="onChange.emit(languageOptions.en.key)">
                    <span>{{languageOptions.en.value}}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #buttons>
    <div class="d-flex justify-content-between align-items-center w-100">
        <div>
            <span class="lang-label">{{'WEBPAGE_LANGUAGE' | translate}}</span>
        </div>
        <div class="lang-buttons">
            <button class="lang-button"
                    [ngClass]="{'active': oppositeLanguage !== languageOptions.ka.key}"
                    (click)="onChange.emit(languageOptions.ka.key)"
            ><span>{{languageOptions.ka.value}}</span></button>
            <button class="lang-button"
                    [ngClass]="{'active': oppositeLanguage !== languageOptions.en.key}"
                    (click)="onChange.emit(languageOptions.en.key)"
            ><span>{{languageOptions.en.value}}</span></button>
        </div>
    </div>
</ng-template>