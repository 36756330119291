import { ProductDetailsModule } from './../product-details/product-details.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalLandingComponent } from './portal-landing.component';
import { SharedModule } from '../../shared/shared.module';
import { PortalCategoriesComponent } from './portal-categories/portal-categories.component';
import { GenericContainerModule } from '../shared/generic-container/generic-container.module';



@NgModule({
  declarations: [PortalLandingComponent, PortalCategoriesComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProductDetailsModule,
    GenericContainerModule,
  ]
})
export class PortalLandingModule { }
