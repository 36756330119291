import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
  Input, OnDestroy
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { FAQResponse } from '../../faq/models/faq-response';
import { GenericResponseData } from '../../../common/entities/generic-response-data';

@Component({
  selector: "search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"]
})
export class SearchBarComponent implements OnDestroy {
  public searchInput: string;
  public showSearch: boolean = true;
  public searchResults: GenericResponseData<Array<FAQResponse>>;
  public inputPlaceholder: string = '';
  public READY_TO_HELP: string[];
  public inputFocus: boolean = false;
  public typingTimer = null;
  public placeholderStore: string;
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild("searchBar") searchBar: ElementRef;

  @Input() showButtonLoader: boolean;
  @Output("onSearch") searchEmitter: EventEmitter<string> = new EventEmitter();
  @Output("onClear") clearEmitter: EventEmitter<null> = new EventEmitter();

  @HostListener('window:resize')
  public onResize(): void {
    this.translateInputPlaceholderText();
  }

  constructor(private translateService: TranslateService) {
    this.onResize();
    this.translateReadyToHelpText();
    this.translateService.onLangChange
        .pipe(takeUntil(this.destroy$))
        .subscribe(_ => {
          this.translateReadyToHelpText();
          this.translateInputPlaceholderText();
        })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private translateInputPlaceholderText(): void {
    this.translateService.get(window.innerWidth > 768 ? 'SEARCH_WHATEVER_YOU_WANT' : 'SEARCH')
        .subscribe(text => {
          this.placeholderStore = text;
          this.inputPlaceholder = this.placeholderStore;
        });
  }

  private translateReadyToHelpText(): void {
    this.translateService.get('READY_TO_HELP')
        .pipe(takeUntil(this.destroy$))
        .subscribe(text => {
          this.READY_TO_HELP = text.split(' ');
        });
  }

  // public filter(): void {
  //   this.searchInput ? (
  //       this.showSearch = false,
  //           this.searchEmitter.emit(this.searchInput)
  //   ) : this.clearEmitter.emit();
  // }

  public filter(): void {
    if (this.searchInput) {
      this.showSearch = false;
      this.searchEmitter.emit(this.searchInput);
    } else {
      this.clearEmitter.emit();
    }
  }

  public clear(): void {
    this.searchInput = "";
    this.showSearch = true;
    this.clearEmitter.emit();
  }

  public keyTriggered(key: KeyboardEvent): void {
    this.showSearch = true;
    this.typingTimer && clearTimeout(this.typingTimer);
    key.keyCode === 13 || !this.searchInput ? this.filter() : this.typingTimer = setTimeout(this.filter.bind(this), 500);
  }

  public focusOnInput(): void {
    this.searchBar.nativeElement.focus();
  }

  public onFocus() {
    this.inputFocus = true;
    this.inputPlaceholder = "";
  }

  public onBlur() {
    this.inputFocus = false;
    this.inputPlaceholder = this.placeholderStore;
  }

}
