import {
	Component,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	OnDestroy,
	ChangeDetectorRef,
	OnInit
} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {animate, style, transition, trigger} from "@angular/animations";
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
	selector: 'footer-products',
	templateUrl: './footer-products.component.html',
	styleUrls: ['./footer-products.component.scss'],
	animations: [
		trigger('expand', [
			transition(':enter',[
				style({maxHeight: 0}),
				animate('500ms cubic-bezier(.4, 0, .1, 1)', style({maxHeight: '300px'}))
			]),
			transition(':leave',
				animate('500ms cubic-bezier(.4, 0, .1, 1)', style({height: '0'}))
			)

		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterProductsComponent implements OnInit, OnDestroy {

	public currentLang: string = '';
	private destroy$: Subject<void> = new Subject<void>();
	public isMobile

	@ViewChild("pos") pos: ElementRef;
	@ViewChild("ecom") ecom: ElementRef;

	public navigations = {
		pos: true,
		ecom: true
	}

	constructor(
		private translate: TranslateService,
		private bpObserver: BreakpointObserver,
		private cdr: ChangeDetectorRef) {
		this.currentLang = translate.currentLang;
		this.translate.onLangChange
			.pipe(takeUntil(this.destroy$))
			.subscribe(lang => {
				this.currentLang = lang.lang;
			})
	}

	ngOnInit() {
		this.bpObserver.observe(['(max-width: 767px)'])
			.pipe(takeUntil(this.destroy$))
			.subscribe(state => {
				if(state.breakpoints['(max-width: 767px)']) {
					this.navigations.ecom = false;
					this.navigations.pos = false;
				} else {
					this.navigations.ecom = true;
					this.navigations.pos = true;
				}

				this.cdr.detectChanges();

			})
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public openMenu(targetName: string): void {
		if (window.innerWidth <= 768) {
			this.navigations[targetName] = !this.navigations[targetName]
		}
	}

	public openPrivacyPolicy(url): void {
		window.open(url, '_blank');
	}
}
