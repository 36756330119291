import { Component, Input } from '@angular/core';

@Component({
  selector: 'tbc-button',
  templateUrl: './tbc-button.component.html',
  host: {
    '[class.tbc-button-max-width]': 'maxWidth',
    '[class.tbc-button-position-left]': "position === 'left'",
    '[class.tbc-button-position-right]': "position === 'right'",
    '[class.tbc-button-disabled]': "showLoader"
  },
  styleUrls: ['./tbc-button.component.scss']
})
export class TbcButtonComponent {
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() showLoader: boolean;
  @Input() showArrow: boolean;
  @Input() maxWidth: any;
  @Input() type: string;
  @Input() appearance: string;
  @Input() position: string;
  @Input() small: boolean;
}
