import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AppConfiguraitonService } from "../../services/app-configuration.service";

@Component({
    selector: 'app-auth',
    templateUrl: './test-auth.component.html',
    styleUrls: ['./test-auth.component.scss'],
})
export class TestAuthComponent implements OnInit, OnDestroy {

    public destroy$: Subject<void> = new Subject<void>();

    private userId: string;
    private URL: string = `${this.appConfig.portalBaseAddress}/Account/TestInitLogin`;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private appConfig: AppConfiguraitonService,
    ) {
        this.route.paramMap.subscribe((p:ParamMap) => {
            this.userId = p.get('userId');

            if(this.userId) {
                this.URL += `/${this.userId}`;
            }
        })
    }

    ngOnInit(): void {
        this.http.post(this.URL, {})
            .pipe(takeUntil(this.destroy$))
            .subscribe((url: {redirectUrl: string}) => window.location.href = url.redirectUrl)
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
