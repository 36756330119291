<generic-container textAlign="left" [titleKey]='currentData.title'>
    <div class="row wrap justify-center justify-md-between">
        <div class="col-12 col-md-6 px-0 pr-md-5 mb-5 mb-md-0 left">
            <div class="content">
                <h2 class="font-weight-bold">
                    {{currentData.left_title | translate}}
                </h2>
                <p class="mb-3">
                    {{currentData.left_description | translate}}
                </p>

                <div>
                    <div class="partner-wrap d-inline-block" *ngFor="let icon of currentData.icon_data">
                        <a *ngIf="icon.name !== 'shopify'" href="{{icon.link}}" target="_blank">
                            <img [ngStyle]="{height : icon.height && icon.height + 'px'}" class="mt-1"
                                src="{{icon.img}}" [alt]="currentData.left_title | translate">
                        </a>
                        <span *ngIf="icon.name === 'shopify'"><img src="../../../../assets/images/product-details/partners/shopify_logo.svg" alt=""></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 px-0 pl-md-5 right">
            <div class="content">
                <h2 class="font-weight-bold">
                    {{currentData.right_title | translate}}
                </h2>
                <p class="mb-3 mb-sm-4">
                    {{currentData.right_description | translate}}
                </p>
            </div>
            <div class="d-flex justify-content-center justify-content-md-start" *ngIf="currentData.button_url">
                <a href="{{currentData.button_url}}" target="_blank">
                    <tbc-button text="{{ 'REDIRECT_TO_PORTAL' | translate }}" [showArrow]="true" [small]="true"
                        appearance="outline-blue">
                    </tbc-button>
                </a>
            </div>
        </div>
    </div>
</generic-container>