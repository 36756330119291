import { ChooseProductParentComponent } from '../choose-product-parent/choose-product-parent.component';
import { Routes } from '@angular/router';
import { OrderProductComponent } from '../order-product.component';
import { ChooseProductComponent } from '../choose-product/choose-product.component';
import { OrderSuccessComponent } from '../order-success/order-success.component';
import { RequestFormComponent } from '../request-form/request-form.component';
import { OrderProductFormGuard } from '../guards/order-product-form.guard';
import { StepsContainerComponent } from '../request-form/steps-container/steps-container.component';

export const orderProductRoutes: Routes = [
    {
        path: '',
        component: OrderProductComponent,
        children: [
            {
                path: 'success',
                component: OrderSuccessComponent
            },
            {
                path: '',
                component: ChooseProductParentComponent
            },
            {
                path: ':product',
                component: ChooseProductComponent
            },
            {
                path: ':product/:id',
                component: RequestFormComponent,
                canActivate: [OrderProductFormGuard],
                children: [
                    {
                        path: ':step',
                        component: StepsContainerComponent
                    }
                ]
            }
        ]
    }
]