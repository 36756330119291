<generic-container textAlign="left" bold="last" titleKey="PRODUCT_DETAIL_TARIFFS">
    <div *ngIf="this.directory.productType === 'ecom'" class="d-flex mb-2">
        <p class="type mx-2 pb-2 position-relative caps font-weight-medium"
            [ngClass]="tableType === 'tariff' && 'active'" (click)="setTable('tariff')">
            {{'CURRENT_TARIFFS' | translate}}
        </p>
        <p class="type mx-2 pb-2 position-relative caps font-weight-medium"
            [ngClass]="tableType === 'limit' && 'active'" (click)="setTable('limit')">
            {{'LIMIT' | translate}}
        </p>
    </div>
    <div class="container-fluid overflow-hidden">
        <div *ngIf="this.directory.productType === 'ecom' && this.directory.productId === 'tbc' && tableType === 'tariff'" class="d-flex justify-content-end align-items-end">
            <div [@tableCell]=" toggleAnimation" class="align-self-end font-weight-bold d-flex p-0">
                <p class="d-flex justify-content-center p-0" style="width: 130px; padding: 0 !important;">{{ 'OTHER_PLUGINS' | translate }}</p>  <p class="d-flex justify-content-center p-0"  style="width: 130px; padding: 0 !important;">{{ 'SHOPIFY' | translate}}</p>
            </div>
        </div>
        <div [ngClass]="{'background-stripes-tbc': this.directory.productType === 'ecom' && this.directory.productId === 'tbc' && tableType !== 'limit', 'background-stripes': !(this.directory.productType === 'ecom' && this.directory.productId === 'tbc') || tableType === 'limit'}" class="{{ this.directory.productType === 'ecom' && this.directory.productId === 'tbc' ? 'background-stripes-tbc' : 'background-stripes' }} row flex-sm-row justify-content-sm-between align-items-sm-center"
            *ngFor="let data of currentData">
            <div [@tableCell]=" toggleAnimation" class="p-3">
                {{data.title | translate}}
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <div [@tableCell]=" toggleAnimation" class="p-3 align-self-start font-weight-bold d-flex justify-content-center">
                    <p class="d-flex justify-content-center"  style="min-width: 130px; max-width: 100%;">{{data.value | translate}}</p> <p *ngIf="this.directory.productType === 'ecom' && this.directory.productId === 'tbc'" class="d-flex justify-content-center" [ngStyle]="{'width': tableType === 'tariff' ? '130px' : 'auto'}">{{data.shopify_value | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</generic-container>