<generic-container textAlign="left" bold="last" titleKey='HOW_TO_BUY'>
    <div class="wrap container-fluid">
        <div class="row">
            <div class="col-12 col-md-4 pr-0 pl-0 mb-4 mb-md-0">
                <online-shopping link="/order/pos"></online-shopping>
            </div>
            <div class="col-12 col-md-4 pr-0 pl-0 mb-4 mb-md-0 pl-md-5">
                <contact-us></contact-us>
            </div>
            <div class="col-12 col-md-4 pr-0 pl-0 pl-md-5">
                <call route="/call/pos/0"></call>
            </div>
        </div>
    </div>
</generic-container>