<section class="text-center text-md-left">
    <generic-container class="payments-generic-container">
        <div class="container-fluid p-0">
            <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 col-12 m-auto">
                    <div class="category-text">
                        <h2 class="caps font-weight-bold mb-4 h2">
                            {{'HOME_PAYMENTS_TITLE' | translate}}
                        </h2>
                        <p class="mb-5 mx-auto mx-md-0">
                            {{'HOME_PAYMENTS_DESCRIPTION' | translate}}
                        </p>
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <tbc-button (click)="initLogin()" position="right" [showLoader]="displayLoader"
                                text="{{ 'LOGIN' | translate }}">
                            </tbc-button>
                            <a class="plain-button ml-md-3" [routerLink]="'space'">
                                <span class="m-auto caps d-block">
                                    {{'FIND_MORE' | translate}}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div #paymentsSectionImageCol class="col-md-6 col-12 p-0 mb-5 mb-md-0">
                    <div #paymentsSectionImageWrapper class="image-wrapper ml-auto ml-md-0">
                        <img src="../../../../assets/images/home/laptop.svg" [alt]="'HOME_PAYMENTS_TITLE' | translate"/>
                        <ng-lottie [options]="{ path: 'assets/images/home/animation.json' }"></ng-lottie>
                    </div>
                </div>
            </div>
        </div>
    </generic-container>
</section>
