import { Component, OnInit } from '@angular/core';
import { ProductInfoService } from '../../shared/product-info/services/product-info.service';

@Component({
  selector: 'app-choose-product-parent',
  templateUrl: './choose-product-parent.component.html'
})
export class ChooseProductParentComponent implements OnInit {
  public products;

  constructor(private productInfoService: ProductInfoService) { }

  ngOnInit() {
    this.products = this.productInfoService.getProductParent();
  }
}
