import { environment } from "../../../environments/environment";

export class Constants {
    public static readonly JWTCookieName: string = "jwt";
    public static readonly JWTCookieTimeoutInMinutes: number = 20;
    public static readonly EndPointBaseAddress: string =
        environment.production ? "http://dev0214.hq.tbc:5005/" : "http://dev0214.hq.tbc:5005"; //http://dev0214.hq.tbc:5000
    // public static readonly EndPointBaseAddress: string = "https://mhub-external.tbcbank.ge";
    public static readonly RegexPatterns = {
        'number': /^[0-9]+$/,
        'number-range': /^[0-9., -]*$/,
        'multiple-words': /^[\u10D0-\u10F0 a-z-]+$/i,
        // 'phone-number': /^((\+){1}91){1}[1-9]{1}[0-9]{9}$/,
        'phone-number': /^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[- 0-9]*$/,
        'web-address': /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        'email':  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        'cadastral-number': /^\d+(\.\d+)*$/
    };
    public static readonly chatEndPointBaseUrl: string = 'https://chat.tbcbank.ge/ClientLogin.aspx';
}