import {Component, OnInit} from "@angular/core";


@Component({
    selector: 'product-enroll',
    templateUrl: './product-enroll.component.html',
    styleUrls: ['./product-enroll.component.scss']
})

export class ProductEnrollComponent {

}