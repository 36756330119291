<shared-header [showCurves]="true" [detailsPage]="true">
    <div class="wrap tos-header-wrapper container-fluid h-100">
        <div class="row h-100">
            <div class="col mt-auto">
                <h1 class="caps font-weight-medium mb-3">
                    {{ termsAndConditionsTitle[currentLang] }}
                </h1>
            </div>
        </div>
    </div>
</shared-header>

<div class="wrap container-fluid tos-sections">
    <section>
        <p [ngStyle]="isFirst && {maxWidth: '617px'}" style="max-width: 617px;" *ngFor="let desc of termsAndConditionsDesc[currentLang]; first as isFirst">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ informationAccuracyTitle[currentLang] }}</h6>
        <p *ngFor="let desc of informationAccuracyDesc[currentLang]">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ responsibilityLimitTitle[currentLang] }}</h6>
        <p *ngFor="let desc of responsibilityLimitDesc[currentLang]">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ webPageUseTitle[currentLang] }}</h6>
        <p *ngFor="let desc of webPageUseDesc[currentLang]">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ intellectualPropertyTitle[currentLang] }}</h6>
        <p *ngFor="let desc of intellectualPropertyDesc[currentLang]">
            {{desc}}
        </p>
    </section>

    <section>
        <h6>{{ dataProtectionTitle[currentLang] }}</h6>
        <p *ngFor="let desc of dataProtectionDesc[currentLang]">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ webPageLinksTitle[currentLang] }}</h6>
        <p *ngFor="let desc of webPageLinksDesc[currentLang]">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ amendmentsTitle[currentLang] }}</h6>
        <p *ngFor="let desc of amendmentsDesc[currentLang]">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ lawsTitle[currentLang] }}</h6>
        <p *ngFor="let desc of lawsDesc[currentLang]">
            {{ desc }}
        </p>
    </section>

    <section>
        <h6>{{ contactTitle[currentLang] }}</h6>
        <p>{{ contactDesc[currentLang][0] }}
            <span class="font-weight-bold">{{contactDesc[currentLang][1]}}</span>
        </p>
    </section>
</div>