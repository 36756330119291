import { Component, Input, OnChanges } from "@angular/core";
import { FAQResponse } from "./models/faq-response";
import { FaqService } from './services/faq.service';

@Component({
	selector: "faq",
	templateUrl: "./faq.component.html",
	styleUrls: ["./faq.component.scss"]
})
export class FaqComponent implements OnChanges {
	@Input() faqs: Array<FAQResponse>;
	@Input() showFeedback: boolean;
	@Input() faqLimit: number;
	@Input() showLabels: boolean = true;
	@Input() groupLabels: boolean;

	public showAll: boolean = false;

	private parentLabels = {
		2: "POS",
		3: "E Commerce"
	}

	constructor(private faqService: FaqService) { }

	ngOnChanges(): void {
		this.faqs && this.showLabels && this.groupLabels && this.removeChildLabels();
	}

	public toggleMenu(faq: FAQResponse): void {
		faq.showAnswer = !faq.showAnswer;
	}

	public seeAll(): void {
		this.showAll = !this.showAll;
	}

	public feedbackClick(feedbackPositive: boolean, faq: FAQResponse) {
		faq.feedbackGiven = true;
		this.faqService.setFaqFeedback(faq.id, feedbackPositive).subscribe();
	}

	private removeChildLabels(): void {
		this.faqs.forEach(faq => {
			let finalLabels = [];
			faq.labels.forEach(label => {
				const parentLabelId = label.parentId;
				if (parentLabelId) {
					label.text = this.parentLabels[parentLabelId] + " " + label.text;
				}
				label.text += " Label";
				const labelId = label.id;
				if (labelId !== 3 && labelId !== 2 || faq.labels.length < 2) {
					finalLabels.push(label);
				}
			});
			faq.labels = finalLabels;
		});
	}
}
