import {NgModule} from "@angular/core";
import {ProductCardComponent} from "./product-card.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
    declarations: [ProductCardComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatTooltipModule
    ],
    exports: [ProductCardComponent]
})

export class ProductCardModule {

}
