import { ExternalLeadGuard } from './guards/external-lead.guard';
import { ExternalLeadService } from './services/external-lead.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { leadRoutes } from './routing/external-lead.routing';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeRefComponent } from './employee-ref/employee-ref.component';
import { LeadSuccessPageComponent } from './lead-success-page/lead-success-page.component';
import { ExternalLeadComponent } from './external-lead.component';
import { TbcButtonModule } from '../shared/tbc-button/tbc-button.module';
import { TooltipModule } from '../shared/tooltip/tooltip.module';



@NgModule({
  declarations: [EmployeeRefComponent, LeadSuccessPageComponent, ExternalLeadComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule.forChild(leadRoutes),
    ReactiveFormsModule,
    MatInputModule,
    TbcButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    RecaptchaModule,
    TooltipModule
  ],
  providers: [
    ExternalLeadService,
    ExternalLeadGuard
  ]
})
export class ExternalLeadModule { }
