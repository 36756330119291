import { filter } from 'rxjs/operators';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    Input,
    OnDestroy
} from "@angular/core";

import { SwiperComponent } from "swiper/angular";
// import Swiper core and required modules
import SwiperCore, { Pagination, SwiperOptions } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
    selector: 'custom-swiper',
    templateUrl: './custom-swiper.component.html',
    styleUrls: ['./custom-swiper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomSwiperComponent implements AfterContentInit, OnDestroy {

    @ContentChild(SwiperComponent) swiper!: SwiperComponent;
    @Input() slidesPerView: number = 3;
    @Input() spaceBetween: number = 30;
    @Input() breakpoints: SwiperOptions['breakpoints'];
    @Input() data: any;
    @Input() maxWidth: number = 500;

    private emptySlides = []
    private prevBp

    constructor(private changeRef: ChangeDetectorRef) {
    }

    disableRight;
    disableLeft;
    showNavigation = false;

    ngAfterContentInit() {

        if (this.data.filter(d => !d.empty).length === 1) {
            this.showNavigation = false;
        }

        this.swiper.s_slideChange.subscribe((swiper: any) => {
            setTimeout(() => {
                this.disableRight = swiper.isBeginning;
                this.disableLeft = swiper.isEnd;
                this.changeRef.detectChanges()
            }, 20)
        })

        this.swiper.breakpoints = this.initBreakpoints();
        this.swiper.spaceBetween = this.spaceBetween;
        this.swiper.pagination = {
            type: "progressbar"
        };


        this.swiper.s_beforeResize.subscribe((swiper: any) => {
            setTimeout(() => {
                this.showNavigation = swiper.width < swiper.virtualSize;
                this.disableRight = swiper.isBeginning;
                this.disableLeft = swiper.isEnd;
                this.changeRef.detectChanges();
            }, 15)
        })

        this.swiper.s_afterInit.subscribe((swiper: any) => {
            setTimeout(() => {
                if (swiper.width < swiper.virtualSize) {
                    this.showNavigation = true;
                }
                this.disableRight = swiper.isBeginning;
                this.disableLeft = swiper.isEnd;
                this.changeRef.detectChanges()
            }, 10)
        })

        this.swiper.s__beforeBreakpoint.subscribe((swiper: any) => {
            setTimeout(() => {

                if (this.prevBp === undefined) {
                    this.prevBp = parseInt(swiper.currentBreakpoint)

                }
                let slidesPerView = this.getSlidesPerView(parseInt(swiper.currentBreakpoint))
                let slides = document.querySelectorAll('.swiper-slide')
                let filteredData = this.data.filter(v => !v.empty)

                if (parseInt(swiper.currentBreakpoint) < 600) {
                    if ((filteredData.length < this.data.length)) {

                        let lastSlideIndex = slides.length - 1
                        while (slides[lastSlideIndex].querySelector('.empty')) {
                            this.emptySlides.push(slides[lastSlideIndex])
                            slides[lastSlideIndex]?.remove()
                            lastSlideIndex--
                        }
                        this.changeRef.detectChanges()
                    }
                }

                if (swiper.allowSlideNext && this.prevBp >= parseInt(swiper.currentBreakpoint)) {
                    if ((filteredData.length < this.data.length)) {

                        if (slides[slidesPerView].querySelector('.empty')) {
                            this.emptySlides.push(slides[slidesPerView])
                            slides[slidesPerView]?.remove()
                            this.changeRef.detectChanges()
                        }
                    }
                } else {
                    if (this.emptySlides.length > 0) {
                        if (slidesPerView > filteredData.length) {
                            document.querySelector('.swiper-wrapper').append(this.emptySlides[this.emptySlides.length - 1])
                            this.emptySlides.pop()
                        }
                    }
                }
                this.prevBp = parseInt(swiper.currentBreakpoint)

            }, 0)
        })
    }

    public slideRight(): void {
        this.swiper.swiperRef.slidePrev();
    }

    public slideLeft(): void {
        this.swiper.swiperRef.slideNext();
    }


    private initBreakpoints(): SwiperOptions['breakpoints'] {
        return {
            1200: {
                slidesPerView: this.getSlidesPerView(1200)
            },
            900: {
                slidesPerView: this.getSlidesPerView(900)
            },
            600: {
                slidesPerView: this.getSlidesPerView(600)
            },
            0: {
                slidesPerView: 1
            }
        }
    }

    private getSlidesPerView(bp: number): number {
        const pv = Math.floor(bp / this.maxWidth);
        return pv > this.data.length ? this.data.length : pv;
    }


    ngOnDestroy() {
        this.swiper.s_slideChange.unsubscribe();
        this.swiper.s_afterInit.unsubscribe();
    }

}
