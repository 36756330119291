<section *ngIf="stepList">
    <ul [ngClass]="{'all-completed': currentStep === stepList.length}"
        class="d-flex justify-content-center progress-list m-0 {{product}}">
        <ng-container *ngFor="let step of stepList; let i = index;">
            <li [ngClass]="{'d-none': !step.stepTitle}">
                <div class="content" (click)="goToStep(i + 1)" [ngClass]="{
                    disabled: currentStep === i + 1 || !stepList[i].valid || currentStep == stepList[stepList.length-1].route,
                    completed: currentStep > i + 1,
                    active: currentStep === i + 1}">
                    <div class="img-wrap" [attr.data-before-content]="i+1"></div>
                    <span>
                        {{ step.stepTitle | translate}}
                    </span>
                </div>
            </li>
            <div *ngIf="step.stepTitle" [ngClass]="{active: currentStep === i + 1}" class="line {{product}}">
            </div>
        </ng-container>
    </ul>
</section>