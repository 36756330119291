import { Routes } from '@angular/router';
import { PrivacyTosComponent } from '../privacy-tos.component';
import { TosComponent } from '../tos/tos.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';

export const privacyTOSRoutes: Routes = [
    {
        path: '',
        component: PrivacyTosComponent,
        children: [
            {
                path: 'terms-and-conditions',
                component: TosComponent
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent
            }
        ]
    }
]