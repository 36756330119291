<generic-container divider="true" titleKey="HOW_TO_ENROLL" textAlign="left">
    <div class="container-fluid">
        <div class="row item-row">
            <div class="col pr-0 pl-0 mb-4 mb-md-0">
                <div>
                    <p>{{'QREAT_PARTNERS_TITLE' | translate}}</p>
                    <span>{{'QREAT_PARTNERS_DESCRIPTION' | translate}}</span>
                    <div class="icons">
                        <img id="fina" src="../../../../assets/images/product-details/partners/fina_logo.png" alt="fina">
                        <img id="restosoft" src="../../../../assets/images/product-details/partners/restosoft.png" alt="restosoft">
                        <img id="poster" src="../../../../assets/images/product-details/partners/poster.png" alt="poster">
                    </div>
                </div>
            </div>
            <div class="col pr-0 mb-4 mb-md-0">
                <div>
                    <p>{{'QREAT_PROGRAMS_TITLE' | translate}}</p>
                    <span>{{'QREAT_PROGRAMS_DESCRIPTION' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</generic-container>