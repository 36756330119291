import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { Constants } from 'src/ng-app/common/constants';
import {NumbersOnlyAndLength , PhoneNumberValidator} from '../../../../shared/validators/number-only.validator';
import { CompanyTypes } from '../../enums/company-types';
import {NumbersLettersAndDash} from "../../../../shared/validators/letters-and-numbers.validator";
import {debounceTime, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {SaveFormService} from "../../../services/save-form.service";

@Component({
  selector: 'geopay-company',
  templateUrl: './geopay-company.component.html',
  styleUrls: ['./geopay-company.component.scss']
})
export class GeopayCompanyComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public formStep: number = 2;
  public companyTypes = CompanyTypes;

  public idData = {
    "person": {
      keyCode: 'PERSONAL_ID',
      errorCode: 'QUANTITY_9_11'
    },
    "company": {
      keyCode: 'IDENTIFICATION_CODE',
      errorCode: 'QUANTITY_9'
    },
    "else": {
      keyCode: 'IDENTIFICATION_CODE',
      errorCode: 'QUANTITY_9_11'
    },
    "default": {
      keyCode: '',
      errorCode: ''
    }
  };

  public identityNumberErrorCode: string;

  @Input() formFields;
  @Input() section;
  @Input() displayLoader: boolean;

  @Input() set serverErrorData(serverErrors) {
    if (serverErrors) {
      if (serverErrors.length && this.formGroup) {
        serverErrors.forEach(serverError => {
          const formField = this.getFormProperty(serverError.fieldName);
          if (formField) {
            formField.setErrors(serverError.serverError);
          }
        });
      }
    }
  }

  @Input() set formStepSubmit(inputVal) {
    if (inputVal) {
      const { currentStep, stepToGo } = inputVal;
      if (currentStep) {
        if (currentStep === this.formStep) {
          if (this.formGroup.status === 'VALID') {
            this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, stepToGo, nextStep: true });
          } else {
            this.formGroup.markAllAsTouched();
          }
        }
      }
    }
  }

  @Output() stepperFormValidity: EventEmitter<any> = new EventEmitter();
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, private saveFormService: SaveFormService) { }

  ngOnInit(): void {
    this.formGroup = this.buildForm();
    this.formGroup.valueChanges
        .pipe(debounceTime(300), switchMap(() => {
          this.formSubmitted.emit({formFields: this.formGroup.value, section: this.section, nextStep: false});
          return of(null)
        }))
        .subscribe()

    this.saveFormService.patchFormValue(this.formGroup, this.section);

    this.companyTypeChosen(this.companyType.value);
  }

  companyTypeChosen(option: number, notOnInit?: boolean) {
    let idDataAccessor = 'default';

    if (option === 1) {
      this.identityNumber.setValidators(
          [
            NumbersOnlyAndLength([9, 11]),
            Validators.required
          ]
      );
      idDataAccessor = 'person';
    } else if (option === 2) {
      this.identityNumber.setValidators([
        NumbersOnlyAndLength([9]),
        Validators.required
      ]);
      idDataAccessor = 'company';
    } else if (option === 3) {
      this.identityNumber.setValidators(
          [
            NumbersLettersAndDash(),
            Validators.minLength(5),
            Validators.maxLength(50),
            Validators.required
          ]);
      idDataAccessor = 'else';
    }

    // notOnInit && (
    //     this.formFields.identityNumber.value = '',
    //         this.identityNumber.patchValue(this.formFields.identityNumber.value),
    //         this.identityNumber.markAsUntouched()
    // );

    if (notOnInit) {
      this.formFields.identityNumber.value = '';
      this.identityNumber.patchValue(this.formFields.identityNumber.value);
      this.identityNumber.markAsUntouched();
    }


    this.identityNumberErrorCode = this.idData[idDataAccessor].errorCode;
    this.formFields.identityNumber.keyCode = this.idData[idDataAccessor].keyCode;
  }

  public goToNextStep(): void {
    if (this.formGroup.status === 'VALID') {
      this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, nextStep: true});
    }
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      companyType: new UntypedFormControl(this.formFields.companyType.value, [
        Validators.required,
      ]),
      legalName: new UntypedFormControl(this.formFields.legalName.value, [
        Validators.required,
      ]),
      identityNumber: new UntypedFormControl(this.formFields.identityNumber.value, [
        Validators.required
      ]),
      physicalAddress: new UntypedFormControl(this.formFields.physicalAddress.value, [
        Validators.required,
      ]),
      phoneNumber: new UntypedFormControl(this.formFields.phoneNumber.value, [
        Validators.required,
        PhoneNumberValidator()
      ]),
      email: new UntypedFormControl(this.formFields.email.value, [
        Validators.required,
        Validators.pattern(new RegExp(Constants.RegexPatterns["email"]))
      ]),
      bankDetails: new UntypedFormControl(this.formFields.bankDetails.value, [
        Validators.pattern(new RegExp(/^GE\d{2}TB\d{16}/)),
        Validators.minLength(22), Validators.maxLength(22),
      ]),
    });
  }

  public getFormProperty(propertyName: string): AbstractControl {
    return this.formGroup.get(propertyName);
  }

  public get companyType(): AbstractControl {
    return this.formGroup.get("companyType");
  }

  public get legalName(): AbstractControl {
    return this.formGroup.get("legalName");
  }

  public get identityNumber(): AbstractControl {
    return this.formGroup.get("identityNumber");
  }

  public get physicalAddress(): AbstractControl {
    return this.formGroup.get("physicalAddress");
  }

  public get phoneNumber(): AbstractControl {
    return this.formGroup.get("phoneNumber");
  }

  public get email(): AbstractControl {
    return this.formGroup.get("email");
  }

  public get bankDetails(): AbstractControl {
    return this.formGroup.get("bankDetails");
  }
}