import { ExternalLeadGuard } from './../guards/external-lead.guard';
import { EmployeeRefComponent } from '../employee-ref/employee-ref.component';
import { LeadSuccessPageComponent } from '../lead-success-page/lead-success-page.component';
import { ExternalLeadComponent } from '../external-lead.component';
import { Routes } from '@angular/router';

export const leadRoutes: Routes = [
    {
        path: '',
        canActivate: [ExternalLeadGuard],
        component: ExternalLeadComponent,
        children: [
            {
                path: 'employeeref',
                component: EmployeeRefComponent
            },
            {
                path: 'employeeref/success',
                component: LeadSuccessPageComponent
            }
        ]
    }
]