<form [formGroup]="formGroup" (submit)="sendForm()">
    <div class="fields-container">
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.identityNumber.keyCode | translate}}" formControlName="identityNumber">
            <mat-error *ngIf="identityNumber.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.pattern
                && (identityNumber.dirty || identityNumber.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.idNumberInvalidLength  && !identityNumber.errors?.pattern
                && (identityNumber.dirty || identityNumber.touched)">
                {{'POS_ID_NUMBER_INVALID_LENGTH' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.serverError
                && (identityNumber.dirty || identityNumber.touched)">
                {{identityNumber.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.fieldOfActivity.keyCode | translate}}" formControlName="fieldOfActivity">
            <mat-hint> {{'COMPANY_SPHERE_TOOLTIP' | translate}} </mat-hint>
            <mat-error *ngIf="fieldOfActivity.errors?.required"> {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="fieldOfActivity.errors?.serverError
                && (fieldOfActivity.dirty || fieldOfActivity.touched)">
                {{fieldOfActivity.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.physicalAddress.keyCode | translate}}" formControlName="physicalAddress">
            <mat-hint>{{ 'POS_CITY_STREET_NUMBER_HELP_TEXT' | translate }}</mat-hint>
            <mat-error *ngIf="physicalAddress.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="physicalAddress.errors?.serverError
                && (physicalAddress.dirty || physicalAddress.touched)">
                {{physicalAddress.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.contactFullname.keyCode | translate}}" formControlName="contactFullname">
            <mat-hint>{{ 'POS_CONTACT_PERSON_HELP_TEXT' | translate }}</mat-hint>
            <mat-error *ngIf="fullname.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="fullname.errors?.pattern
            && (fullname.dirty || fullname.touched)">
                {{'ERRORS_ENTER_NAME' | translate}}</mat-error>
            <mat-error *ngIf="fullname.errors?.serverError
            && (fullname.dirty || fullname.touched)">
                {{fullname.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.contactPhoneNumber.keyCode | translate}}" formControlName="contactPhoneNumber">
            <mat-error *ngIf="phoneNumber.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="(phoneNumber.errors?.phoneNumberInvalid && (phoneNumber.dirty || phoneNumber.touched))">
                {{'ERROR_PHONE_NUMBER' | translate}}</mat-error>
            <mat-error *ngIf="phoneNumber.errors?.serverError">
                {{phoneNumber.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.legalNameInLatin.keyCode | translate}}" formControlName="legalNameInLatin">
            <mat-error *ngIf="nameInLatin.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="nameInLatin.errors?.pattern
                && (nameInLatin.dirty || nameInLatin.touched)">
                {{'ERROR_COMPANY_NAME_EN' | translate}}</mat-error>
            <mat-error *ngIf="nameInLatin.errors?.serverError
                && (nameInLatin.dirty || nameInLatin.touched)">
                {{nameInLatin.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.nameOnReceipt.keyCode | translate}}"
                   formControlName="nameOnReceipt">
            <mat-hint>
                {{'IN_ENGLISH_OR_GEORGIAN' | translate}}
            </mat-hint>
            <mat-error *ngIf="nameOnReceipt.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="nameOnReceipt.errors?.pattern
                && (nameOnReceipt.dirty || nameOnReceipt.touched)">
                {{'ERROR_COMPANY_RECEIPT_PATTERN' | translate}}</mat-error>
            <mat-error *ngIf="nameOnReceipt.errors?.serverError
                && (nameOnReceipt.dirty || nameOnReceipt.touched)">
                {{nameOnReceipt.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
            <mat-label>{{formFields.storeManagementType.keyCode | translate}}</mat-label>
            <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering formControlName="storeManagementType">
                <mat-option [value]="1">Fina</mat-option>
                <mat-option [value]="2">Poster</mat-option>
                <mat-option [value]="3">{{'OTHER' | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="storeManagementType.errors?.required
                && (storeManagementType.dirty || storeManagementType.touched)">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
        </mat-form-field>
    </div>
    <div class="button-wrap d-flex justify-content-between flex-row-reverse" style="margin-top: 2rem;">
        <tbc-button position="right" class="tbc-button-component with-arrow max-width" [maxWidth]="1000" [disabled]="false"
                    [showLoader]="displayLoader" [showArrow]="true" text="{{'SEND' | translate}}">
        </tbc-button>
    </div>
</form>