import {AfterViewInit, Component, ElementRef, Input, ViewChild} from "@angular/core";

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})

export class VideoComponent  {

    @Input() video: any;



}
