import { APP_INITIALIZER } from '@angular/core';
import { AppConfiguraitonService } from '../../services/app-configuration.service';

export const AppInitializerLoader = {
    provide: APP_INITIALIZER,
    useFactory: AppInitializerFactory,
    deps: [AppConfiguraitonService],
    multi: true
}

export function AppInitializerFactory(appConfiguraitonService: AppConfiguraitonService) {
    return () => appConfiguraitonService.load();
}