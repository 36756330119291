import { Component } from '@angular/core';
import { ProductInfo } from '../../shared/product-info/models/product-info';
import { ProductInfoService } from '../../shared/product-info/services/product-info.service';

@Component({
      selector: 'pos-products',
      templateUrl: './pos-products.component.html'
})
export class PosProductsComponent {
      constructor(private productInfoService: ProductInfoService) { }

      public products: Array<ProductInfo> = this.productInfoService.getPosProducts();
}
