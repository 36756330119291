import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Injectable({
    providedIn: "any"
})

export class SaveFormService {


    patchFormValue(formGroup: FormGroup, section: string): void {
        const formValue = JSON.parse(sessionStorage.getItem('formValues'));

        if(!formValue || !formValue[section]) return;

        formGroup.patchValue(formValue[section]);
        formGroup.markAllAsTouched()
    }


}