import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomDropdownComponent} from "./custom-dropdown.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [CustomDropdownComponent],
    imports: [CommonModule, TranslateModule],
    exports: [CustomDropdownComponent]
})
export class CustomDropdownModule { }
