import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    ChangeDetectorRef, HostListener, OnDestroy
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfiguraitonService } from "../../services/app-configuration.service";
import { TranslateService } from "@ngx-translate/core";
import {LanguageService} from "../../services/language.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {animate, group, style, transition, trigger} from "@angular/animations";
import {AuthenticationService} from "../../services/authentication.service";
import {interval, ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('openClose', [
            transition(':enter',[
                group([
                    style({height: 0}),
                    animate('600ms cubic-bezier(.4, 0, .1, 1)', style({height: 'calc(var(--expanded-menu-height) - var(--header-menu-height)'})),
                ])
            ]),
            transition(':leave',[
                group([
                    animate('600ms cubic-bezier(.4, 0, .1, 1)', style({height: '0'})),
                ])
                ]
            )

        ])
    ]
})
export class HeaderComponent implements OnInit, OnDestroy {

    @HostListener('window:resize')
    public windowResize() {
        const headerHeight = (<HTMLElement>document.getElementsByClassName('navbar wrap')[0]).offsetHeight
        document.documentElement.style.setProperty('--expanded-menu-height', `${window.innerHeight}px`)
        document.documentElement.style.setProperty('--header-menu-height', `${headerHeight}px`)
    }

    destroy$: Subject<void> = new Subject<void>();
    public show: boolean;
    public language: string;
    public displayLoader: boolean = false;
    public isMobile: boolean;
    public isUserAuth$: Subject<boolean> = new Subject<boolean>();
    public showLoginButton$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
    public dashboardUrl: string = '';
    @Input() questionMarkColor: string;

    constructor(private http: HttpClient,
        private appConfig: AppConfiguraitonService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private bpObserver: BreakpointObserver,
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.translateService.onLangChange.subscribe(lang => {
            this.language = lang.lang.toUpperCase();
        })

        this.bpObserver.observe(['(max-width: 1024px)'])
            .subscribe(state => {
                this.isMobile = state.matches;
                this.cdr.detectChanges();
            })

        this.checkIfUserIsAuth();
        this.checkIfUserIsAuthInterval();

        this.isUserAuth$.pipe(takeUntil(this.destroy$)).subscribe(isAuth => {
            if(isAuth) {
                this.dashboardUrl = this.authenticationService.getDashboardUrl()
            } else {
                this.dashboardUrl = '';
            }
        })
    }

    public checkIfUserIsAuthInterval() {
        interval(2000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.checkIfUserIsAuth();
            })
    }

    private checkIfUserIsAuth() {
        let isAuth = this.authenticationService.isAuthenticated();

        this.isUserAuth$.next(isAuth);
        this.showLoginButton$.next(isAuth);
    }

    public toggle(): void {
        this.show = !this.show;


        this.toggleScroll()
        if(this.show) {
            const headerHeight = (<HTMLElement>document.getElementsByClassName('navbar wrap')[0]).offsetHeight
            document.documentElement.style.setProperty('--expanded-menu-height', `${window.innerHeight}px`)
            document.documentElement.style.setProperty('--header-menu-height', `${headerHeight}px`)
        }

    }
    public close(): void {
        this.show = false;
        this.toggleScroll()
    }

    public toggleScroll() {
        document.getElementsByTagName('body')[0].style.overflowY = this.show ? 'hidden' : 'auto';
        const wrapperElement: HTMLElement = (<HTMLElement>document.getElementsByClassName('app-wrapper')[0]);
        wrapperElement.style.overflowY = this.show ? 'hidden' : 'auto';
        wrapperElement.style.height = this.show ? `${window.innerHeight}px` : 'unset';
    }

    public changeLang(lang: 'ka' | 'en'): void {
        this.languageService.setLanguage(lang);
    }

    public get oppositeLanguage(): 'ka' | 'en' {
        return this.languageService.getCurrentLanguage() === 'ka' ? 'en' : 'ka';
    }

    returnToDashboard(): void {
        window.open(this.dashboardUrl, '_blank')
    }

    initLogin() {
        this.displayLoader = true;
        setTimeout(() => {
            try {
                (<any>window).gtag("event", "event",
                    { eventCategory: 'MainWeb',
                        eventAction: 'Landing_login_up'});
                (<any>window).fbq('track', 'Landing_login_up')
            } catch (e) {
                console.error('Tracker Error', e);
            }
        })
        window.location.href = `${this.appConfig.portalBaseAddress}/gateway/SSO/init-session`;

        // this.http.post(`${this.appConfig.portalBaseAddress}/Account/InitLogin`, null)
        //     .subscribe((res: { redirectUrl: string }) => {
        //         window.location.href = res.redirectUrl;
        //     }, () => {
        //         this.displayLoader = false;
        //     });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
