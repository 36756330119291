import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentProductCostComponent } from './payment-product-cost.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
	declarations: [
		PaymentProductCostComponent
	],
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		PaymentProductCostComponent
	]
})
export class PaymentProductCostModule { }
