<section class="lead-text">
    <h1 class="caps font-weight-bold text-center mb-4" style="color: #F00" *ngIf="validationSummary">
        {{validationSummary | translate}}</h1>
    <h1 class="caps font-weight-bold text-center mb-4" *ngIf="!validationSummary">
        {{'EMPLOYEE_REF_LEAD_TITLE' | translate}}</h1>
    <p class="lead-desc font-weight-medium mb-4">
        {{'EMPLOYEE_REF_LEAD_DESC' | translate}}
    </p>
</section>
<section class="mb-4">
    <form [formGroup]="formGroup" (submit)="submit()">
        <div class="fields-container">
            <div *ngFor="let formInput of formInputs;">
                <mat-form-field class="tbcds-input" hideRequiredMarker>
                    <input matInput placeholder="{{ 'EMPLOYEE_REF_' + formInput.label | translate}}"
                        formControlName="{{formInput.formControlName}}">
                    <mat-error *ngIf="getErrorMessage(formInput.formControlName)">
                        {{getErrorMessage(formInput.formControlName) | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
                <mat-label>{{'EMPLOYEE_REF_TYPE' | translate}}</mat-label>
                <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering formControlName="type">
                    <mat-option [value]="2">{{employeeRefTypes[2] | translate}}</mat-option>
                    <mat-option [value]="3">
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="mb-0">
                                {{employeeRefTypes[3] | translate}}
                            </p>
<!--                            <p class="mb-0">-->
<!--                                <tbcds-tooltip [tip]="'POS_CASHIER_REF_TOOLTIP' | translate"-->
<!--                                    class="custom-tooltip d-md-none">-->
<!--                                </tbcds-tooltip>-->

<!--                                <tbcds-tooltip class="custom-tooltip d-none d-md-block">-->
<!--                                    <span class="ml-1 font-weight-medium" style="font-size: 12px;">-->
<!--                                        {{'POS_CASHIER_REF_TOOLTIP' | translate}}-->
<!--                                    </span>-->
<!--                                </tbcds-tooltip>-->
<!--                            </p>-->
                        </div>
                    </mat-option>
                    <mat-option [value]="5">{{employeeRefTypes[5] | translate}}</mat-option>
                    <mat-option [value]="4">{{employeeRefTypes[4] | translate}}</mat-option>
                    <mat-option [value]="1">{{employeeRefTypes[1] | translate}}</mat-option>
                </mat-select>
                <mat-error *ngIf="getFormControl('type').errors?.required
                    && (getFormControl('type').dirty || getFormControl('type').touched)">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="tbcds-input" *ngIf="posType === 2 || posType === 3 || posType === 5" hideRequiredMarker>
                <input matInput placeholder="{{ 'POS_QUANTITY' | translate}}"
                       formControlName="productCount">
                <mat-error *ngIf="getErrorMessage('productCount')">
                    {{getErrorMessage('productCount') | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
                <input matInput placeholder="{{ 'COMMENT' | translate}}"
                       formControlName="comment">
                <mat-hint>
                    {{'FIELD_NOT_REQUIRED' | translate}}
                </mat-hint>
                <mat-error *ngIf="getErrorMessage('comment')">
                    {{getErrorMessage('comment') | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="button-wrap d-flex justify-content-between flex-row-reverse"
            style="margin-top: calc(1rem + .4375em);">
            <tbc-button [showLoader]="formSubmitted" [disabled]="formGroup.status === 'INVALID'" [showArrow]="true"
                [maxWidth]="true" text="{{'SEND' | translate}}">
            </tbc-button>
        </div>
    </form>
</section>
<p class="lead-desc font-weight-medium text-center m-0">
    {{'YOUR_INFO_WILL_BE_SECURE' | translate}}
</p>
<!--<re-captcha #captchaRef='reCaptcha' size="invisible" (resolved)="$event && resolvedRecaptcha($event)"-->
<!--    siteKey="6Lf95tMZAAAAAJmvvrZX3Mm1dE3EDq7zAXEt4ymV">-->
<!--</re-captcha>-->
