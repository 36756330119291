import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header.component";
import { SharedModule } from "../../shared/shared.module";
import { RouterModule } from "@angular/router";
import { QuestionMarkDynamicColorComponent } from "./question-mark-dynamic-color/question-mark-dynamic-color.component";
import { TbcButtonModule } from "../shared/tbc-button/tbc-button.module";
import {CustomDropdownModule} from "../shared/custom-dropdown/custom-dropdown.module";

@NgModule({
  declarations: [HeaderComponent, QuestionMarkDynamicColorComponent],
    imports: [SharedModule, CommonModule, RouterModule, TbcButtonModule, CustomDropdownModule],
  exports: [HeaderComponent]
})
export class HeaderModule { }
