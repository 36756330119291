import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";



@Component({
	selector: 'product-how-to',
	templateUrl: './product-how-to.component.html',
	styleUrls: ['./product-how-to.component.scss']
})
export class ProductHowToComponent implements OnChanges {
	@Input() directory: { productType: string, productId: string };
	public currentLang: string = '';
	private destroy$: Subject<void> = new Subject<void>();
	public currentData;
	public mainKey = 'HOW_IT_WORKS';
	public titleKey;


	constructor(private translate: TranslateService,) {
		this.currentLang = translate.currentLang;
		this.translate.onLangChange
			.pipe(takeUntil(this.destroy$))
			.subscribe(lang => {
				this.currentLang = lang.lang;
			})
	}


	public data = {
		space: {
			default: {
				data: [
					{
						text: 'PORTAL_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'PORTAL_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'PORTAL_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			}
		},
		pos: {
			standard: {
				data: [
					{
						text: 'POS_STANDARD_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'POS_STANDARD_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'POS_STANDARD_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			tpos: {
				data: [
					{
						text: 'T_POS_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'T_POS_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'T_POS_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			integrated: {
				data: [
					{
						text: 'POS_INTEGRATED_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'POS_INTEGRATED_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'POS_INTEGRATED_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			cashier: {
				data: [
					{
						text: 'POS_CASHIER_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'POS_CASHIER_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'POS_CASHIER_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			self: {
				data: [
					{
						text: 'POS_SELF_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'POS_SELF_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'POS_SELF_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			android: {
				data: [
					{
						text: 'POS_ANDROID_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'POS_ANDROID_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'POS_ANDROID_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			qrhoreca: {
				data: [
					{
						text: 'POS_QREAT_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'POS_QREAT_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'POS_QREAT_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
		},
		ecom: {
			link: {
				data: [
					{
						text: 'ECOM_LINK_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'ECOM_LINK_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'ECOM_LINK_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			geopay: {
				data: [
					{
						text: 'ECOM_GEOPAY_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'ECOM_GEOPAY_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'ECOM_GEOPAY_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			},
			tbc: {
				data: [
					{
						text: 'ECOM_TPAY_HOW_IT_WORKS_DESCRITION_1',
					},
					{
						text: 'ECOM_TPAY_HOW_IT_WORKS_DESCRITION_2',
					},
					{
						text: 'ECOM_TPAY_HOW_IT_WORKS_DESCRITION_3',
					}
				]
			}
		}
	}

	ngOnChanges(): void {
		this.currentData = this.data[this.directory.productType][this.directory.productId];
		if (this.directory.productType === 'space') {
			this.titleKey = 'PORTAL_HOW_TO';
		} else {
			this.titleKey = this.mainKey;
		}
	}
}
