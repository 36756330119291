import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { FileFieldsEcom, FileFieldsGeopay, FilesService, ProgressInfo } from './services/files.service';


@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploaderComponent implements OnInit {
  progressInfos: ProgressInfo[] = [];
  filteredTypes: string;
  @Output() uploadFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteFile: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("fileInput") fileInput;
  @Input() limitedFileTypes = [];
  @Input() objFields: FileFieldsEcom | FileFieldsGeopay;
  @Input() helpText: string;
  @Input() required: boolean;
  fileUploadTouched = false;

  constructor(
    private filesService: FilesService
  ) {
  }

  ngOnInit(): void {
    this.filteredTypes = this.limitedFileTypes.map((t) => `.${t}`).join(",");
    this.progressInfos = this.filesService.progressInfos.filter(
      x => x.tag === this.objFields.tag && x.fieldOfActivity === this.objFields.fieldOfActivity
    )
  }

  get error(): boolean {
    return this.progressInfos.filter(f => f.success).length === 0
  }

  uploadFiles(event): void {
    const lastUploaded = Array.from(event);
    for (let i = 0; i < lastUploaded.length; i++) {
      this.uploadFile.emit({ self: this, file: lastUploaded[i] });
    }
    this.fileInput.nativeElement.value = ""; // Clear input to append file with same name
  }

  removeFile(fileInfo) {
    if (fileInfo.success) {
      this.deleteFile.emit({ self: this, fileInfo: fileInfo });
      this.spliceFileFromList(fileInfo.index);
    } else {
      fileInfo.subscription$.unsubscribe();
      if (fileInfo.subscription$.closed) {
        this.spliceFileFromList(fileInfo.index);
      }
    }
  }

  spliceFileFromList(index: string) {
    this.fileUploadTouched = true;
    this.progressInfos = this.progressInfos.filter(f => f.index !== index);
    this.filesService.progressInfos = this.filesService.progressInfos.filter(f => f.index !== index);
  }
}
