import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { Constants } from 'src/ng-app/common/constants';
import { PhoneNumberValidator } from "../../../../shared/validators/number-only.validator";
import {debounceTime, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {SaveFormService} from "../../../services/save-form.service";

@Component({
  selector: 'geopay-personal',
  templateUrl: './geopay-personal.component.html',
  styleUrls: ['./geopay-personal.component.scss']
})
export class GeopayPersonalComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public formStep: number = 1;

  @Input() formFields;
  @Input() section;
  @Input() displayLoader: boolean;

  @Input() set serverErrorData(serverErrors) {
    if (serverErrors) {
      if (serverErrors.length && this.formGroup) {
        serverErrors.forEach(serverError => {
          const formField = this.getFormProperty(serverError.fieldName);
          if (formField) {
            formField.setErrors(serverError.serverError);
          }
        });
      }
    }
  }

  @Input() set formStepSubmit(inputVal) {
    if (inputVal) {
      const { currentStep, stepToGo } = inputVal;
      if (currentStep) {
        if (currentStep === this.formStep) {
          if (currentStep > stepToGo) {
            this.goBack.emit(stepToGo);
          } else {
            if (this.formGroup.status === 'VALID') {
              this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, stepToGo, nextStep: true });
            } else {
              this.formGroup.markAllAsTouched();
            }
          }
        }
      }
    }
  }

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, private saveFormService: SaveFormService) { }

  ngOnInit(): void {
    this.formGroup = this.buildContactForm();
    this.formGroup.valueChanges
        .pipe(debounceTime(300), switchMap(() => {
          this.formSubmitted.emit({formFields: this.formGroup.value, section: this.section, nextStep: false});
          return of(null)
        }))
        .subscribe()

    this.saveFormService.patchFormValue(this.formGroup, this.section);

  }


  public goToNextStep(): void {
    if (this.formGroup.status === 'VALID') {
      this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, nextStep: true});
    }
  }

  private buildContactForm(): UntypedFormGroup {
    return this.formBuilder.group({
      contactFullName: new UntypedFormControl(this.formFields.contactFullName.value, [
        Validators.required,
        Validators.pattern(new RegExp(Constants.RegexPatterns["multiple-words"]))
      ]),
      contactPhoneNumber: new UntypedFormControl(this.formFields.contactPhoneNumber.value, [
        Validators.required,
        PhoneNumberValidator()
      ]),
      contactEmail: new UntypedFormControl(this.formFields.contactEmail.value, [
        Validators.required,
        Validators.pattern(new RegExp(Constants.RegexPatterns["email"]))
      ])
    });
  }

  public getFormProperty(propertyName: string): AbstractControl {
    return this.formGroup.get(propertyName);
  }

  public get contactFullName(): AbstractControl {
    return this.formGroup.get("contactFullName");
  }

  public get contactPhoneNumber(): AbstractControl {
    return this.formGroup.get("contactPhoneNumber");
  }

  public get contactEmail(): AbstractControl {
    return this.formGroup.get("contactEmail");
  }

}
