import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
      public router: Router,
      private titleService: Title,
      private meta: Meta,
      private translateService: TranslateService
  ) {
    this.translateMetaTags(Texts);
    this.translateService.onLangChange.subscribe(() => this.translateMetaTags(Texts));
  }

  private translateMetaTags(texts): void {
    for (let i in texts) {
      if (texts[i]['PAGE_TITLE']) {
        for (let k in texts[i]) {
          this.translateService.get(texts[i][k])
              .subscribe(translatedVal => texts[i][k] = translatedVal)
        }
      } else {
        this.translateMetaTags(texts[i])
      }
    }
  }

  public setMetaInfo(lastRoute: string) {
    let splitLastRoute = lastRoute.split('/');
    if (!splitLastRoute[0]) {
      splitLastRoute.shift();
    }
    let parentRoute;
    switch (splitLastRoute[0]) {
      case 'ecom':
      case 'pos':
      case 'details':
      case 'space':
        parentRoute = splitLastRoute[0];
        break;
      case 'order':
        parentRoute = 'order';
        break;
      default:
        parentRoute = 'default';
    }
    let pageTitle;
    let metaTitle;
    let metaDesc;
    try {
      if (parentRoute === 'details' || parentRoute === 'order') {
        if (splitLastRoute[1] && splitLastRoute[2]) {
          pageTitle = Texts[parentRoute][splitLastRoute[1]][splitLastRoute[2]]['PAGE_TITLE'];
          metaTitle = Texts[parentRoute][splitLastRoute[1]][splitLastRoute[2]]['META_TITLE'];
          metaDesc = Texts[parentRoute][splitLastRoute[1]][splitLastRoute[2]]['META_DESCRIPTION'];
        }
      } else {
        pageTitle = Texts[parentRoute]['PAGE_TITLE'];
        metaTitle = Texts[parentRoute]['META_TITLE'];
        metaDesc = Texts[parentRoute]['META_DESCRIPTION'];
      }
    } catch (error) {
      console.error(error)
    }
    this.titleService.setTitle(pageTitle);
    this.meta.updateTag({ property: 'og:title', content: metaTitle });
    this.meta.updateTag({ property: 'og:description', content: metaDesc });
    this.meta.updateTag({ name: 'title', content: metaTitle });
    this.meta.updateTag({ name: 'description', content: metaDesc });
  }
}

const Texts = {
  default: {
    "PAGE_TITLE": "DEFAULT_PAGE_TITLE",
    "META_TITLE": "DEFAULT_META_TITLE",
    "META_DESCRIPTION": "DEFAULT_META_DESCRIPTION"
  },
  ecom: {
    "PAGE_TITLE": "ECOM_PAGE_TITLE",
    "META_TITLE": "ECOM_META_TITLE",
    "META_DESCRIPTION": "ECOM_META_DESCRIPTION"
  },
  pos: {
    "PAGE_TITLE": "POS_PAGE_TITLE",
    "META_TITLE": "POS_META_TITLE",
    "META_DESCRIPTION": "POS_META_DESCRIPTION"
  },
  space: {
    "PAGE_TITLE": "SPACE_PAGE_TITLE",
    "META_TITLE": "SPACE_META_TITLE",
    "META_DESCRIPTION": "SPACE_META_DESCRIPTION"
  },
  order: {
    pos: {
      "1": {
        "PAGE_TITLE": "ORDER_POS_1_PAGE_TITLE",
        "META_TITLE": "ORDER_POS_1_META_TITLE",
        "META_DESCRIPTION": "ORDER_POS_1_META_DESCRIPTION"
      },
      "2": {
        "PAGE_TITLE": "ORDER_POS_2_PAGE_TITLE",
        "META_TITLE": "ORDER_POS_2_META_TITLE",
        "META_DESCRIPTION": "ORDER_POS_2_META_DESCRIPTION"
      },
      "3": {
        "PAGE_TITLE": "ORDER_POS_3_PAGE_TITLE",
        "META_TITLE": "ORDER_POS_3_META_TITLE",
        "META_DESCRIPTION": "ORDER_POS_3_META_DESCRIPTION"
      },
      "4": {
        "PAGE_TITLE": "ORDER_POS_4_PAGE_TITLE",
        "META_TITLE": "ORDER_POS_4_META_TITLE",
        "META_DESCRIPTION": "ORDER_POS_4_META_DESCRIPTION"
      },
      "5": {
        "PAGE_TITLE": "ORDER_POS_5_PAGE_TITLE",
        "META_TITLE": "ORDER_POS_5_META_TITLE",
        "META_DESCRIPTION": "ORDER_POS_5_META_DESCRIPTION"
      },
      "6": {
        "PAGE_TITLE": "ORDER_POS_6_PAGE_TITLE",
        "META_TITLE": "ORDER_POS_6_META_TITLE",
        "META_DESCRIPTION": "ORDER_POS_6_META_DESCRIPTION"
      }
    },
    ecom: {
      "2": {
        "PAGE_TITLE": "ORDER_ECOM_2_PAGE_TITLE",
        "META_TITLE": "ORDER_ECOM_2_META_TITLE",
        "META_DESCRIPTION": "ORDER_ECOM_2_META_DESCRIPTION"
      },
      "3": {
        "PAGE_TITLE": "ORDER_ECOM_3_PAGE_TITLE",
        "META_TITLE": "ORDER_ECOM_3_META_TITLE",
        "META_DESCRIPTION": "ORDER_ECOM_3_META_DESCRIPTION"
      },
      "4": {
        "PAGE_TITLE": "ORDER_ECOM_4_PAGE_TITLE",
        "META_TITLE": "ORDER_ECOM_4_META_TITLE",
        "META_DESCRIPTION": "ORDER_ECOM_4_META_DESCRIPTION"
      }
    }
  },
  details: {
    ecom: {
      tbc: {
        "PAGE_TITLE": "DETAILS_ECOM_TBC_PAGE_TITLE",
        "META_TITLE": "DETAILS_ECOM_TBC_META_TITLE",
        "META_DESCRIPTION": "DETAILS_ECOM_TBC_META_DESCRIPTION"
      },
      link: {
        "PAGE_TITLE": "DETAILS_ECOM_LINK_PAGE_TITLE",
        "META_TITLE": "DETAILS_ECOM_LINK_META_TITLE",
        "META_DESCRIPTION": "DETAILS_ECOM_LINK_META_DESCRIPTION"
      },
      geopay: {
        "PAGE_TITLE": "DETAILS_ECOM_GEOPAY_PAGE_TITLE",
        "META_TITLE": "DETAILS_ECOM_GEOPAY_META_TITLE",
        "META_DESCRIPTION": "DETAILS_ECOM_GEOPAY_META_DESCRIPTION"
      }
    },
    pos: {
      integrated: {
        "PAGE_TITLE": "DETAILS_POS_INTEGRATED_PAGE_TITLE",
        "META_TITLE": "DETAILS_POS_INTEGRATED_META_TITLE",
        "META_DESCRIPTION": "DETAILS_POS_INTEGRATED_META_DESCRIPTION"
      },
      standard: {
        "PAGE_TITLE": "DETAILS_POS_STANDARD_PAGE_TITLE",
        "META_TITLE": "DETAILS_POS_STANDARD_META_TITLE",
        "META_DESCRIPTION": "DETAILS_POS_STANDARD_META_DESCRIPTION"
      },
      tpos: {
        "PAGE_TITLE": "DETAILS_POS_TPOS_PAGE_TITLE",
        "META_TITLE": "DETAILS_POS_TPOS_META_TITLE",
        "META_DESCRIPTION": "DETAILS_POS_TPOS_META_DESCRIPTION"
      },
      cashier: {
        "PAGE_TITLE": "DETAILS_POS_CASHIER_PAGE_TITLE",
        "META_TITLE": "DETAILS_POS_CASHIER_META_TITLE",
        "META_DESCRIPTION": "DETAILS_POS_CASHIER_META_DESCRIPTION"
      },
      android: {
        "PAGE_TITLE": "DETAILS_POS_ANDROID_PAGE_TITLE",
        "META_TITLE": "DETAILS_POS_ANDROID_META_TITLE",
        "META_DESCRIPTION": "DETAILS_POS_ANDROID_META_DESCRIPTION"
      },
      self: {
        "PAGE_TITLE": "DETAILS_POS_SELF_PAGE_TITLE",
        "META_TITLE": "DETAILS_POS_SELF_META_TITLE",
        "META_DESCRIPTION": "DETAILS_POS_SELF_META_DESCRIPTION"
      }
    }
  }
}

  // public setMetaInfo(lastRoute: string) {
  //   let splitLastRoute = lastRoute.split('/');
  //   if (!splitLastRoute[0]) {
  //     splitLastRoute.shift();
  //   }
  //   let urlToUse;
  //   switch (splitLastRoute[0]) {
  //     case 'ecom':
  //     case 'pos':
  //     case 'details':
  //     case 'space':
  //       urlToUse = splitLastRoute.join('_').toUpperCase();
  //       break;
  //     default:
  //       urlToUse = 'DEFAULT';
  //   }

  //   this.translateService
  //     .get('PAGE_TITLE_' + urlToUse)
  //     .pipe(take(1))
  //     .subscribe((title) => {
  //       this.titleService.setTitle(title);
  //     });
  //   this.translateService
  //     .get('META_TITLE_' + urlToUse)
  //     .pipe(take(1))
  //     .subscribe((content) => {
  //       this.meta.updateTag({ property: 'og:title', content });
  //     });
  //   this.translateService
  //     .get('META_DESCRIPTION_' + urlToUse)
  //     .pipe(take(1))
  //     .subscribe((content) => {
  //       this.meta.updateTag({ property: 'og:description', content });
  //     });
  // }


