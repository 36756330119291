import {ChangeDetectionStrategy, Component, Input, OnChanges, Output, SimpleChanges} from "@angular/core"
import {SwiperOptions} from "swiper";
import {Subject} from "rxjs";
import {LabelId} from "../../../common/enums/label-id.enum";

@Component({
    selector: 'support-videos',
    templateUrl: './support-videos.component.html',
    styleUrls: ['./support-videos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SupportVideosComponent {
    breakpoints: SwiperOptions['breakpoints'] = {
        1180: {
            slidesPerView: 3
        },
        800: {
            slidesPerView: 2,
        },
        0: {
            slidesPerView: 1
        }
    }
    activeLabel: LabelId = undefined;
    labelId = LabelId;
    @Input() videos: any[];
    @Output() categoryChange: Subject<LabelId> = new Subject<LabelId>();
    labels: any = [
        {
            key_code: 'ALL_VIDEOS',
            labelId: undefined
        },
        {
            key_code: 'STANDARD_POS_VIDEOS',
            labelId: LabelId.StandardPos
        },
        {
            key_code: 'INTEGRATED_POS_VIDEOS',
            labelId: LabelId.IntegratedPos
        },
        {
            key_code: 'CASHIER_POS_VIDEOS',
            labelId: LabelId.CashierPos
        },
    ]

    changeLabel(labelId: LabelId = undefined) {
        this.activeLabel = labelId;
        this.categoryChange.next(labelId)

    }
}
