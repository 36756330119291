<generic-container textAlign="left" [titleKey]="titleKey">
    <div *ngIf="directory.productId !== 'tpos'" class="row products-list">
        <div *ngFor="let product of currentData?.data; let i = index; let last = last;" class="col-12 mb-lg-0 mb-4"
            [ngClass]="currentData?.data?.length === 4 ? 'col-sm-6 col-lg-3' : currentData?.data?.length === 3 && 'col-md-4'">
            <div>
                <div class="img-shared-wrap position-relative mb-3">
                    <img class="ml-auto mr-auto d-block" [ngClass]="{'is-pos': directory?.productType == 'pos'}"
                        src="../../../../assets/images/product-details/{{directory.productType}}/{{directory.productId}}/{{i+1}}.svg" [alt]="product.text | translate">
                </div>
                <div class="d-flex">
                    <h2 class="fs-16 font-weight-medium my-0 mx-auto d-block text-center desc">
                        {{product.text | translate}}
                    </h2>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="directory.productId === 'tpos' && currentLang == 'ka'"  class="products-list row">
        <div class="col-12 mb-lg-0 mb-4 d-flex tpos-detail-cont">
            <div class="col-4 single-detail">
                <div class="img-shared-wrap position-relative mb-3">
                    <img class="ml-auto mr-auto d-block" [ngClass]="{'is-pos': directory?.productType == 'pos'}"
                        src="../../../../assets/images/product-details/pos/tpos/1.svg" alt="tpos">
                </div>
                <div class="d-flex">
                    <h2 class="fs-16 font-weight-medium my-0 mx-auto d-block text-center desc">
                        გადმოწერე და გააქტიურე აპლიკაცია <a href="https://play.google.com/store/apps/details?id=com.tbc_t2p.app" target="_blank" style="color: #00AEEF; text-decoration: underline;">T-POS</a> ტელეფონში, გამოიძახე და მიუთითე მომხმარებლის მიერ გადასახდელი თანხა.
                    </h2>
                </div>
            </div>
            <div class="col-4 single-detail">
                <div class="img-shared-wrap position-relative mb-3">
                    <img class="ml-auto mr-auto d-block" [ngClass]="{'is-pos': directory?.productType == 'pos'}"
                        src="../../../../assets/images/product-details/pos/tpos/2.svg" alt="დააფიქსირე მომხმარებლის ბარათი მოწყობილობაზე.">
                </div>
                <div class="d-flex">
                    <h2 class="fs-16 font-weight-medium my-0 mx-auto d-block text-center desc">
                        დააფიქსირე მომხმარებლის ბარათი მოწყობილობაზე.
                    </h2>
                </div>
            </div>
            <div class="col-4 single-detail">
                <div class="img-shared-wrap position-relative mb-3">
                    <img class="ml-auto mr-auto d-block" [ngClass]="{'is-pos': directory?.productType == 'pos'}"
                        src="../../../../assets/images/product-details/pos/tpos/3.svg" alt="გადახდა შესრულებულია - ქვითარი შეგიძლია მომხმარებელს გაუზიარო SMS-ით ან QR-ის სახით.">
                </div>
                <div class="d-flex">
                    <h2 class="fs-16 font-weight-medium my-0 mx-auto d-block text-center desc">
                        გადახდა შესრულებულია - ქვითარი შეგიძლია მომხმარებელს გაუზიარო SMS-ით ან QR-ის სახით.
                    </h2>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="directory.productId === 'tpos' && currentLang == 'en'"  class="products-list row">
        <div class="col-12 mb-lg-0 mb-4 d-flex">
            <div class="col-4 single-detail">
                <div class="img-shared-wrap position-relative mb-3">
                    <img class="ml-auto mr-auto d-block" [ngClass]="{'is-pos': directory?.productType == 'pos'}"
                        src="../../../../assets/images/product-details/pos/tpos/1.svg" alt="tpos">
                </div>
                <div class="d-flex">
                    <h2 class="fs-16 font-weight-medium my-0 mx-auto d-block text-center desc">
                        Download and activate the application <a href="https://play.google.com/store/apps/details?id=com.tbc_t2p.app" target="_blank" style="color: #00AEEF; text-decoration: underline;">T-POS</a> on the phone, open and specify the amount to be paid by the user.
                    </h2>
                </div>
            </div>
            <div class="col-4 single-detail">
                <div class="img-shared-wrap position-relative mb-3">
                    <img class="ml-auto mr-auto d-block" [ngClass]="{'is-pos': directory?.productType == 'pos'}"
                        src="../../../../assets/images/product-details/pos/tpos/2.svg" alt="Attach the user card to the device.">
                </div>
                <div class="d-flex">
                    <h2 class="fs-16 font-weight-medium my-0 mx-auto d-block text-center desc">
                        Attach the user card to the device.
                    </h2>
                </div>
            </div>
            <div class="col-4 single-detail">
                <div class="img-shared-wrap position-relative mb-3">
                    <img class="ml-auto mr-auto d-block" [ngClass]="{'is-pos': directory?.productType == 'pos'}"
                        src="../../../../assets/images/product-details/pos/tpos/3.svg" alt="Payment is made - you can share the receipt with the customer via SMS or QR.">
                </div>
                <div class="d-flex">
                    <h2 class="fs-16 font-weight-medium my-0 mx-auto d-block text-center desc">
                        Payment is made - you can share the receipt with the customer via SMS or QR.
                    </h2>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="directory?.productId === 'tpos'" class="container-fluid mt-5 d-flex justify-content-center btn-cont">
        <a [href]="'assets/statics/product-details/tpos_triplet.pdf'" target="_blank">
            <button class="how-to-use-btn">{{ 'HOW_TO_USE' | translate }}</button>
        </a>
    </div>
</generic-container>