
import {Injectable} from "@angular/core";

@Injectable()

export class AnalyticsService {

    POS_TERMINALS = {
        "/order/pos/1/1": "S3_standard_start_1",
        "/order/pos/1/2": "S3_standard_personal_2",
        "/order/pos/1/3": "S3_standard_company_3",
        "/order/pos/1/4": "S3_standard_additional_4",
        "/order/pos/1/6": "S3_standard_success",
        "/order/pos/2/1": "integrated_Step1",
        "/order/pos/2/2": "integrated_Step2",
        "/order/pos/2/3": "integrated_success",
        "/order/pos/3/1": "S3_cashier_start_1",
        "/order/pos/3/2": "S3_cashier_personal_3",
        "/order/pos/3/3": "S3_cashier_company_3",
        "/order/pos/3/4": "S3_cashier_additional_4",
        "/order/pos/3/6": "S3_cashier_success",
        "/order/pos/4/1": "S3_selfservice_start_1",
        "/order/pos/4/2": "S3_selfservice_personal_3",
        "/order/pos/4/3": "S3_selfservice_company_3",
        "/order/pos/4/4": "S3_selfservice_additional_4",
        "/order/pos/4/6": "S3_selfservice_success",
        "/order/pos/6/1": "tpos_step1",
        "/order/pos/6/2": "tpos_success",
    };

    ECOM = {
        "/order/ecom/2/1": "S3_invoice_personal_1",
        "/order/ecom/2/2": "S3_invoice_company_2",
        "/order/ecom/2/3": "S3_invoice_business_3",
        "/order/ecom/2/5": "S3_ecommerce_success",
        "/order/ecom/3/1": "S3_ecommerce_personal_1",
        "/order/ecom/3/2": "S3_ecommerce_company_2",
        "/order/ecom/3/3": "S3_ecommerce_business_3",
        "/order/ecom/3/4": "S3_ecommerce_payments_4",
        "/order/ecom/3/5": "S3_ecommerce_additional_5",
        "/order/ecom/3/7": "S3_ecommerce_success",
        "/order/ecom/4/1": "S3_paybylink_personal_1",
        "/order/ecom/4/2": "S3_paybylink_company_2",
        "/order/ecom/4/3": "S3_paybylink_business_3",
        "/order/ecom/4/4": "S3_paybylink_payments_4",
        "/order/ecom/4/5": "S3_paybylink_additional_5",
        "/order/ecom/4/7": "S3_paybylink_success",
    };

    sendPosEvent(url: string) {
        setTimeout(() => {
            try {
                (<any>window).fbq('track', this.POS_TERMINALS[url]);
                (<any>window).gtag("event", "event", {eventCategory: 'POS Terminals', eventAction: this.POS_TERMINALS[url], eventLabel: url})
            } catch (e) {
                console.error('Tracker Error', e)
            }
        })
    }

    sendEcomEvent(url: string) {
       setTimeout(() => {
            try {
                (<any>window).fbq('track', this.ECOM[url]);
                (<any>window).gtag("event", "event", {eventCategory: 'E-commerce', eventAction: this.ECOM[url], eventLabel: url})
            } catch (e) {
                console.error('Tracker Error', e)
            }
       })
    }

}
