import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SupportPageComponent } from "./support-page.component";
import { FaqComponent } from "../faq/faq.component";
import { SharedModule } from "../../shared/shared.module";
import { SupportContactUsComponent } from "./contact-us/support-contact-us.component";
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { FormsModule } from "@angular/forms";
import { SelectLabelComponent } from "./select-label/select-label.component";
import { RouterModule } from "@angular/router";
import { SupportPageService } from './services/support-page.service';
import { FaqModule } from '../faq/faq.module';
import { FaqService } from '../faq/services/faq.service';
import { GenericContainerModule } from '../shared/generic-container/generic-container.module';
import { SharedHeaderModule } from '../shared/shared-header/shared-header.module';
import { ContactInfoModule } from '../shared/contact-info/contact-info.module';
import {VideoModule} from "../shared/video/video.module";
import {SlickModule} from "../shared/slick/slick.module";
import {SupportVideosModule} from "../shared/support-videos/support-videos.module";

@NgModule({
  declarations: [
    SupportPageComponent,
    SupportContactUsComponent,
    SearchBarComponent,
    SelectLabelComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule,
        FaqModule,
        GenericContainerModule,
        SharedHeaderModule,
        ContactInfoModule,
        VideoModule,
        SlickModule,
        SupportVideosModule
    ],
  providers: [FaqService, SupportPageService],
  exports: [SupportPageComponent]
})
export class SupportPageModule { }
