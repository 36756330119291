import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { OrderGenericContainerComponent } from './order-generic-container.component';
import { customTranslateLoaderFactory } from '../../../core/loaders/translate.loader';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';

@NgModule({
  declarations: [OrderGenericContainerComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoaderFactory,
        deps: [HttpClient, Router, AppConfiguraitonService]
      }
    })
  ],
  exports: [
    OrderGenericContainerComponent
  ]
})
export class OrderGenericContainerModule { }
