import {AbstractControl, ValidatorFn} from "@angular/forms";

export const NumbersLettersAndDash = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const regex = /^[\w-]*$/
        if (regex.test(control.value)) {
            return null;
        } else {
            return {invalidCharacters: true}
        }
    }
}