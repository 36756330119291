import {NgModule} from "@angular/core";
import {SupportVideosComponent} from "./support-videos.component";
import {SwiperModule} from "swiper/angular";
import {GenericContainerModule} from "../generic-container/generic-container.module";
import {VideoModule} from "../video/video.module";
import {CustomSwiperModule} from "../custom-swiper/custom-swiper.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [SupportVideosComponent],
    imports: [SwiperModule, GenericContainerModule, VideoModule, CustomSwiperModule, CommonModule, TranslateModule],
    exports: [SupportVideosComponent]
})

export class SupportVideosModule {

}
