<div [ngClass]="!noSpacer && 'generic-spacer'">
  <div class="wrap container-fluid">
    <div class="row">
      <div class="col">
        <!-- <div [ngClass]="!noSpacer && 'generic-spacer-bottom'" *ngIf="boldTitle.length"
          class="caps text-center {{textAlign ? 'text-md-' + textAlign : 'text-md-center'}}">
          <h1 class="generic-header"
            [innerHTML]="bold === 'first' ? boldTitle[0] : bold === 'last' ? boldTitle[1] : boldTitle[2]"></h1>
        </div> -->
        <div *ngIf="titleKey" [ngClass]="!noSpacer && 'generic-spacer-bottom'"
          class="caps text-center {{textAlign ? 'text-md-' + textAlign : 'text-md-center'}}">
          <h2 class="generic-header font-weight-medium">{{titleKey | translate}}</h2>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>