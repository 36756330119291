import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-header',
  templateUrl: './shared-header.component.html',
  styleUrls: ['./shared-header.component.scss']
})
export class SharedHeaderComponent {
  @Input() showCurves: boolean;
  @Input() detailsPage: boolean;
}
