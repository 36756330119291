import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shopify-banner',
  templateUrl: './shopify-banner.component.html',
  styleUrls: ['./shopify-banner.component.scss']
})
export class ShopifyBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
