<generic-container>
    <div class="shopify_banner_wrapper">
        <div class="shopify_img">
            <img class="dots" src="../../../../assets/images/products/ecom/Ellipse.svg" alt="">
            <img src="../../../../assets/images/products/ecom/shopify-banner.jpg" alt="">
        </div>
        <div class="shopify_info">
            <h1>{{ 'SHOPIFY_HEADER' | translate }}</h1>
            <p>{{ 'SHOPIFY_DESCRIPTION_PART_1' | translate }}</p>
            <p>{{ 'SHOPIFY_DESCRIPTION_PART_2' | translate }}</p>
            <!-- <div class="shopify_offer_list">
                <div class="list"><span><img src="./assets/list/check.716c6635b16b4574.svg.svg" alt="list_icon"></span>{{ 'SHOPIFY_LIST_1' | translate }}</div>
                <div class="list"><span><img src="./assets/list/check.716c6635b16b4574.svg.svg" alt="list_icon"></span>{{ 'SHOPIFY_LIST_2' | translate }}</div>
                <div class="list"><span><img src="./assets/list/check.716c6635b16b4574.svg.svg" alt="list_icon"></span>{{ 'SHOPIFY_LIST_3' | translate }}</div>
            </div> -->
        </div>  
    </div>
</generic-container>