<h1 class="caps font-weight-bold text-center pb-2rem mt-2">
    {{product.toUpperCase() + '_' + productChild + '_FORM_TITLE' | translate}}
</h1>
<div *ngIf="formSteps.length > 2" class="pb-2rem">
    <div class="wrap" *ngIf="currentStep !== formSteps.length">
        <progress-step [product]="product" [currentStep]="currentStep" [stepList]="formSteps"
                       (stepChanged)="stepperStepChanged($event)">
        </progress-step>
    </div>
</div>
<div class="order-form-wrap">
    <h1 class="caps font-weight-bold text-center pb-2rem" style="color: #F00" *ngIf="validationSummary">
        {{validationSummary | translate}}</h1>
    <h1 class="caps font-weight-bold text-center pb-2rem" *ngIf="formTitle && !validationSummary">
        {{formTitle | translate}}</h1>

    <section *ngIf="product === 'pos'">
        <pos-company *ngIf="currentStep === 1 && productChild !== 6 && productChild !== 8" [formFields]="formFieldData.POS_COMPANY" section="POS_COMPANY"
                     (formSubmitted)="nextStep($event)" [formStepSubmit]="formStepSubmit" [displayLoader]="formSubmitted"
                     [serverErrorData]="serverErrorData">
        </pos-company>
        <pos-terminal *ngIf="currentStep === 2 && productChild !== 6 && productChild !== 8" (formSubmitted)="nextStep($event)" [formFields]="formFieldData.POS_TERMINAL" section="POS_TERMINAL"
                      (goBack)="stepChanged($event || currentStep - 1)"
                      (formSent)="sendFormFinal($event)" [displayLoader]="formSubmitted" [posType]="productChild"
                      [serverErrorData]="serverErrorData">
        </pos-terminal>
        <pos-tpos *ngIf="productChild === 6 && currentStep === 1" (formSubmitted)="nextStep($event)"  [formFields]="formFieldData.POS_T_POS" section="POS_T_POS"
                    (formSent)="sendFormFinal($event)" [displayLoader]="formSubmitted" [posType]="productChild"
                    [serverErrorData]="serverErrorData"></pos-tpos>
        <pos-qrhoreca *ngIf="productChild === 8 && currentStep === 1" (formSubmitted)="nextStep($event)"  [formFields]="formFieldData.POS_QR_HORECA" section="POS_QR_HORECA"
                    (formSent)="sendFormFinal($event)" [displayLoader]="formSubmitted" [posType]="productChild"
                    [serverErrorData]="serverErrorData"></pos-qrhoreca>
    </section>

    <section *ngIf="product === 'ecom' && !isGeoPay">
        <ecom-company-details *ngIf="currentStep === 1" (formSubmitted)="nextStep($event)"
                              [formFields]="formFieldData.ECOM_COMPANY" section="ECOM_COMPANY" [formStepSubmit]="formStepSubmit"
                              [displayLoader]="formSubmitted" [ecomType]="productChild" [serverErrorData]="serverErrorData"
                              (goBack)="stepChanged($event || currentStep - 1)">
        </ecom-company-details>
        <ecom-working-sphere *ngIf="currentStep === 2" (formSubmitted)="nextStep($event)" [ecomType]="productChild" (formSent)="sendFormFinal($event)"
                             [formFields]="formFieldData.ECOM_SPHERE" section="ECOM_SPHERE"
                             [displayLoader]="formSubmitted" (sphereChanged)="onSphereChange($event)" [eComApplicationId]="orderLeadId"
                             [serverErrorData]="serverErrorData" (goBack)="stepChanged($event || currentStep - 1)">
        </ecom-working-sphere>
    </section>

    <section *ngIf="isGeoPay">
        <geopay-personal *ngIf="currentStep === 1" (formSubmitted)="nextStep($event)"
                         [formFields]="formFieldData.GEO_PERSONAL" section="GEO_PERSONAL" [formStepSubmit]="formStepSubmit"
                         [displayLoader]="formSubmitted" [serverErrorData]="serverErrorData"
                         (goBack)="stepChanged($event || currentStep - 1)"></geopay-personal>
        <geopay-company *ngIf="currentStep === 2" (formSubmitted)="nextStep($event)"
                        [formFields]="formFieldData.GEO_COMPANY" section="GEO_COMPANY" [formStepSubmit]="formStepSubmit"
                        [displayLoader]="formSubmitted" [serverErrorData]="serverErrorData"
        ></geopay-company>
        <geopay-sphere *ngIf="currentStep === 3" (formSent)="sendFormFinal($event)" [formFields]="formFieldData.GEO_SPHERE"
                       section="GEO_SPHERE" [formStepSubmit]="formStepSubmit" [displayLoader]="formSubmitted"
                       [serverErrorData]="serverErrorData" (goBack)="stepChanged($event || currentStep - 1)"
                       [geopayApplicationId]="orderLeadId"></geopay-sphere>
    </section>

    <section>
        <success-page *ngIf="currentStep === formSteps.length"></success-page>
    </section>

    <re-captcha style="visibility: hidden" #captchaRef='reCaptcha' size="invisible" (resolved)="$event && recaptchaResolved($event)"
                siteKey="6Lf95tMZAAAAAJmvvrZX3Mm1dE3EDq7zAXEt4ymV">
    </re-captcha>
</div>