<div [ngClass]="detailsPage && 'details-page'" class="background position-relative background-color-alice-blue">
    <div *ngIf="showCurves">
        <div class="background-assets-left">
            <img src="../../../../assets/images/support/search-bar-curve-left.png" alt="search bar">
        </div>
        <div class="background-assets-right">
            <img src="../../../../assets/images/support/search-bar-curve-right.png" alt="search bar">
        </div>
    </div>

    <ng-content></ng-content>
</div>