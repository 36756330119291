import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  currentLang: string;

  @Output() cookieAgreed: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Inject(DOCUMENT) private _document: Document, private translateService: TranslateService,) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
  }

  public agreeCookie(): void {
    this._document.cookie = 'cookie_agreed=1';
    this.cookieAgreed.emit();
  }

  refresh() {
    this.ngOnInit()
  }
}
