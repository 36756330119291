<section>
    <form [formGroup]="formGroup" (submit)="goToNextStep()">
        <div class="fields-container">
            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.contactFullName.keyCode | translate}}"
                       formControlName="contactFullName">
                <mat-error *ngIf="contactFullName.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="contactFullName.errors?.pattern
                && (contactFullName.dirty || contactFullName.touched)">
                    {{'ERRORS_ENTER_NAME' | translate}}</mat-error>
                <mat-error *ngIf="contactFullName.errors?.serverError
                && (contactFullName.dirty || contactFullName.touched)">
                    {{contactFullName.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.contactPhoneNumber.keyCode | translate}}"
                       formControlName="contactPhoneNumber">
                <mat-error *ngIf="contactPhoneNumber.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="contactPhoneNumber.errors?.maxlength">
                    {{'PHONE_NUMBER_MAX_LENGTH_25' | translate}}</mat-error>
                <mat-error *ngIf="contactPhoneNumber.errors?.phoneNumberInvalid && contactPhoneNumber.value.length > 0 && !contactPhoneNumber.errors?.maxlength">
                    {{'ERROR_PHONE_NUMBER' | translate}}</mat-error>
                <mat-error *ngIf="contactPhoneNumber.errors?.serverError
                || contactPhoneNumber.errors?.serverError && contactPhoneNumber.touched">
                    {{contactPhoneNumber.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.contactEmail.keyCode | translate}}"
                       formControlName="contactEmail">
                <mat-error *ngIf="contactEmail.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="contactEmail.errors?.pattern
                || contactEmail.errors?.pattern && contactEmail.touched">
                    {{'ERRORS_ENTER_EMAIL_CORRECTLY' | translate}}</mat-error>
                <mat-error *ngIf="contactEmail.errors?.serverError
                || contactEmail.errors?.serverError && contactEmail.touched">
                    {{contactEmail.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div style="margin-top: 2rem;">
            <tbc-button [showLoader]="displayLoader" [disabled]="false" [showArrow]="true"
                        [maxWidth]="true" text="{{'CONTINUE' | translate}}">
            </tbc-button>
        </div>
    </form>
</section>