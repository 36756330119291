import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';

@Injectable()
export class ExternalLeadService {
  private orderProductPath: string = "/Lead";

  constructor(private config: AppConfiguraitonService, private http: HttpClient) { }

  public sendEmployeeRef(lead: any, recaptchaToken: any): Observable<any> {
    const url = `${this.config.baseAddress}${this.orderProductPath}/EmployeeReferral`;
    if (recaptchaToken) {
      return this.http.post(url, lead,
        { headers: new HttpHeaders().set('x-recaptcha-response', recaptchaToken) }
      );
    }
    return this.http.post(url, lead);
  }
}
