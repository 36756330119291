import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Constants } from '../../../common/constants';

export const NumbersOnly = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const initialValue = control.value;
        let tempVal = initialValue.replace(/[^0-9]*/g, '');

        if (initialValue !== tempVal) {
            control.patchValue(tempVal);
            control.markAsTouched();

            return { NumbersOnly: true };
        }

        return null;
    }
}

export const PhoneNumberValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const value = control.value;
        if (value) {
            const phoneNumber = value.slice().replace(/\D/g, '');
            const regex = Constants.RegexPatterns["phone-number"];

            if (phoneNumber.length < 9 || phoneNumber.length > 14 || !regex.test(value)) {
                return { phoneNumberInvalid: true };
            }

            return null;
        }
    }
}

export const IDNumberValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const value = control.value;
        if (value && value.length !== 9 && value.length !== 11) {
            return {idNumberInvalidLength: true};
        }
        return null;
    }
}

export const NumbersOnlyAndLength = (lengths: number[]): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const initialValue = control.value;
        let tempVal = initialValue.replace(/[^0-9]*/g, '');

        if (initialValue !== tempVal) {
            control.patchValue(tempVal);
            if (checkLength(lengths, tempVal.length)) {
                return null;
            }

            control.markAsTouched();
            return { NumbersOnly: true };
        }

        if (!checkLength(lengths, tempVal.length) && tempVal.length) {
            return { Length: true };
        }

        return null;
    }
}

export const MaxValue = (max: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const initialValue = parseInt(control.value);
        if(initialValue > max) {
            control.markAsTouched();
            return {MaxValue: true}
        }
        return null;
    }
}

const checkLength = (validLengths: number[], controlLength: number): boolean => {
    let bool = false;
    validLengths.forEach(validLength => {
        if (validLength === controlLength) {
            bool = true;
        }
    })
    return bool;
}
