import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  public setCookie(name, value, days = 1, domain = null) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = domain ?
        name + "=" + (value || "") + "; expires=" + date.toUTCString() + '; domain=' + domain :
        name + "=" + (value || "") + "; expires=" + date.toUTCString();
  }

  public getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  public deleteCookie(name: string, domain: string = null): void {
    document.cookie = domain ? `${name}=; expires=Thu, 1 jan 1970 00:00:00 UTC; path=/; domain=${domain}` : `${name}=; expires=Thu, 1 jan 1970 00:00:00 UTC; path=/`
  }

}
