<div class="wrapper d-flex h-100">
    <div class="left">
        <img src="../../../../../assets/images/contact-info/online-shop.svg" [alt]="'CONTACT_INFO_ORDER_ONLINE_TITLE' | translate">
    </div>
    <div class="right d-flex flex-column col px-0">
        <h3 class="font-weight-bold">{{'CONTACT_INFO_ORDER_ONLINE_TITLE' | translate}}</h3>
        <p class="mb-2 mb-md-4">{{'CONTACT_INFO_ORDER_ONLINE_DESC' | translate}}</p>
        <div class="go-to mt-auto">
            <a *ngIf="!disabled" [routerLink]="link" class="link font-weight-medium caps">{{ "REQUEST_APPLICATION" | translate }}</a>
            <a *ngIf="disabled" class="link font-weight-medium caps disabled">{{ "REQUEST_APPLICATION" | translate }}</a>
        </div>
    </div>
</div>