import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Observable } from 'rxjs';
import { GenericResponse } from '../common/entities/generic-response';
import { Dictionary } from '../common/entities/localization/dictionary';
import { AppConfiguraitonService } from './app-configuration.service';
import { GenericResponseData } from '../common/entities/generic-response-data';

@Injectable()
export class LocalizationService {
    private readonly localizationBasePath: string = "/Localization";

    constructor(private genericService: GenericService, private appConfiguraitonService: AppConfiguraitonService) { }

    public getTranslation(keyCode: string, language: string): Observable<GenericResponse<GenericResponseData<Array<Dictionary>>>> {
        return this.genericService.get(`${this.appConfiguraitonService.baseAddress}${this.localizationBasePath}/dictionary/code`,
            { code: keyCode, language });
    }
}