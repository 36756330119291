import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderProductComponent } from './order-product.component';
import { RouterModule, Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductInfoModule } from '../shared/product-info/product-info.module';
import { OrderGenericContainerModule } from '../shared/order-generic-container/order-generic-container.module';
import { orderProductRoutes } from './routing/order-product.routing';
import { ProgressBarModule } from '../shared/progress-bar/progress-bar.module';
import { customTranslateLoaderFactory } from '../../core/loaders/translate.loader';
import { HttpClient } from '@angular/common/http';
import { RequestFormModule } from './request-form/request-form.module';

import { ProductInfoService } from '../shared/product-info/services/product-info.service';
import { OrderProductFormGuard } from './guards/order-product-form.guard';
import { ChooseProductComponent } from './choose-product/choose-product.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { AppConfiguraitonService } from '../../services/app-configuration.service';
import { ChooseProductParentComponent } from './choose-product-parent/choose-product-parent.component';
import {SaveFormService} from "./services/save-form.service";

@NgModule({
	declarations: [
		OrderProductComponent,
		ChooseProductComponent,
		OrderSuccessComponent,
		ChooseProductParentComponent
	],
	imports: [
		CommonModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: customTranslateLoaderFactory,
				deps: [HttpClient, Router, AppConfiguraitonService]
			}
		}),
		RouterModule.forChild(orderProductRoutes),
		ProductInfoModule,
		OrderGenericContainerModule,
		ProgressBarModule,
		MatInputModule,
		MatSelectModule,
		ReactiveFormsModule,
		RequestFormModule
	],
	providers: [
		OrderProductFormGuard,
		ProductInfoService,
		SaveFormService
	]
})
export class OrderProductModule { }
