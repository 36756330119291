import { Component, OnChanges, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'product-partners',
	templateUrl: './product-partners.component.html',
	styleUrls: ['./product-partners.component.scss']
})
export class ProductPartnersComponent implements OnChanges {
	@Input() directory: { productType: string, productId: string };
	public currentData: any;
	public productType: string;

	public data = {
		pos: {
			integrated: {
				title: 'HOW_TO_JOIN',
				left_title: 'POS_INTEGRATED_PARTNER_LEFT_TITLE',
				left_description: 'POS_INTEGRATED_PARTNER_LEFT_DESCRIPTION',
				right_title: 'POS_INTEGRATED_PARTNER_RIGHT_TITLE',
				right_description: 'POS_INTEGRATED_PARTNER_RIGHT_DESCRIPTION',
				icon_data: [
					{
						img: '../../../../assets/images/product-details/partners/apex_logo.png',
						link: 'https://www.apex.ge',
						description: 'APEX_DETAILS'
					},
					{
						img: '../../../../assets/images/product-details/partners/bdo_logo.png',
						link: 'https://www.bdo.ge',
						description: 'BDO_DETAILS'
					},
					{
						img: '../../../../assets/images/product-details/partners/fina_logo.png',
						link: 'http://www.fina.ge',
						description: 'FINA_DETAILS'
					},
					{
						img: '../../../../assets/images/product-details/partners/sap.png',
						link: 'https://www.sap.com/index.html',
						description: 'SAP_DETAILS'
					},
					{
						img: '../../../../assets/images/product-details/partners/fmg.png',
						link: 'http://fmgsoft.ge',
						description: 'FMG_DETAILS',
						height: 36
					}
				]
			}
		},
		ecom: {
			tbc: {
				title: 'ECOM_TBC_CHECKOUT_PARTNER_TITLE',
				left_title: 'ECOM_TBC_CHECKOUT_LEFT_TITLE',
				left_description: 'ECOM_TBC_CHECKOUT_PARTNER_LEFT_DESCRIPTION',
				right_title: 'ECOM_TBC_CHECKOUT_PARTNER_RIGHT_TITLE',
				right_description: 'ECOM_TPAY_GET_INVOLVED_YOURSELF_DESCRIPTION',
				button_url: 'https://developers.tbcbank.ge',
				icon_data: [
					{
						img: '../../../../assets/images/product-details/partners/cscart_logo.png',
						link: 'https://cscart.ge/ka/%E1%83%9D%E1%83%9C%E1%83%9A%E1%83%90%E1%83%98%E1%83%9C-%E1%83%9B%E1%83%90%E1%83%A6%E1%83%90%E1%83%96%E1%83%98%E1%83%98%E1%83%A1-%E1%83%9B%E1%83%9D%E1%83%93%E1%83%A3%E1%83%9A%E1%83%94%E1%83%91%E1%83%98/%E1%83%97%E1%83%98%E1%83%91%E1%83%98%E1%83%A1%E1%83%98-%E1%83%92%E1%83%90%E1%83%93%E1%83%90%E1%83%AE%E1%83%93%E1%83%94%E1%83%91%E1%83%98-%E1%83%92%E1%83%90%E1%83%93%E1%83%90%E1%83%AE%E1%83%93%E1%83%98%E1%83%A1-%E1%83%9B%E1%83%94%E1%83%97%E1%83%9D%E1%83%93%E1%83%98-ka/',
						description: 'CSCART_DETAILS'
					},
					{
						img: '../../../../assets/images/product-details/partners/woocomerce.png',
						link: 'https://wordpress.org/plugins/search/tpay-ge-woocommerce/',
						description: 'PNP_DETAILS',
						height: 24
					},
					{
						img: '../../../../assets/images/product-details/partners/b2c_logo.svg',
						link: 'https://www.b2c.ge',
						description: 'B2C_DETAILS',
						height: 24
					},
					{
						img: '../../../../assets/images/product-details/partners/kovzy.svg',
						link: 'https://kovzy.com',
						description: 'KOVZY_DESC',
						height: 24
					},
					{
						img: '../../../../assets/images/product-details/partners/shopify_logo.svg',
						name: 'shopify',
						description: 'SHOPIFY_DETAILS'
					}
				]
			}

		}
	}

	constructor(private route: ActivatedRoute) {
		this.isRedirectToPortalButtonNeeded();
	}

	ngOnChanges(): void {
		this.currentData = this.data[this.directory.productType][this.directory.productId];
	}

	private isRedirectToPortalButtonNeeded(): void {
		this.route.params
			.subscribe((params: { productType: string, productId: string }) => {
				this.productType = params.productType;
			});
	}
}
