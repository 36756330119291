import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'progress-step',
  templateUrl: './progress-step.component.html',
  styleUrls: ['./progress-step.component.scss']
})
export class ProgressStepComponent {
  @Input() stepList: any;
  @Input() currentStep: number;
  @Output() stepChanged: EventEmitter<number> = new EventEmitter();
  @Input() product: 'pos' | 'ecom';

  public goToStep(step): void {
    if (this.currentStep != this.stepList[this.stepList.length - 1].route) {
      this.stepChanged.emit(step);
    }
  }
}
