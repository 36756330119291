import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionTimeoutService {
    userActivity;
    refreshTimeout;
    userInactive: Subject<any> = new Subject();
    timeoutLimit = 10 * 60 * 1000;
    timeIsOut: boolean;

    initTimeout() {
        this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.timeoutLimit);
    }

    restartTimeout() {
        clearTimeout(this.userActivity);
        this.initTimeout();
    }
}
