import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'product-details',
	templateUrl: './product-details.component.html',
	styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent {
	public directory;
	public productType: string;
	public productId: string;

	constructor(private activatedRoute: ActivatedRoute) {
		this.activatedRoute.params.subscribe(params => {
			this.directory = params;

			this.productType = params.productType;
			this.productId = params.productId;
		});
	}

	public get partnersShouldBeVisible(): boolean {
		return (this.productType == 'ecom' && this.productId == 'tbc') ||
			(this.productType == 'pos' && this.productId == 'integrated');
	}

	public controlPanelShouldBeVisible(): boolean {
		return this.productType === 'ecom' && (this.productId === 'tbc' || this.productId === 'link');
	}

	public functionsShouldBeVisible(): boolean {
		if (this.productType === 'ecom') {
			return true;
		}
		else if (this.productType == 'pos' && this.productId == 'integrated') {
			return false;
		}
		else {
			return true;
		}
	}

	public shopifyShouldBeVisible(): boolean {
		return (this.productType === 'ecom' && this.productId === 'tbc')
	}
}