import { Injectable } from '@angular/core';

@Injectable()
export class PosTableService {
  headers = ["POS_STANDARD", "CASHIER", "QR_EAT", "T_POS", "ANDROID", "INTEGRATED", "SELF_SERVICE",];
  fields = [
    {
      title: '',
      show: true,
      showExpand: false,
      values: [
        [
          { type: 'text', text: 'POS_INSTALLATION_TRAINING' },
          { type: 'text', text: 'FREE' },
          { type: 'text', text: 'FREE' },
          { type: 'text', text: 'FREE' },
          { type: 'text', text: 'FREE' },
          { type: 'text', text: 'FREE' },
          { type: 'text', text: 'FREE' },
          { type: 'text', text: 'FREE' }
        ]
      ]
    },
    {
      title: '',
      show: true,
      showExpand: false,
      values: [
        [
          { type: 'text', text: 'POS_MIN_MONTHLY_FEE' },
          { type: 'text', text: 'POS_STANDARD_COMISSION' },
          { type: 'text', text: 'POS_CASHIER_COMISSION' },
          { type: 'text', text: 'T_POS_COMISSION' },
          { type: 'text', text: 'T_POS_COMISSION' },
          { type: 'text', text: 'POS_ANDROID_COMISSION' },
          { type: 'text', text: 'POS_INTEGRATED_COMISSION' },
          { type: 'text', text: 'POS_SELF_COMISSION' },

        ]
      ]
    },
    {
      title: 'POS_TRANSACTION_COMMISSION_FEE',
      show: true,
      showExpand: true,
      values: [
        [
          { type: 'text', text: 'POS_COST_TBC_CARDS' },
          { type: 'text', text: 'POS_COST_TBC_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_TBC_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_TBC_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_TBC_CARDS_VALUE_T_POS' },
          { type: 'text', text: 'POS_COST_TBC_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_TBC_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_TBC_CARDS_VALUE' },
        ],
        [
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS' },
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS_VALUE' },
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS_VALUE' },
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS_VALUE' },
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS_VALUE_T_POS' },
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS_VALUE' },
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS_VALUE' },
          { type: 'text', text: 'POS_COST_PARTNERS_BANKS_VALUE' },
        ],
        [
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS' },
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS_VALUE_T_POS' },
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_OTHER_GEORGIAN_CARDS_VALUE' },
        ],
        [
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS', subText: 'POS_COST_FOREIGN_CARDS_SUB' },
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS_VALUE_T_POS' },
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS_VALUE' },
          { type: 'text', text: 'POS_COST_FOREIGN_CARDS_VALUE' },
        ]
      ]
    }
  ]
}
