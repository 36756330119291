import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FilesService } from './services/files.service';
import { NgModule } from '@angular/core';
import { FileUploaderComponent } from './file-uploader.component';
import { DragDropDirective } from './directives/drag-drop.directive'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { customTranslateLoaderFactory } from '../../../core/loaders/translate.loader';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';
import { FileValidator } from "./services/file.validator";



@NgModule({
  declarations: [FileUploaderComponent, DragDropDirective],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatInputModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoaderFactory,
        deps: [HttpClient, Router, AppConfiguraitonService]
      }
    })
  ],
  exports: [FileUploaderComponent],
  providers: [
    FilesService,
    FileValidator
  ]
})
export class FileUploaderModule { }
