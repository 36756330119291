import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { GenericService } from '../../../services/generic.service';
import { UserStory } from '../models/user-story';
import { GenericResponse } from '../../../common/entities/generic-response';
import { GenericResponseData } from '../../../common/entities/generic-response-data';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';

@Injectable()
export class UserStoryService {

    openDialog: Subject<any> = new Subject<any>();

    private readonly userStoriesBasePath: string = "/UserStory";

    constructor(private genericService: GenericService, private appConfiguraitonService: AppConfiguraitonService) { }

    public getUserStories(): Observable<GenericResponse<GenericResponseData<Array<UserStory>>>> {
        return this.genericService.get(`${this.appConfiguraitonService.baseAddress}${this.userStoriesBasePath}`);
    }

    public getUserStory(userStoryId: number): Observable<GenericResponse<UserStory>> {
        return this.genericService.get(`${this.appConfiguraitonService.baseAddress}${this.userStoriesBasePath}/${userStoryId}`);
    }
}