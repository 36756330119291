import { CookieService } from './cookie.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {interval} from "rxjs";
import {AppConfiguraitonService} from "./app-configuration.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
      private translateService: TranslateService,
      private cookieService: CookieService,
      private appConfigurationService: AppConfiguraitonService) {
    interval(2000)
        .subscribe(r => {
          let lang = this.cookieService.getCookie("lang") as "ka" | "en" || "ka";
          if(lang !== this.translateService.currentLang) {
            this.translateService.use(lang)
            this.cookieService.setCookie("lang", lang, 1, this.appConfigurationService.getHostName());
          }
        })
  }

  public initLanguage() {
    // let lang = localStorage.getItem("lang") as "ka" | "en" || "ka";
    let lang = this.cookieService.getCookie("lang") as "ka" | "en" || "ka";
    this.translateService.setDefaultLang(lang);
    this.setLanguage(lang);
  }

  public setLanguage(language: 'ka' | 'en'): void {
    this.translateService.use(language);
    this.cookieService.deleteCookie("lang")
    localStorage.setItem("lang", language);
    this.cookieService.setCookie("lang", language, 1, this.appConfigurationService.getHostName());
  }

  public getCurrentLanguage() {
    return this.translateService.currentLang as 'ka' | 'en';
  }

  public onChange() {
    return this.translateService.onLangChange;
  }
}
