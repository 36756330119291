import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { of } from "rxjs";
import { debounceTime, pairwise, startWith, switchMap } from "rxjs/operators";
import { MenuLanguages } from '../../enums/menu-language';
import { PosQuantity } from '../../enums/pos-quantity';
import {SaveFormService} from "../../../services/save-form.service";

@Component({
  selector: 'pos-terminal',
  templateUrl: './pos-terminal.component.html',
  styleUrls: ['./pos-terminal.component.scss']
})
export class PosTerminalComponent implements OnInit {
  public formGroup: FormGroup;
  public timeError: boolean = false;
  public menuLanguages = MenuLanguages;
  public posQuantity = PosQuantity;
  private cursorPosition: number;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();

  @ViewChild('dayTimeInput') dayTimeInput: ElementRef;

  @Input() formFields;
  @Input() section;
  @Input() displayLoader: boolean;
  @Input() posType: number;
  @Input() set serverErrorData(serverErrors) {
    if (serverErrors) {
      if (serverErrors.length && this.formGroup) {
        serverErrors.forEach(serverError => {
          const formField = this.getFormProperty(serverError.fieldName);
          if (formField) {
            formField.setErrors(serverError.serverError);
          }
        });
      }
    }
  }

  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Output() formSent: EventEmitter<any> = new EventEmitter();
  @ViewChild('captchaRef') reCaptcha;

  constructor(private formBuilder: FormBuilder, private saveFormService: SaveFormService) { }

  ngOnInit(): void {
    this.formGroup = this.buildForm();
    if (this.posType === 1 || this.posType === 5) {
      this.formGroup.addControl('posReportingDetails', this.getAdditionalFunctionControls());
    }

    this.formGroup.valueChanges
      .pipe(debounceTime(300), switchMap(() => {
        this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, nextStep: false });
        return of(null)
      }))
      .subscribe()

    this.saveFormService.patchFormValue(this.formGroup, this.section);


    this.dayCloseTime.valueChanges
      .pipe(startWith(this.dayCloseTime.value), pairwise())
      .subscribe(([prev, next]: [any, any]) => {

        if (+`${next[0]}${next[1]}` >= 24) {
          this.dayCloseTime.setValue(next.substr(2))
        }

        if (next === ':') {
          this.dayCloseTime.setValue('00');
        }

        if (next[0] > 2) {
          const val = `0${next[0]}${next.substr(1)}`
          this.dayCloseTime.setValue(val);
        }

        if (next.length >= 3 && next[0] && next[1] && next[2] === ':') {
          this.dayTimeInput.nativeElement.selectionStart = 5;
        }

        if (prev.length == 1 && next.length == 2 && !next.includes(':')) {
          this.dayCloseTime.setValue(`${next}:00`)
        }
      })
  }

  public goBackEmit(): void {
    this.goBack.emit();
  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      count: new FormControl(this.formFields.count.value, [
        Validators.required
      ]),
      language: new FormControl(this.formFields.language.value, [
        Validators.required,
      ]),
      nameOnReceipt: new FormControl(this.formFields.nameOnReceipt.value, [
        Validators.pattern(new RegExp(/^[\u10A0-\u10FCa-zA-Z0-9 ]+$/i)),
        Validators.required,
      ]),
      dayCloseTime: new FormControl(this.formFields.dayCloseTime.value, [
        Validators.pattern(new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)),
        Validators.required,
      ]),
      nameInLatin: new FormControl(this.formFields.nameInLatin.value, [
        Validators.required,
        Validators.pattern(new RegExp(/^[\w\+\_\- ]+$/i))
      ])
    });
  }

  private getAdditionalFunctionControls(): FormGroup {
    return this.formBuilder.group({
      preauthorization: new FormControl(this.formFields.posReportingDetails.preauthorization.value),
      thankYou: new FormControl(this.formFields.posReportingDetails.thankYou.value),
      refund: new FormControl(this.formFields.posReportingDetails.refund.value),
      payWithLoyalPoints: new FormControl(this.formFields.posReportingDetails.payWithLoyalPoints.value),
      dcc: new FormControl(this.formFields.posReportingDetails.dcc.value)
    });

  }

  caretPosition(event) {
    this.cursorPosition = event.target.selectionStart;
    if (event.key === 'Backspace' &&
      this.dayCloseTime.value[this.cursorPosition - 1] == ':') {
      event.preventDefault();
      event.target.selectionStart -= 1;
      event.target.selectionEnd -= 1;
    }
  }

  public sendForm() {
    if (this.formGroup.status === 'VALID') {
      this.reCaptcha.execute();
    }
  }

  resolved(captchaResponse: string) {
    this.formSent.emit({ recaptchaToken: captchaResponse, ...this.formGroup.value });
    this.reCaptcha.reset();
  }

  public getFormProperty(propertyName: string): AbstractControl {
    return this.formGroup.get(propertyName);
  }

  public get count(): AbstractControl {
    return this.formGroup.get("count");
  }

  public get language(): AbstractControl {
    return this.formGroup.get("language");
  }

  public get nameOnReceipt(): AbstractControl {
    return this.formGroup.get("nameOnReceipt");
  }

  public get dayCloseTime(): AbstractControl {
    return this.formGroup.get("dayCloseTime");
  }

  public get nameInLatin(): AbstractControl {
    return this.formGroup.get("nameInLatin");
  }

}
