export class TableService {
    constructor() {
    }

    headers = ['ECOM_LINK', 'TBC_CHECKOUT_HEADER', "E_INVOICE_HEADER"];
    fields = [
        {
            title: 'PAYMENT_TYPES_FIELD',
            show: true,
            showExpand: true,
            tooltip: true,
            values: [
                [
                    { type: 'text', text: 'PAY_WITH_CARD' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                ],
                [
                    { type: 'text', text: 'QR' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ],
                [
                    { type: 'text', text: 'PAY_WITH_MOBILE_BANK_FIELD' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ],
                [
                    { type: 'text', text: 'PAYMENT_TYPE_APPLE_PAY' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ],
                [
                    { type: 'text', text: 'PAYMENT_TYPE_ERTGULI' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ],
                [
                    { type: 'text', text: 'PAYMENT_TYPE_INSTALLMENT' },
                    { type: 'img', text: '' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ],
               
                [
                    { type: 'text', text: 'PAYMENT_TYPE_PAY_LATER' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ],
                [
                    { type: 'text', text: 'PAYMENT_TYPE_SPLIT' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ],
            ]
        },
        {
            title: 'TRANSACTION_TYPES_FIELD',
            show: false,
            showExpand: true,
            tooltip: true,
            values: [
                [
                    { type: 'text', text: 'PAY_FIELD' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                ],
                [
                    { type: 'text', text: 'CANCEL_FIELD' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                ],
                [
                    { type: 'text', text: 'PRE_AUTH_FIELD' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                ],
                [
                    { type: 'text', text: 'RECURSION_FIELD' },
                    { type: 'img', text: '' },
                    { type: 'img', text: 'assets/images/check_blue.svg' },
                    { type: 'img', text: '' },
                ]
            ]
        },
        {
            title: 'RATES_FIELD',
            show: false,
            showExpand: true,
            values: [
                [
                    { type: 'text', text: 'GEORGIAN_CARDS' },
                    { type: 'text', text: 'GEORGIAN_CARDS_COL_LINK' },
                    { type: 'text', text: 'GEORGIAN_CARDS_COL_1' },
                    { type: 'text', text: 'GEORGIAN_CARDS_COL_3' },
                ],
                [
                    { type: 'text', text: 'FOREIGN_CARDS' },
                    { type: 'text', text: 'FOREIGN_CARDS_COL_LINK' },
                    { type: 'text', text: 'FOREIGN_CARDS_COL_1' },
                    { type: 'text', text: 'FOREIGN_CARDS_COL_3' },
                ],
                [
                    { type: 'text', text: 'MONTHLY_COMMISSION' },
                    { type: 'text', text: 'MONTHLY_COMMISSION_COL_LINK' },
                    { type: 'text', text: 'MONTHLY_COMMISSION_COL_1' },
                    { type: 'text', text: 'MONTHLY_COMMISSION_COL_3' },
                ],
                [
                    { type: 'text', text: 'APPLE_PAY_COMMISSION' },
                    { type: 'text', text: 'APPLE_PAY_COMMISSION_COL_LINK' },
                    { type: 'text', text: 'APPLE_PAY_COMMISSION_COL_1' },
                    { type: 'text', text: 'APPLE_PAY_COMMISSION_COL_3' },
                ]
            ]
        }
    ]
}