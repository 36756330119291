import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestAuthComponent } from './test-auth.component';

@NgModule({
  declarations: [TestAuthComponent],
  imports: [
    CommonModule,
  ]
})
export class TestAuthModule { }
