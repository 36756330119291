<div class="wrapper d-flex h-100">
    <div class="left">
        <img src="../../../../../assets/images/support/call.svg" [alt]="'CONTACT_INFO_CONTACT_US_TITLE' | translate">
    </div>
    <div class="right d-flex flex-column col px-0">
        <h3 class="font-weight-bold">{{'CONTACT_INFO_CONTACT_US_TITLE' | translate}}</h3>
        <p class=" mb-2 mb-md-4">{{'CONTACT_INFO_CONTACT_US_DESC' | translate}}</p>
        <div class="go-to mt-auto">
            <a class="link font-weight-medium" href="tel:+995322272727">2 27 27 27</a>
        </div>
    </div>
</div>