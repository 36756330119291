<form [formGroup]="formGroup" (submit)="goToNextStep()">
    <div class="fields-container">
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{identityNumberPlaceholder | translate}}" formControlName="identityNumber">
            <mat-error *ngIf="identityNumber.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.pattern
                && (identityNumber.dirty || identityNumber.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.idNumberInvalidLength  && !identityNumber.errors?.pattern
                && (identityNumber.dirty || identityNumber.touched)">
                {{'POS_ID_NUMBER_INVALID_LENGTH' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.serverError
                && (identityNumber.dirty || identityNumber.touched)">
                {{identityNumber.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.fieldOfActivity.keyCode | translate}}" formControlName="fieldOfActivity">
            <mat-hint> {{'COMPANY_SPHERE_TOOLTIP' | translate}} </mat-hint>
            <mat-error *ngIf="fieldOfActivity.errors?.required"> {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="fieldOfActivity.errors?.serverError
                && (fieldOfActivity.dirty || fieldOfActivity.touched)">
                {{fieldOfActivity.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.physicalAddress.keyCode | translate}}" formControlName="physicalAddress">
            <mat-hint>{{ 'POS_CITY_STREET_NUMBER_HELP_TEXT' | translate }}</mat-hint>
            <mat-error *ngIf="physicalAddress.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="physicalAddress.errors?.serverError
                && (physicalAddress.dirty || physicalAddress.touched)">
                {{physicalAddress.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <ng-container formGroupName="contact">
            <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.contact.fullname.keyCode | translate}}" formControlName="fullname">
                <mat-hint>{{ 'POS_CONTACT_PERSON_HELP_TEXT' | translate }}</mat-hint>
                <mat-error *ngIf="fullname.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="fullname.errors?.pattern
                && (fullname.dirty || fullname.touched)">
                    {{'ERRORS_ENTER_NAME' | translate}}</mat-error>
                <mat-error *ngIf="fullname.errors?.serverError
                && (fullname.dirty || fullname.touched)">
                    {{fullname.errors?.serverError | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.contact.phoneNumber.keyCode | translate}}" formControlName="phoneNumber">
                <mat-error *ngIf="phoneNumber.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="phoneNumber.errors?.phoneNumberInvalid">
                    {{'ERROR_PHONE_NUMBER' | translate}}</mat-error>
                <mat-error *ngIf="phoneNumber.errors?.serverError">
                    {{phoneNumber.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
        </ng-container>
    </div>

    <div style="margin-top: 2rem;">
        <tbc-button [showLoader]="displayLoader"  [showArrow]="true" [disabled]="false"
                    [maxWidth]="true" text="{{'CONTINUE' | translate}}">
        </tbc-button>
    </div>
</form>