import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallRequestGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!next.firstChild) {
      this.router.navigate(['404']);
    } else {
      const params = next.firstChild.params;
      const product = params.product;

      if (((product === 'mypos' || product === 'support') && params.id != 0) ||
        (product !== 'pos' &&
          product !== 'ecom' &&
          product !== 'mypos' &&
          product !== 'support' &&
          product !== 'space')) {
        this.router.navigate(['404']);
      }
    }

    return true;
  }

}
