<form [formGroup]="formGroup" (submit)="sendForm()">
    <div class="fields-container">
        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{websiteKeycode | translate}}" formControlName="website">
            <mat-hint>{{ 'ECOM_WORKING_SPHERE_WEBSITE_HELP_TEXT' | translate }}</mat-hint>
            <mat-error *ngIf="website.errors?.required">
                {{'ERROR_REQUIRED' | translate}}
            </mat-error>
            <mat-error *ngIf="website.errors?.pattern && (website.dirty || website.touched)">
                {{'ERROR_COMPANY_NAME_EN' | translate}}
            </mat-error>
            <mat-error *ngIf="website.errors?.serverError
                && (website.dirty || website.touched)">
                {{website.errors?.serverError | translate}}
            </mat-error>
        </mat-form-field>
        <mat-checkbox *ngIf=" ecomType === 3" formControlName="shopify" class="custom-shopify-checkbox">
            {{ 'SHOPIFY_CHECKBOX' | translate }}
        </mat-checkbox>
        <mat-form-field class="tbcds-textarea hint-input" hideRequiredMarker>
            <textarea rows="8" matInput placeholder="{{formFields.fieldOfActivityDescription.keyCode | translate}}"
                      formControlName="fieldOfActivityDescription"></textarea>
            <mat-hint>{{ 'WORKING_SPHERE_DESC_MAX_600' | translate }}</mat-hint>
            <mat-error *ngIf="fieldOfActivityDescription.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="fieldOfActivityDescription.errors?.pattern
                && (fieldOfActivityDescription.dirty || fieldOfActivityDescription.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error *ngIf="fieldOfActivityDescription.errors?.serverError
                && (fieldOfActivityDescription.dirty || fieldOfActivityDescription.touched)">
                {{fieldOfActivityDescription.errors?.serverError | translate}}</mat-error>
            <mat-error *ngIf="fieldOfActivityDescription.errors?.maxlength
                && (fieldOfActivityDescription.dirty || fieldOfActivityDescription.touched)">
                {{'MAX_LENGTH_3000' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
            <mat-label>{{formFields.cardType.keyCode | translate}}</mat-label>
            <mat-select (selectionChange)="cardTypeChanged()" panelClass="tbcds-dropdown-panel"
                        disableOptionCentering formControlName="cardType">
                <mat-option [value]="1">{{cardTypes[1] | translate}}</mat-option>
                <mat-option [value]="2">{{cardTypes[2] | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="cardType.errors?.required
                && (cardType.dirty || cardType.touched)">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="cardType.value === 2" class="tbcds-dropdown hint-dropdown" hideRequiredMarker>
            <mat-label>{{formFields.additionalCurrency.keyCode | translate}}</mat-label>
            <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering
                        formControlName="additionalCurrency">
                <mat-option [value]="1">{{ecomCurrencies[2] | translate}}</mat-option>
                <mat-option [value]="2">{{ecomCurrencies[4] | translate}}</mat-option>
                <mat-option [value]="6">{{ecomCurrencies[2] | translate}}/{{ecomCurrencies[4] | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="additionalCurrency.errors?.required
                && (additionalCurrency.dirty || additionalCurrency.touched)">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.estimatedMonthlyTurnover.keyCode | translate}}"
                   formControlName="estimatedMonthlyTurnover">
            <mat-hint>{{ "ECOM_ESTIMATED_MONTHLY_TURNOVER_HELP_TEXT" | translate }}</mat-hint>
            <mat-error *ngIf="estimatedMonthlyTurnover.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="estimatedMonthlyTurnover.errors?.pattern
                && (estimatedMonthlyTurnover.dirty || estimatedMonthlyTurnover.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>

            <mat-error *ngIf="estimatedMonthlyTurnover.errors?.max && !estimatedMonthlyTurnover.errors?.pattern">
                {{'MAX_NUMBER_EXCEEDED' | translate}}</mat-error>

            <mat-error *ngIf="estimatedMonthlyTurnover.errors?.serverError
                && (estimatedMonthlyTurnover.dirty || estimatedMonthlyTurnover.touched)">
                {{estimatedMonthlyTurnover.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.productPriceRange.keyCode | translate}}"
                   formControlName="productPriceRange">
            <mat-hint>{{ "ECOM_PRODUCT_PRICE_RANGE_HELP_TEXT" | translate }}</mat-hint>
            <mat-error *ngIf="productPriceRange.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="productPriceRange.errors?.pattern
                && (productPriceRange.dirty || productPriceRange.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error *ngIf="productPriceRange.errors?.max && !productPriceRange.errors?.pattern">
                {{'MAX_NUMBER_EXCEEDED' | translate}}</mat-error>
            <mat-error *ngIf="productPriceRange.errors?.serverError
                && (productPriceRange.dirty || productPriceRange.touched)">
                {{productPriceRange.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <p class="heading font-weight-bold mb-3 mt-5">
            {{'CHOOSE_ADDITIONAL_FUNCTIONS' | translate}}
        </p>
        <div class="fields-container row mx-0" style="margin-bottom: 3rem;">
            <mat-checkbox *ngIf="ecomType === 4 || !shopify.value" formControlName="payWithLoyalPoints" class="custom-form-check position-relative">
                {{formFields.payWithLoyalPoints.keyCode | translate}}
                <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.payWithLoyalPoints.keyCode | translate">
                </tbcds-tooltip>
            </mat-checkbox>
            <mat-checkbox *ngIf="ecomType === 3" formControlName="installmentPayment"
                          class="custom-form-check position-relative">
                {{formFields.installmentPayment.keyCode | translate}}
                <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.installmentPayment.keyCode | translate">
                </tbcds-tooltip>
            </mat-checkbox>
            <ng-container *ngIf="ecomType === 4 || !shopify.value">
                <mat-checkbox *ngIf="ecomType === 3 || ecomType === 4" formControlName="payByQROrByMobileBank"
                          class="custom-form-check position-relative">
                {{formFields.payByQROrByMobileBank.keyCode | translate}}
                <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.payByQROrByMobileBank.keyCode | translate">
                </tbcds-tooltip>
            </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="ecomType === 4 || !shopify.value">
                <mat-checkbox *ngIf="ecomType === 3 || ecomType === 4" formControlName="payWithInternetBank"
                          class="custom-form-check position-relative">
                {{formFields.payWithInternetBank.keyCode | translate}}
                <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.payWithInternetBank.keyCode | translate">
                </tbcds-tooltip>
            </mat-checkbox>
            </ng-container>
            
            <mat-checkbox  formControlName="applePay" class="custom-form-check position-relative">
                {{formFields.applePay.keyCode | translate}}
                <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.applePay.keyCode | translate">
                </tbcds-tooltip>
            </mat-checkbox>
            <ng-container *ngIf="ecomType === 4 || !shopify.value">
                <mat-checkbox *ngIf="ecomType === 3 || ecomType === 4" formControlName="googlePay" class="custom-form-check position-relative">
                    {{formFields.googlePay.keyCode | translate}}
                    <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.googlePay.keyCode | translate">
                    </tbcds-tooltip>
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="ecomType === 4 || !shopify.value">
            <mat-checkbox *ngIf="ecomType === 3 || 4" formControlName="BNPL" class="custom-form-check position-relative">
                {{formFields.BNPL.keyCode | translate}}
                <tbcds-tooltip [tip]="'TOOLTIP_' + formFields.BNPL.keyCode | translate">
                </tbcds-tooltip>
            </mat-checkbox>
        </ng-container>
        </div>
    </div>
    <div class="button-wrap d-flex justify-content-between flex-row-reverse" style="margin-top: 2rem;">
        <tbc-button position="right" class="form-button-width" [maxWidth]="200" [disabled]="false"
                    [showLoader]="displayLoader" [showArrow]="true" text="{{'SEND' | translate}}">
        </tbc-button>
        <tbc-button class="mr-3 form-button-width" [maxWidth]="200" position="left" (click)="goBack.emit()"
                    appearance="outline" type="button" text="{{'GO_BACK' | translate}}">
        </tbc-button>
    </div>
</form>

<re-captcha #captchaRef='reCaptcha' size="invisible" (resolved)="$event && resolved($event)"
            siteKey="6Lf95tMZAAAAAJmvvrZX3Mm1dE3EDq7zAXEt4ymV">
</re-captcha>