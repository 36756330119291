import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ecom-cost',
  templateUrl: './ecom-cost.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcomCostComponent {

  constructor() {
  }
}
