<!-- <p class="header-info font-weight-medium pb-2rem mb-0">
    {{'ECOM_FORM_HEADER'| translate}}
</p> -->
<form [formGroup]="formGroup" (submit)="goToNextStep()">
    <div class="fields-container">
        <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
            <mat-label>{{formFields.companyType.keyCode | translate}}</mat-label>
            <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering
                        (selectionChange)="companyTypeChosen($event.value, true)" formControlName="companyType">
                <mat-option [value]="1">{{companyTypes[1] | translate}}</mat-option>
                <mat-option [value]="2">{{companyTypes[2] | translate}}</mat-option>
                <mat-option [value]="3">{{companyTypes[3] | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="companyType.errors?.required 
            && (companyType.dirty || companyType.touched)">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="formFields.identityNumber.keyCode" class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.identityNumber.keyCode | translate}}"
                   formControlName="identityNumber">
            <mat-error *ngIf="identityNumber.errors?.required && !identityNumber.errors?.NumbersOnly">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.invalidCharacters
                        && (identityNumber.dirty || identityNumber.touched)">
                {{'ERROR_INVALID_CHARACTERS' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.minlength
                        && !identityNumber.errors?.invalidCharacters">
                {{"MIN_LENGTH_ID_NUMBER_5" | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.maxlength
                        && !identityNumber.errors?.invalidCharacters">
                {{"MAX_LENGTH_ID_NUMBER_50" | translate}}</mat-error>

            <mat-error *ngIf="identityNumber.errors?.NumbersOnly
                && (identityNumber.dirty || identityNumber.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.Length
                && (identityNumber.dirty || identityNumber.touched)
                && !identityNumber.errors?.NumbersOnly">
                {{identityNumberErrorCode | translate}}</mat-error>


            <mat-error *ngIf="identityNumber.errors?.serverError
                && (identityNumber.dirty || identityNumber.touched)">
                {{identityNumber.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.legalName.keyCode | translate}}" formControlName="legalName">
            <mat-error *ngIf="legalName.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="legalName.errors?.serverError
                && (legalName.dirty || legalName.touched)">
                {{legalName.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.physicalAddress.keyCode | translate}}"
                   formControlName="physicalAddress">
            <mat-error *ngIf="physicalAddress.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="physicalAddress.errors?.serverError
                && (physicalAddress.dirty || physicalAddress.touched)">
                {{physicalAddress.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.phoneNumber.keyCode | translate}}" formControlName="phoneNumber">
            <mat-error *ngIf="phoneNumber.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="phoneNumber.errors?.maxlength">
                {{'PHONE_NUMBER_MAX_LENGTH_25' | translate}}</mat-error>
            <mat-error *ngIf="phoneNumber.errors?.phoneNumberInvalid && phoneNumber.value.length > 0 && !phoneNumber.errors?.maxlength">
                {{'INCORRECT_FORMAT' | translate}}</mat-error>
            <!--            <mat-error *ngIf="(phoneNumber.errors?.minlength || phoneNumber.errors?.maxlength)-->
            <!--                && (phoneNumber.dirty || phoneNumber.touched) && !phoneNumber.errors?.pattern">-->
            <!--                {{'QUANTITY_22' | translate}}</mat-error>-->
            <mat-error *ngIf="phoneNumber.errors?.serverError
                && (phoneNumber.dirty || phoneNumber.touched)">
                {{phoneNumber.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.email.keyCode | translate}}" formControlName="email">
            <mat-error *ngIf="email.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="email.errors?.pattern
                && (email.dirty || email.touched)">
                {{'ERRORS_ENTER_EMAIL_CORRECTLY' | translate}}</mat-error>
            <mat-error *ngIf="email.errors?.serverError
                && (email.dirty || email.touched)">
                {{email.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.bankDetails.keyCode | translate}}" formControlName="bankDetails">
            <mat-hint>
                {{'FIELD_NOT_REQUIRED' | translate}}
            </mat-hint>
            <mat-error *ngIf="bankDetails.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="bankDetails.errors?.pattern
                && (bankDetails.dirty || bankDetails.touched)">
                {{'INCORRECT_FORMAT' | translate}}</mat-error>
            <mat-error *ngIf="(bankDetails.errors?.minlength || bankDetails.errors?.maxlength)
                && (bankDetails.dirty || bankDetails.touched) && !bankDetails.errors?.pattern">
                {{'QUANTITY_22' | translate}}</mat-error>
            <mat-error *ngIf="bankDetails.errors?.serverError
                && (bankDetails.dirty || bankDetails.touched)">
                {{bankDetails.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top: 2rem;">
        <tbc-button [showLoader]="displayLoader" [showArrow]="true" [disabled]="false"
                    [maxWidth]="true" text="{{'CONTINUE' | translate}}">
        </tbc-button>
    </div>
</form>