import {Component, Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'product-contact',
	templateUrl: './product-contact.component.html'
})
export class ProductContactComponent {
	@Input() directory: any;
	public orderUrl: string;
	public callUrl: string;
	public productId: string;

	constructor(private router: ActivatedRoute) {
		this.buildOrderUrl();
	}

	private buildOrderUrl(): void {
		this.router.params
			.subscribe(params => {
				this.productId = params.productId
				this.orderUrl = `/order/${params.productType}/${this.getProductId(params.productType, params.productId)}`;
				this.callUrl = `/call/${params.productType}/${this.getProductId(params.productType, params.productId)}`
			});
	}

	private getProductId(productType: string, productName: string): number {
		var result = 0;

		switch (productType) {
			case 'pos':
				switch (productName) {
					case 'standard':
						result = 1;
						break;
					case 'integrated':
						result = 2;
						break;
					case 'cashier':
						result = 3;
						break;
					case 'self':
						result = 4;
						break;
					case 'android':
						result = 5;
						break;
					case 'tpos':
						result = 6;
						break;
					case 'qrhoreca':
						result = 8;
						break;
					default:
						break;
				}
				break;
			case 'ecom':
				switch (productName) {
					case 'tbc':
						result = 3;
						break;
					case 'link':
						result = 4;
						break;
					case 'geopay':
						result = 2;
						break;
					default:
						break;
				}
				break;
			default:
				break;

		}

		return result;
	}
}
