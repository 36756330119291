import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PortalLandingModule } from '../portal-landing/portal-landing.module';
import { ExternalLeadModule } from '../external-lead/external-lead.module';
import { RoutingModule } from '../../routing/routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from '../home/home.module';
import { HeaderModule } from '../header/header.module';
import { SharedModule } from '../../shared/shared.module';
import { FooterModule } from '../footer/footer.module';
import { PosModule } from '../pos/pos.module';
import { SupportPageModule } from '../support-page/support-page.module';
import { EcomModule } from '../ecom/ecom.module';
import { AppInitializerLoader } from '../../core/loaders/app-initializer.loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderProductModule } from '../order-product/order-product.module';
import { ProductDetailsModule } from '../product-details/product-details.module';
import { CallRequestModule } from '../call-request/call-request.module';
import { PrivacyTosModule } from '../privacy-tos/privacy-tos.module';
import { TestAuthModule } from "../test-auth/test-auth.module";
import {AnalyticsService} from "../../services/analytics.service";
import {ActiveTabService} from "../../services/active-tab.service";
import {SessionTimeoutModule} from "../session-timeout/session-timeout.module";
import {CookiePolicyModule} from "../cookie-policy/cookie-policy.module";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        RoutingModule,
        HomeModule,
        HeaderModule,
        FooterModule,
        PosModule,
        TestAuthModule,
        SupportPageModule,
        EcomModule,
        OrderProductModule,
        CallRequestModule,
        ProductDetailsModule,
        PrivacyTosModule,
        HttpClientModule,
        ExternalLeadModule,
        PortalLandingModule,
        SessionTimeoutModule,
        CookiePolicyModule
    ],
    providers: [
        AppInitializerLoader,
        AnalyticsService,
        ActiveTabService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
