import { Subscription } from 'rxjs';


export class FilesService {
    public progressInfos = [];
    public index = 0;
    constructor() { }
}

export class AttachmentEcom {
    constructor(
        public file: string,
        public fileName: string,
        public fieldOfActivity: number,
        public tag: string,
        public eComApplicationId: string,
    ) { }
}

export class AttachmentGeopay {
    constructor(
        public file: string,
        public fileName: string,
        public fieldOfActivity: number,
        public tag: string,
        public geopayApplicationId: string
    ) { }
}

export class FileFieldsEcom {
    constructor(
        public fieldOfActivity: number,
        public tag: string,
        public eComApplicationId: string,
    ) { }
}

export class FileFieldsGeopay {
    constructor(
        public fieldOfActivity: number,
        public tag: string,
        public geopayApplicationId: string
    ) { }
}

export class ProgressInfo {
    constructor(
        public value: number,
        public fileName: string,
        public message: string,
        public fileSize: number,
        public formControlName: string,
        public sphereName: string,
        public success: boolean,
        public subscription$?: Subscription,
        public tag?: string,
        public index?: string,
        public fieldOfActivity?: number,
        public statusCode?: number,
        public fileId?: string,
    ) { }
}