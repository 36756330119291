import { Component, Input, ChangeDetectionStrategy, OnChanges, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';
import { ProductInfo } from './models/product-info';

// import Swiper core and required modules
import SwiperCore, {Pagination, SwiperOptions} from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);
@Component({
  selector: 'product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductInfoComponent implements OnChanges {
  @Input() products: Array<ProductInfo>;
  @Input() isOrderPage: boolean;

  @ViewChild(SwiperComponent) swiper: SwiperComponent;

  public displayProducts: Array<ProductInfo>;
  public slidesPerView: number = 4;
  public disableLeft = false;
  public disableRight = true;

  breakpoints: SwiperOptions['breakpoints'] = {
    1200: {
      slidesPerView: 4
    },
    800: {
      slidesPerView: 3,
    },
    520: {
      slidesPerView: 2
    },
    0: {
      slidesPerView: 1
    }
  }

  public sendGoogleAnalytics(meta: string): void {
    const product = meta.toLocaleLowerCase().includes('pos') ? 'pos' : 'ecom';
    const action = 'S1_order_' + product;
    setTimeout(() => {
      try {
        (<any>window).gtag('event', 'event', {
          eventCategory: 'MainWeb',
          eventAction: action,
        });

        (<any>window).fbq('track', action)
      } catch (e) {
        console.error('Tracker Error', e)
      }
    })
  }

  constructor(){

  }

  ngOnChanges(): void {
    this.displayProducts = this.products;

    if (this.displayProducts) {
      if (window.innerWidth < 768) {
        if (this.displayProducts[0].parent === 'ecom') {
          const temp = this.displayProducts[0];
          this.displayProducts[0] = this.displayProducts[1];
          this.displayProducts[1] = temp;
        }
      }
    }
  }
}
