import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http"
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";
import { Observable, of } from 'rxjs';

@Injectable()
export class GenericService {
    constructor(private http: HttpClient, private router: Router) { }

    /**
     * @description constructs http get request that interprets body as a JSON object and returns response body in a given type `T`
     * @param url The endpoint URL
     * @param params object which will be casted as a query string
     * @returns an `Observable` of `HttpResponse`, with a response body in the request type
     */
    public get<T>(url: string, params?: any): Observable<T> {
        return this.http.get<T>(url, { params: params })
            .pipe();
    }

    /**
     * @description constructs http post request that interprets body as a JSON object and returns Observable of response
     * @param url The endpoint URL
     * @param body object which will be sent as a request payload
     * @returns an `Observable` of `HttpResponse`, with a response body in the request type
     */
    public post<T>(url: string, body?: any): Observable<T> {
        return this.http.post<T>(url, body)
            .pipe();
    }

    /**
     * @description constructs http put request that interprets body as a JSON object and returns Observable of response
     * @param url The endpoint URL
     * @param body object which will be sent as a request payload
     * @returns an `Observable` of `HttpResponse`, with a response body in the request type
     */
    public put<T>(url: string, body?: any): Observable<T> {
        return this.http.put<T>(url, body)
            .pipe();
    }

    /**
     * @description constructs http delete request that interprets body as a JSON object and returns response body in a given type T
     * @param url The endpoint URL
     * @param params request params; query string
     * @returns an `Observable` of `HttpResponse`, with a response body in the request type
     */
    public delete<T>(url: string, params?: any): Observable<T> {
        return this.http.delete<T>(url, { params: params })
            .pipe();
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error);

            return of(result as T);
        };
    }
}