import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../../services/generic.service';
import { GenericResponse } from '../../../common/entities/generic-response';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class OrderProductFormService {
    private orderProductPath: string = "/Lead";

    public stepData;
    public formData;

    constructor(private genericService: GenericService,
        private config: AppConfiguraitonService,
        private http: HttpClient
    ) { }

    getProductStepData2(product) {
        if (product === "pos") {
            return posStepsWithAdditionalFunctions;
        } else if (product === "ecom") {
            return ecomSteps;
        }
    }

    public getFormData(product, productChild) {
        if (product === "pos") {
            if (productChild === 1 || productChild === 5) {
                this.stepData = posStepsWithAdditionalFunctions
                this.formData = JSON.parse(JSON.stringify(posFormWithAdditionalFunctions));
            } else if (productChild === 6) {
                this.stepData = posStepForTPosFunction
                this.formData = JSON.parse(JSON.stringify(posFormForTPos));
            } else if(productChild === 8) {
                this.stepData = posStepForQrHorecaFunction
                this.formData = JSON.parse(JSON.stringify(posFormQrHoreca));
            } else {
                this.stepData = posStepsWithoutAdditionalFunctions
                this.formData = JSON.parse(JSON.stringify(posFormWithoutAdditionalFunctions));
            }
        } else if (product === "ecom") {
            if (productChild === 2) {
                this.stepData = geopaySteps;
                this.formData = JSON.parse(JSON.stringify(geopayForm));
            } else {
                this.stepData = ecomSteps;
                this.formData = JSON.parse(JSON.stringify(ecomForm));
            }
        }
        return { stepData: this.stepData, formData: this.formData };
    }

    public sendPosLead(step: string, lead: any): Observable<any> {
        const url = `${this.config.baseAddress}${this.orderProductPath}/pos/${step}`;
        return this.generateRequest(url, lead);
    }

    public sendTPosLead(step: string, lead: any): Observable<any> {
        const url = `${this.config.baseAddress}${this.orderProductPath}/${step}`;
        return this.generateRequest(url, lead);
    }

    public TPosFinalize(step: string, lead: any): Observable<any> {
        const url = `${this.config.baseAddress}${this.orderProductPath}/TPos/${step}`;
        return this.generateRequest(url, lead);
    }

    public QrHorecaFinalize(step: string, lead: any): Observable<any> {
        const url = `${this.config.baseAddress}${this.orderProductPath}/Horeca/${step}`;
        return this.generateRequest(url, lead);
    }

    public sendEcomLead(step: string, lead: any): Observable<any> {
        const url = `${this.config.baseAddress}${this.orderProductPath}/ECom/${step}`;
        return this.generateRequest(url, lead);
    }

    public sendGeopayLead(step: string, lead: any): Observable<any> {
        const url = `${this.config.baseAddress}${this.orderProductPath}/Geopay/${step}`;
        return this.generateRequest(url, lead);
    }

    private generateRequest(url, lead) {
        const recaptchaToken = lead.recaptchaToken;
        delete lead.recaptchaToken;
        if (recaptchaToken) {
            return this.http.post(url, lead,
                { headers: new HttpHeaders().set('x-recaptcha-response', recaptchaToken) });
        } else {
            return this.http.post(url, lead);
        }
    }

    public sendFeedback(feedback: any): Observable<GenericResponse<void>> {
        return this.genericService.post(`${this.config.baseAddress}${this.orderProductPath}/ApplicationFeedback`, feedback);
    }
}

const posFormWithAdditionalFunctions = {
    'POS_COMPANY': {
        identityNumber: {
            value: '',
            keyCode: 'POS_FORM_ID_NUMBER'
        },
        fieldOfActivity: {
            value: '',
            keyCode: 'COMPANY_SPHERE'
        },
        physicalAddress: {
            value: '',
            keyCode: 'POS_COMPANY_PHYSICAL_ADDRESS'
        },
        contact: {
            fullname: {
                value: '',
                keyCode: 'POS_CONTACT_PERSON_FULL_NAME'
            },
            phoneNumber: {
                value: '',
                keyCode: 'POS_CONTACT_PERSON_PHONE_NUMBER'
            },
        }
    },
    'POS_TERMINAL': {
        count: {
            value: '',
            keyCode: 'POS_AMOUNT'
        },
        language: {
            value: '',
            keyCode: 'TERMINAL_LANG'
        },
        nameOnReceipt: {
            value: '',
            keyCode: 'COMPANY_NAME_RECEIPT'
        },
        nameInLatin: {
            value: '',
            keyCode: 'COMPANY_NAME_EN'
        },
        dayCloseTime: {
            value: '00:00',
            keyCode: 'POS_TERMINAL_DAY_CLOSING_TIME'
        },
        posReportingDetails: {
            thankYou: {
                value: false,
                keyCode: 'CHECKBOX_POS_THANKS'
            },
            payWithLoyalPoints: {
                value: false,
                keyCode: 'CHECKBOX_POS_LOYAL'
            },
            preauthorization: {
                value: false,
                keyCode: 'CHECKBOX_POS_PREAUTH'
            },
            refund: {
                value: false,
                keyCode: 'CHECKBOX_POS_REFUND'
            },
            dcc: {
                value: false,
                keyCode: 'CHECKBOX_POS_DCC'
            }
        }
    }
};


const posFormWithoutAdditionalFunctions = {
    'POS_COMPANY': {
        identityNumber: {
            value: '',
            keyCode: 'POS_FORM_ID_NUMBER'
        },
        fieldOfActivity: {
            value: '',
            keyCode: 'COMPANY_SPHERE'
        },
        physicalAddress: {
            value: '',
            keyCode: 'POS_COMPANY_PHYSICAL_ADDRESS'
        },
        contact: {
            fullname: {
                value: '',
                keyCode: 'POS_CONTACT_PERSON_FULL_NAME'
            },
            phoneNumber: {
                value: '',
                keyCode: 'POS_CONTACT_PERSON_PHONE_NUMBER'
            },
        }
    },
    'POS_TERMINAL': {
        count: {
            value: '',
            keyCode: 'POS_AMOUNT'
        },
        language: {
            value: '',
            keyCode: 'TERMINAL_LANG'
        },
        nameOnReceipt: {
            value: '',
            keyCode: 'COMPANY_NAME_RECEIPT'
        },
        nameInLatin: {
            value: '',
            keyCode: 'COMPANY_NAME_EN'
        },
        dayCloseTime: {
            value: '00:00',
            keyCode: 'POS_TERMINAL_DAY_CLOSING_TIME'
        }
    }
};

const ecomForm = {
    'ECOM_COMPANY': {
        identityNumber: {
            value: '',
            keyCode: 'ECOM_FORM_ID_NUMBER'
        },
        physicalAddress: {
            value: '',
            keyCode: 'ECOM_COMPANY_ADDRESS'
        },
        contactFullName: {
            value: '',
            keyCode: 'ECOM_CONTACT_FULL_NAME'
        },
        phoneNumber: {
            value: '',
            keyCode: 'ECOM_COMPANY_PHONE'
        },
        email: {
            value: '',
            keyCode: 'ECOM_COMPANY_EMAIL'
        }
    },
    'ECOM_SPHERE': {
        website: {
            value: '',
            keyCode: 'WEBSITE_ADDRESS'
        },
        shopify: {
            value: false,
            keyCode: 'SHOPIFY'
        },
        fieldOfActivity: {
            value: '',
            keyCode: 'WORKING_SPHERE'
        },
        fieldOfActivityDescription: {
            value: '',
            keyCode: 'BUSINESS_DETAILS'
        },
        sourceOfProduction: {
            value: '',
            keyCode: 'PRODUCT_RECEIVE_CHANNEL'
        },
        productionDescription: {
            value: '',
            keyCode: 'SPHERE_TOURISM_DETAILS'
        },
        branchCount: {
            value: '',
            keyCode: 'MICRO_BRANCH_QUANTITY'
        },
        cardType: {
            value: '',
            keyCode: 'ECOM_CARD_TYPE'
        },
        additionalCurrency: {
            value: '',
            keyCode: 'ECOM_ADDITIONAL_CURRENCY'
        },
        estimatedMonthlyTurnover: {
            value: '',
            keyCode: 'ECOM_MONTHLY_REVENUE'
        },
        productPriceRange: {
            value: '',
            keyCode: 'ECOM_PRODUCT_PRICE_RANGE'
        },
        applePay: {
            value: false,
            keyCode: 'CHECKBOX_APPLE_PAY'
        },
        googlePay: {
            value: false,
            keyCode: 'CHECKBOX_GOOGLE_PAY'
        },
        installmentPayment: {
            value: false,
            keyCode: 'CHECKBOX_INSTALLMENT'
        },
        payWithInternetBank: {
            value: false,
            keyCode: 'CHECKBOX_INTERNET_BANK'
        },
        payByQROrByMobileBank: {
            value: false,
            keyCode: 'CHECKBOX_QR_OR_MOBILE_BANK'
        },
        payWithLoyalPoints: {
            value: false,
            keyCode: 'CHECKBOX_LOYAL'
        },
        BNPL: {
            value: false,
            keyCode: 'CHECKBOX_PAY_LATER'
        },
    }
};

const geopayForm = {
    'GEO_PERSONAL': {
        contactFullName: {
            value: '',
            keyCode: 'FIRST_LAST_NAME'
        },
        contactPhoneNumber: {
            value: '',
            keyCode: 'PHONE'
        },
        contactEmail: {
            value: '',
            keyCode: 'EMAIL'
        }
    },
    'GEO_COMPANY': {
        companyType: {
            value: '',
            keyCode: 'COMPANY_TYPE'
        },
        identityNumber: {
            value: '',
            keyCode: ''
        },
        legalName: {
            value: '',
            keyCode: 'COMPANY_NAME'
        },
        physicalAddress: {
            value: '',
            keyCode: 'COMPANY_ADDRESS'
        },
        phoneNumber: {
            value: '',
            keyCode: 'PHONE'
        },
        email: {
            value: '',
            keyCode: 'EMAIL'
        },
        bankDetails: {
            value: '',
            keyCode: 'GEOPAY_ACCOUNT_NUMBER'
        },
    },
    'GEO_SPHERE': {
        workingSphere: {
            value: '',
            keyCode: 'WORKING_SPHERE'
        },
        fieldOfActivity: {
            value: '',
            keyCode: 'WORKING_SPHERE'
        },
        volumeOfExpectedTransactionsPerMonth: {
            value: '',
            keyCode: 'MONTHLY_TRANSACTION_AMOUNT'
        },
        transactionMaxAmount: {
            value: '',
            keyCode: 'TRANSACTION_MAX_AMOUNT'
        },

        numberOfRooms: {
            value: '',
            keyCode: 'HOTEL_ROOM_QUANTITY'
        },
        minAmountOfRooms: {
            value: '',
            keyCode: 'ROOM_PRICE_MIN'
        },
        maxAmountOfRooms: {
            value: '',
            keyCode: 'ROOM_PRICE_MAX'
        },
        cadastrialNumber: {
            value: '',
            keyCode: 'GEOPAY_CADASTRIAL_NUMBER'
        },
        realEstate: {
            value: '',
            keyCode: 'GEOPAY_REAL_ESTATE'
        },
        website: {
            value: '',
            keyCode: 'WEBSITE_ADDRESS'
        },

        agreementExists: {
            value: '',
            keyCode: 'GEOPAY_AGREEMENT_EXISTS'
        },
        productionType: {
            value: '',
            keyCode: 'GEOPAY_PRODUCTION_TYPE'
        },
        productionTypeDescription: {
            value: '',
            keyCode: 'GEOPAY_PRODUCTION_TYPE_DESCRIPTION'
        },
    }
};

const posFormForTPos = {
    'POS_T_POS': {
        identityNumber: {
            value: '',
            keyCode: 'POS_FORM_ID_NUMBER'
        },
        fieldOfActivity: {
            value: '',
            keyCode: 'COMPANY_SPHERE'
        },
        physicalAddress: {
            value: '',
            keyCode: 'POS_COMPANY_PHYSICAL_ADDRESS'
        },

        contactFullname: {
            value: '',
            keyCode: 'POS_CONTACT_PERSON_FULL_NAME'
        },
        contactPhoneNumber: {
            value: '',
            keyCode: 'POS_CONTACT_PERSON_PHONE_NUMBER'
        },
        count: {
            value: '',
            keyCode: 'POS_AMOUNT'
        },
        legalNameInLatin: {
            value: '',
            keyCode: 'COMPANY_NAME_EN'
        },
        nameOnReceipt: {
            value: '',
            keyCode: 'COMPANY_NAME_RECEIPT'
        },
    }
}


const posFormQrHoreca = {
    'POS_QR_HORECA': {
        identityNumber: {
            value: '',
            keyCode: 'POS_FORM_ID_NUMBER'
        },
        fieldOfActivity: {
            value: '',
            keyCode: 'COMPANY_SPHERE'
        },
        physicalAddress: {
            value: '',
            keyCode: 'POS_QR_HORECA_PHYSICAL_ADDRESS'
        },

        contactFullname: {
            value: '',
            keyCode: 'POS_CONTACT_PERSON_FULL_NAME'
        },
        contactPhoneNumber: {
            value: '',
            keyCode: 'POS_CONTACT_PERSON_PHONE_NUMBER'
        },
        legalNameInLatin: {
            value: '',
            keyCode: 'COMPANY_NAME_EN'
        },
        nameOnReceipt: {
            value: '',
            keyCode: 'COMPANY_NAME_RECEIPT'
        },
        storeManagementType: {
            value: '',
            keyCode: 'QR_HORECA_STORE_MANAGEMENT_TYPE'
        },
        posType: {
            value: 6
        }
    }
}

const ecomSteps = [
    {
        formTitle: 'ECOM_COMPANY_DETAILS',
        route: '1',
        stepTitle: 'ECOM_COMPANY_STEP',
        valid: true
    },
    {
        formTitle: 'ECOM_BUSINESS_SPHERE',
        route: '2',
        stepTitle: 'ECOM_BUSINESS_SPHERE_STEP',
        valid: false
    },
    {
        route: '3'
    }
];

const posStepsWithAdditionalFunctions = [
    {
        formTitle: 'COMPANY_DETAILS',
        route: '1',
        stepTitle: 'COMPANY',
        valid: true
    },
    {
        formTitle: 'TERMINAL',
        route: '2',
        stepTitle: 'TERMINAL',
        valid: false
    },
    {
        route: '3'
    }
];


const posStepsWithoutAdditionalFunctions = [
    {
        formTitle: 'COMPANY_DETAILS',
        route: '1',
        stepTitle: 'COMPANY',
        valid: true
    },
    {
        formTitle: 'TERMINAL',
        route: '2',
        stepTitle: 'TERMINAL',
        valid: false
    },
    {
        route: '3'
    }
];

const posStepForTPosFunction = [
    {
        formTitle: 'COMPANY_DETAILS',
        route: '1',
        stepTitle: 'T_POS',
        valid: false
    },
    {
        route: '2'
    }
]

const geopaySteps = [
    {
        formTitle: 'PERSONAL_INFO',
        route: '1',
        stepTitle: 'PERSONAL',
        valid: true
    },
    {
        formTitle: 'COMPANY_DETAILS',
        route: '2',
        stepTitle: 'COMPANY',
        valid: false
    },
    {
        formTitle: 'GEOPAY_SPHERE',
        route: '3',
        stepTitle: 'GEOPAY_SPHERE_STEP',
        valid: false
    },
    {
        route: '4'
    }
];


const posStepForQrHorecaFunction = [
    {
        formTitle: 'COMPANY_DETAILS',
        route: '1',
        stepTitle: 'QR_HORECA',
        valid: false
    },
    {
        route: '2'
    }
]