import {NgModule} from "@angular/core";
import {VideoComponent} from "./video.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        VideoComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [
        VideoComponent
    ]
})

export class VideoModule {

}
