<form [formGroup]="formGroup" (submit)="goToNextStep()">
    <div class="fields-container">
        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{identityNumberPlaceholder | translate}}" formControlName="identityNumber">
            <mat-error *ngIf="identityNumber.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.pattern
                && (identityNumber.dirty || identityNumber.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.idNumberInvalidLength && !identityNumber.errors?.pattern
                && (identityNumber.dirty || identityNumber.touched)">
                {{'ECOM_ID_NUMBER_INVALID_LENGTH' | translate}}</mat-error>
            <mat-error *ngIf="identityNumber.errors?.serverError
                && (identityNumber.dirty || identityNumber.touched)">
                {{identityNumber.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.physicalAddress.keyCode | translate}}" formControlName="physicalAddress">
            <mat-hint>{{ 'ECOM_CITY_STREET_NUMBER_HELP_TEXT' | translate }}</mat-hint>
            <mat-error *ngIf="physicalAddress.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="physicalAddress.errors?.serverError
                && (physicalAddress.dirty || physicalAddress.touched)">
                {{physicalAddress.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.contactFullName.keyCode | translate}}" formControlName="contactFullName">
            <mat-error *ngIf="contactFullName.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="contactFullName.errors?.pattern
                && (contactFullName.dirty || contactFullName.touched)">
                {{'ERRORS_ENTER_NAME' | translate}}</mat-error>
            <mat-error *ngIf="contactFullName.errors?.serverError
                && (contactFullName.dirty || contactFullName.touched)">
                {{contactFullName.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.phoneNumber.keyCode | translate}}"
                   formControlName="phoneNumber">
            <mat-error *ngIf="phoneNumber.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="phoneNumber.errors?.maxlength">
                {{'PHONE_NUMBER_MAX_LENGTH_25' | translate}}</mat-error>
            <mat-error *ngIf="phoneNumber.errors?.phoneNumberInvalid && phoneNumber.value.length > 0 && !phoneNumber.errors?.maxlength">
                {{'ERROR_PHONE_NUMBER' | translate}}</mat-error>
            <mat-error *ngIf="phoneNumber.errors?.serverError
                || phoneNumber.errors?.serverError && phoneNumber.touched">
                {{phoneNumber.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.email.keyCode | translate}}" formControlName="email">
            <mat-error *ngIf="email.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="email.errors?.pattern || email.errors?.invalidChars
                || email.errors?.pattern && email.touched">
                {{'ERRORS_ENTER_EMAIL_CORRECTLY' | translate}}</mat-error>
            <mat-error *ngIf="email.errors?.serverError
                || email.errors?.serverError && email.touched">
                {{email.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top: 2rem;">
        <tbc-button [showLoader]="displayLoader" [showArrow]="true" [disabled]="false"
                    [maxWidth]="true" text="{{'CONTINUE' | translate}}">
        </tbc-button>
    </div>
</form>