import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TableService } from "../table.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { animate, keyframes, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-ecom-product-table',
  templateUrl: './ecom-product-table.component.html',
  styleUrls: ['./ecom-product-table.component.scss'],
  animations: [
    trigger('tableCell', [
      transition('* <=> *', [
        animate(250, keyframes([
          style({ opacity: 0.3, offset: 0 }),
          style({ opacity: 0.7, offset: 0.6 }),
          style({ opacity: 1, offset: 1 })
        ]))
      ])
    ]),
  ]
})
export class EcomProductTableComponent implements OnInit {
  headers = [];
  fields = [];
  selectedTitle = 1;
  selectedHeader = 'TBC_CHECKOUT_HEADER';
  currentIndex = 0;
  isMobile: boolean = window.innerWidth <= 768;
  public toggleAnimation: boolean;

  constructor(private tableService: TableService) {
    this.headers = tableService.headers;
    this.fields = tableService.fields;
  }

  ngOnInit(): void { }

  expandTable(field) {
    if (field.showExpand) {
      field.show = !field.show;
    }
  }

  setTable(header, i) {
    this.selectedHeader = header;
    this.currentIndex = i;
    this.toggleAnimation = !this.toggleAnimation;
  }

}
