import {Component, OnInit, Output, EventEmitter, Input, ViewChild} from "@angular/core";
import {FormGroup, FormBuilder, FormControl, Validators, AbstractControl} from "@angular/forms";
import {TourismServices} from "../../enums/tourism-services";
import {GeopayWorkingSpheres} from "../../enums/working-spheres";
import {RealEstates} from "../../enums/real-estates";
import {FileUploadService} from "../../services/file-upload.service";
import {Constants} from "../../../../../common/constants";
import {FileValidator} from "../../../../shared/file-uploader/services/file.validator";
import {debounceTime, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {SaveFormService} from "../../../services/save-form.service";

@Component({
    selector: "geopay-sphere",
    templateUrl: "./geopay-sphere.component.html",
    styleUrls: ["./geopay-sphere.component.scss"],
})
export class GeopaySphereComponent implements OnInit {
    public chosenSphere: number;
    public formGroup: FormGroup;
    public formStep: number = 3;
    public workingSpheres = GeopayWorkingSpheres;
    public tourismServices = TourismServices;
    public realEstates = RealEstates;

    @ViewChild('captchaRef') reCaptcha;
    @Input() formFields;
    @Input() section;
    @Input() displayLoader: boolean;
    @Input() geopayApplicationId;
    @Output() sphereChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() formSent: EventEmitter<any> = new EventEmitter();

    @Input() set serverErrorData(serverErrors) {
        if (serverErrors) {
            if (serverErrors.length && this.formGroup) {
                serverErrors.forEach((serverError) => {
                    let fieldName: string = serverError.fieldName;
                    const props = ["hotel", "carRental", "tourism"];

                    props.forEach((prop) => {
                        const toReplace = prop + ".";
                        fieldName = fieldName.replace(toReplace, "");
                        fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
                    });

                    const formField = this.getFormProperty(fieldName);
                    if (formField) {
                        formField.setErrors(serverError.serverError);
                    }
                });
            }
        }
    }

    @Input() set formStepSubmit(inputVal) {
        if (inputVal) {
            const {currentStep, stepToGo} = inputVal;
            if (currentStep) {
                if (currentStep === this.formStep) {
                    if (currentStep > stepToGo) {
                        this.goBack.emit(stepToGo);
                    } else {
                        if (this.formGroup.status === "VALID") {
                            this.formEmit();
                        } else {
                            this.formGroup.markAllAsTouched();
                        }
                    }
                }
            }
        }
    }

    @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
    @Output() goBack: EventEmitter<any> = new EventEmitter();

    constructor(
        private formBuilder: FormBuilder,
        private fileUploadService: FileUploadService,
        private fileValidator: FileValidator,
        private saveFormService: SaveFormService
        ) {
    }

    ngOnInit(): void {
        this.formGroup = this.buildForm();
        this.formGroup.valueChanges
            .pipe(debounceTime(300), switchMap(() => {
                this.formSubmitted.emit({formFields: this.formGroup.value, section: this.section, nextStep: false});
                return of(null)
            }))
            .subscribe()

        this.saveFormService.patchFormValue(this.formGroup, this.section);

        this.workingSphereChosen(this.fieldOfActivity.value);
    }

    public sendForm() {
        if (this.formGroup.status === 'VALID') {
            this.reCaptcha.execute();
        }
    }

    resolved(captchaResponse: string) {
        this.formSent.emit({recaptchaToken: captchaResponse, ...this.formGroup.value});
        this.reCaptcha.reset();
    }

    getFileFields(docType) {
        return {
            fieldOfActivity: this.fieldOfActivity.value,
            tag: docType,
            geopayApplicationId: this.geopayApplicationId,
        };
    }

    onUploadFile(ev, docType, formControlName, sphereName) {
        this.fileUploadService.convertFileToBase64(ev.file).then((base64: string) => {
            const progressInfo = this.fileUploadService.getProgressInfo(ev, formControlName, sphereName);
            progressInfo.subscription$ = this.fileUploadService
                .uploadGeopay(ev.file.name, base64, this.getFileFields(docType))
                .subscribe((event) => this.fileUploadService.handleFileResponse(event, progressInfo, this.fieldOfActivity.value, docType));
        });
    }

    onDeleteFile(ev) {
        this.fileUploadService
            .deleteFileGeopay({
                geopayApplicationId: this.geopayApplicationId,
                fileId: ev.fileInfo.fileId,
            })
            .subscribe((res) => {
                if (res.statusCode === 200) {
                    ev.self.progressInfos = ev.self.progressInfos.filter((f) => f.index !== ev.fileInfo.index);
                    ev.self.filesService.progressInfos = ev.self.filesService.progressInfos.filter((f) => f.index !== ev.fileInfo.index);
                }
            });
    }

    validateFile() {
        return this.fileValidator.validateFile(this.formGroup.value.agreementExists, this.fieldOfActivity.value, "geopay");
    }

    public goToNextStep(): void {
        if (this.formGroup.status === "VALID") {
            this.formEmit();
        }
    }

    public formEmit() {
        const formFields = JSON.parse(JSON.stringify(this.formGroup.value));

        formFields.numberOfRooms && (formFields.numberOfRooms = +formFields.numberOfRooms);
        formFields.minAmountOfRooms && (formFields.minAmountOfRooms = +formFields.minAmountOfRooms);
        formFields.maxAmountOfRooms && (formFields.maxAmountOfRooms = +formFields.maxAmountOfRooms);
        formFields.volumeOfExpectedTransactionsPerMonth &&
        (formFields.volumeOfExpectedTransactionsPerMonth = +formFields.volumeOfExpectedTransactionsPerMonth);
        formFields.transactionMaxAmount && (formFields.transactionMaxAmount = +formFields.transactionMaxAmount);

        this.formSubmitted.emit({formFields, section: this.section, nextStep: true});
    }

    public realEstateChosen(option: number) {
        this.formGroup.get("agreementExists").setValue(option == 1);
        if (option === 2) {
            this.formGroup.addControl("cadastrialNumber", new FormControl(this.formFields.cadastrialNumber.value, [
                Validators.required,
                Validators.pattern(Constants.RegexPatterns['cadastral-number']),
                Validators.maxLength(100)
            ]));
        } else {
            this.formGroup.removeControl("cadastrialNumber");
        }
    }

    public tourismTypeChosen(option) {
        if (option === 3) {
            this.formGroup.addControl(
                "productionTypeDescription",
                new FormControl(this.formFields.productionTypeDescription.value, [Validators.required])
            );
        } else {
            this.formGroup.removeControl("productionTypeDescription");
        }
    }

    public workingSphereChosen(option) {
        this.sphereChanged.emit({formFields: this.formGroup.value, section: this.section});

        const tempVolume = this.volumeOfExpectedTransactionsPerMonth.value;
        const tempMaxAmount = this.transactionMaxAmount.value;

        this.formGroup = this.buildForm();
        this.fieldOfActivity.patchValue(option);
        this.volumeOfExpectedTransactionsPerMonth.patchValue(tempVolume);
        this.transactionMaxAmount.patchValue(tempMaxAmount);

        switch (option) {
            case 1:
                this.addTourismSphereFormControls();
                this.tourismTypeChosen(this.productionType.value);
                break;
            case 2:
                this.addHotelSphereFormControls();
                this.realEstateChosen(this.realEstate.value);
                break;
            case 3:
                this.addAutoSphereFormControls();
                break;
        }

        this.chosenSphere = option;
    }

    private addHotelSphereFormControls() {
        this.formGroup.addControl("agreementExists", new FormControl(this.formFields.agreementExists.value, [Validators.required]));
        this.formGroup.addControl(
            "numberOfRooms",
            new FormControl(this.formFields.numberOfRooms.value, [
                Validators.required,
                Validators.pattern(new RegExp(Constants.RegexPatterns["number"])),
                Validators.max(2147483647),
            ])
        );
        this.formGroup.addControl(
            "minAmountOfRooms",
            new FormControl(this.formFields.minAmountOfRooms.value, [
                Validators.required,
                Validators.pattern(new RegExp(Constants.RegexPatterns["number"])),
                Validators.max(2147483647),
            ])
        );
        this.formGroup.addControl(
            "maxAmountOfRooms",
            new FormControl(this.formFields.maxAmountOfRooms.value, [
                Validators.required,
                Validators.pattern(new RegExp(Constants.RegexPatterns["number"])),
                Validators.max(2147483647),
            ])
        );
        this.formGroup.addControl("realEstate", new FormControl(this.formFields.realEstate.value, [Validators.required]));
        this.formGroup.addControl(
            "website",
            new FormControl(this.formFields.website.value, [
                Validators.required,
                Validators.pattern(new RegExp(Constants.RegexPatterns["web-address"])),
            ])
        );
    }

    private addAutoSphereFormControls() {
        this.formGroup.addControl("agreementExists", new FormControl(this.formFields.agreementExists.value, [Validators.required]));
        this.formGroup.addControl(
            "website",
            new FormControl(this.formFields.website.value, [Validators.pattern(new RegExp(Constants.RegexPatterns["web-address"]))])
        );
    }

    private addTourismSphereFormControls() {
        this.formGroup.addControl("productionType", new FormControl(this.formFields.productionType.value, [Validators.required]));
        this.formGroup.addControl("agreementExists", new FormControl(this.formFields.agreementExists.value, [Validators.required]));
        this.formGroup.addControl(
            "website",
            new FormControl(this.formFields.website.value, [
                Validators.required,
                Validators.pattern(new RegExp(Constants.RegexPatterns["web-address"])),
            ])
        );
    }

    private buildForm(): FormGroup {
        return this.formBuilder.group({
            fieldOfActivity: new FormControl(this.formFields.fieldOfActivity.value, [Validators.required]),
            volumeOfExpectedTransactionsPerMonth: new FormControl(this.formFields.volumeOfExpectedTransactionsPerMonth.value, [
                Validators.required,
                Validators.max(2147483647),
                Validators.pattern(new RegExp(Constants.RegexPatterns["number"])),
            ]),
            transactionMaxAmount: new FormControl(this.formFields.transactionMaxAmount.value, [
                Validators.required,
                Validators.max(2147483647),
                Validators.pattern(new RegExp(Constants.RegexPatterns["number"])),
            ]),
        });
    }

    public getFormProperty(propertyName: string): AbstractControl {
        return this.formGroup.get(propertyName);
    }

    public get fieldOfActivity(): AbstractControl {
        return this.formGroup.get("fieldOfActivity");
    }

    public get volumeOfExpectedTransactionsPerMonth(): AbstractControl {
        return this.formGroup.get("volumeOfExpectedTransactionsPerMonth");
    }

    public get transactionMaxAmount(): AbstractControl {
        return this.formGroup.get("transactionMaxAmount");
    }

    public get numberOfRooms(): AbstractControl {
        return this.formGroup.get("numberOfRooms");
    }

    public get minAmountOfRooms(): AbstractControl {
        return this.formGroup.get("minAmountOfRooms");
    }

    public get maxAmountOfRooms(): AbstractControl {
        return this.formGroup.get("maxAmountOfRooms");
    }

    public get realEstate(): AbstractControl {
        return this.formGroup.get("realEstate");
    }

    public get cadastrialNumber(): AbstractControl {
        return this.formGroup.get("cadastrialNumber");
    }

    public get website(): AbstractControl {
        return this.formGroup.get("website");
    }

    public get productionType(): AbstractControl {
        return this.formGroup.get("productionType");
    }

    public get productionTypeDescription(): AbstractControl {
        return this.formGroup.get("productionTypeDescription");
    }

    public get agreementExists(): AbstractControl {
        return this.formGroup.get("agreementExists");
    }
}
