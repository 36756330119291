<div class="wrap container-fluid footer-products-wrapper">
	<div class="row mb-4 justify-content-between">
		<div class="col-12 col-md-2 mr-md-auto product-group">
			<div>
				<div (click)="openMenu('pos')">
					<div class="label-wrapper">
						<a [routerLink]="['/pos']">
							<h3 class="caps font-weight-bold label">
								{{ "FOOTER_POS_TERMINALS_TITLE" | translate }}
							</h3>
						</a>
					</div>
				</div>
				<ul class="expandable" #pos *ngIf="navigations.pos" @expand>
					<li>
						<a [routerLink]="['/details/pos/standard']">{{ "POS_STANDARD" | translate }}</a>
					</li>
					<li>
						<a [routerLink]="['/details/pos/cashier']">{{ "CASHIER" | translate }}</a>
					</li>
					<li>
						<a [routerLink]="['/details/pos/qrhoreca']">{{ 'QREat' | translate }}</a>
					</li>
					<li>
						<a [routerLink]="['/details/pos/tpos']">{{ "T_POS" | translate }}</a>
					</li>
					<li>
						<a [routerLink]="['/details/pos/android']">{{ "ANDROID" | translate }}</a>
					</li>
					<li>
						<a [routerLink]="['/details/pos/integrated']">{{ "INTEGRATED" | translate }}</a>
					</li>
					<li>
						<a [routerLink]="['/details/pos/self']">{{ "SELF_SERVICE" | translate }}</a>
					</li>
				</ul>
				<div class="expand" [ngClass]="{'rt': navigations.pos}" (click)="openMenu('pos')">
					<img src="assets/images/footer_expand.svg" alt="">
				</div>
			</div>
		</div>
		<div class="col-12 col-md-2 mr-md-auto product-group">
			<div>
				<div (click)="openMenu('ecom')">
					<div class="label-wrapper">
						<a [routerLink]="['/ecom']">
							<h3 class="caps font-weight-bold label">
								{{ "E_COMMERCE" | translate }}
							</h3>
						</a>
					</div>
				</div>
				<ul class="expandable" #ecom *ngIf="navigations.ecom" @expand>
					<li>
						<a [routerLink]="['/details/ecom/tbc']">{{'TPAY_CHECKOUT'| translate}}</a>
					</li>
					<li>
						<a [routerLink]="['/details/ecom/link']">{{ "ECOM_LINK" | translate }}</a>
					</li>
					<li>
						<a [routerLink]="['/details/ecom/geopay']">{{"E_INVOICE_HEADER" | translate}}</a>
					</li>
				</ul>
				<div class="expand" [ngClass]="{'rt': navigations.ecom}" (click)="openMenu('ecom')">
					<img src="assets/images/footer_expand.svg" alt="">
				</div>
			</div>
		</div>
		<div class="col-12 col-md-2 mr-md-auto product-group">
			<div class="position-relative space-text-wrapper">
				<a [routerLink]="['/space']">
					<h3 class="caps font-weight-bold label">
						<div *ngIf="false" class="new-label position-absolute">
							{{'NEW' | translate}}
						</div>
						{{ "HEADER_SPACE" | translate }}
					</h3>
				</a>
			</div>
		</div>
		<div class="col-12 promise-container">
			<div class="center-row">
				<h3 class="caps font-weight-bold label our-promise">{{'OUR_PROMISE' | translate}}</h3>
				<ul>
					<li class="promise_description">
						{{'OUR_PROMISE_DESCRIPTION' | translate}}
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col privacy d-flex flex-column flex-md-row align-items-md-start">
			<div class="mr-md-4 mb-3 mb-md-0">
				<a *ngIf="currentLang == 'ka'" class="d-flex align-items-center" href="https://www.tbcbank.ge/web/documents/10184/476942/PRIVACY+POLICY+GEO" target="_blank" style="cursor: pointer">
					<span class="mr-2">{{'PRIVACY_POLICY'| translate}}</span>
					<img style="width: 14px" src="assets/images/external-link-white.svg" [alt]="'PRIVACY_POLICY'| translate">
				</a>
				<a *ngIf="currentLang == 'en'" class="d-flex align-items-center" href="https://www.tbcbank.ge/web/documents/10184/476942/PRIVACY+POLICY+EN" target="_blank" style="cursor: pointer">
					<span class="mr-2">{{'PRIVACY_POLICY'| translate}}</span>
					<img style="width: 14px" src="assets/images/external-link-white.svg" [alt]="'PRIVACY_POLICY'| translate">
				</a>
			</div>
			<a routerLink="/terms-and-conditions">{{'TERMS_AND_CONDITIONS'| translate}}</a>
		</div>
	</div>
</div>
