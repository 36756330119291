import { Component, Input, OnChanges } from '@angular/core';
import { trigger, transition, keyframes, animate, style } from '@angular/animations';

@Component({
	selector: 'product-cost',
	templateUrl: './product-cost.component.html',
	styleUrls: ['./product-cost.component.scss'],
	animations: [
		trigger('tableCell', [
			transition('* <=> *', [
				animate(250, keyframes([
					style({ opacity: 0.3, offset: 0 }),
					style({ opacity: 0.7, offset: 0.6 }),
					style({ opacity: 1, offset: 1 })
				]))
			])
		]),
	]
})
export class ProductCostComponent implements OnChanges {
	@Input() directory: { productType: string, productId: string };
	public initData;
	public currentData;
	public toggleAnimation: boolean;
	public tableType: 'tariff' | 'limit';

	public data = {
		pos: {
			standard: {
				tariff: [
					{
						title: 'POS_STANDARD_TARIFF_TITLE_1',
						value: 'POS_STANDARD_TARIFF_VALUE_1',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_2',
						value: 'POS_STANDARD_TARIFF_VALUE_2',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_3',
						value: 'POS_STANDARD_TARIFF_VALUE_3',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_4',
						value: 'POS_STANDARD_TARIFF_VALUE_4',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_5',
						value: 'POS_STANDARD_TARIFF_VALUE_5',
					}
				]
			},
			tpos: {
				tariff: [
					{
						title: 'T_POS_TARIFF_TITLE_1',
						value: 'T_POS_TARIFF_VALUE_1',
					},
					{
						title: 'T_POS_TARIFF_TITLE_2',
						value: 'T_POS_TARIFF_VALUE_2',
					},
					{
						title: 'T_POS_TARIFF_TITLE_3',
						value: 'T_POS_TARIFF_VALUE_3',
					},
					{
						title: 'T_POS_TARIFF_TITLE_4',
						value: 'T_POS_TARIFF_VALUE_4',
					},
					{
						title: 'T_POS_TARIFF_TITLE_5',
						value: 'T_POS_TARIFF_VALUE_5',
					}
				]
			},
			integrated: {
				tariff: [
					{
						title: 'POS_INTEGRATED_TARIFF_TITLE_1',
						value: 'POS_INTEGRATED_TARIFF_VALUE_1',
					},
					{
						title: 'POS_INTEGRATED_TARIFF_TITLE_2',
						value: 'POS_INTEGRATED_TARIFF_VALUE_2',
					},
					{
						title: 'POS_INTEGRATED_TARIFF_TITLE_3',
						value: 'POS_INTEGRATED_TARIFF_VALUE_3',
					},
					{
						title: 'POS_INTEGRATED_TARIFF_TITLE_4',
						value: 'POS_INTEGRATED_TARIFF_VALUE_4',
					},
					{
						title: 'POS_INTEGRATED_TARIFF_TITLE_5',
						value: 'POS_INTEGRATED_TARIFF_VALUE_5',
					}
				]
			},
			cashier: {
				tariff: [
					{
						title: 'POS_CASHIER_TARIFF_TITLE_1',
						value: 'POS_CASHIER_TARIFF_VALUE_1',
					},
					{
						title: 'POS_CASHIER_TARIFF_TITLE_2',
						value: 'POS_CASHIER_TARIFF_VALUE_2',
					},
					{
						title: 'POS_CASHIER_TARIFF_TITLE_3',
						value: 'POS_CASHIER_TARIFF_VALUE_3',
					},
					{
						title: 'POS_CASHIER_TARIFF_TITLE_4',
						value: 'POS_CASHIER_TARIFF_VALUE_4',
					},
					{
						title: 'POS_CASHIER_TARIFF_TITLE_5',
						value: 'POS_CASHIER_TARIFF_VALUE_5',
					}
				]
			},
			self: {
				tariff: [
					{
						title: 'POS_SELF_TARIFF_TITLE_1',
						value: 'POS_SELF_TARIFF_VALUE_1',
					},
					{
						title: 'POS_SELF_TARIFF_TITLE_2',
						value: 'POS_SELF_TARIFF_VALUE_2',
					},
					{
						title: 'POS_SELF_TARIFF_TITLE_3',
						value: 'POS_SELF_TARIFF_VALUE_3',
					},
					{
						title: 'POS_SELF_TARIFF_TITLE_4',
						value: 'POS_SELF_TARIFF_VALUE_4',
					},
					{
						title: 'POS_SELF_TARIFF_TITLE_5',
						value: 'POS_SELF_TARIFF_VALUE_5',
					}
				]
			},
			android: {
				tariff: [
					{
						title: 'POS_STANDARD_TARIFF_TITLE_1',
						value: 'POS_STANDARD_TARIFF_VALUE_1',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_2',
						value: 'POS_STANDARD_TARIFF_VALUE_2',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_3',
						value: 'POS_STANDARD_TARIFF_VALUE_3',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_4',
						value: 'POS_STANDARD_TARIFF_VALUE_4',
					},
					{
						title: 'POS_STANDARD_TARIFF_TITLE_5',
						value: 'POS_STANDARD_TARIFF_VALUE_5',
					}
				]
			},
			qrhoreca: {
				tariff: [
					{
						title: 'POS_QREAT_TARIFF_TITLE_1',
						value: 'POS_QREAT_TARIFF_VALUE_1',
					},
					{
						title: 'POS_QREAT_TARIFF_TITLE_2',
						value: 'POS_QREAT_TARIFF_VALUE_2',
					},
					{
						title: 'POS_QREAT_TARIFF_TITLE_3',
						value: 'POS_QREAT_TARIFF_VALUE_3',
					},
					{
						title: 'POS_QREAT_TARIFF_TITLE_4',
						value: 'POS_QREAT_TARIFF_VALUE_4',
					}
				]
			}
		},
		ecom: {
			geopay: {
				tariff: [
					{
						title: 'ECOM_GEOPAY_TARIFF_TITLE_1',
						value: 'ECOM_GEOPAY_TARIFF_VALUE_1',
					},
					{
						title: 'ECOM_GEOPAY_TARIFF_TITLE_2',
						value: 'ECOM_GEOPAY_TARIFF_VALUE_2',
					},
					{
						title: 'ECOM_GEOPAY_TARIFF_TITLE_3',
						value: 'ECOM_GEOPAY_TARIFF_VALUE_3',
					}
				],
				limit: [
					{
						title: 'ECOM_GEOPAY_TARIFF_TITLE_1_LIMIT',
						value: 'ECOM_GEOPAY_TARIFF_VALUE_1_LIMIT',
					},
					{
						title: 'ECOM_GEOPAY_TARIFF_TITLE_2_LIMIT',
						value: 'ECOM_GEOPAY_TARIFF_VALUE_2_LIMIT',
					},
					{
						title: 'ECOM_GEOPAY_TARIFF_TITLE_3_LIMIT',
						value: 'ECOM_GEOPAY_TARIFF_VALUE_3_LIMIT',
					}
				]
			},
			link: {
				tariff: [
					{
						title: 'ECOM_LINK_TARIFF_TITLE_1',
						value: 'ECOM_LINK_TARIFF_VALUE_1',
					},
					{
						title: 'ECOM_LINK_TARIFF_TITLE_2',
						value: 'ECOM_LINK_TARIFF_VALUE_2',
					},
					{
						title: 'ECOM_LINK_TARIFF_TITLE_3',
						value: 'ECOM_LINK_TARIFF_VALUE_3',
					},
				],
				limit: [
					{
						title: 'ECOM_LINK_TARIFF_TITLE_1_LIMIT',
						value: 'ECOM_LINK_TARIFF_VALUE_1_LIMIT',
					},
					{
						title: 'ECOM_LINK_TARIFF_TITLE_2_LIMIT',
						value: 'ECOM_LINK_TARIFF_VALUE_2_LIMIT',
					},
					{
						title: 'ECOM_LINK_TARIFF_TITLE_3_LIMIT',
						value: 'ECOM_LINK_TARIFF_VALUE_3_LIMIT',
					}
				]
			},

			tbc: {

				tariff: [
					{
						title: 'ECOM_TPAY_TARIFF_TITLE_1',
						value: 'ECOM_TPAY_TARIFF_VALUE_1',
						shopify_value: 'SHOPIFY_TARIFF_VALUE_1',
					},
					{
						title: 'ECOM_TPAY_TARIFF_TITLE_2',
						value: 'ECOM_TPAY_TARIFF_VALUE_2',
						shopify_value: 'SHOPIFY_TARIFF_VALUE_2',
					},
					{
						title: 'ECOM_TPAY_TARIFF_TITLE_3',
						value: 'ECOM_TPAY_TARIFF_VALUE_3',
						shopify_value: 'SHOPIFY_TARIFF_VALUE_3',
					},
					{
						title: 'ECOM_TPAY_TARIFF_TITLE_4',
						value: 'ECOM_TPAY_TARIFF_VALUE_4',
						shopify_value: 'SHOPIFY_TARIFF_VALUE_4',
					},
				],
				limit: [
					{
						title: 'ECOM_TPAY_TARIFF_TITLE_1_LIMIT',
						value: 'ECOM_TPAY_TARIFF_VALUE_1_LIMIT',
					},
					{
						title: 'ECOM_TPAY_TARIFF_TITLE_2_LIMIT',
						value: 'ECOM_TPAY_TARIFF_VALUE_2_LIMIT',
					},
					{
						title: 'ECOM_TPAY_TARIFF_TITLE_3_LIMIT',
						value: 'ECOM_TPAY_TARIFF_VALUE_3_LIMIT',
					}
				],
			}
		}
	}

	ngOnChanges(): void {
		this.initData = this.data[this.directory.productType][this.directory.productId];
		this.setTable('tariff');
	}

	public setTable(type: 'tariff' | 'limit'): void {
		this.toggleAnimation = !this.toggleAnimation;
		this.tableType = type;
		this.currentData = this.initData[this.tableType];
	}
}
