import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ProductInfo } from '../../shared/product-info/models/product-info';
import { ProductInfoService } from '../../shared/product-info/services/product-info.service';

@Component({
      selector: 'ecom-products',
      templateUrl: './ecom-products.component.html',
      changeDetection: ChangeDetectionStrategy.OnPush
})
export class EcomProductsComponent {
      constructor(private productInfoService: ProductInfoService) { }

      public products: Array<ProductInfo> = this.productInfoService.getEcomProducts();
}
