<section class="d-flex">
    <ng-content></ng-content>
</section>
<div class="wrap row position-relative" *ngIf="showNavigation">
    <div class="slick-buttons">
        <div class="slide-left">
            <button class="arrow-btn" (click)="slideRight()" [disabled]="disableRight">
                <img class="arrow-img" src="/assets/images/paginator-arrow-colored.svg" alt="arrow-right">
            </button>

        </div>
        <div class="slide-right">
            <button class="arrow-btn" (click)="slideLeft()" [disabled]="disableLeft">
                <img class="arrow-img" src="/assets/images/paginator-arrow-colored.svg" alt="arrow-right">
            </button>
        </div>
    </div>
</div>
