import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-success-page',
  templateUrl: './lead-success-page.component.html'
})
export class LeadSuccessPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
