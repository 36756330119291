import { Component, Input } from '@angular/core';
import { FAQResponse } from '../../faq/models/faq-response';
import { FaqService } from '../../faq/services/faq.service';
import { LabelId } from '../../../common/enums/label-id.enum';

@Component({
	selector: 'product-faq',
	templateUrl: './product-faq.component.html'
})
export class ProductFaqComponent {
	public faqs: Array<FAQResponse>;

	@Input() directory: { productType: string, productId: string };

	constructor(private faqService: FaqService) { }

	ngOnChanges(): void {
		if (this.directory) {
			this.getFaqs(this.getLabelId());
		}
	}

	private getLabelId(): LabelId {
		var result: LabelId;

		if (this.directory.productType == 'space') {
			result = LabelId.Space;
		} else if (this.directory.productType == 'pos') {
			switch (this.directory.productId) {
				case 'standard':
					result = LabelId.StandardPos;
					break;
				case 'tpos':
					result = LabelId.TPos;
					break;
				case 'integrated':
					result = LabelId.IntegratedPos;
					break;
				case 'cashier':
					result = LabelId.CashierPos;
					break;
				case 'self':
					result = LabelId.SelfPos;
					break;
				case 'android':
					result = LabelId.Android;
					break;
				case 'qrhoreca':
					result = LabelId.QRHoreca
				default:
					break;
			}
		} else if (this.directory.productType == 'ecom') {
			switch (this.directory.productId) {
				case 'tbc':
					result = LabelId.TPay;
					break;
				case 'link':
					result = LabelId.LinkECom;
					break;
				case 'geopay':
					result = LabelId.GeoPay;
					break;
				default:
					break;
			}
		}

		return result;
	}

	private getFaqs(labelId: LabelId): void {
		this.faqService.getFAQs(labelId)
			.subscribe(response => {
				if (response.isSuccess) {
					this.faqs = response.data;
				}
			})
	}
}
