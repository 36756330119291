import {Component, OnInit, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Constants } from 'src/ng-app/common/constants';
import {IDNumberValidator, PhoneNumberValidator} from '../../../../shared/validators/number-only.validator'
import {debounceTime, switchMap, takeUntil} from "rxjs/operators";
import {of, Subject} from "rxjs";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {SaveFormService} from "../../../services/save-form.service";

@Component({
  selector: 'pos-company',
  templateUrl: './pos-company.component.html',
  styleUrls: ['./pos-company.component.scss']
})
export class PosCompanyComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public formStep: number = 3;
  public identityNumberPlaceholder: string;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() formFields;
  @Input() section;
  @Input() displayLoader: boolean;

  @Input() set serverErrorData(serverErrors) {
    if (serverErrors) {
      if (serverErrors.length && this.formGroup) {
        serverErrors.forEach(serverError => {
          const formField = this.getFormProperty(serverError.fieldName);
          if (formField) {
            formField.setErrors(serverError.serverError);
          }
        });
      }
    }
  }

  @Input() set formStepSubmit(inputVal) {
    if (inputVal) {
      const { currentStep, stepToGo } = inputVal;
      if (currentStep) {
        if (currentStep === this.formStep) {
          if (currentStep > stepToGo) {
            this.goBack.emit(stepToGo);
          } else {
            if (this.formGroup.status === 'VALID') {
              this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, stepToGo, nextStep: true });
            } else {
              this.formGroup.markAllAsTouched();
            }
          }
        }
      }
    }
  }

  @Output() stepperFormValidity: EventEmitter<any> = new EventEmitter();
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private bpObserver: BreakpointObserver, private saveFormService: SaveFormService) { }

  ngOnInit(): void {
    this.formGroup = this.buildForm();
    this.formGroup.valueChanges
        .pipe(debounceTime(300), switchMap(() => {
          this.formSubmitted.emit({formFields: this.formGroup.value, section: this.section, nextStep: false});
          return of(null)
        }))
        .subscribe();

    this.saveFormService.patchFormValue(this.formGroup, this.section);

    const savedForm = sessionStorage.getItem(this.section);

    if(savedForm) {
      this.formGroup.patchValue(JSON.parse(savedForm));
    }

    this.identityPlaceholder();
  }

  private identityPlaceholder(): void {
    this.bpObserver.observe(['(max-width: 767px)'])
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.identityNumberPlaceholder = this.formFields.identityNumber.keyCode;
          } else {
            this.identityNumberPlaceholder = `${this.formFields.identityNumber.keyCode}_LONG`;
          }
        })
  }

  public goToNextStep(): void {
    if (this.formGroup.status === 'VALID') {
      this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, nextStep: true });
    }
  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      identityNumber: new FormControl(this.formFields.identityNumber.value, [
        Validators.required,
        Validators.pattern(new RegExp(Constants.RegexPatterns['number'])),
        IDNumberValidator()
      ]),
      contact: this.formBuilder.group({
        fullname: new FormControl(this.formFields.contact.value?.fullname, [
          Validators.pattern(new RegExp(Constants.RegexPatterns["multiple-words"])),
          Validators.required,
        ]),
        phoneNumber: new FormControl(this.formFields.contact.value?.phoneNumber, [
          PhoneNumberValidator(),
          Validators.required
        ]),
      }),
      fieldOfActivity: new FormControl(this.formFields.fieldOfActivity.value, [
        Validators.required,
      ]),
      physicalAddress: new FormControl(this.formFields.physicalAddress.value, [
        Validators.required,
      ]),
    });
  }

  public getFormProperty(propertyName: string): AbstractControl {
    return this.formGroup.get(propertyName);
  }

  public get fieldOfActivity(): AbstractControl {
    return this.formGroup.get("fieldOfActivity");
  }

  public get physicalAddress(): AbstractControl {
    return this.formGroup.get("physicalAddress");
  }

  public get identityNumber(): AbstractControl {
    return this.formGroup.get("identityNumber");
  }

  public get fullname(): AbstractControl {
    return this.formGroup.get("contact").get('fullname');
  }

  public get phoneNumber(): AbstractControl {
    return this.formGroup.get("contact").get('phoneNumber');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
