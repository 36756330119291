<button class="tbc-button-component {{appearance}}" [ngClass]="{
        'with-arrow': showArrow && !disabled,
        'max-width': maxWidth,
        'show-loader': showLoader,
        'outline' : appearance,
        'small': small
    }" [disabled]="disabled || showLoader" type="{{type}}" [ngStyle]="{'max-width': +maxWidth > 0 && maxWidth + 'px'}">
    <div class="button-content-wrap">
        <div class="tbc-text">
            <span>
                {{text}}
            </span>
            <img class="tbc-arrow" *ngIf="showArrow && !disabled"
                [src]="appearance === 'outline-blue' ? '../../../../assets/images/contact-info/arrow-right-blue.svg' : '../../../../assets/images/arrow-white.svg'" alt="tbc-arrow">
        </div>
    </div>

    <span class="tbc-loader">
        <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>
    </span>
</button>