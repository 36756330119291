<nav class="wrap px-0 container-fluid">
  <div
    class="row mx-0 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-center">
    <div class="nav-item tbc-logo mb-md-0">
      <img class="w-100 d-inline-block" src="../../../../assets/images/logo/tbc-logo-white.svg" loading="lazy"
        style="max-width: 230px;" alt="tbc-logo">
    </div>
    <div class="d-flex" *ngIf="!isMaintenance">
      <div class="nav-item help d-flex align-items-center mr-3 mr-md-5">
        <img src="assets/images/help-icon-white.svg" width="20" height="20" alt="help" [alt]="'HELP' | translate">
        <a class="caps help-text d-block" routerLink="/support">{{ "HELP" | translate }}</a>
      </div>
    </div>
  </div>
</nav>
