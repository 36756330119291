<div class="container-fluid position-relative wrap">
    <div [ngClass]="displayProducts[0].parent !== 'pos' && 'row products-list text-center'">
        <div *ngIf="products.length === 2" class="col-lg-2"></div>
        <custom-swiper [data]="displayProducts" [maxWidth]="300" *ngIf="displayProducts[0].parent === 'pos'; else ecomproducts">
            <swiper>
                <ng-template swiperSlide *ngFor="let product of displayProducts; let i = index">
                    <div class="col-12 mb-lg-0 mb-4 text-center product-item {{product.parent}} {{product.product}} h-100"
                         [ngClass]="products.length === 4 ? 'col-sm-6 col-lg-3'
                         : products.length === 3 ? 'col-md-4' : products.length === 2 && 'col-md-6 col-lg-4'">
                        <product-card [product]="product" [productsLength]="products.length"
                                      [isOrderPage]="isOrderPage"></product-card>
                    </div>
                </ng-template>
            </swiper>
        </custom-swiper>
        <div *ngIf="products.length === 2" class="col-lg-2"></div>
    </div>
</div>

<ng-template #ecomproducts>
    <div *ngFor="let product of displayProducts"
         class="col-12 mb-lg-0 mb-4 product-item text-center {{product.parent}} {{product.product}} h-100"
         [ngClass]="products.length === 4 ? 'col-sm-6 col-lg-3'
         : products.length === 3 ? 'col-md-4' : products.length === 2 && 'col-md-6 col-lg-4'">
        <product-card [product]="product" [productsLength]="products.length"
                      [isOrderPage]="isOrderPage"></product-card>
    </div>

</ng-template>
