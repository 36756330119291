import {NgModule} from "@angular/core";
import {CustomSwiperComponent} from "./custom-swiper.component";
import {CommonModule} from "@angular/common";
import {ProductCardModule} from "../product-card/product-card.module";

@NgModule({
    declarations: [CustomSwiperComponent],
    imports: [CommonModule, ProductCardModule],
    exports: [CustomSwiperComponent]
})

export class CustomSwiperModule {

}
