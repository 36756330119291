<form [formGroup]="formGroup" (submit)="sendForm()">
    <div class="fields-container">
        <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
            <mat-label>{{formFields.fieldOfActivity.keyCode | translate}}</mat-label>
            <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering
                        (selectionChange)="workingSphereChosen($event.value)" formControlName="fieldOfActivity">
                <mat-option [value]="2">{{workingSpheres[2] | translate}}</mat-option>
                <mat-option [value]="1">{{workingSpheres[1] | translate}}</mat-option>
                <mat-option [value]="3">{{workingSpheres[3] | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="fieldOfActivity.errors?.required
                && (fieldOfActivity.dirty || fieldOfActivity.touched)">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
        </mat-form-field>

        <ng-container *ngIf="chosenSphere === 2">
            <mat-form-field *ngIf="numberOfRooms" class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.numberOfRooms.keyCode | translate}}"
                       formControlName="numberOfRooms">
                <mat-error *ngIf="numberOfRooms.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="numberOfRooms.errors?.pattern
                    && (numberOfRooms.dirty || numberOfRooms.touched)">
                    {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
                <mat-error *ngIf="numberOfRooms.errors?.max && !numberOfRooms.errors?.pattern">
                    {{'MAX_NUMBER_EXCEEDED' | translate}}</mat-error>
                <mat-error *ngIf="numberOfRooms.errors?.serverError
                    && (numberOfRooms.dirty || numberOfRooms.touched)">
                    {{numberOfRooms.errors?.serverError | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="minAmountOfRooms" class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.minAmountOfRooms.keyCode | translate}}"
                       formControlName="minAmountOfRooms">
                <mat-error *ngIf="minAmountOfRooms.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="minAmountOfRooms.errors?.pattern
                    && (minAmountOfRooms.dirty || minAmountOfRooms.touched)">
                    {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
                <mat-error *ngIf="minAmountOfRooms.errors?.max && !minAmountOfRooms.errors?.pattern">
                    {{'MAX_NUMBER_EXCEEDED' | translate}}</mat-error>
                <mat-error *ngIf="minAmountOfRooms.errors?.serverError
                    && (minAmountOfRooms.dirty || minAmountOfRooms.touched)">
                    {{minAmountOfRooms.errors?.serverError | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="maxAmountOfRooms" class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.maxAmountOfRooms.keyCode | translate}}"
                       formControlName="maxAmountOfRooms">
                <mat-error *ngIf="maxAmountOfRooms.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="maxAmountOfRooms.errors?.pattern
                    && (maxAmountOfRooms.dirty || maxAmountOfRooms.touched)">
                    {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
                <mat-error *ngIf="maxAmountOfRooms.errors?.max && !maxAmountOfRooms.errors?.pattern">
                    {{'MAX_NUMBER_EXCEEDED' | translate}}</mat-error>
                <mat-error *ngIf="maxAmountOfRooms.errors?.serverError
                    && (maxAmountOfRooms.dirty || maxAmountOfRooms.touched)">
                    {{maxAmountOfRooms.errors?.serverError | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
                <mat-label>{{formFields.realEstate.keyCode | translate}}</mat-label>
                <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering
                            (selectionChange)="realEstateChosen($event.value)" formControlName="realEstate">
                    <mat-option [value]="1">{{realEstates[1] | translate}}</mat-option>
                    <mat-option [value]="2">{{realEstates[2] | translate}}</mat-option>
                </mat-select>
                <mat-error *ngIf="realEstate.errors?.required
                    && (realEstate.dirty || realEstate.touched)">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <div *ngIf="realEstate?.value == 1" class="form-field">
                <app-file-uploader [required]="true"
                                   (uploadFile)="onUploadFile($event, 'LICENSE', 'realEstate', chosenSphere)"
                                   (deleteFile)="onDeleteFile($event)" helpText='UPLOAD_LICENSE_ESTATE_GEOPAY'
                                   [objFields]="getFileFields('LICENSE')"
                                   [limitedFileTypes]='["PDF", "DOC", "DOCX", "PNG", "JPG", "HEIC", "JPEG", "XLS", "XLSX"]'>
                </app-file-uploader>
            </div>

            <mat-form-field *ngIf="cadastrialNumber" class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.cadastrialNumber.keyCode | translate}}"
                       formControlName="cadastrialNumber">
                <mat-error *ngIf="cadastrialNumber.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="(cadastrialNumber.errors?.pattern || cadastrialNumber.errors?.maxlength)
                    && (cadastrialNumber.dirty || cadastrialNumber.touched)">
                    {{'ERROR_CADASTRIAL_FORMAT' | translate}}</mat-error>
                <mat-error *ngIf="cadastrialNumber.errors?.serverError
                    && (cadastrialNumber.dirty || cadastrialNumber.touched)">
                    {{cadastrialNumber.errors?.serverError | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.website.keyCode | translate}}" formControlName="website">
                <mat-error *ngIf="website.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="website.errors?.pattern
                    && (website.dirty || website.touched)">
                    {{'ERROR_WEBSITE_FORMAT' | translate}}</mat-error>
                <mat-error *ngIf="website.errors?.serverError
                    && (website.dirty || website.touched)">
                    {{website.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="chosenSphere === 1">
            <mat-form-field class="tbcds-dropdown" hideRequiredMarker>
                <mat-label>{{formFields.productionType.keyCode | translate}}</mat-label>
                <mat-select panelClass="tbcds-dropdown-panel" disableOptionCentering
                            (selectionChange)="tourismTypeChosen($event.value)" formControlName="productionType">
                    <mat-option [value]="1">{{tourismServices[1] | translate}}</mat-option>
                    <mat-option [value]="2">{{tourismServices[2] | translate}}</mat-option>
                    <mat-option [value]="3">{{tourismServices[3] | translate}}</mat-option>
                </mat-select>
                <mat-error *ngIf="productionType.errors?.required
                    && (productionType.dirty || productionType.touched)">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="productionTypeDescription" class="tbcds-textarea" hideRequiredMarker>
                <textarea rows="8" matInput placeholder="{{formFields.productionTypeDescription.keyCode | translate}}"
                          formControlName="productionTypeDescription"></textarea>
                <mat-error *ngIf="productionTypeDescription.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="productionTypeDescription.errors?.serverError
                    && (productionTypeDescription.dirty || productionTypeDescription.touched)">
                    {{productionTypeDescription.errors?.serverError | translate}}</mat-error>
            </mat-form-field>

            <div>
                <p class="tbcds-title">
                    {{formFields.agreementExists.keyCode | translate}}
                </p>
                <mat-radio-group class="tbcds-radio" formControlName="agreementExists">
                    <mat-radio-button class="mr-2" [value]="true">{{'YES' | translate}}</mat-radio-button>
                    <mat-radio-button [value]="false">{{'NO' | translate}}</mat-radio-button>
                </mat-radio-group>

                <div *ngIf="formGroup.value.agreementExists" class="form-field">
                    <app-file-uploader [required]="true"
                                       (uploadFile)="onUploadFile($event, 'LICENSE', 'agreementExists', chosenSphere)"
                                       (deleteFile)="onDeleteFile($event)" helpText='UPLOAD_LICENSE_TOURISM_GEOPAY'
                                       [objFields]="getFileFields('LICENSE')"
                                       [limitedFileTypes]='["PDF", "DOC", "DOCX", "PNG", "JPG", "HEIC", "JPEG", "XLS", "XLSX"]'>
                    </app-file-uploader>
                </div>
            </div>

            <mat-form-field class="tbcds-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.website.keyCode | translate}}" formControlName="website">
                <mat-error *ngIf="website.errors?.required">
                    {{'ERROR_REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="website.errors?.pattern
                    && (website.dirty || website.touched)">
                    {{'ERROR_WEBSITE_FORMAT' | translate}}</mat-error>
                <mat-error *ngIf="website.errors?.serverError
                    && (website.dirty || website.touched)">
                    {{website.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="chosenSphere === 3">
            <div>
                <p class="tbcds-title">
                    {{formFields.agreementExists.keyCode | translate}}
                </p>
                <mat-radio-group class="tbcds-radio" formControlName="agreementExists">
                    <mat-radio-button class="mr-2" [value]="true">{{'YES' | translate}}</mat-radio-button>
                    <mat-radio-button [value]="false">{{'NO' | translate}}</mat-radio-button>
                </mat-radio-group>

                <div *ngIf="formGroup.value.agreementExists" class="form-field">
                    <app-file-uploader [required]="true"
                                       (uploadFile)="onUploadFile($event, 'LICENSE', 'agreementExists', chosenSphere)"
                                       (deleteFile)="onDeleteFile($event)" helpText='UPLOAD_LICENSE_AUTO_GEOPAY'
                                       [objFields]="getFileFields('LICENSE')"
                                       [limitedFileTypes]='["PDF", "DOC", "DOCX", "PNG", "JPG", "HEIC", "JPEG", "XLS", "XLSX"]'>
                    </app-file-uploader>
                </div>
            </div>

            <mat-form-field class="tbcds-input hint-input" hideRequiredMarker>
                <input matInput placeholder="{{formFields.website.keyCode | translate}}" formControlName="website">
                <mat-hint>
                    {{'FIELD_NOT_REQUIRED' | translate}}
                </mat-hint>
                <mat-error *ngIf="website.errors?.pattern
                    && (website.dirty || website.touched)">
                    {{'ERROR_WEBSITE_FORMAT' | translate}}</mat-error>
                <mat-error *ngIf="website.errors?.serverError
                    && (website.dirty || website.touched)">
                    {{website.errors?.serverError | translate}}</mat-error>
            </mat-form-field>
        </ng-container>

        <mat-form-field *ngIf="volumeOfExpectedTransactionsPerMonth" class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.volumeOfExpectedTransactionsPerMonth.keyCode | translate}}"
                   formControlName="volumeOfExpectedTransactionsPerMonth">
            <mat-error *ngIf="volumeOfExpectedTransactionsPerMonth.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="volumeOfExpectedTransactionsPerMonth.errors?.pattern
                && (volumeOfExpectedTransactionsPerMonth.dirty || volumeOfExpectedTransactionsPerMonth.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error
                    *ngIf="volumeOfExpectedTransactionsPerMonth.errors?.max && !volumeOfExpectedTransactionsPerMonth.errors?.pattern">
                {{'MAX_NUMBER_EXCEEDED' | translate}}</mat-error>
            <mat-error *ngIf="volumeOfExpectedTransactionsPerMonth.errors?.serverError
                && (volumeOfExpectedTransactionsPerMonth.dirty || volumeOfExpectedTransactionsPerMonth.touched)">
                {{volumeOfExpectedTransactionsPerMonth.errors?.serverError | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="transactionMaxAmount" class="tbcds-input" hideRequiredMarker>
            <input matInput placeholder="{{formFields.transactionMaxAmount.keyCode | translate}}"
                   formControlName="transactionMaxAmount">
            <mat-error *ngIf="transactionMaxAmount.errors?.required">
                {{'ERROR_REQUIRED' | translate}}</mat-error>
            <mat-error *ngIf="transactionMaxAmount.errors?.pattern
                && (transactionMaxAmount.dirty || transactionMaxAmount.touched)">
                {{'ERROR_NUMBERS_ONLY' | translate}}</mat-error>
            <mat-error *ngIf="transactionMaxAmount.errors?.max && !transactionMaxAmount.errors?.pattern">
                {{'MAX_NUMBER_EXCEEDED' | translate}}</mat-error>
            <mat-error *ngIf="transactionMaxAmount.errors?.serverError
                && (transactionMaxAmount.dirty || transactionMaxAmount.touched)">
                {{transactionMaxAmount.errors?.serverError | translate}}</mat-error>
        </mat-form-field>
    </div>
    <div class="button-wrap d-flex justify-content-between flex-row-reverse" style="margin-top: 2rem;">
        <tbc-button position="right" class="form-button-width" [maxWidth]="200" [showLoader]="displayLoader"
                    [disabled]="!validateFile()" [showArrow]="true"
                    text="{{'SEND' | translate}}">
        </tbc-button>
        <tbc-button class="mr-3 form-button-width" [maxWidth]="200" position="left" (click)="goBack.emit()"
                    appearance="outline" type="button" text="{{'GO_BACK' | translate}}">
        </tbc-button>
    </div>
</form>

<re-captcha #captchaRef='reCaptcha' size="invisible" (resolved)="$event && resolved($event)"
            siteKey="6Lf95tMZAAAAAJmvvrZX3Mm1dE3EDq7zAXEt4ymV">
</re-captcha>