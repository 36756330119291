<shared-header [showCurves]="true">
    <div class="wrap pos-header-wrapper container-fluid generic-spacer-bottom">
        <div class="row">
            <div class="col-12 col-md-6">
                <h1 class="caps font-weight-medium mb-3">{{'POS_HEADER_POS_TERMINALS_TITLE' | translate}}</h1>
                <p>{{'POS_HEADER_DESCRIPTION' | translate}}</p>
            </div>
            <div class="col-12 col-md-6">
                <ul class="list">
                    <li class="pt-2 pb-4">{{'POS_HEADER_LIST_1' | translate}}</li>
                    <li class="pb-4">{{'POS_HEADER_LIST_2' | translate}}</li>
                    <li class="pb-4">{{'POS_HEADER_LIST_3' | translate}}</li>
                </ul>
            </div>
        </div>
    </div>
</shared-header>