<section *ngIf="faqs; else faqsElse" class="wrap">
	<ng-container *ngFor="let faq of faqs; let i = index">
		<div *ngIf="!faqLimit || i < faqLimit || showAll" class="faq-element">
			<div class="question" (click)="toggleMenu(faq)">
				<div class="left d-flex">
					<h3 class="font-weight-bold">{{ faq.questionCode | translate }}</h3>
					<div class="label-wrapper d-flex flex-wrap justify-content-end" *ngIf="showLabels">
						<div class="label" *ngFor="let label of faq.labels">
							<span>{{ label.text | translate }}</span>
						</div>
					</div>
				</div>
				<div class="right">
					<div [ngClass]="!faq.showAnswer ? 'show collapse' : 'sh	ow expand'"></div>
				</div>
			</div>
			<div [ngClass]="!faq.showAnswer ? 'collapsable closed' : 'collapsable open'">
				<p class="answer" [ngClass]="{'mb-5': !showFeedback, 'mb-3': showFeedback}">
					{{ faq.answerCode | translate }}</p>
				<div *ngIf="showFeedback" [ngClass]="{'mb-5': faq.showAnswer}">
					<div [ngClass]="{'hide': faq.feedbackGiven}"
						class="feedback align-items-sm-center flex-column flex-sm-row">
						<div class="mb-2 mb-sm-0 mr-sm-4">
							<span class="fs-14 font-weight-bold">{{'IS_THE_ANSWER_HELPFUL' | translate}}</span>
						</div>
						<div>
							<a (click)="feedbackClick(true, faq)" class="custom-button btn-padding">
								<span class="white">{{'YES' | translate}}</span>
							</a>
							<a (click)="feedbackClick(false, faq)" class="custom-button btn-padding white">
								{{'NO' | translate}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</section>

<ng-template #faqsElse>
	<div>
		<h2 class="caps text-center not-found-text">
			{{'NOT_FOUND' | translate}}
		</h2>
	</div>
</ng-template>

<div *ngIf="faqs?.length > faqLimit" class="d-flex justify-content-center" style="margin-top:60px">
	<a class="custom-button white expand-button" (click)="seeAll()">
		<div class="content">
			<img class="arrow" src="../../../assets/images/arrow.svg" [ngClass]="{'up': showAll}" alt="arrow">
			<p *ngIf="faqs?.length > faqLimit">
				<span *ngIf="!showAll; else showAllElse">{{'SHOW_MORE' | translate}}</span>
				<ng-template #showAllElse>{{'SHOW_LESS' | translate}}</ng-template>
			</p>
		</div>
	</a>
</div>
