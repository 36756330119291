import { Component } from '@angular/core';
// import * as Rellax from 'rellax';

@Component({
	selector: 'home-experience',
	templateUrl: './home-experience.component.html',
	styleUrls: ['./home-experience.component.scss']
})
export class HomeExperienceComponent {
	// ngAfterViewInit(): void {
	// 	var rellax = new Rellax('.rellax-1', {
	// 		center: true,
	// 		speed: 3
	// 	});

	// 	var rellax = new Rellax('.rellax-2', {
	// 		center: true,
	// 		speed: 2
	// 	});
	// }
}
