import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderGenericContainerModule } from '../../shared/order-generic-container/order-generic-container.module';
import { RouterModule, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { customTranslateLoaderFactory } from '../../../core/loaders/translate.loader';
import { HttpClient } from '@angular/common/http';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from '../../shared/tooltip/tooltip.module';

import { RequestFormComponent } from './request-form.component';
import { ProgressStepComponent } from './progress-step/progress-step.component';
import { OrderProductFormService } from '../services/order-product-form.service';
import { CompanyDetailsComponent } from './steps-ecom/ecom-company-details/ecom-company-details.component';
import { WorkingSphereComponent } from './steps-ecom/ecom-working-sphere/ecom-working-sphere.component';
import { PosCompanyComponent } from './steps-pos/pos-company/pos-company.component';
import { PosTerminalComponent } from './steps-pos/pos-terminal/pos-terminal.component';
import { StepsContainerComponent } from './steps-container/steps-container.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { SuccessPageComponent } from './success-page/success-page.component';
import { TbcButtonModule } from '../../shared/tbc-button/tbc-button.module';
import { FileUploaderModule } from '../../shared/file-uploader/file-uploader.module'
import { FileUploadService } from "./services/file-upload.service";
import { RecaptchaModule } from "ng-recaptcha";
import { GeopayCompanyComponent } from './steps-geopay/geopay-company/geopay-company.component';
import { GeopayPersonalComponent } from './steps-geopay/geopay-personal/geopay-personal.component';
import { GeopaySphereComponent } from './steps-geopay/geopay-sphere/geopay-sphere.component';
import { PosTposComponent } from './steps-pos/pos-tpos/pos-tpos.component';
import {PosQrhorecaComponent} from "./steps-pos/pos-qrhoreca/pos-qrhoreca.component";


@NgModule({
  declarations: [
    RequestFormComponent,
    ProgressStepComponent,
    CompanyDetailsComponent,
    WorkingSphereComponent,
    PosCompanyComponent,
    PosTerminalComponent,
    StepsContainerComponent,
    SuccessPageComponent,
    GeopayCompanyComponent,
    GeopayPersonalComponent,
    GeopaySphereComponent,
    PosTposComponent,
    PosQrhorecaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    OrderGenericContainerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
    TooltipModule,
    DateInputsModule,
    IntlModule,
    TbcButtonModule,
    FileUploaderModule,
    MatCheckboxModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoaderFactory,
        deps: [HttpClient, Router, AppConfiguraitonService]
      }
    }),
    RecaptchaModule
  ],
    exports: [
        RequestFormComponent,
        WorkingSphereComponent
    ],
  providers: [
    OrderProductFormService,
    FileUploadService
  ]
})
export class RequestFormModule { }
