import { LanguageService } from './../../../services/language.service';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
	selector: 'footer-navigation',
	templateUrl: './footer-navigation.component.html',
	styleUrls: ['./footer-navigation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterNavigationComponent {
	@Input() isMaintenance: boolean;
}
