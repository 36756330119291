import { Injectable } from "@angular/core";
import { GenericService } from "../../../services/generic.service";
import { Observable, of } from "rxjs";
import { GenericResponse } from "../../../common/entities/generic-response";
import { AppConfiguraitonService } from '../../../services/app-configuration.service';
import { LabelId } from '../../../common/enums/label-id.enum';

@Injectable()
export class FaqService {
	private readonly faqEndpointPath: string = "/Faq";

	constructor(private genericService: GenericService, private appConfiguraitonService: AppConfiguraitonService) { }

	public getFAQs(labelId?: LabelId): Observable<GenericResponse<Array<any>>> {
		return this.genericService.get(`${this.appConfiguraitonService.baseAddress}${this.faqEndpointPath}`, { labelId });
	}

	public setFaqFeedback(faqId: number, feedbackIsPositive: boolean) {
		return this.genericService.post(`${this.appConfiguraitonService.baseAddress}${this.faqEndpointPath}/Evalution`, { faqId: faqId, helpful: feedbackIsPositive });
	}
}
