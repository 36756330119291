import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-download',
  templateUrl: './product-download.component.html',
  styleUrls: ['./product-download.component.scss']
})
export class ProductDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
