import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EcomComponent} from './ecom.component';
import {EcomContactComponent} from './ecom-contact/ecom-contact.component';
import {ProductInfoModule} from '../shared/product-info/product-info.module';
import {SharedModule} from '../../shared/shared.module';
import {EcomProductsComponent} from './ecom-products/ecom-products.component';
import {EcomHeaderComponent} from './ecom-header/ecom-header.component';
import {SharedHeaderModule} from '../shared/shared-header/shared-header.module';
import {EcomCostComponent} from './ecom-cost/ecom-cost.component';
import {EcomFaqComponent} from './ecom-faq/ecom-faq.component';
import {FaqModule} from '../faq/faq.module';
import {ContactInfoModule} from '../shared/contact-info/contact-info.module';
import {GenericContainerModule} from '../shared/generic-container/generic-container.module';
import {EcomDevelopersComponent} from './ecom-developers/ecom-developers.component';
import {ProductInfoService} from '../shared/product-info/services/product-info.service';
import {TableService} from "./ecom-cost/table.service";
import {EcomProductTableComponent} from './ecom-cost/ecom-product-table/ecom-product-table.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {EcomVideosComponent} from "./ecom-videos/ecom-videos.component";
import {CustomSwiperModule} from "../shared/custom-swiper/custom-swiper.module";
import {SwiperModule} from "swiper/angular";
import {VideoModule} from "../shared/video/video.module";


@NgModule({
    declarations: [
        EcomComponent,
        EcomContactComponent,
        EcomProductsComponent,
        EcomHeaderComponent,
        EcomCostComponent,
        EcomFaqComponent,
        EcomDevelopersComponent,
        EcomProductTableComponent,
        EcomVideosComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ProductInfoModule,
        SharedHeaderModule,
        FaqModule,
        ContactInfoModule,
        GenericContainerModule,
        MatTooltipModule,
        CustomSwiperModule,
        SwiperModule,
        VideoModule
    ],
    providers: [
        ProductInfoService,
        TableService
    ]
})
export class EcomModule {
}
