import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public currentLang: string = '';

  public termsAndConditionsTitle: {en: string, ka: string} = {
    ka: 'წესები და პირობები',
    en: 'Terms and Conditions'
  }
  public termsAndConditionsDesc: {en: string[], ka: string[]} = {
    ka: [
        'წინამდებარე ვებ-გვერდის გამოყენება რეგულირდება სს „თიბისი ბანკის” (საიდენტიფიკაციო ნომერი: 204854595, მისამართი: თბილისი, კ. მარჯანიშვილის ქ. #7) მიერ დადგენილი წესებითა და პირობებით.',
        'ტერმინები „ბანკი”, „ჩვენ” გულისხმობს სს „თიბისი ბანკს” (ასევე მისი ჯგუფის წევრ მონათესავე, შვილობილ, აფილირებულ კომპანიებს), ხოლო ტერმინი „თქვენ”, „მომხმარებელი” გულისხმობს ინდივიდს, კომპანიას, ორგანიზაციულ წარმონაქმნს, რომელიც იყენებს ბანკის ვებ-გვერდს www.tbcbank.ge',
        'გთხოვთ, ყურადღებით გაეცნოთ წინამდებარე წესებსა და პირობებს, რომელიც არეგულირებს თქვენ მიერ ვებ-გვერდის გამოყენების პირობებს. იმ შემთხვევაში, თუ არ ეთანხმებით წინამდებარე წესებსა და პირობებს, გთხოვთ, დატოვოთ ბანკის ვებ-გვერდი.',
        'ვებ-გვერდზე სტუმრობით, ან მასზე გამოქვეყნებული ინფორმაციის გამოყენებით, ადასტურებთ და სრულად ეთანხმებით მითითებულ ყველა დანაწესს, რომელიც შესაძლოა ცალმხრივად შეიცვალოს სს „თიბისი ბანკის” მიერ გარკვეული პერიოდულობით, რაც დაფიქსირდება მოცემულ ვებ-გვერდზე და მასზე თქვენი სტუმრობა ნიშნავს შესაბამის პირობებზე დათანხმებას.',
        'ბანკის ვებ-გვერდზე მითითებული ინფორმაცია, უმეტეს შემთხვევაში, განკუთვნილია იმ პირებისთვის, რომლებიც დაინტერესებულნი არიან საქართველოს ფინანსურ ბაზარზე მომსახურების მიღებით. შესაძლოა, ვებ-გვერდზე მითითებული ინფორმაცია არ იყოს ხელმისაწვდომი მსოფლიოს ყველა გეოგრაფიულ არეალში, ბანკის გადაწყვეტილების შესაბამისად.',
        'ბანკის ვებ-გვერდზე მითითებული გარკვეული ინფორმაციის გასაანალიზებლად, შესაძლოა დაგჭირდეთ შესაბამისი სფეროს სპეციალისტის კონსულტაცია.',
        'ბანკის ვებ-გვერდზე მითითებული კონკრეტული პროდუქტები/მომსახურება რეგულირდება სპეციალური საბანკო მომსახურების ხელშეკრულებით.'
    ],
    en: [
        'The use of this website shall be governed by the Rules and Conditions set by TBC Bank JSC (identification number: 204854595, address: #7 K. Marjanishvili str. Tbilisi). ',
        'The terms "Bank" and "We" imply TBC Bank JSC (also its group member companies, subsidiaries, affiliated companies). The terms "You" and "Customer" mean an individual, organization using the Bank\'s website: www.tbcbank.ge.',
        'We request that you read these Rules and Conditions carefully, which will regulate the usage of the website by you.  If you do not agree with this Rules and Conditions, please, leave the Bank\'s website.\n' +
        'By visiting the website or using the information published thereon, you confirm-deletion and fully agree with all the specified covenants, subject to unilateral change by the TBC Bank JSC at regular intervals, which will be depicted on this website and your visit thereto means your approval of relevant conditions.',
        'The information published on the Bank\'s website is mostly intended for those interested in receiving the Georgian financial market services. The information published on the website may not be available in all geographical areas of the world if so decided by the Bank.',
        'For analyzing the information published on the Bank\'s website, you may need to consult with an expert in a relevant field.',
        'Particular products/services specified on the Bank\'s website shall be governed by specific bank service agreements.'
    ]
  }

  public informationAccuracyTitle: {en: string, ka: string} = {
    ka: 'ინფორმაციის სიზუსტე',
    en: 'Accuracy of Information'
  }
  public informationAccuracyDesc: {en: string[], ka: string[]} = {
    ka: [
      'ბანკი არ არის პასუხისმგებელი ვებ-გვერდზე მითითებული ინფორმაციის შინაარსის სიზუსტეზე, მიუხედავად ჩვენი დიდი ძალისხმევისა მოგაწოდოთ უტყუარი ინფორმაცია. აღნიშნული არ წარმოადგენს დადასტურებას და გარანტიას, რომ ვებ-გვერდზე მითითებული ინფორმაცია არის ზუსტი, უტყუარი და შესაფერისი მისი ნებისმიერი გამოყენებისთვის. ბანკის ვებ-გვერდზე არსებული ნებისმიერი ინფორმაცია მითითებულია „როგორც არის” (“As Is”) საწყისზე. შესაბამისად, ვებ-გვერდზე არსებული ნებისმიერი ინფორმაციის გამოყენება სრულად წარმოადგენს მომხმარებლის პირად რისკს და ბანკი არ არის პასუხისმგებელი ვებ-გვერდზე გამოქვეყნებული ინფორმაციის გამოყენებით დამდგარ ზიანზე. წინამდებარე დათქმა არ ეხება ბანკის ვებ-გვერდზე განთავსებული ხელშეკრულების შემადგენელ ნაწილებს.',
      'ვებ-გვერდზე არსებული ინფორმაცია არ წარმოადგენს დაპირებას მომსახურებაზე/პროდუქტზე ან რაიმე სახის სახელშეკრულებო ურთიერთობით ბანკის შებოჭვის საფუძველს.',
    ],
    en: [
      'The Bank shall not be responsible for the accuracy of the information published on the website despite our efforts to supply you with truthful information. This representation is not a confirmation or an assurance that the information published on the website is accurate, truthful and adequate for any type of application. Any information published on the Bank\'s website is specified as on an "As Is" basis.  Therefore, the use of any information published on the website is totally related to the User\'s personal risk and the bank is not responsible for the damage caused by the use of the information published on the website. This agreement does not apply to the constituent parts of the agreement published on the Bank\'s website.',
      'The information published on the website shall not be deemed a promise for a service/product or any ground for restricting the Bank by any type of contractual relations.',
    ]
  }

  public responsibilityLimitTitle: {en: string, ka: string} = {
    ka: 'პასუხისმგებლობის შეზღუდვა',
    en: 'Limitation of Responsibility'
  }
  public responsibilityLimitDesc: {en: string[], ka: string[]} = {
    ka: [
      'ბანკი და მისი შესაბამისი თანამშრომლები არ არიან პასუხისმგებელნი დამდგარი შედეგისათვის, რომელიც მოიცავს პირდაპირ, არაპირდაპირ, სპეციალურ, შემთხვევით, მიზეზ-შედეგობრივ, არასახელშეკრულებო ზიანს, რაც გამოწვეულია ვებ-გვერდზე მითითებული ინფორმაციის გამოყენებით.',
      'ბანკი არ აგებს პასუხს ვებ-გვერდის გამოყენებით გამოწვეულ რაიმე სახის ზიანზე, დანაკარგზე, თუნდაც ტექნიკური შეფერხების, დეფექტის, ოპერაციის გაუქმება/შეჩერების, კომპიუტერული ვირუსის, ან სისტემის გაუმართაობის შემთხვევაში.',
      'ვებ-გვერდზე წარმოდგენილი შინაარსის ხელმისაწვდომობა შესაძლოა დამოკიდებული იყოს საძიებო სისტემის ფუნქციებსა და შეზღუდვებზე. ბანკი არ იღებს პასუხისმგებლობას იმ შემთხვევებზე, თუ ვებ-გვერდის შემადგენელი გარკვეული კომპონენტები არაა ხელმისაწვდომი თქვენთვის.'
    ],
    en: [
      'The Bank and its relevant staff shall not be held responsible for the actual effect, covering direct, indirect, special, accidental, cause-effect, non-contractual damage, caused by the use of the information published on the website.',
      'The Bank shall not be held responsible for any damage or loss caused by the use of the website, even in case of technical delay, defect, cancellation/suspension of operation, or system disruption.',
      'Availability of the content published on the website may depend on the functions and restrictions of the search system. The Bank shall not be held responsible if certain components of the website are not available to you.',
    ]
  }

  public webPageUseTitle: {en: string, ka: string} = {
    ka: 'ვებ-გვერდის გამოყენება',
    en: 'Use of the Website'
  }
  public webPageUseDesc: {en: string[], ka: string[]} = {
    ka: [
      'ვებ-გვერდზე მითითებული ინფორმაცია განკუთვნილია მხოლოდ მომხმარებლების პირადი გამოყენებისთვის. თქვენ ვალდებული\n' +
      '            ხართ, ვებ-გვერდზე განთავსებული ინფორმაცია, ასევე ვებ-გვერდის სტრუქტურა, გამოსახულება და დიაზინი არ\n' +
      '            გაავრცელოთ, არ გადასცეთ, არ მოახდინოთ მისი ასლების დამზადება ან/და რეპროდუცირება (მათ შორის სოციალურ\n' +
      '            მედიაში) კომერციული მიზნებისთვის, ბანკის წინასწარი წერილობითი თანხმობის გარეშე, რაც ჩვენს ერთპიროვნულ\n' +
      '            დისკრეციას წარმოადგენს. ბანკის წინასწარი წერილობითი თანხმობა არ არის საჭირო, თუ ვებ-გვერდზე მითითებული\n' +
      '            ინფორმაციის/შინაარსის გამოყენება ხდება ბანკის წინასწარ განცხადებული თანხმობის შესაბამისად, ან ასეთი\n' +
      '            ინფორმაცია გამოიყენება პირადი მოხმარებისთვის, საგანმანათლებლო ან ინფორმაციული მიზნებისთვის.',
      'ვებ-გვერდისა და სისტემის არაავტორიზებული გამოყენება (მათ შორის არარსებული დასახელებით და კოდით) სასტიკად\n' +
      '            აკრძალულია. იქ სადაც მოითხოვება რეგისტრაცია და პაროლის გამოყენება, თქვენ ვალდებული ხართ, კონფიდენციალურად\n' +
      '            შეინახოთ და არ დაუშვათ მესამე პირის მიერ ვებ-გვერდზე წვდომის შესაძლებლობა თქვენ მაგივრად.',
      'შესაძლებელია, რომ ვებ-გვერდის რომელიმე კონკრეტული გვერდი შეიცავდეს წინამდებარე წესებისაგან და პირობებისაგან\n' +
      '            განსხვავებულ დანაწესებს. მათ შორის კოლიზიის არსებობის შემთხვევაში, უპირატესობა ენიჭება წინამდებარე\n' +
      '            დანაწესებს.',
      'ბანკის ვებ-გვერდის (მათ შორის ფორუმების) გამოყენებისას, აკრძალული გაქვთ უკანონო/თაღლითური ქმედება,\n' +
      '            ცილისმწამებლური, სიძულვილის ენით საუბარი, ან სხვა არასასურველი ინფორმაციის განთავსება,\n' +
      '            გავრცელება/ტრანსმისია, რაც შესაძლოა არღვევდეს მოქმედ კანონმდებლობას და რეგულაციას, ან ზიანს აყენებდეს ბანკის\n' +
      '            საქმიან რეპუტაციას. ასევე გეკრძალებათ ვებ-გვერდისთვის ზიანის მიყენება ნებისმიერი სახის მავნე პროგრამის\n' +
      '            მეშვეობით („ტროას ცხენი,” „ლოგიკური ბომბი” ან სხვა მსგავსი ქმედებების განხორციელება).',
      'თქვენს ერთპიროვნულ რისკს წარმოადგენს ვებ-გვერდზე მოსახვედრად გამოყენებული ნებისმიერი პროგრამული მოდულის\n' +
      '            (სატელეფონო, კომპიუტერული და სხვა) გაუმართავობა.',
      'იმ შემთხვევაში, თუ ვებ-გვერდზე წვდომა შეუძლებელი გახდება ბანკის ტექნიკური შეფერხების, ან ბანკისაგან\n' +
      '            დამოუკიდებელი სხვა ნებისმიერი მიზეზით, ბანკი არ არის პასუხისმგებელი დამდგარ შედეგზე.',
    ],
    en: [
      'The information published on the website is intended only for personal use by the users. You undertake not to disseminate, transfer or make a copy/reproduce (including in social media) the website structure, image, and design for commercial purposes, without obtaining a prior written approval of the Bank, which is our sole discretion.  You are not liable to seek for the bank\'s prior written approval, if the use of the information/content published on the website is permitted by the Bank\'s prior consent, or the information is used for personal use for educational or informative purposes.',
      'Unauthorized use of the website and the system (including under unknown name and code) is strictly prohibited. Where the registration and the password are required, you are committed to non-disclosure thereof and shall not allow for a third party access to the website except yourself.',
      'Any particular page of the website may contain an agreement different from these Rules and Conditions, including in case of collision, these Covenants shall prevail.',
      'When using the Bank\'s website (including its forums), you are prohibited to commit illegal/fraud action, talk with slander and detest, or publish, disseminate/transmit any other undesired information, which may violate the effective legislation and regulations or damage the Bank\'s business reputation. Apart from this, you are also prohibited to damage the website by any harmful programme („Trojan Horses," „Logic Bomb" or by any other similar actions).',
      'It is your sole risk if any programme module (telephone, computer, etc.) used for visiting the website is not streamlined.',
      'The Bank shall not be held responsible for relevant consequences if access to the website becomes impossible due to the Bank\'s technical delay, or due to any other reason beyond the Bank\'s control.'
    ]
  }

  public intellectualPropertyTitle: {en: string, ka: string} = {
    ka: 'ინტელექტუალური საკუთრება',
    en: 'Intellectual Property'
  }
  public intellectualPropertyDesc: {en: string[], ka: string[]} = {
    ka: [
      'ვებ-გვერდზე, მის შინაარსზე, სტრუქტურასა და დიზაინზე სრულად ვრცელდება სს „თიბისი ბანკის” საავტორო უფლება. სს\n' +
      '            „თიბისი ბანკის” ექსკლუზიური უფლებები ვრცელდება ასევე ბანკის მიერ შექმნილ და რეგისტრირებულ ინტელექტუალური\n' +
      '            საკუთრების ნებისმიერ ობიექტზე, მათ შორის მის კუთვნილ სასაქონლო ნიშნებზე.',
      'ბანკის საავტორო (©) და სხვა ინტელექტუალური საკუთრების უფლებები ვრცელდება წინამდებარე ვებ-გვერდსა და მასზე\n' +
      '            არსებულ მთელ შინაარსზე (პროდუქტებისა და მომსახურების ჩათვლით).',
      'აკრძალულია წინამდებარე ვებ-გვერდზე მითითებული ნებისმიერი ინფორმაციის/შინაარსის, მათ შორის ლოგო, პიქტოგრამა,\n' +
      '            გრაფიკა, სურათი, გამოსახულება, პატენტი, მომსახურების/სასაქონლო ნიშნები, დიზაინი, ბანკის მიერ შექმნილი\n' +
      '            ვიზუალური მედია და სხვა ინტელექტუალური საკუთრების (რეგისტრირებული, ან არარეგისტრირებული) უნებართვო\n' +
      '            გამოყენება, დამუშავება და გამოქვეყნება (მათ შორის სოციალურ მედიაში) ბანკის წინასწარი წერილობითი თანხმობის\n' +
      '            გარეშე. ბანკის თანხმობა არ არის საჭირო, თუ წინამდებარე მონაცემების გამოყენება ხორციელდება არაკომერციული,\n' +
      '            პირადი მიზნებისთვის და იმ შემთხვევებში, როცა წინასწარ თანხმობას არ ითვალიწინებს საქართველოს კანონმდებლობა,\n' +
      '            ან შესაბამისი საერთაშორისო რეგულაცია.',
      'ინტელექტუალური საკუთრების ვებ-გვერდზე მითითება არ ნიშნავს მომხმარებლისათვის მის გამოყენებაზე ლიცენზიის\n' +
      '            მინიჭებას. ვებ-გვერდიდან მომხმარებლის მიერ ნებისმიერი ინფორმაციის კომერციული მიზნებისთვის გადმოწერა, ან\n' +
      '            ასლის დამზადება ჩაითვლება საქართველოს კანონმდებლობის დარღვევად.',
      'წესებსა და პირობებში მითითებული არცერთი დანაწესი არ განიჭებთ უფლებას, ან ლიცენზიას წინამდებარე მუხლში\n' +
      '            ჩამოთვლილი ბანკის ინტელექტუალური საკუთრების გამოყენებაზე.',
      'იმ შემთხვევაში, თუ ბანკის რომელიმე ვებ-გვერდი მომხმარებლებს ანიჭებს ჩატის, ინტერაქციისა, თუ\n' +
      '            დიალოგის/დისკუსიის გამართვის შესაძლებლობას, ვალდებულნი ხართ, წინამდებარე არხებში დააფიქსიროთ მხოლოდ ის\n' +
      '            ინფორმაცია, რომელზეც ფლობთ ინტელექტუალურ საკუთრებას, ან გაქვთ შესაბამისი პირის თანხმობა. ამასთან ერთად,\n' +
      '            ბანკს ანიჭებთ უფლებას დაამუშაოს (მათ შორის, გამოაქვეყნოს) თქვენ მიერ დაფიქსირებული ინფორმაცია კანონიერი,\n' +
      '            საკომუნიკაციო/საინფორმაციო (მათ შორის, საგამოფენოდ, კონკურსში მონაწილეობისათვის) მიზნებისათვის.',
      'ბანკის ჯგუფის წევრი კომპანიები შესაძლოა იყენებდნენ ბანკის სასაქონლო ნიშანს, დასახელებას და ლოგოტიპს, რომელიც\n' +
      '            წარმოადგენს ბანკის საკუთრებას. ლაჟვარდის ფერი არის ბანკის კუთვნილი სასაქონლო ნიშნის შემადგენელი ნაწილი,\n' +
      '            რომელიც რეგისტრირებულია დადგენილი წესით.',
      'ბანკის ვებ-გვერდზე მითითებული კომპანიების სახელები, სასაქონლო ნიშნები, ლოგოები ეკუთვნის შესაბამის პირებს.'
    ],
    en: [
      'TBC Bank\'s copyright fully covers the website, its content, structure, and design. Exclusive rights of TBC Bank JSC also apply to any object of copyright created and registered by the Bank, including its own trademarks.',
      'The bank\'s copyright (©) and other intellectual property rights apply to this website and the whole content published thereon (including products and services).',
      'Unauthorized use, processing and publishing (including, in social media) of any information/content, including logo, pictogram, graph, photo, image, patent, service marks/trademarks, design, visual media created by the Bank and other intellectual property (whether registered or not) is prohibited without a prior written approval of the Bank.  You are not required to seek for the Bank\'s prior approval, if these data are used for non-commercial, personal purposes and if the law of Georgia or a relevant international regulatory document does not provide for a preliminary approval.',
      'Displaying (indicating) the intellectual property on the website does not mean a permit granted to users for the use thereof. User\'s downloading from the website or making a copy of any information for commercial purposes shall be deemed the breach of the Georgian Law.',
      'None of the Covenants included in the Rules and Conditions authorizes you or gives you a permit to use the Bank\'s intellectual property listed in this provision.',
      'If any of the Bank\'s websites gives the users the opportunity to have a chat, interaction, or dialogue/discussion, you shall be liable to deliver in the mentioned channels only the information with respect to which you have the intellectual property right, or have obtained an approval of a relevant person. In addition, you authorize the Bank to process (including, to publish) the information published by you for legal, communication/informative (including, for the participation in exhibitions, contests) purposes.  ',
      'The Bank\'s group member companies may use the Bank\'s trademarks, name, and logotype, representing the Bank\'s ownership. The blue color is a part of the trademark owned by the Bank, which is registered according to the established rule. ',
      'Names, trademarks, and logos of the companies published on the Bank\'s website belong to relevant entities.',
    ]
  }

  public dataProtectionTitle: {en: string, ka: string} = {
    ka: 'კონფიდენციალურობის დაცვა',
    en: 'Confidentiality and Data Protection'
  }
  public dataProtectionDesc: {en: string[], ka: string[]} = {
    ka: [
      'სს „თიბისი ბანკი” უზრუნველყოფს მისთვის მიწოდებულ კონფიდენციალური ინფორმაციის მაქსიმალურ დაცულობას\n' +
      '            კანონმდებლობით დადგენილი წესით.',
      'ბანკი მომხმარებლის პერსონალურ ინფორმაციას ითხოვს საჭიროებიდან გამომდინარე, იმ მოცულობით, რაც საჭიროა ეფექტური\n' +
      '            მომსახურების გასაწევად საქართველოს კანონმდებლობით დადგენილი წესით. მომხმარებელს ნებისმიერ დროს შეუძლია\n' +
      '            მოითხოვოს ინფორმაცია ბანკისგან მისი პერსონალური ინფორმაციის დამუშავების თაობაზე.',
      'ბანკის მხრიდან კონფიდენციალური ინფორმაციის გადაცემა ხორციელდება თქვენი წინასწარი თანხმობით, ან კანონით\n' +
      '            გათვალისწინებულ სხვა შემთხვევებში.',
      'ბანკი მაქსიმალურად ზრუნავს თქვენი პირადი კონფიდენციალური ინფორმაციის უსაფრთხოებასა და გაუმჟღავნებლობაზე,\n' +
      '            მაგრამ იმ ფაქტის გათვალისწინებით, რომ თქვენი მონაცემების დისტანციურად მოწოდების პროცესი არ არის დაშიფრული,\n' +
      '            ბანკი იხსნის პასუხისმგებლობას მესამე პირების მიერ თქვენი მონაცემების არასანქცირებულ წვდომაზე ამ მონაცემების\n' +
      '            ბანკისთვის მიწოდების დროს.',
      'ბანკი მაქსიმალურად ზრუნავს თქვენი პირადი ინფორმაციის უსაფრთხოებასა და კონფიდენციალურობაზე, თუმცა იხსნის\n' +
      '            პასუხისმგებლობას მესამე პირების მიერ აღნიშნული ინფორმაციის არასანქცირებულ წვდომაზე ამ მონაცემების ბანკისთვის\n' +
      '            მიწოდების დროს (მათ შორის დისტანციური მიწოდებისას გამოყენებულ პლატფორმებზე)',
      'კონფიდენციალური ინფორმაციის ბანკისთვის მიწოდების დროს, მომხმარებელი ვალდებულია, არ მოახდინოს ისეთი ავტომატური\n' +
      '            საშუალებების გამოყენება, რამაც შესაძლოა საფრთხე შეუქმნას ბანკის უსაფრთხოებასა და დაცულობას.',
      'ვებ-გვერდის სტუმრობისას, თქვენ მიერ დატოვებული ნებისმიერი „ელექტრონული ნაკვალევი”, შესაძლოა გამოყენებულ იქნას\n' +
      '            ბანკისთვის დამახასიათებელი ტექნიკური ოპერაციების განსახორციელებლად, სტატისტიკის საწარმოებლად, ან საიტის\n' +
      '            ხარისხის გასაუმჯობესებლად.',
      'ბანკის მიერ ადმინისტრაციული ფუნქციების ეფექტურად შესასრულებლად, შესაძლოა თქვენ მიერ გამოგზავნილ იქნას\n' +
      '            პერსონალური მონაცემები ბანკის ელექტრონულ ფოსტაზე. გაცნობებთ, რომ ბანკი არ არის პასუხისმგებელი, გადაცემის\n' +
      '            პროცესში, მესამე პირის არასანქცირებული ჩარევის შედეგად, აღნიშნული ინფორმაციის უკანონო მოპოვებაზე, ან იმ\n' +
      '            ინფორმაციაზე, რომელიც მომხმარებელმა თავადვე გახადა საჯარო, ან გაავრცელა ნაკლებად უსაფრთხო ელექტრონული\n' +
      '            საშუალებებით.',
      'წინამდებარე წესებსა და პირობებზე დათანხმებით, მომხმარებელი ადასტურებს, მასთან გაფორმებული ხელშეკრულების\n' +
      '            ფარგლებში, ბანკისთვის მინიჭებულ უფლებას, გაუგზავნოს მომხმარებელს შეტყობინება (მათ შორის, ელექტრონული ფორმით:\n' +
      '            მოკლე ტექსტური შეტყობინება, ელექტრონული ფოსტა) ვებ-გვერდზე მითითებული პროდუქტისა, თუ მომსახურების თაობაზე.',
    ],
    en: [
      'TBC Bank JSC shall ensure maximum protection of confidential information delivered to it in compliance with the law of Georgia.',
      'The Bank requests customers\' personal information as necessary and in the amount necessary for providing efficient service in compliance with the law of Georgia. Customers can request information on processing their personal data at any time. ',
      'The Bank will only disclose confidential information upon the customer\'s consent or as prescribed by the law.',
      'Whereas the Bank uses its best efforts to protect your privacy and confidentiality of your personal information, given that your data are not encrypted during the remote transfer, the Bank shall not be held accountable for a third party\'s unauthorized access to the information when it is being transferred to the Bank.',
      'Whereas the Bank uses its best efforts to protect your privacy and confidentiality of your personal information, it shall not be held accountable for a third party\'s unauthorized access to your data when they are being transferred to the Bank (including online platforms used for remote transfer).',
      'When sharing confidential information with the Bank, the customer shall not use any automatic functions that may compromise the Bank\'s security and safety.',
      'Your digital footprint generated by your website visits can be used for the Bank\'s technical operations, for statistical purposes or for the improvement of the quality of the website.',
      'If you send your personal information to the Bank\'s email address to support its efficient administrative performance, the Bank shall not be held responsible for unlawful acquisition of the information by a third party through an unauthorized intervention in the process of information transfer. Neither shall the Bank be responsible for the disclosure of information which a customer has made public or spread through a less secure electronic communication medium.',
      'By agreeing to these Rules and Conditions, the Customer confirms that the Bank is authorized to send him/her information (including via email and SMS) about products and services publicized on its website.',
    ]
  }

  public webPageLinksTitle: {en: string, ka: string} = {
    ka: 'ვებ-გვერდზე მითითებული ბმულები',
    en: 'Links Published on the Website'
  }
  public webPageLinksDesc: {en: string[], ka: string[]} = {
    ka: [
      'ბანკი უფლებამოსილია ვებ-გვერდზე მიუთითოს ჰიპერბმულები, მესამე პირის მფლობელობაში არსებული ვებ-გვერდების\n' +
      '            ბმულები, თქვენივე ინფორმირებულობის უზრუნველსაყოფად.',
      'მესამე პირის მფლობელობაში არსებული ბმულები და მათზე მითითებული ინფორმაცია, არ ექვემდებარება ბანკის კონტროლს,\n' +
      '            ხოლო მათზე მოცემული პროდუქტებისა და შინაარსის სიზუსტე არ წარმოადგენს ბანკის პასუხისმგებლობის საგანს.\n' +
      '            შესაბამისად, თქვენ ხართ პასუხისმგებელი მესამე პირების რესურსებზე/ვებ-გვერდზე არსებული წესებისა და პირობების\n' +
      '            გაცნობაზე.'
    ],
    en: [
      'To furnish you with more information, the Bank is authorized to publish on its website hyperlinks leading to third persons\' websites.',
      'Websites operated by third persons are not subject to the Bank\'s control and the Bank shall not be held responsible for the accuracy of their content and product information. Therefore, becoming acquainted with the rules and conditions published on third parties\' websites shall be deemed your responsibility.',
    ]
  }

  public amendmentsTitle: {en: string, ka: string} = {
    ka: 'ცვლილების განხორციელება',
    en: 'Amendments'
  }
  public amendmentsDesc: {en: string[], ka: string[]} = {
    ka: [
      'ბანკი უფლებამოსილია ცვლილება შეიტანოს წინამდებარე წესებსა და პირობებში მომხმარებელთათვის წინასწარი\n' +
      '            შეტყობინების გარეშე.',
      'მომხმარებელი პასუხისმგებელია რეგულარულად გაეცნოს წინამდებარე წესებსა და პირობებს. თქვენ მიერ ვებ-გვერდზე\n' +
      '            სტუმრობა/მითითებული ინფორმაციის გამოყენება ავტომატურად ნიშნავს თქვენს დათანხმებას წინამდებარე წესებისა და\n' +
      '            პირობების მოთხოვნებზე.'
    ],
    en: [
      'The Bank is authorized to make amendments to the present Rules and Conditions without giving prior notice to customers.',
      'Customers are obliged to consult the Rules and Conditions regularly. Your visit to the website and/or use of the information provided automatically indicates your consent to these Rules and Conditions.',
    ]
  }

  public lawsTitle: {en: string, ka: string} = {
    ka: 'მარეგულირებელი სამართალი და იურისდიქცია',
    en: 'Regulations and Laws'
  }
  public lawsDesc: {en: string[], ka: string[]} = {
    ka: [
      'წინამდებარე წესები და პირობები რეგულირდება საქართველოს კანონმდებლობით.',
      'დავის წარმოშობის შემთხვევაში, საქმეს განიხილავს საქართველოს საერთო სასამართლოების სისტემა კანონმდებლობით\n' +
      '            დადგენილი წესით.',
      'წინამდებარე დანაწესების კონკრეტული ჩანაწერის ბათილობა არ იწვევს წესებისა და პირობების სხვა დათქმების\n' +
      '            ბათილობას.'
    ],
    en: [
      'The present Rules and Conditions are subject to the Law of Georgia.',
      'In case of disputes, a case will be dealt with by common courts of Georgia in compliance with the law.',
      'The voidance of any provision hereunder shall not result in the voidance of other rules and conditions.',
    ]
  }

  public contactTitle: {en: string, ka: string} = {
    ka: 'საკონტაქტო ინფორმაცია',
    en: 'Contact Information'
  }
  public contactDesc: {en: string[], ka: string[]} = {
    ka: [
      'ნებისმიერი კითხვის არსებობის შემთხვევაში, რაც უკავშირდება წინამდებარე წესებსა და პირობებს და ვებ-გვერდზე\n' +
      '            მითითებულ ინფორმაციას, გთხოვთ, მოგვმართოთ წერილობითი სახით მისამართზე:',
      'თბილისი, მარჯანიშვილის #7 ან მოგვწეროთ ელექტრონული ფოსტის საშუალებით:\n' +
      '                info@tbcbank.com.ge',
    ],
    en: [
      'Should you have any questions regarding the Rules and Conditions and the information published on the website, please sent us a letter at ',
      '#7 Marjanishvili street, Tbilisi or mail us at info@tbcbank.com.ge.',
    ]
  }

  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange
        .pipe(takeUntil(this.destroy$))
        .subscribe(lang => this.currentLang = lang.lang)
  }

  ngOnInit(): void { }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
