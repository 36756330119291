<div class="table-container">

    <div [ngClass]="{'p-0': isMobile}" class="container">
        <div *ngIf="isMobile" class="d-flex mb-2 flex-wrap justify-content-center justify-content-sm-start">
            <p *ngFor="let header of headers; let i = index"
                class="table-type mx-2 pb-2 position-relative caps font-weight-medium"
                [ngClass]="selectedHeader === header && 'active'" (click)="setTable(header, i)">
                {{ header | translate }}
            </p>
        </div>
        <!-- <div *ngIf="isMobile" class="header-responsive--flex">
            <div class="header-responsive" (click)="setTable(header, i)" *ngFor="let header of headers; let i = index">
                <div [ngClass]="{'header-active': selectedHeader === header, 'header-passive': selectedHeader !== header}"
                    class="header-responsive__header">{{ header | translate }}</div>
                <div *ngIf="selectedHeader === header" class="outline-bottom"></div>
            </div>
        </div> -->


        <div *ngIf="!isMobile" class="row table-headers">
            <div class="col"></div>
            <div *ngFor="let header of headers" class="col text-center">{{ header | translate }}</div>
        </div>
    </div>

    <div class="table-content" [ngClass]="{'closed-gap': !field.show}" *ngFor="let field of fields; let i = index">
        <div class="container">
            <div class="row table-title" [ngClass]="{'cursor-pointer': field.showExpand}" *ngIf="field.title"
                (click)="expandTable(field)">
                <div [@tableCell]=" toggleAnimation" class="col">{{ field.title | translate }}</div>
                <div [@tableCell]=" toggleAnimation" *ngIf="field.showExpand" class="col text-right show"
                    [ngClass]="{'expand': field.show}"></div>
            </div>
        </div>

        <div class="table-fields container" [ngClass]="field.show ? 'visible' : 'closed'">
            <div class="row" *ngFor="let value of field.values;" [ngClass]="{'background-light': !field.title}">
                <div *ngFor="let v of value; let idx = index"
                    [ngClass]="{'text-center': idx > 0, 'display-none': isMobile && idx!==currentIndex+1 && idx!==0}"
                    class="col">
                    <div [@tableCell]=" toggleAnimation" *ngIf="v.type === 'text' && idx === 0" class="tooltip-field"
                        [matTooltip]="field.tooltip && (v.text + '_TOOLTIP' | translate)"
                        [matTooltipPosition]="'below'">
                        {{ v.text | translate }} </div>
                    <div [@tableCell]=" toggleAnimation" *ngIf="v.type === 'text' && idx > 0">{{ v.text | translate }}
                    </div>
                    <span [@tableCell]=" toggleAnimation" *ngIf="v.type === 'img'">
                        <img class="check" [draggable]="false" [src]="v.text" alt="">
                    </span>
                </div>
            </div>
        </div>


    </div>
</div>