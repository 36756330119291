<shared-header [showCurves]="true" [detailsPage]="true">
    <section class="wrap container-fluid generic-spacer-bottom">
        <div class="header-row">
            <div [ngClass]="{'space-content': directory.productType === 'space'}" class="content">
                <h1 class="caps font-weight-medium mb-4">
                    {{currentData?.title | translate}}
                </h1>
                <p class="description" *ngIf="currentData?.description">
                   {{currentData?.description | translate}}
                </p>
                <p class="description" *ngIf="!currentData?.description">
                    <span>{{currentData?.description1 | translate}}</span>
                    <span>{{currentData?.description2 | translate}}</span>
                    <span>{{currentData?.description3 | translate}}</span>
                    <span>{{currentData?.description4 | translate}}</span>
                </p>
                <div *ngIf="currentData?.bullets" class="bullets">
                    <div *ngFor="let bullet of currentData?.bullets" class="bullet">
                        <p class="mb-2">{{bullet | translate}}</p>
                    </div>
                </div>

                <!-- <div *ngIf="directory.productId === 'tpos'" class="offer-container mt-4">
                    <span class="offer d-flex flex-column align-items-center mx-md-0 justify-content-center caps">{{ 'TPOS_OFFER' | translate }}</span>
                    <p class="mt-1">{{ currentData?.offerDescription | translate }}</p>
                </div> -->

                <div class="d-flex flex-column flex-md-row align-items-center mx-auto mx-md-0 justify-content-center mt-5"
                    style="width: fit-content;">
                    <ng-container *ngIf="directory.productType !== 'space'">
                       <ng-container *ngIf="currentData?.disabled">
                           <a class="disabled custom-button mb-3 mb-md-0 mr-md-3">
                               {{(directory.productType === 'pos' ? 'REQUEST_POS' : directory.productType === 'ecom' &&
                               'REQUEST_ECOM') | translate}}
                           </a>
                           <!-- <tbcds-tooltip class="custom-tooltip d-md-none mt-2">
                               <span class="ml-1 font-weight-medium" style="cursor: default;">
                                   {{'POS_CASHIER_DISABLED' | translate}}
                               </span>
                           </tbcds-tooltip> -->
                       </ng-container>
                        <a *ngIf="!currentData?.disabled" class="custom-button mb-3 mr-md-3 mb-md-0"
                            [routerLink]="currentData.routerUrl">
                            {{(directory.productType === 'pos' ? 'REQUEST_POS' : directory.productType === 'ecom' &&
                            'REQUEST_ECOM') | translate}}
                        </a>
                    </ng-container>
                    <tbc-button *ngIf="directory.productType === 'space'" (click)="initLogin()" position="right"
                        [showLoader]="displayLoader" text="{{ 'LOGIN' | translate }}">
                    </tbc-button>
                    <a class="plain-button call-button"
                    [routerLink]="currentData.callRoute">
                        <div class="d-flex">
                            <img src="../../../../assets/images/call-small.svg" alt="" [alt]="'WE_WILL_CALL_YOU' | translate">
                            <p class="m-auto caps">
                                {{'WE_WILL_CALL_YOU' | translate}}
                            </p>
                        </div>
                    </a>
                </div>
<!--                <tbcds-tooltip *ngIf="currentData?.disabled" class="custom-tooltip d-none d-md-block mt-2"-->
<!--                    style="margin-bottom: -1.5rem;">-->
<!--                    <span *ngIf="directory.productId === 'cashier'" class="ml-1 font-weight-medium"-->
<!--                        style="cursor: default;">-->
<!--                        {{'POS_CASHIER_DISABLED' | translate}}-->
<!--                    </span>-->
<!--                    <span *ngIf="directory.productId !== 'cashier'" class="ml-1 font-weight-medium"-->
<!--                        style="cursor: default;">-->
<!--                        {{(directory.productType + '_' + directory.productId + '_DISABLED').toUpperCase() | translate}}-->
<!--                    </span>-->
<!--                </tbcds-tooltip>-->
            </div>
            <div [ngClass]="{'space-img-wrapper': directory.productType === 'space'}"
                class="d-flex flex-column ml-md-auto">
                <div class="img-wrap pr-1 pt-1 m-auto mr-md-0">
                    <img style="width: 320px" *ngIf="directory.productId === 'link'" src="assets/images/products/ecom/link.svg" [alt]="currentData?.title | translate">
                    <img *ngIf="directory.productType !== 'space' && directory.productId !== 'link'"
                        src="../../../../assets/images/products/{{directory.productType}}/{{directory.productId}}.webp" [alt]="currentData?.title | translate">

                    <div style="box-shadow: 0 0 20px -8px rgb(0 0 0 / 40%)" *ngIf="directory.productType === 'space'">
                        <ng-lottie [options]="{path: 'assets/images/home/animation.json'}"> </ng-lottie>
                    </div>
                </div>
            </div>
        </div>
    </section>
</shared-header>
