<div class='file-upload'>
    <div class="file-upload--select"
         (click)="fileInput.click()"
         appDragDrop
         [ngClass]="{'error': fileUploadTouched && error && required}"
         (onFileDropped)="uploadFiles($event)">
        <span> {{ "UPLOAD_FILES" | translate }} </span>
        <div class='uploader-input'>
            <input multiple='multiple' hidden type="file" [accept]='filteredTypes' #fileInput
                (change)="uploadFiles($event.target.files)">
            <img src="assets/images/file-upload.svg" alt="">
        </div>
    </div>
    <mat-error *ngIf='fileUploadTouched && error && required; else elseBlock'>
        {{ 'FILE_UPLOAD_REQUIRED' | translate }}
    </mat-error>
    <ng-template #elseBlock>
        <mat-hint *ngIf='helpText'>
            {{ helpText | translate }}
        </mat-hint>
    </ng-template>
    <div class='margin-bottom-primary'> </div>
    <div class="file-upload--selected" *ngFor='let fileInfo of progressInfos; let idx = index'>
        <div class='file-icon'>
            <img src="assets/images/file-icon.png" alt="file">
        </div>
        <div class="upload-container">

            <div class='upload'>
                <div class="upload--file-info">
                    <span class='upload--title-text'>{{ fileInfo.fileName }}</span>
                    <span class='upload--size-text'>{{ fileInfo.fileSize | number: '1.0-0'}}kb</span>
                </div>
                <span *ngIf='fileInfo.success && fileInfo.statusCode === 200' class='upload-message__success'>
                    <img src="assets/images/check-green.png" alt="check green">&nbsp;{{ 'SUCCESSFULLY_UPLOADED' | translate }}
                </span>
                <span *ngIf='!fileInfo.success && fileInfo.message !== "uploading" ' class='upload-message__failed'>
                    <img
                        src="assets/images/x-red.png" alt="x red">&nbsp;{{ fileInfo.message?.toUpperCase().split(' ').join('_') | translate }}
                </span>
            </div>

            <div class="upload-status">
                <mat-progress-bar *ngIf='!fileInfo.success'
                    [ngClass]="fileInfo.message === 'uploading' ? 'uploading' : 'failed'" mode="determinate"
                    [value]="fileInfo.value"></mat-progress-bar>
            </div>

        </div>

        <div class='remove-file-button' (click)='removeFile(fileInfo)'>
            <img src="assets/images/close-circle.png" alt="x">
        </div>
    </div>
</div>