<section *ngIf="directory.productId !== 'tpos'" class="product-details-wrapper">
    <product-header [directory]="directory"></product-header>
    <div class="product-details-background">
<!--        <ecom-tbc-website *ngIf="productType == 'ecom' && productId == 'tbc'"></ecom-tbc-website>-->
        <product-how-to [directory]="directory"></product-how-to>
        <product-functions [directory]="directory" *ngIf="functionsShouldBeVisible()"></product-functions>
        <app-product-download *ngIf="directory.productId === 'tpos'" ></app-product-download>
        <app-shopify-banner *ngIf="shopifyShouldBeVisible()"></app-shopify-banner>
        <product-control-panel [directory]="directory" *ngIf="controlPanelShouldBeVisible()"></product-control-panel>
        <product-partners [directory]="directory" *ngIf="partnersShouldBeVisible"></product-partners>
        <product-videos *ngIf="directory.productId !== 'qrhoreca'" [directory]="directory"></product-videos>
        <product-cost [directory]="directory"></product-cost>
        <product-enroll *ngIf="directory.productId === 'qrhoreca'"></product-enroll>
        <product-faq [directory]="directory"></product-faq>
        <product-contact [directory]="directory"></product-contact>
        <product-storage [directory]="directory"></product-storage>
    </div>
</section>

<section *ngIf="directory.productId === 'tpos'" class="product-details-wrapper">
    <product-header [directory]="directory"></product-header>
    <div class="product-details-background">
        <product-how-to [directory]="directory"></product-how-to>
        <product-functions [directory]="directory" *ngIf="functionsShouldBeVisible()"></product-functions>
        <app-product-download *ngIf="directory.productId === 'tpos'" ></app-product-download>
        <product-cost [directory]="directory"></product-cost>
        <product-faq [directory]="directory"></product-faq>
        <product-contact></product-contact>
        <product-storage [directory]="directory"></product-storage>
    </div>
</section>
