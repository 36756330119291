    <div class="generic container-fluid d-flex justify-content-center download-cont">
        <div class="col-md-5 col-12 img-wrapper d-flex justify-content-end">
            <img class="tpos-img-for-desktop" src="../../../../assets/images/products/pos/mobile-new.webp" alt="">
            <img class="tpos-img-for-mobile" src="../../../../assets/images/products/pos/tpos-for-mobile.webp" alt="">
        </div>
        <div class="col-md-7 col-12 pl-md-5 pr-md-5 pr-lg-5 pl-lg-5 d-flex flex-column justify-content-center txt-wrapper">
            <h2 class="mb-4">{{ 'DOWNLOAD-TPOS' | translate }}</h2>
            <!-- <h1>ჩამოტვირთეთ აპლიკაცია T-POS</h1> -->
            <p class="mb-5">{{ 'DOWNLOAD_TPOS_MUST_HAVE' | translate }}</p>
            <!-- <p>მოწყობილობას უნდა ქონდეს Android 8.1 ან უფრო ახალი ვერსია და NFC-ის მხარდაჭერა.</p> -->
            <a href="https://play.google.com/store/apps/details?id=com.tbc_t2p.app" target="_blank">
                <button>
                    <span>{{ 'DOWNLOAD_TPOS' | translate }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                        <g id="external-link" transform="translate(-2 -2)">
                          <path id="Path_8013" data-name="Path 8013" d="M11.587,16.8H4.217A2.219,2.219,0,0,1,2,14.587V7.217A2.219,2.219,0,0,1,4.217,5H8.237a.876.876,0,0,1,0,1.753H4.217a.464.464,0,0,0-.464.464v7.371a.464.464,0,0,0,.464.464h7.371a.464.464,0,0,0,.464-.464V10.567a.876.876,0,0,1,1.753,0v4.021A2.219,2.219,0,0,1,11.587,16.8Z" transform="translate(0 -0.805)" fill="#fff"/>
                          <path id="Path_8014" data-name="Path 8014" d="M18.9,7.773a.876.876,0,0,1-.876-.876V3.753H14.876a.876.876,0,0,1,0-1.753H18.9a.876.876,0,0,1,.876.876V6.9A.876.876,0,0,1,18.9,7.773Z" transform="translate(-3.773)" fill="#fff"/>
                          <path id="Path_8015" data-name="Path 8015" d="M-.124,7.867a.874.874,0,0,1-.622-.259.876.876,0,0,1,0-1.239L5.918-.245A.876.876,0,1,1,7.153,1L.494,7.613A.874.874,0,0,1-.124,7.867Z" transform="translate(8.274 3.009)" fill="#fff"/>
                        </g>
                    </svg>
                </button>
            </a>
        </div>
    </div>