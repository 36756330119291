<div class="order-spacer">
    <div class="wrap container-fluid">
      <div class="row">
        <div class="col">
            <h1 class="generic-header text-center font-weight-medium generic-spacer-bottom caps" *ngIf="headerKey">
                {{headerKey | translate}}
            </h1>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>