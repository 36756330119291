import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { GenericService } from '../services/generic.service';
import { AuthenticationService } from '../services/authentication.service';
import { AuthHeaderInterceptor } from '../core/interceptors/auth-http-interceptor';
import { customTranslateLoaderFactory } from '../core/loaders/translate.loader';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { RouterModule, Router } from '@angular/router';
import { AppConfiguraitonService } from '../services/app-configuration.service';

@NgModule({
    imports: [
        // BrowserModule,
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: customTranslateLoaderFactory,
                deps: [HttpClient, Router, AppConfiguraitonService]
            }
        }),
        RouterModule
    ],
    exports: [
        // BrowserModule,
        CommonModule,
        HttpClientModule,
        TranslateModule,
        SafeHtmlPipe,
        RouterModule
    ],
    declarations: [SafeHtmlPipe],
    providers: [
        AuthenticationService,
        GenericService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHeaderInterceptor,
            multi: true
        }
    ],
})
export class SharedModule { }
