import { Injectable } from '@angular/core';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';
import { GenericService } from '../../../services/generic.service';
import { Observable } from 'rxjs';
import { GenericResponse } from '../../../common/entities/generic-response';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class CallRequestService {
  private orderProductPath: string = "/Lead";

  constructor(private genericService: GenericService,
              private config: AppConfiguraitonService,
              private http: HttpClient
              ) { }

  public sendCallLead(lead: any): Observable<any> {
    const url = `${this.config.baseAddress}${this.orderProductPath}/CallMe`;
    return this.generateRequest(url, lead);
  }

  public getMymarketUid(referrer: string): Observable<GenericResponse<any>> {
    return this.genericService.post(`${this.config.baseAddress}${this.orderProductPath}/MyMarketLead/Initialize`, { referrer });
  }

  public sendMymarkeCallLead(lead: any): Observable<any> {
    const url = `${this.config.baseAddress}${this.orderProductPath}/MyMarketLead/Finalize`;
    return this.generateRequest(url, lead);
  }

  private generateRequest(url, lead) {
    const recaptchaToken = lead.recaptchaToken;
    delete lead.recaptchaToken;
    if (recaptchaToken) {
      return this.http.post(url, lead,
          { headers: new HttpHeaders().set('x-recaptcha-response', recaptchaToken) });
    } else {
      return this.http.post(url, lead);
    }
  }
}
