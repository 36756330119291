import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild
} from "@angular/core";

interface Option {
    key: Languages,
    value: string
}

interface LanguageOptions {
    ka: Option,
    en: Option
}

type Languages = 'ka' | 'en'

@Component({
    selector: 'lang-custom-dropdown',
    templateUrl: './custom-dropdown.component.html',
    styleUrls: ['./custom-dropdown.component.scss']
})

export class CustomDropdownComponent {
    @HostListener('document:click', ['$event'])
    clickEv(event) {
        event.stopImmediatePropagation();
        if (!this.itemsBody) return;

        if (
            (event.target !== this.langDropdown.nativeElement && !this.langDropdown.nativeElement.contains(event.target)) ||
            (event.target === this.itemsBody.nativeElement || this.itemsBody.nativeElement.contains(event.target))) {
            this.itemsBody.nativeElement.classList.remove('show')
            this.show = false;
        }
    }

    @ViewChild('langDropdown') langDropdown: ElementRef;
    @ViewChild('itemsBody') itemsBody: ElementRef;

    @Input() oppositeLanguage: Languages;
    @Input() dropdown: boolean = true;
    @Output() onChange: EventEmitter<Languages> = new EventEmitter<Languages>();

    show: boolean = false;

    public languageOptions: any = Object.freeze({
        ka: {
            key: 'ka',
            value: 'ქარ'
        },
        en: {
            key: 'en',
            value: 'eng'
        }
    });

    public showDropdown(): void {
        this.itemsBody.nativeElement.classList.toggle('show')
        this.show = !this.show;
    }
}
