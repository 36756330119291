import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'product-functions',
  templateUrl: './product-functions.component.html',
  styleUrls: ['./product-functions.component.scss']
})
export class ProductFunctionsComponent implements OnChanges {
  @Input() directory: { productType: string, productId: string };
  public currentData: Array<any>;
  public pageTitle: string
  // public displayData;

  // public dataLimit = 4;
  // public expandToggle: boolean = false;

  public data = {
    space: {
      default: {
        title: 'PORTAL_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/space/1.svg',
            title: 'PORTAL_FUNCTIONS_TITLE_1',
            description: 'PORTAL_FUNCTIONS_DESCRIPTION_1',
          },
          {
            img: 'assets/images/product-details/space/2.svg',
            title: 'PORTAL_FUNCTIONS_TITLE_2',
            description: 'PORTAL_FUNCTIONS_DESCRIPTION_2',
          },
          {
            img: 'assets/images/product-details/space/3.svg',
            title: 'PORTAL_FUNCTIONS_TITLE_3',
            description: 'PORTAL_FUNCTIONS_DESCRIPTION_3',
            width: 48,
          },
          {
            img: 'assets/images/product-details/space/4.svg',
            title: 'PORTAL_FUNCTIONS_TITLE_4',
            description: 'PORTAL_FUNCTIONS_DESCRIPTION_4',
          },
          {
            img: 'assets/images/product-details/space/5.svg',
            title: 'PORTAL_FUNCTIONS_TITLE_5',
            description: 'PORTAL_FUNCTIONS_DESCRIPTION_5',
            width: 48,
          },
          {
            img: 'assets/images/product-details/ertguli.svg',
            title: 'PORTAL_FUNCTIONS_TITLE_6',
            description: 'PORTAL_FUNCTIONS_DESCRIPTION_6',
          },
        ],
      }
    },
    pos: {
      standard: {
        title: 'POS_STANDARD_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/guard.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_1',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_1',
          },
          // {
          //   img: 'assets/images/product-details/card.svg',
          //   title: 'POS_STANDARD_FUNCTIONS_TITLE_2',
          //   description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_2',
          // },
          {
            img: 'assets/images/product-details/automatic-payment.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_3',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_3',
          },
          {
            img: 'assets/images/product-details/new/dcc.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_4',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_4',
          },
          {
            img: 'assets/images/product-details/new/tips.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_5',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_5',
          },
          {
            img: 'assets/images/product-details/ertguli.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_6',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_6',
          },
        ],
      },
      tpos: {
        title: 'T_POS_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/card.svg',
            title: 'T_POS_FUNCTIONS_TITLE_1',
            description: 'T_POS_FUNCTIONS_DESCRIPTION_1',
          },
          {
            img: 'assets/images/product-details/cancel.svg',
            title: 'T_POS_FUNCTIONS_TITLE_3',
            description: 'T_POS_FUNCTIONS_DESCRIPTION_3',
          },
          {
            img: 'assets/images/product-details/transactions.svg',
            title: 'T_POS_FUNCTIONS_TITLE_4',
            description: 'T_POS_FUNCTIONS_DESCRIPTION_4',
          }
        ],
      },
      cashier: {
        title: 'POS_CASHIER_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/card.svg',
            title: 'POS_CASHIER_FUNCTIONS_TITLE_1',
            description: 'POS_CASHIER_FUNCTIONS_DESCRIPTION_1',
          },
          {
            img: 'assets/images/product-details/new/salaro-aparati.svg',
            title: 'POS_CASHIER_FUNCTIONS_TITLE_2',
            description: 'POS_CASHIER_FUNCTIONS_DESCRIPTION_2',
            width: 48,
          },
          {
            img: 'assets/images/product-details/new/z-ang.svg',
            title: 'POS_CASHIER_FUNCTIONS_TITLE_3',
            description: 'POS_CASHIER_FUNCTIONS_DESCRIPTION_3',
            width: 48,
          },
          {
            img: 'assets/images/product-details/new/technical-support.svg',
            title: 'POS_CASHIER_FUNCTIONS_TITLE_4',
            description: 'POS_CASHIER_FUNCTIONS_DESCRIPTION_4',
            width: 48,
          },
          {
            img: 'assets/images/product-details/ertguli.svg',
            title: 'POS_CASHIER_FUNCTIONS_TITLE_5',
            description: 'POS_CASHIER_FUNCTIONS_DESCRIPTION_5',
          },
        ],
      },
      self: {
        title: 'POS_SELF_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/card.svg',
            title: 'POS_SELF_FUNCTIONS_TITLE_1',
            description: 'POS_SELF_FUNCTIONS_DESCRIPTION_1',
          },
          {
            img: 'assets/images/product-details/new/reports.svg',
            title: 'POS_SELF_FUNCTIONS_TITLE_2',
            description: 'POS_SELF_FUNCTIONS_DESCRIPTION_2',
            width: 48,
          },
          {
            img: 'assets/images/product-details/new/tvit-moms.svg',
            title: 'POS_SELF_FUNCTIONS_TITLE_3',
            description: 'POS_SELF_FUNCTIONS_DESCRIPTION_3',
          },
          {
            img: 'assets/images/product-details/new/technical-support.svg',
            title: 'POS_SELF_FUNCTIONS_TITLE_4',
            description: 'POS_SELF_FUNCTIONS_DESCRIPTION_4',
            width: 48,
          },
        ],
      },
      android: {
        title: 'POS_ANDROID_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/guard.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_1',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_1',
          },
          {
            img: 'assets/images/product-details/automatic-payment.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_3',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_3',
          },
          {
            img: 'assets/images/product-details/new/tips.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_5',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_5',
          },
          {
            img: 'assets/images/product-details/ertguli.svg',
            title: 'POS_STANDARD_FUNCTIONS_TITLE_6',
            description: 'POS_STANDARD_FUNCTIONS_DESCRIPTION_6',
          },
        ],
      },
      qrhoreca: {
        title: 'POS_QREAT_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/order-control.svg',
            title: 'POS_QREAT_FUNCTIONS_TITLE_OC',
            description: 'POS_QREAT_FUNCTIONS_DESCRIPTION_OC',
          },
          {
            img: 'assets/images/product-details/new/tips.svg',
            title: 'POS_QREAT_FUNCTIONS_TITLE_TIPS',
            description: 'POS_QREAT_FUNCTIONS_DESCRIPTION_TIPS',
          },
          {
            img: 'assets/images/product-details/apple-pay.svg',
            title: 'POS_QREAT_FUNCTIONS_TITLE_AP',
            description: 'POS_QREAT_FUNCTIONS_DESCRIPTION_AP',
          },
          {
            img: 'assets/images/product-details/GPAY.svg',
            title: 'POS_QREAT_FUNCTIONS_TITLE_GP',
            description: 'POS_QREAT_FUNCTIONS_DESCRIPTION_GP',
          },
          {
            img: 'assets/images/product-details/mobile-bank.svg',
            title: 'POS_QREAT_FUNCTIONS_TITLE_MB',
            description: 'POS_QREAT_FUNCTIONS_DESCRIPTION_MB',
          },
        ],
      },
    },

    ecom: {
      tbc: {
        title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/guard.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_2',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_2',
          },
          {
            img: 'assets/images/product-details/recursive.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_9',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_9',
          },
          {
            img: 'assets/images/product-details/qr.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_3',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_3',
          },
          {
            img: 'assets/images/product-details/internet-bank.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_4',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_4',
          },
          {
            img: 'assets/images/product-details/cancel.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_5',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_5',
          },
          {
            img: 'assets/images/product-details/ertguli.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_6',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_6',
          },
          {
            img: 'assets/images/product-details/apple-pay.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_7',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_7',
          },
          {
            img: 'assets/images/product-details/tbc-leasing.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_8',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_8',
          },
          {
            img: 'assets/images/product-details/GPAY.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_11',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_11',
          },
          {
            img: 'assets/images/product-details/link.svg',
            title: 'ECOM_FUNCTIONS_PBL_TITLE',
            description: 'ECOM_FUNCTIONS_PBL_DESCRIPTION',
          },
          {
            img: 'assets/images/product-details/split.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_10',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_10',
          },
          {
            img: 'assets/images/product-details/pay_later.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_12',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_12',
          },
          // {
          //   img: 'assets/images/product-details/GPAY.svg',
          //   title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_11',
          //   description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_11',
          // },
        ],
      },

      link: {
        title: 'ECOM_LINK_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/manage_products.svg',
            title: 'ECOM_FUNCTION_MANAGE_PRODUCTS_TITLE',
            description: 'ECOM_FUNCTION_MANAGE_PRODUCTS_DESCRIPTION',
          },
          {
            img: 'assets/images/product-details/manage_carts.svg',
            title: 'ECOM_FUNCTION_MANAGE_CARTS_TITLE',
            description: 'ECOM_FUNCTION_MANAGE_CARTS_DESCRIPTION',
          },
          {
            img: 'assets/images/product-details/manage_payments.svg',
            title: 'ECOM_FUNCTION_MANAGE_PAYMENTS_TITLE',
            description: 'ECOM_FUNCTION_MANAGE_PAYMENTS_DESCRIPTION',
          },
          {
            img: 'assets/images/product-details/qr.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_3',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_3',
          },
          {
            img: 'assets/images/product-details/internet-bank.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_4',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_4',
          },
          // {
          //   img: 'assets/images/product-details/cancel.svg',
          //   title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_5',
          //   description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_5',
          // },
          {
            img: 'assets/images/product-details/ertguli.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_6',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_6',
          },
          {
            img: 'assets/images/product-details/apple-pay.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_7',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_7',
          },
          // {
          //   img: 'assets/images/product-details/tbc-leasing.svg',
          //   title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_8',
          //   description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_8',
          // },
          {
            img: 'assets/images/product-details/GPAY.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_11',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_11',
          },
          {
            img: 'assets/images/product-details/pay_later.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_12',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_12',
          },
          {
            img: 'assets/images/product-details/split.svg',
            title: 'ECOM_TBC_CHECKOUT_FUNCTIONS_TITLE_13',
            description: 'ECOM_TBC_CHECKOUT_FUNCTIONS_DESCRIPTION_13',
          },

        ],
      },

      geopay: {
        title: 'ECOM_GEOPAY_FUNCTIONS_PAGE_TITLE',
        data: [
          {
            img: 'assets/images/product-details/control-panel.svg',
            title: 'ECOM_GEOPAY_FUNCTIONS_TITLE_1',
            description: 'ECOM_GEOPAY_FUNCTIONS_DESCRIPTION_1',
          },
          {
            img: 'assets/images/product-details/cancel.svg',
            title: 'ECOM_GEOPAY_FUNCTIONS_TITLE_2',
            description: 'ECOM_GEOPAY_FUNCTIONS_DESCRIPTION_2',
          },
          {
            img: 'assets/images/product-details/guard.svg',
            title: 'ECOM_GEOPAY_FUNCTIONS_TITLE_3',
            description: 'ECOM_GEOPAY_FUNCTIONS_DESCRIPTION_3',
          },
        ],
      },
    },
  };

  ngOnChanges(): void {
    this.currentData = this.data[this.directory.productType][this.directory.productId].data;
    // this.collapse();

    this.setTitle();
  }

  // public showAll() {
  // 	this.displayData = this.currentData.slice();
  // 	this.expandToggle = true;
  // }

  // collapse(): void {
  // 	this.displayData = this.currentData.slice(0, 4);
  // 	this.expandToggle = false;
  // }

  private setTitle(): void {
    this.pageTitle = this.data[this.directory.productType][this.directory.productId].title;
  }
}
