<div class="header">
    <div class="header-content">
        <div class="d-flex align-items-center h-100 w-100 justify-content-between">
            <div class="header-title d-flex justify-content-center flex-column h-100">
                <h1 class="caps mb-3 font-weight-medium main-title-text">
                    {{'BEST_PARTNER' | translate}}
                    <br>
                    <span class="mt-2 mt-md-3 d-block font-weight-bold" [@valueChanged]="titleText">
                        {{titleText | translate}}
                    </span>
                </h1>
                <tbc-button routerLink="/order" class="mt-md-3 d-inline-block" [showArrow]="true"
                            (click)="landingStartOrder()"
                            text="{{'OFFERING_BANNER_BUTTON' | translate}}">
                </tbc-button>
            </div>
            <div class="gallery-container h-100"
                 (mousedown)="mouseDown($event)"
                 (mouseup)="mouseUp($event)"
                 (touchstart)="mouseDown($event)"
                 (touchend)="mouseUp($event)"
                 (transitionend)="transitionEnd()"
                 (transitionrun)="disableSlide()">
                <div class="slides position-absolute" >
                    <img class="gallery-item gallery-item-{{i - 4}}"
                         [alt]="('BEST_PARTNER' | translate) + (titleText | translate)"
                         [ngClass]="{
                         'gallery-item-active': i - 4 === index,
                         'next-slide': i - 5 === index,
                         'prev-slide': i - 3 === index
                         }"
                         draggable="false"
                         (click)="switchSlide(i - 5 === index, i - 3 === index)"
                         *ngFor="let imageSrc of sliderImages; let i = index"
                         src="{{ imageSrc.src }}" alt="">
                </div>
            </div>
        </div>
    </div>
</div>


