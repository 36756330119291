<shared-header [showCurves]="true">
  <section class="wrap container-fluid generic-spacer-bottom">
    <div class="header">
      <h2>
        {{'ANY_QUESTIONS' | translate}}
      </h2>
      <div class="d-flex flex-wrap justify-content-center" style="margin-bottom: 2rem;">
        <h1 class="help-text mb-2" [class.last]="isLast" *ngFor="let help of READY_TO_HELP; last as isLast">
          {{help}}
        </h1>
      </div>
    </div>
    <div [ngClass]="inputFocus && 'animate'" class="search-bar">
      <a class="search-icon" (click)="focusOnInput()"></a>
      <input (blur)="onBlur()" (focus)="onFocus()" type="text" #searchBar [(ngModel)]="searchInput"
        (keyup)="keyTriggered($event)" [placeholder]="inputPlaceholder" />
      <a [ngClass]="{'hide' : showSearch || showButtonLoader}" class="close-button" (click)="clear()">
        <img src="../../../../assets/images/delete-icon.svg" alt="delete icon"/>
      </a>
      <a [ngClass]="{'hide' : !(showSearch || showButtonLoader), 'displayLoader': showButtonLoader}"
        class="custom-button" (click)="filter()">
        <span class="button-text">
          {{ 'SEARCH_FAQ' | translate }}
        </span>
        <span class="loader">
          <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
          </div>
        </span>
      </a>
    </div>
  </section>
</shared-header>
