import { Component } from '@angular/core';

@Component({
	selector: 'ecom-developers',
	templateUrl: './ecom-developers.component.html',
	styleUrls: ['./ecom-developers.component.scss']
})
export class EcomDevelopersComponent {
	public offerings = [
		{
			img: 'assets/images/ecom/easy-integration.png',
			text: 'EASY_INTEGRATION'
		},
		{
			img: 'assets/images/ecom/doc.png',
			text: 'DOCUMENTATION_SWAGGER',
			className: 'documentation'
		},
		{
			img: 'assets/images/ecom/code.png',
			text: 'CODE_EXAMPLES'
		},
		{
			img: 'assets/images/ecom/bug.png',
			text: 'TEST_ENVIRONMENT',
			className: 'bug'
		},
		{
			img: 'assets/images/ecom/plug.png',
			text: 'WOOCOMMERCE_CSCART_PLUGINS'
		},
	]
}
