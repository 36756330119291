import { Component, OnInit } from '@angular/core';
import { FAQResponse } from '../../faq/models/faq-response';
import { FaqService } from '../../faq/services/faq.service';
import { LabelId } from '../../../common/enums/label-id.enum';

@Component({
	selector: 'ecom-faq',
	templateUrl: './ecom-faq.component.html'
})
export class EcomFaqComponent implements OnInit {
	public faqs: Array<FAQResponse>;

	constructor(private faqService: FaqService) { }

	ngOnInit(): void {
		this.getFaqs();
	}

	private getFaqs(): void {
		this.faqService.getFAQs(LabelId.ECom)
			.subscribe(response => {
				if (response.isSuccess) {
					this.faqs = response.data;
				}
			})
	}
}
