import {Component, Input, OnChanges} from "@angular/core";
import {LabelId} from "../../../common/enums/label-id.enum";
import {map} from "rxjs/operators";
import {SupportPageService} from "../../support-page/services/support-page.service";
import {AppConfiguraitonService} from "../../../services/app-configuration.service";

@Component({
    selector: 'product-videos',
    templateUrl: './product-videos.component.html'
})

export class ProductVideosComponent implements OnChanges {
    public showSwiper = false;
    videos: any;

    @Input() directory: { productType: string, productId: string };

    constructor(
        private supportPageService: SupportPageService,
        private appConfigurationService: AppConfiguraitonService
    ) {
    }


    ngOnChanges(): void {
        if (this.directory) {
            this.getVideos(this.getLabelId());
        }
    }

    private getLabelId(): any {
        var result: LabelId;

        if (this.directory.productType == 'space') {
            result = LabelId.Space;
        } else if (this.directory.productType == 'pos') {
            switch (this.directory.productId) {
                case 'standard':
                    result = LabelId.StandardPos;
                    break;
                case 'integrated':
                    result = LabelId.IntegratedPos;
                    break;
                case 'cashier':
                    result = LabelId.CashierPos;
                    break;
                case 'self':
                    result = LabelId.SelfPos;
                    break;
                case 'android':
                    result = LabelId.Android;
                    break;
                case 'tpos':
                    result = LabelId.TPos;
                    break;
                case 'qrhoreca':
                    result = LabelId.QRHoreca;
                    break
                default:
                    break;
            }
        } else if (this.directory.productType == 'ecom') {
            switch (this.directory.productId) {
                case 'tbc':
                    result = LabelId.TPay;
                    break;
                case 'link':
                    result = LabelId.LinkECom;
                    break;
                case 'geopay':
                    result = LabelId.GeoPay;
                    break;
                default:
                    break;
            }
        }

        return result;
    }

    private getVideos(labelId: LabelId) {
        this.supportPageService.getVideos(labelId)
            .pipe(map(r => {
                return r.videos.map(video => {
                    video.path = this.appConfigurationService.baseAddress + video.path
                    return video;
                })
            }))
            .subscribe(response => {
                this.videos = response;
                if(this.videos.length) {
                    while(this.videos.length < 3) {
                        this.videos.push({empty: true})
                    }
                    this.showSwiper = true;
                }
            })
    }
}
