import {Component, OnInit, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  Validators,
  UntypedFormGroup, UntypedFormBuilder
} from '@angular/forms';
import { Constants } from 'src/ng-app/common/constants';
import {debounceTime, switchMap, takeUntil} from "rxjs/operators";
import {of, Subject} from "rxjs";
import {IDNumberValidator, PhoneNumberValidator} from "../../../../shared/validators/number-only.validator";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {SaveFormService} from "../../../services/save-form.service";

@Component({
  selector: 'ecom-company-details',
  templateUrl: './ecom-company-details.component.html',
  styleUrls: ['./ecom-company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup;
  public formStep: number = 2;
  public identityNumberPlaceholder: string;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() formFields;
  @Input() section;
  @Input() displayLoader: boolean;
  @Input() ecomType: number;

  @Input() set serverErrorData(serverErrors) {
    if (serverErrors) {
      if (serverErrors.length && this.formGroup) {
        serverErrors.forEach(serverError => {
          const formField = this.getFormProperty(serverError.fieldName);
          if (formField) {
            formField.setErrors(serverError.serverError);
          }
        });
      }
    }
  }

  @Input() set formStepSubmit(inputVal) {
    if (inputVal) {
      const {currentStep, stepToGo} = inputVal;
      if (currentStep) {
        if (currentStep === this.formStep) {
          if (currentStep > stepToGo) {
            this.goBack.emit(stepToGo);
          } else {
            if (this.formGroup.status === 'VALID') {
              this.formSubmitted.emit({
                formFields: this.formGroup.value,
                section: this.section,
                stepToGo,
                nextStep: true
              });
            } else {
              this.formGroup.markAllAsTouched();
            }
          }
        }
      }
    }
  }

  @Output() stepperFormValidity: EventEmitter<any> = new EventEmitter();
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() goBack: EventEmitter<any> = new EventEmitter();

  constructor(
      private formBuilder: UntypedFormBuilder,
      private bpObserver: BreakpointObserver,
      private saveFormService: SaveFormService) { }

  ngOnInit(): void {
    this.formGroup = this.buildForm();
    this.formGroup.valueChanges
        .pipe(debounceTime(300), switchMap(() => {
          this.formSubmitted.emit({formFields: this.formGroup.value, section: this.section, nextStep: false});
          return of(null)
        })).subscribe();

    this.saveFormService.patchFormValue(this.formGroup, this.section);


    this.identityPlaceholder();
  }

  private identityPlaceholder(): void {
    this.bpObserver.observe(['(max-width: 767px)'])
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.identityNumberPlaceholder = this.formFields.identityNumber.keyCode;
          } else {
            this.identityNumberPlaceholder = `${this.formFields.identityNumber.keyCode}_LONG`;
          }
        })
  }

  public goToNextStep(): void {
    if (this.formGroup.status === 'VALID') {
      this.formSubmitted.emit({formFields: this.formGroup.value, section: this.section, nextStep: true});
    }
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      identityNumber: new UntypedFormControl(this.formFields.identityNumber.value, [
        Validators.required,
        Validators.pattern(new RegExp(Constants.RegexPatterns['number'])),
        IDNumberValidator()
      ]),
      physicalAddress: new UntypedFormControl(this.formFields.physicalAddress.value, [
        Validators.required,
      ]),
      contactFullName: new UntypedFormControl(this.formFields.contactFullName.value, [
        Validators.pattern(new RegExp(Constants.RegexPatterns["multiple-words"])),
        Validators.required,
      ]),
      phoneNumber: new UntypedFormControl(this.formFields.phoneNumber.value, [
        PhoneNumberValidator(),
        Validators.required
      ]),
      email: new UntypedFormControl(this.formFields.email.value, [
        Validators.required,
        Validators.pattern(new RegExp(Constants.RegexPatterns["email"]))
      ])
    });
  }

  public get websiteKeycode() {
    let keyCode = this.formFields.website.keyCode;
    if (this.ecomType === 4) {
      keyCode += '_PBL';
    }
    return keyCode;
  }

  public getFormProperty(propertyName: string): AbstractControl {
    return this.formGroup.get(propertyName);
  }

  public get physicalAddress(): AbstractControl {
    return this.formGroup.get("physicalAddress");
  }

  public get website(): AbstractControl {
    return this.formGroup.get("website");
  }

  public get identityNumber(): AbstractControl {
    return this.formGroup.get("identityNumber");
  }

  public get contactFullName(): AbstractControl {
    return this.formGroup.get("contactFullName");
  }

  public get email(): AbstractControl {
    return this.formGroup.get("email");
  }

  public get phoneNumber(): AbstractControl {
    return this.formGroup.get("phoneNumber");
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
