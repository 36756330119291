<generic-container *ngIf="showSwiper" titleKey="VIDEOS_TITLE" textAlign="left">
    <custom-swiper
            [spaceBetween]="15"
            [data]="videos"
            [maxWidth]="378"
    >
        <swiper>
            <ng-template swiperSlide *ngFor="let video of videos">
                    <app-video [video]="video"></app-video>
            </ng-template>
        </swiper>
    </custom-swiper>
</generic-container>
