import { Routes } from '@angular/router';
import { CallRequestComponent } from '../call-request.component';
import { CallSuccessPageComponent } from '../call-success-page/call-success-page.component';
import { CallFormComponent } from '../call-form/call-form.component';
import { CallRequestGuard } from '../guards/call-request.guard';

export const callRoutes: Routes = [
    {
        path: '',
        component: CallRequestComponent,
        canActivate: [CallRequestGuard],
        children: [
            { path: 'mypos', redirectTo: 'mypos/0', pathMatch: 'full' },
            {
                path: ':product/:id',
                component: CallFormComponent
            },
            {
                path: 'success',
                component: CallSuccessPageComponent
            }
        ]
    }
]