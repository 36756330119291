export enum LabelId {
    Popular = 1,
    Pos,
    ECom,
    TPay,
    StandardECom,
    GeoPay,
    StandardPos,
    IntegratedPos,
    CashierPos,
    SelfPos ,
    Space ,
    LinkECom ,
    Android,
    TPos,
    QRHoreca
}
