<div class="wrapper d-flex h-100">
    <div class="left">
        <img src="../../../../../assets/images/support/email.svg" [alt]="'CONTACT_INFO_EMAIL_US_TITLE' | translate">
    </div>
    <div class="right d-flex flex-column col px-0">
        <h2 class="font-weight-bold">{{'CONTACT_INFO_EMAIL_US_TITLE' | translate}}</h2>
        <p class="mb-2 mb-md-4">{{'CONTACT_INFO_EMAIL_US_DESC' | translate}}</p>
        <div class="go-to mt-auto">
            <a [href]="'mailto:' + ('EMAIL_US_ADDRESS' | translate)"
                class="link font-weight-medium">{{ "EMAIL_US_ADDRESS" | translate }}</a>
        </div>
    </div>
</div>