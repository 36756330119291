import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductInfo } from '../../shared/product-info/models/product-info';
import { ProductInfoService } from '../../shared/product-info/services/product-info.service';

@Component({
	selector: 'choose-product',
	templateUrl: './choose-product.component.html'
})
export class ChooseProductComponent {
	public products: Array<ProductInfo>;

	constructor(private route: ActivatedRoute, private productInfoService: ProductInfoService) {
		this.subscribeRouter();
	}

	private subscribeRouter(): void {
		this.route.params
			.subscribe(params => {
				if (params && params.product) {
					switch (params.product) {
						case 'pos':
							this.products = this.productInfoService.getPosProducts();
							break;
						case 'ecom':
							this.products = this.productInfoService.getEcomProducts();
							break;
						default:
							break;
					}
				}
			})
	}
}
