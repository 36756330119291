import { PortalLandingComponent } from '../features/portal-landing/portal-landing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../features/home/home.component';
import { PosComponent } from '../features/pos/pos.component';
import { SupportPageComponent } from '../features/support-page/support-page.component';
import { EcomComponent } from '../features/ecom/ecom.component';
import { ProductDetailsComponent } from '../features/product-details/product-details.component';
import { TestAuthComponent } from "../features/test-auth/test-auth.component";

const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: '', loadChildren: () => import('../features/privacy-tos/privacy-tos.module').then(m => m.PrivacyTosModule) },
	{ path: '', loadChildren: () => import('../features/external-lead/external-lead.module').then(m => m.ExternalLeadModule) },
	{ path: 'testenv', component: TestAuthComponent },
	{ path: 'testenv/:userId', component: TestAuthComponent },
	{ path: 'pos', component: PosComponent },
	{ path: 'support', component: SupportPageComponent },
	{ path: 'ecom', component: EcomComponent },
	{ path: 'space', component: PortalLandingComponent },
	{ path: 'order', loadChildren: () => import('../features/order-product/order-product.module').then(m => m.OrderProductModule) },
	{ path: 'call', loadChildren: () => import('../features/call-request/call-request.module').then(m => m.CallRequestModule) },
	{ path: 'details/:productType/:productId', component: ProductDetailsComponent },
	{ path: 'maintenance', loadChildren: () => import('../features/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
	{ path: '404', loadChildren: () => import('../features/not-found/not-found.module').then(m => m.NotFoundModule) },
	{ path: '**', redirectTo: '404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class RoutingModule { }
