import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductInfoComponent } from './product-info.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { customTranslateLoaderFactory } from '../../../core/loaders/translate.loader';
import { HttpClient } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import {SlickModule} from "../slick/slick.module";
import { SwiperModule } from 'swiper/angular';
import {ProductCardModule} from "../product-card/product-card.module";
import {CustomSwiperModule} from "../custom-swiper/custom-swiper.module";
import {GenericContainerModule} from "../generic-container/generic-container.module";



@NgModule({
  declarations: [ProductInfoComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: customTranslateLoaderFactory,
                deps: [HttpClient, Router, AppConfiguraitonService]
            },
        }),
        RouterModule,
        MatTooltipModule,
        SlickModule,
        SwiperModule,
        ProductCardModule,
        CustomSwiperModule,
    ],
  exports: [
    ProductInfoComponent
  ]
})
export class ProductInfoModule { }
