import { Injectable } from '@angular/core';
import { JWT } from '../common/entities/jwt';
import { setCookie, deleteCookie, getCookie } from '../common/helpers/utils';
import { Constants } from '../common/constants';
import {HttpClient} from "@angular/common/http";
import {AppConfiguraitonService} from "./app-configuration.service";
import {CookieService} from "./cookie.service";

export interface OTP {
    access: {
        token: string,
        expires: string
    },
    refresh: {
        token: string,
        expires: string
    }
}


@Injectable()
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private config: AppConfiguraitonService,
        private cookie: CookieService) {
    }
    /**
     * @description sets cookie with value `JWT` and user state at `UserStoreService`
     * @param jwt type `JWT`
     * @param user type `User`
     * @returns void
     */
    // public authorize(jwt: JWT): void {
    //     var value: any = jwt;
    //     value.date = new Date();
    //
    //     setCookie(Constants.JWTCookieName, JSON.stringify(value), Constants.JWTCookieTimeoutInMinutes);
    //
    //     // this.userStore.setUser(user);
    // }

    /**
     * @description logs user out using deleting `JWT` cookie and removing user from `USerStateService`
     * @returns void
     */
    public logout(): void {
        deleteCookie(Constants.JWTCookieName);

        // this.userStore.setUser(null);
    }

    /**
     * @description checks if cookie `JWT` is still available
     * @returns `boolean`
     */
    public isUserAuthenticated(): boolean {
        var jwtCookie = getCookie(Constants.JWTCookieName);

        return !!jwtCookie;
    }

    /**
     * @description returns accessToken
     * @returns `string`
     */
    public getToken(): string {
        var cookie = getCookie(Constants.JWTCookieName);

        if (cookie) {
            var jwt: JWT = JSON.parse(cookie);

            if (jwt && jwt.accessToken) {
                return jwt.accessToken;
            }
        }

        return "";
    }

    removeUserTokens() {
            this.cookie.deleteCookie('authToken', this.config.getHostName());
            this.cookie.deleteCookie('authRefreshToken', this.config.getHostName());
            this.cookie.deleteCookie('tokenExpirityDate', this.config.getHostName());
    }


    authorize(accessToken: string, refreshToken: string) {
        setCookie('authToken', accessToken, Constants.JWTCookieTimeoutInMinutes, this.config.getHostName());
        setCookie('authRefreshToken', refreshToken, Constants.JWTCookieTimeoutInMinutes, this.config.getHostName());

        let tokenExpirityDate = new Date();
        tokenExpirityDate.setTime(tokenExpirityDate.getTime() + (3 * 60 * 1000));
        setCookie('tokenExpirityDate', tokenExpirityDate.toJSON(), null, this.config.getHostName())
        // localStorage.setItem('tokenExpirityDate', tokenExpirityDate.toJSON())
    }

    refreshToken() {
        return this.http.post<OTP>(`${this.config.baseAddress}/UsersAccount/RefreshToken`, {
            accessToken: this.getAuthToken(), refreshToken: this.getRefreshToken()
        });
    }

    isAuthenticated() {
        return !!getCookie('authToken');
    }

    public getDashboardUrl(): string {
        return getCookie('dashboardUrl')
    }

    public getAuthToken(): string {
        return getCookie('authToken');
    }

    public getRefreshToken(): string {
        return getCookie('authRefreshToken');
    }
}
