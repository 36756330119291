import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineShoppingComponent } from './online-shopping/online-shopping.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SharedModule } from '../../../shared/shared.module';
import { EmailUsComponent } from './email-us/email-us.component';
import { ChatComponent } from './chat/chat.component';
import { CallComponent } from './call/call.component';

@NgModule({
	declarations: [
		OnlineShoppingComponent,
		ContactUsComponent,
		EmailUsComponent,
		ChatComponent,
		CallComponent
	],
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		OnlineShoppingComponent,
		ContactUsComponent,
		EmailUsComponent,
		ChatComponent,
		CallComponent
	]
})
export class ContactInfoModule { }
