<section class="product-details-wrapper">
    <ecom-header></ecom-header>
    <div class="product-details-background">
        <ecom-products></ecom-products>
        <ecom-cost></ecom-cost>
        <ecom-developers></ecom-developers>
        <ecom-faq></ecom-faq>
        <ecom-contact></ecom-contact>
    </div>
</section>
