<div class="background-wrapper-white">
    <generic-container textAlign="left" bold="last" titleKey='PORTAL_CATEGORY_TITLE'>
        <div class="wrap container-fluid p-0">

            <div class="row portal-row-margin">
                <div class="col-md-6 col-12 p-0">
                    <div class="category-image">
                        <img src="../../../../assets/images/products/space/cat_1.svg"
                            style="width: 100%;display:block" alt="product space category 1"/>
                    </div>
                </div>
                <div class="col-md-6 col-12 vertical-center top">
                    <div class="category-text px-4">
                        <h3 class="font-weight-bold mb-4 h2">
                            {{'PORTAL_CATEGORY_1_TITLE' | translate}}
                        </h3>
                        <p class="mb-5">
                            {{'PORTAL_CATEGORY_1_DESC' | translate}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="wrap row flex-row-reverse portal-row-margin">
                <div class="col-md-6 col-12 p-0">
                    <div class="category-image pl-xl-0 pl-4">
                        <img class="m-md-auto" src="../../../../assets/images/products/space/cat_2.svg"
                            style="width: 100%;display:block" alt="product space category 2" />
                    </div>
                </div>
                <div class="col-md-6 col-12 vertical-center p-0">
                    <div class="category-text px-4">
                        <h3 class="font-weight-bold mb-4 h2">
                            {{'PORTAL_CATEGORY_2_TITLE' | translate}}
                        </h3>
                        <p class="mb-5">
                            {{'PORTAL_CATEGORY_2_DESC' | translate}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-12 p-0">
                    <div class="category-image">
                        <img src="../../../../assets/images/products/space/cat_3.svg"
                            style="width: 100%;display:block" alt="products space category 3"/>
                    </div>
                </div>
                <div class="col-md-6 col-12 vertical-center top">
                    <div class="category-text px-4">
                        <h3 class="font-weight-bold mb-4 h2">
                            {{'PORTAL_CATEGORY_3_TITLE' | translate}}
                        </h3>
                        <p class="mb-5">
                            {{'PORTAL_CATEGORY_3_DESC' | translate}}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </generic-container>
</div>