import { Injectable } from '@angular/core';
import { ProductInfo } from '../models/product-info';

@Injectable()
export class ProductInfoService {
    public getPosProducts(): Array<ProductInfo> {
        return posProducts;
    }

    public getEcomProducts(): Array<ProductInfo> {
        return ecomProducts;
    }

    public getProductParent() {
        return _productParent;
    }
}

const _productParent = [
    {
        title: 'E_COMMERCE',
        description: 'E_COM_HEAEDR_DESC',
        requestUrl: '/order/ecom',
        moreUrl: '/ecom',
        img: "/assets/images/home/categories/laptop.webp"
    },
    {
        title: 'POS_HEADER_POS_TERMINALS_TITLE',
        description: 'POS_HEADER_DESCRIPTION',
        requestUrl: '/order/pos',
        moreUrl: '/pos',
        img: "/assets/images/order/pos.png"
    }
]

const posProducts: Array<ProductInfo> = [
    {
        title: 'POS_STANDARD',
        description: 'POS_STANDARD_DESC',
        requestUrl: '/order/pos/1',
        moreUrl: '/details/pos/standard',
        img: "/assets/images/products/pos/standard.webp",
        product: "standard",
        parent: "pos",
        requestTitle: 'REQUEST_POS',
    },
    {
        title: 'CASHIER',
        description: 'POS_CASHIER_DESC',
        requestUrl: '/order/pos/3',
        moreUrl: '/details/pos/cashier',
        img: "/assets/images/products/pos/cashier.webp",
        product: "cashier",
        parent: "pos",
        requestTitle: 'REQUEST_POS',
        // disabled: true
    },
    {
        title: 'QREat',
        description: 'QREat_DESC',
        requestUrl: '/order/pos/8',
        moreUrl: '/details/pos/qrhoreca',
        img: "/assets/images/products/pos/qrhoreca.webp",
        product: "qr-eat",
        parent: "pos",
        requestTitle: 'REQUEST_QREat',
    },
    {
        title: 'T_POS',
        description: 'T_POS_DESC',
        requestUrl: '/order/pos/6',
        moreUrl: '/details/pos/tpos',
        img: "/assets/images/products/pos/tpos.webp",
        product: "t-pos",
        parent: "pos",
        requestTitle: 'REQUEST_POS',
        // disabled: true
    },
    {
        title: 'POS_ANDROID',
        description: 'POS_ANDROID_DESC',
        requestUrl: '/order/pos/5',
        moreUrl: '/details/pos/android',
        img: "/assets/images/products/pos/android.webp",
        product: "android",
        parent: "pos",
        requestTitle: 'REQUEST_POS'
    },
    {
        title: 'INTEGRATED',
        description: 'POS_INTEGRATED_DESC',
        requestUrl: '/order/pos/2',
        moreUrl: '/details/pos/integrated',
        img: "/assets/images/products/pos/integrated.webp",
        product: "integrated",
        parent: "pos",
        requestTitle: 'REQUEST_POS'
    },
    {
        title: 'SELF_SERVICE',
        description: 'POS_SELF_DESC',
        requestUrl: '/order/pos/4',
        moreUrl: '/details/pos/self',
        img: "/assets/images/products/pos/self.webp",
        product: "self",
        parent: "pos",
        requestTitle: 'REQUEST_POS'
    }
];

const ecomProducts: Array<ProductInfo> = [
    {
        title: 'ECOM_LINK',
        description: 'ECOM_LINK_PRODUCT_DESC',
        requestUrl: '/order/ecom/4',
        moreUrl: '/details/ecom/link',
        img: "/assets/images/products/ecom/link.svg",
        product: "link",
        parent: "ecom",
        requestTitle: 'REQUEST_ECOM'
    },
    {
        title: 'TPAY_CHECKOUT',
        description: 'ECOM_PAY_CHECKOUT_DESC',
        requestUrl: '/order/ecom/3',
        moreUrl: '/details/ecom/tbc',
        img: "/assets/images/products/ecom/tbc.webp",
        product: "tbc",
        parent: "ecom",
        requestTitle: 'REQUEST_ECOM'
    },
    {
        title: 'GEOPAY',
        description: 'ECOM_GEOPAY_DESC',
        requestUrl: '/order/ecom/2',
        moreUrl: '/details/ecom/geopay',
        img: "/assets/images/products/ecom/geopay.webp",
        product: "geopay",
        parent: "ecom",
        requestTitle: 'REQUEST_ECOM'
    }
];
