<generic-container textAlign="left" titleKey="FOR_DEVELOPERS_BY_DEVELOPERS">
    <section class="wrap container-fluid d-flex">
        <div class="row w-100 justify-center justify-md-between flex-row-reverse">
            <div class="col-12 col-md-6 p-0">
                <img class="side-image d-block w-100" src="../../../../assets/images/ecom/laptop_developers.png" [alt]="'FOR_DEVELOPERS_BY_DEVELOPERS' | translate">
            </div>
            <div class="col-12 col-md-6 p-0">
                <div class="my-5 mt-md-0">
                    <div class="d-flex" *ngFor="let offer of offerings; let last = last">
                        <div>
                            <img style="width: 18px" [src]="offer.img" [class]="offer.className" [alt]="offer.text | translate">
                        </div>
                        <h3 class="pl-3 mb-4" [ngClass]="last && 'bullet-last'">
                            {{offer.text | translate}}
                        </h3>
                    </div>
                </div>
                <div class="d-flex justify-content-center justify-content-md-start">
                    <a class="custom-button" href="https://developers.tbcbank.ge/docs/checkout-overview">
                        {{ 'REDIRECT_TO_PORTAL' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </section>
</generic-container>