import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IDNumberValidator, PhoneNumberValidator } from '../../../../shared/validators/number-only.validator';
import { PosQuantity } from '../../enums/pos-quantity';
import {SaveFormService} from "../../../services/save-form.service";
import {debounceTime, switchMap} from "rxjs/operators";
import {of} from "rxjs";


@Component({
  selector: 'pos-tpos',
  templateUrl: './pos-tpos.component.html',
  styleUrls: ['./pos-tpos.component.scss']
})
export class PosTposComponent implements OnInit {
  public formGroup: FormGroup;

  public posQuantity = PosQuantity;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();

  @Input() formFields;
  @Input() section;
  @Input() displayLoader: boolean;
  @Input() posType: number;
  @Input() set serverErrorData(serverErrors) {
    if (serverErrors) {
      if (serverErrors.length && this.formGroup) {
        serverErrors.forEach(serverError => {
          const formField = this.getFormProperty(serverError.fieldName);
          if (formField) {
            formField.setErrors(serverError.serverError);
          }
        });
      }
    }
  }

  // @Input() set formStepSubmit(inputVal) {
  //   if (inputVal) {
  //     const { currentStep, stepToGo } = inputVal;
  //     if (currentStep) {
  //       if (currentStep === this.formStep) {
  //         if (currentStep > stepToGo) {
  //           this.goBack.emit(stepToGo);
  //         } else {
  //           if (this.formGroup.status === 'VALID') {
  //             this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, stepToGo, nextStep: true });
  //           } else {
  //             this.formGroup.markAllAsTouched();
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  @Output() formSent: EventEmitter<any> = new EventEmitter();


  constructor(private formBuilder: FormBuilder, private saveFormService: SaveFormService) { }

  ngOnInit(): void {
    this.formGroup = this.buildForm();
    this.saveFormService.patchFormValue(this.formGroup, this.section);
    this.formGroup.valueChanges
        .pipe(debounceTime(300), switchMap(() => {
          this.formSubmitted.emit({ formFields: this.formGroup.value, section: this.section, nextStep: false });
          return of(null)
        }))
        .subscribe()

  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      identityNumber: new FormControl(this.formFields.identityNumber.value, [
        Validators.required,
        Validators.pattern(/^[0-9]+$/),
        IDNumberValidator()
      ]),
      fieldOfActivity: new FormControl(this.formFields.fieldOfActivity.value, [
        Validators.required,
      ]),
      physicalAddress: new FormControl(this.formFields.physicalAddress.value, [
        Validators.required,
      ]),

      contactFullname: new FormControl(this.formFields.contactFullname.value, [
        Validators.pattern(new RegExp(/^[\u10D0-\u10F0 a-z-]+$/i)),
        Validators.required,
      ]),
      contactPhoneNumber: new FormControl(this.formFields.contactPhoneNumber.value, [
        PhoneNumberValidator(),
        Validators.required
      ]),
      count: new FormControl(this.formFields.count.value, [
        Validators.required
      ]),
      legalNameInLatin: new FormControl(this.formFields.legalNameInLatin.value, [
        Validators.required,
        Validators.pattern(new RegExp(/^[\w\+\_\- ]+$/i))
      ]),
      nameOnReceipt: new FormControl(this.formFields.nameOnReceipt.value, [
        Validators.pattern(new RegExp(/^[\u10A0-\u10FCa-zA-Z0-9 ]+$/i)),
        Validators.required,
      ]),
    });
  }

  public sendForm() {
    if (this.formGroup.status === 'VALID') {
      this.formSent.emit(this.formGroup.value);
    }
  }

  public getFormProperty(propertyName: string): AbstractControl {
    return this.formGroup.get(propertyName);
  }

  public get identityNumber(): AbstractControl {
    return this.formGroup.get("identityNumber");
  }

  public get fieldOfActivity(): AbstractControl {
    return this.formGroup.get("fieldOfActivity");
  }

  public get physicalAddress(): AbstractControl {
    return this.formGroup.get("physicalAddress");
  }

  public get fullname(): AbstractControl {
    return this.formGroup.get('contactFullname');
  }

  public get phoneNumber(): AbstractControl {
    return this.formGroup.get('contactPhoneNumber');
  }

  public get count(): AbstractControl {
    return this.formGroup.get("count");
  }

  public get nameInLatin(): AbstractControl {
    return this.formGroup.get("legalNameInLatin");
  }

  public get nameOnReceipt(): AbstractControl {
    return this.formGroup.get("nameOnReceipt");
  }

}
