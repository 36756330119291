import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { ExternalLeadService } from '../services/external-lead.service';
import { EmployeeRefTypes } from '../enum/employee-ref-types';
import { Constants } from '../../../common/constants';
import { MaxValue, NumbersOnly } from '../../shared/validators/number-only.validator';
import { PhoneNumberValidator } from '../../shared/validators/number-only.validator'

@Component({
  selector: 'app-employee-ref',
  templateUrl: './employee-ref.component.html',
  styleUrls: ['./employee-ref.component.scss']
})
export class EmployeeRefComponent {
  public employeeRefTypes = EmployeeRefTypes;

  public formInputs = [
    {
      label: 'EMAIL',
      formControlName: 'email',
    },
    {
      label: 'REFERRAL_ID',
      formControlName: 'employeeReferralId',
    },
    {
      label: 'IDENTIFICATION',
      formControlName: 'identificationNumber',
    },
    {
      label: 'FULL_NAME',
      formControlName: 'fullName',
    },
    {
      label: 'PHONE_NUMBER',
      formControlName: 'phoneNumber',
    },
  ];

  public formGroup: UntypedFormGroup;
  public formSubmitted: boolean;
  public validationSummary: string;

  public posType = undefined;

  @ViewChild('captchaRef') reCaptcha;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private externalLeadService: ExternalLeadService
  ) {
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(new RegExp(Constants.RegexPatterns["email"])),
        Validators.maxLength(100)
      ]),
      employeeReferralId: new UntypedFormControl('', [
        Validators.required,
        NumbersOnly(),
        Validators.maxLength(100)
      ]),
      identificationNumber: new UntypedFormControl('', [
        Validators.required,
        NumbersOnly(),
        Validators.maxLength(100),
      ]),
      fullName: new UntypedFormControl('', [
        Validators.pattern(new RegExp(Constants.RegexPatterns["multiple-words"])),
        Validators.required,
        Validators.maxLength(100)
      ]),
      phoneNumber: new UntypedFormControl('', [
        PhoneNumberValidator(),
        Validators.required,
      ]),
      type: new UntypedFormControl('', [
        Validators.required,
      ]),
      comment: new UntypedFormControl('', []),
    });

    this.formGroup.get('type').valueChanges
        .subscribe(value => {

          if(value === 2 || value === 3 || value === 5) {
            this.formGroup.addControl('productCount', new UntypedFormControl('1', [Validators.required, NumbersOnly(), MaxValue(50), Validators.min(1)]));
            this.posType = value;
          } else {
            this.posType = undefined;
            this.formGroup.removeControl('productCount');
          }
        })
  }

  public submit() {
    if (this.formGroup.status === 'VALID') {
      this.formSubmitted = true;
      this.validationSummary = null;
      this.sendLead("captchaResponse");

      // this.reCaptcha.execute();
    }
  }

  public resolvedRecaptcha(captchaResponse: string) {
    this.sendLead(captchaResponse);
    this.reCaptcha.reset();
  }

  private sendLead(captchaResponse: string) {
    this.externalLeadService.sendEmployeeRef(this.formGroup.value, captchaResponse)
      .subscribe(response => {
        if (response.isSuccess) {
          this.router.navigate(['employeeref/success']);
        } else if (response.errors) {
          for (var propName in response.errors) {
            this.getFormControl(propName).setErrors({ serverError: response.errors[propName][0] || 'FIELD_IS_INVALID' });
          }
        }
        this.formSubmitted = false;
      }, () => {
        this.validationSummary = "GENERAL_ERROR";
        this.formSubmitted = false;
      });
  }

  public getErrorMessage(formControlName: string): string {
    const formControl = this.getFormControl(formControlName);

    if (this.formSubmitted || formControl.dirty || formControl.touched) {
      if (formControl.hasError('required')) {
        return 'ERROR_REQUIRED';
      }

      if (formControl.hasError('maxlength')) {
        return 'ERROR_LENGTH';
      }

      if (formControl.hasError('NumbersOnly')) {
        return 'ERROR_NUMBERS_ONLY';
      }

      if (formControl.hasError('serverError')) {
        return formControl.getError('serverError');
      }

      if (formControl.hasError('pattern') || formControl.hasError('phoneNumberInvalid')) {
        return 'INVALID_FIELD';
      }

      if(formControl.hasError('MaxValue')) {
        return 'INVALID_POS_PRODUCT_QUANTITY'
      }
    }

    return '';
  }

  public getFormControl(formControlName: string): UntypedFormControl {
    return this.formGroup.get(formControlName) as UntypedFormControl;
  }
}
