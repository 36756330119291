import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallRequestComponent } from './call-request.component';
import { SharedModule } from '../../shared/shared.module';
import { CallFormComponent } from './call-form/call-form.component';
import { CallSuccessPageComponent } from './call-success-page/call-success-page.component';
import { RouterModule, Router } from '@angular/router';
import { callRoutes } from './routing/call-request.routing';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { customTranslateLoaderFactory } from '../../core/loaders/translate.loader';
import { AppConfiguraitonService } from '../../services/app-configuration.service';
import { HttpClient } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CallRequestService } from './services/call-request.service';
import { TbcButtonModule } from '../shared/tbc-button/tbc-button.module';
import { MatSelectModule } from '@angular/material/select';
import { RecaptchaModule } from "ng-recaptcha";




@NgModule({
  declarations: [
    CallRequestComponent,
    CallFormComponent,
    CallSuccessPageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoaderFactory,
        deps: [HttpClient, Router, AppConfiguraitonService]
      }
    }),
    RouterModule.forChild(callRoutes),
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    TbcButtonModule,
    MatSelectModule,
    RecaptchaModule
  ],
  exports: [
    CallRequestComponent
  ],
  providers: [
    CallRequestService
  ]
})
export class CallRequestModule { }
