<section class="wrap container-fluid generic-spacer-top generic-spacer-bottom">
    <div [ngClass]="expandStorage && 'expand'" class="wrapper" (click)="triggerExpand()">
        <div class="left">
            <h2 class="mb-2">
                <span>
                    {{'PRODUCT_DETAIL_DOCUMENT' | translate}}
                </span>
                <span>
                    {{'STORAGE' | translate}}
                </span>
            </h2>
            <p class="mb-0">
                {{'DOCUMENT_STORAGE_DESCRIPTION' | translate}}
            </p>
        </div>
        <div class="right">
            <div [ngClass]="expandStorage && 'rotate'" class="img-wrap">
                <img src="../../../../assets/images/product-details/circle.svg" alt="product detail circle">
            </div>
        </div>
    </div>
    <div [ngClass]="expandStorage && 'expand'" class="expandable row container-fluid">
        <div [ngClass]="i % 2 === 0 ? 'left' : 'pr-0 pl-md-5 right'" *ngFor="let func of currentData; let i = index"
            class="product col-12 col-md-6 pl-0 mb-4">
            <a class="content" [href]=" currentLang === 'en' ? func?.downloadUrlEng : func?.downloadUrlGeo" target="_blank">
                <div class="img-wrap">
                    <img src="../../../../assets/images/product-details/circle.svg" alt="products detail circle">
                </div>
                <p class="mb-0">{{func?.title | translate}}</p>
            </a>
        </div>
    </div>
</section>