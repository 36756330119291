import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { combineLatest, interval, Subject } from 'rxjs';
import { LanguageService } from '../../services/language.service';
import { MetaService } from "../../services/meta.service";
import { AnalyticsService } from "../../services/analytics.service";
import { AuthenticationService } from "../../services/authentication.service";
import { ActiveTabService } from "../../services/active-tab.service";
import { CookieService } from "../../services/cookie.service";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    @HostListener('window:load', ['$event'])
    load() {
        this.activeTabService.setTabAsActive()
    }
    @HostListener('window:pagehide', ['$event'])
    unload() {
        this.activeTabService.removeTab()
    }
    @HostListener('document:visibilitychange', ['$event'])
    hidden() {
        if (document.visibilityState === 'visible') {
            this.activeTabService.setTabAsActive()
        } else {
            this.activeTabService.removeTab()
            let isAnotherTabActive = this.cookie.getCookie('active-tab') === 'reporting'
            if (!isAnotherTabActive) {
                this.activeTabService.setTabAsActive()
            }
        }
    }

    private destroy$: Subject<void> = new Subject<void>();
    public showCookiePolicy: boolean;

    public isMaintenance: boolean;

    constructor(
        public router: Router,
        private languageService: LanguageService,
        private metaService: MetaService,
        private analyticsService: AnalyticsService,
        private authService: AuthenticationService,
        private activeTabService: ActiveTabService,
        private cookie: CookieService,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this.languageService.initLanguage();
        this.listenToRouteAndLanguageChanges();
        this.checkIfTokenRefreshIsNeeded();
        this.activeTabService.checkTabs()
    }

    ngOnInit() {
        const cookie = Object.fromEntries(this._document.cookie.split('; ').map(v => v.split(/=(.*)/s).map(decodeURIComponent)));
        this.showCookiePolicy = !cookie['cookie_agreed'];
    }

    public listenToRouteAndLanguageChanges() {
        combineLatest([
            this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
            this.languageService.onChange()
        ]).subscribe(([routerEvent]: any) => {
            this.isMaintenance = routerEvent.urlAfterRedirects === "/maintenance";
            this.metaService.setMetaInfo(routerEvent.url);

            if (routerEvent.url?.includes('order/pos')) {
                this.analyticsService.sendPosEvent(routerEvent.url)
            }
            if (routerEvent.url?.includes('order/ecom')) {
                this.analyticsService.sendEcomEvent(routerEvent.url)
            }

        });
    }

    private checkIfTokenRefreshIsNeeded(): void {
        interval(2000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {

                if (this.activeTabService.isTabActive()) {
                    const expiryDate = new Date(this.cookie.getCookie('tokenExpirityDate'));
                    if (expiryDate.getTime() <= new Date().getTime() && this.authService.isAuthenticated()) {
                        this.refreshToken();
                    }
                }
            });
    }

    private refreshToken(): void {
        this.authService.refreshToken()
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
                this.authService.authorize(response.access.token, response.refresh.token);
            }, _ => {
                this.authService.removeUserTokens()
            })
    }

    ngOnDestroy() {
        this.destroy$.next()
    }
}
