import { Injectable } from "@angular/core";
import { GenericService } from "../../../services/generic.service";
import { Observable, of } from "rxjs";
import { GenericResponse } from "../../../common/entities/generic-response";
import { GenericResponseData } from "../../../common/entities/generic-response-data";
import { catchError } from 'rxjs/operators';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';
import {LabelId} from "../../../common/enums/label-id.enum";

@Injectable()
export class SupportPageService {
	private readonly faqEndpointPath: string = "/Faq";
	private readonly videosEndpointPath: string = "/SiteVideos";

	constructor(private genericService: GenericService, private appConfiguraitonService: AppConfiguraitonService) { }

	public searchFAQs(keyword: string): Observable<any> {
		return this.genericService.get(`${this.appConfiguraitonService.baseAddress}${this.faqEndpointPath}/keyword`, { keyword })
			.pipe(catchError(val => val.statusCode === 404 && of(null)));
	}

	public getFAQs(): Observable<GenericResponse<GenericResponseData<Array<any>>>> {
		return this.genericService.get(`${this.appConfiguraitonService.baseAddress}${this.faqEndpointPath}`);
	}

	public getVideos(labels?: LabelId): Observable<GenericResponseData<{videos: any[]}>> {
		return this.genericService.get(`${this.appConfiguraitonService.baseAddress}${this.videosEndpointPath}`, {labels});
	}
}
