import {NgModule} from "@angular/core";
import {SlickComponent} from "./slick.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [SlickComponent],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports: [SlickComponent]
})

export class SlickModule {

}
