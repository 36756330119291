import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { AppConfiguraitonService } from "../../../../services/app-configuration.service";
import { AttachmentEcom, AttachmentGeopay, FileFieldsEcom, FileFieldsGeopay } from "../../../shared/file-uploader/services/files.service";
import { Observable } from "rxjs";
import { ProgressInfo } from "../../../shared/file-uploader/services/files.service";

@Injectable()
export class FileUploadService {

    private formData: any;
    private baseUrl = `${this.config.baseAddress}`;

    constructor(
        private http: HttpClient,
        private config: AppConfiguraitonService
    ) { }

    uploadEcom(fileName: string, file: string, objectFields: FileFieldsEcom): Observable<any> {
        this.formData = new AttachmentEcom(
            file,
            fileName,
            objectFields.fieldOfActivity,
            objectFields.tag,
            objectFields.eComApplicationId,
        );

        const req = new HttpRequest("POST", `${this.baseUrl}/Lead/ECom/UploadFile`, this.formData, { reportProgress: true, });

        return this.http.request(req);
    }

    uploadGeopay(fileName: string, file: string, objectFields: FileFieldsGeopay): Observable<any> {
        this.formData = new AttachmentGeopay(
            file,
            fileName,
            objectFields.fieldOfActivity,
            objectFields.tag,
            objectFields.geopayApplicationId,
        );

        const req = new HttpRequest("POST", `${this.baseUrl}/Lead/Geopay/UploadFile`, this.formData, { reportProgress: true, });

        return this.http.request(req);
    }

    public handleFileResponse(event, progressInfo, fieldOfActivity, docType) {
        if (event.type === HttpEventType.UploadProgress) {
            progressInfo.value = Math.round((100 * event.loaded) / event.total);
            progressInfo.message = "uploading";
            progressInfo.success = false;
        } else if (event instanceof HttpResponse) {
            progressInfo.fieldOfActivity = fieldOfActivity;
            progressInfo.tag = docType;
            progressInfo.success = event.body.isSuccess;
            progressInfo.statusCode = event.body.statusCode;
            progressInfo.message = event.body.message;
            progressInfo.fileId = event.body.isSuccess ? event.body.data.fileId : null;
        }
    }

    deleteFileEcom(file): Observable<any> {
        const params = new HttpParams();
        params.set("eComApplicationId", file.eComApplicationId);
        params.set("fileId", file.fileId);
        return this.http.request("delete", `${this.baseUrl}/Lead/ECom/RemoveFile`, {
            body: { eComApplicationId: file.eComApplicationId, fileId: file.fileId },
        });
    }

    deleteFileGeopay(file): Observable<any> {
        const params = new HttpParams();
        params.set("geopayApplicationId", file.geopayApplicationId);
        params.set("fileId", file.fileId);
        return this.http.request("delete", `${this.baseUrl}/Lead/Geopay/RemoveFile`, {
            body: { geopayApplicationId: file.geopayApplicationId, fileId: file.fileId },
        });
    }

    convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.toString().split(',')[1]);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    getProgressInfo(ev, formControlName, sphereName) {
        const progressInfo = new ProgressInfo(0, ev.file.name, "", ev.file.size / 1024, formControlName, sphereName, false);
        progressInfo.index = window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
        ev.self.progressInfos.push(progressInfo);
        ev.self.filesService.progressInfos.push(progressInfo);

        return progressInfo;
    }

}