<div class="video" [ngClass]="{'border': !video.empty, 'empty': video.empty}">
    <video *ngIf="!video.empty"
            class="mb-3"
            controls
            preload="metadata">
        <source [src]="video.path + '#t=0.1'" type="video/mp4">
    </video>
    <div class="info">
        <div class="title pl-4 pr-4 mb-3">
            <h3 class="font-weight-bold" style="font-size: 22px!important;">{{video.nameCode | translate}}</h3>
        </div>
        <div class="description pl-4">
<!--            <p>ვიდეო აღწერა</p>-->
        </div>
    </div>
</div>
