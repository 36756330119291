import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { animate, keyframes, style, transition, trigger } from "@angular/animations";

@Component({
    selector: 'home-banner',
    templateUrl: './home-banner.component.html',
    styleUrls: ['./home-banner.component.scss'],
    animations: [
        trigger(
            'valueChanged',
            [
                transition('void => *', []),
                transition('* => void', []),
                transition('* => *', [
                    animate(500, keyframes([
                        style({ opacity: 0 }),
                        style({ opacity: 1 }),
                    ])),
                ]),
            ]),
    ]
})
export class HomeBannerComponent implements AfterViewInit, OnDestroy {

    public sliderImages: { id: number, src: string }[] = [
        {
            id: -4,
            src: 'assets/images/slider/martivad.jpg',
        },
        {
            id: -3,
            src: 'assets/images/slider/online.jpg',
        },
        {
            id: -2,
            src: 'assets/images/slider/terminalit.jpg',
        },
        {
            id: -1,
            src: 'assets/images/slider/martivad.jpg',
        },
        {
            id: 0,
            src: 'assets/images/slider/online.jpg',
        },
        {
            id: 1,
            src: 'assets/images/slider/terminalit.jpg',
        },
        {
            id: 2,
            src: 'assets/images/slider/martivad.jpg',
        },
        {
            id: 3,
            src: 'assets/images/slider/online.jpg',
        },
        {
            id: 4,
            src: 'assets/images/slider/terminalit.jpg',
        },
    ];

    private titleTexts: any = {
        '4': 'IN_PHYSICAL',
        '3': 'IN_DIGITAL',
        '2': 'IN_PAYMENTS',
        '1': 'IN_PHYSICAL',
        '0': 'IN_DIGITAL',
        '-1': 'IN_PAYMENTS',
        '-2': 'IN_PHYSICAL',
        '-3': 'IN_DIGITAL',
        '-4': 'IN_PAYMENTS',
    };
    public index = 0;
    public titleText: string = this.titleTexts[`${this.index}`];
    private slide: any;
    private galleryItems: any;
    private sliderInterval: any;
    private slideDisable: boolean;

    touchStartX: number = 0;
    touchEndX: number = 0;

    constructor(@Inject(DOCUMENT) private document: Document) { }

    ngAfterViewInit(): void {
        this.startSlider();
    }

    ngOnDestroy() {
        clearInterval(this.sliderInterval);
    }

    public landingStartOrder() {
        setTimeout(() => {
            try {
                (<any>window).gtag('event', 'event', {
                    eventCategory: 'MainWeb',
                    eventAction: 'Landing_startorder',
                });

                (<any>window).fbq('track', 'Landing_startorder');
            } catch (e) {
                console.error('Tracker Error', e)
            }
        })

    }

    private startSlider(): void {
        this.slide = (document.querySelector('.slides') as HTMLElement);
        this.galleryItems = document.querySelectorAll('.gallery-item');
        clearInterval(this.sliderInterval)
        this.sliderInterval = setInterval(() => {
            this.setSliderState();
            this.slideRight();
        }, 3000)
    }

    private restartSlider(): void {
        this.stopSlider();
        this.slide.style.transition = 'none';
        this.slide.style.transform = 'translate3D(0, 0, 0)';
        this.galleryItems.forEach(item => item.style.transition = 'none');
        this.index = 0;
        this.startSlider();
    }

    public switchSlide(next, prev): void {
        this.stopSlider();
        if (next && !this.slideDisable) {
            this.setSliderState();
            this.slideRight();
        }
        if (prev && !this.slideDisable) {
            this.setLeftSliderState();
            this.slideLeft();
        }
        this.startSlider();
    }

    private slideLeft(): void {
        this.slide.style.transition = 'all .5s';
        this.galleryItems.forEach(item => item.style.transition = 'all .5s')
        const item = document.getElementsByClassName(`gallery-item-${this.index}`)[0]
        this.slide.style.transform = `translate3D(calc( ${item.clientWidth * -this.index}px ), 0, 0)`;
    }

    private slideRight(): void {
        if (this.index === 4) {
            this.restartSlider();
        }
        this.slide.style.transition = 'all .5s';
        this.galleryItems.forEach(item => item.style.transition = 'all .5s')
        const item = document.getElementsByClassName(`gallery-item-${this.index}`)[0];
        if (this.index < 0) {
            this.slide.style.transform = `translate3D(calc( ${item.clientWidth * -this.index}px ), 0, 0)`;
        } else {
            this.slide.style.transform = `translate3D(calc( -${item.clientWidth * this.index}px ), 0, 0)`;
        }
    }

    private setSliderState(): void {
        this.index += 1;
        this.titleText = this.titleTexts[`${this.index}`];
    }

    private setLeftSliderState(): void {
        this.index -= 1;
        this.titleText = this.titleTexts[`${this.index}`];
    }

    stopSlider() {
        clearInterval(this.sliderInterval);
    }

    transitionEnd() {
        this.slideDisable = false;
        if (this.index === 3 || this.index === -3) {
            this.restartSlider();
        }
    }

    disableSlide() {
        this.slideDisable = true;
    }


    mouseDown(event: any) {
        this.touchStartX = event?.clientX || event?.touches[0]['clientX']
    }

    mouseUp(event) {
        this.touchEndX = event?.clientX || event?.changedTouches[0]['clientX'];

        if (this.touchStartX === this.touchEndX || this.slideDisable) return;

        setTimeout(() => {
            if (this.touchStartX < this.touchEndX) {
                this.setLeftSliderState()
                this.slideLeft();
            } else {
                this.setSliderState();
                this.slideRight();
            }
            this.startSlider()
        }, 0)

    }
}
