import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { FooterNavigationComponent } from './footer-navigation/footer-navigation.component';
import { FooterProductsComponent } from './footer-products/footer-products.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	declarations: [
		FooterComponent,
		FooterNavigationComponent,
		FooterProductsComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule
	],
	exports: [
		FooterComponent
	]
})
export class FooterModule { }
