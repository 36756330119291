import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { AppConfiguraitonService } from '../../../services/app-configuration.service';

@Component({
	selector: 'product-header',
	templateUrl: './product-header.component.html',
	styleUrls: ['./product-header.component.scss']
})
export class ProductHeaderComponent implements OnChanges {
	@Input() directory: { productType: string, productId: string };
	public currentData;

	public displayLoader: boolean = false;

	constructor(private http: HttpClient,
		private appConfig: AppConfiguraitonService
	) { }

	public data = {
		space: {
			default: {
				title: 'PORTAL_HEADER_TITLE',
				description: 'PORTAL_HEADER_DESCRIPTION',
				callRoute: '/call/space/0'
			}
		},
		pos: {
			standard: {
				title: 'POS_STANDARD_HEADER_TITLE',
				description: 'POS_STANDARD_HEADER_DESCRIPTION',
				bullets: [
					'POS_STANDARD_HEADER_BULLET_1', 'POS_STANDARD_HEADER_BULLET_2'
				],
				routerUrl: '/order/pos/1',
				callRoute: '/call/pos/1'
			},
			tpos: {
				// disabled: true,
				title: 'T_POS_HEADER_TITLE',
				description: 'T_POS_HEADER_DESCRIPTION',
				bullets: [
					'T_POS_HEADER_BULLET_1', 'T_POS_HEADER_BULLET_2',
					'T_POS_HEADER_BULLET_3',
				],
				routerUrl: '/order/pos/6',
				callRoute: '/call/pos/6',
				offerDescription: 'T_POS_OFFER_DESCRIPTION'
			},
			integrated: {
				title: 'POS_INTEGRATED_HEADER_TITLE',
				description: 'POS_INTEGRATED_HEADER_DESCRIPTION',
				bullets: [
					'POS_INTEGRATED_HEADER_BULLET_1', 'POS_INTEGRATED_HEADER_BULLET_2'
				],
				routerUrl: '/order/pos/2',
				callRoute: '/call/pos/2'
			},
			cashier: {
				title: 'POS_CASHIER_HEADER_TITLE',
				description: 'POS_CASHIER_HEADER_DESCRIPTION',
				bullets: [
					'POS_CASHIER_HEADER_BULLET_1', 'POS_CASHIER_HEADER_BULLET_2'
				],
				routerUrl: '/order/pos/3',
				callRoute: '/call/pos/3',
				// disabled: true
			},
			self: {
				title: 'POS_SELF_HEADER_TITLE',
				description: 'POS_SELF_HEADER_DESCRIPTION',
				bullets: [
					'POS_SELF_HEADER_BULLET_1', 'POS_SELF_HEADER_BULLET_2'
				],
				routerUrl: '/order/pos/4',
				callRoute: '/call/pos/4'
			},
			android: {
				title: 'POS_ANDROID_HEADER_TITLE',
				description: 'POS_ANDROID_HEADER_DESCRIPTION',
				bullets: [
					'POS_ANDROID_HEADER_BULLET_1', 'POS_ANDROID_HEADER_BULLET_2', 'POS_ANDROID_HEADER_BULLET_3'
				],
				routerUrl: '/order/pos/5',
				callRoute: '/call/pos/5'
			},
			qrhoreca: {
				title: 'POS_QREAT_HEADER_TITLE',
				description: 'POS_QREAT_HEADER_DESCRIPTION',
				bullets: [
					'POS_QREAT_HEADER_BULLET_1', 'POS_QREAT_HEADER_BULLET_2', 'POS_QREAT_HEADER_BULLET_3'
				],
				routerUrl: '/order/pos/8',
				callRoute: '/call/pos/8',
				disabled: false
			}
		},
		ecom: {
			link: {
				title: 'ECOM_LINK',
				description1: 'ECOM_LINK_HEADER_DESCRIPTION_1',
				description2: 'ECOM_LINK_HEADER_DESCRIPTION_2',
				description3: 'ECOM_LINK_HEADER_DESCRIPTION_3',
				description4: 'ECOM_LINK_HEADER_DESCRIPTION_4',
				bullets: [
					'ECOM_LINK_HEADER_BULLET_1',
					'ECOM_LINK_HEADER_BULLET_2',
					'ECOM_LINK_HEADER_BULLET_3',
					'ECOM_LINK_HEADER_BULLET_4',
					'ECOM_LINK_HEADER_BULLET_5',
					'ECOM_LINK_HEADER_BULLET_6'
				],
				routerUrl: '/order/ecom/4',
				callRoute: '/call/ecom/4'
			},
			geopay: {
				title: 'ECOM_GEOPAY_HEADER_TITLE',
				description: 'ECOM_GEOPAY_HEADER_DESCRIPTION',
				bullets: [
					'ECOM_GEOPAY_HEADER_BULLET_1', 'ECOM_GEOPAY_HEADER_BULLET_2'
				],
				routerUrl: '/order/ecom/2',
				callRoute: '/call/ecom/2'
			},
			tbc: {
				title: 'ECOM_TPAY_HEADER_TITLE',
				description: 'ECOM_TPAY_HEADER_DESCRIPTION',
				bullets: [
					'ECOM_TPAY_HEADER_BULLET_1', 'ECOM_TPAY_HEADER_BULLET_2', 'ECOM_TPAY_HEADER_BULLET_3'
				],
				routerUrl: '/order/ecom/3',
				callRoute: '/call/ecom/3'
			}
		}
	}

	ngOnChanges(): void {
		this.currentData = this.data[this.directory.productType][this.directory.productId];
	}

	initLogin() {
		this.displayLoader = true;

		setTimeout(() => {
			try {
				(<any>window).gtag('event', 'event', {
					eventCategory: 'MainWeb',
					eventAction: 'Space_login',
				});
				(<any>window).fbq('track', 'Space_login')
			} catch (e) {
				console.error('Tracker Error', e)
			}
		})
		window.location.href = `${this.appConfig.portalBaseAddress}/gateway/SSO/init-session`;

		// this.http.post(`${this.appConfig.portalBaseAddress}/Account/InitLogin`, null)
		// 	.subscribe((res: { redirectUrl: string }) => {
		// 		window.location.href = res.redirectUrl;
		// 	}, () => {
		// 		this.displayLoader = false;
		// 	});
	}
}
