<div class="scrollable" #scrollable (scroll)="checkScrollWidth()">
    <div class="table-container container-fluid">

        <div [ngClass]="{'p-0': isMobile}" class="inner-container">
            <div *ngIf="isMobile" class="d-flex mb-2 flex-wrap" style="width: calc(100% + 24px); overflow: hidden;">
                <p *ngFor="let header of headers; let i = index"
                   class="table-type mx-2 pb-2 position-relative caps font-weight-medium"
                   [ngClass]="selectedHeader === header && 'active'" (click)="setTable(header, i)">
                    {{ header | translate }}
                </p>
            </div>

            <div *ngIf="!isMobile" class="ml-0 mr-0 row table-headers">
                <div class="col"></div>
                <div *ngFor="let header of headers" class="col text-center">{{ header | translate }}</div>
            </div>
        </div>

        <div class="table-content" [ngClass]="{'closed-gap': !field.show}" *ngFor="let field of fields; let i = index">
            <div class="inner-container">
                <div class="row table-title" [ngClass]="{'cursor-pointer': field.showExpand}" *ngIf="field.title"
                     (click)="expandTable(field)">
                    <div [@tableCell]=" toggleAnimation" class="col">{{ field.title | translate }}</div>
                    <div [@tableCell]=" toggleAnimation" *ngIf="field.showExpand" class="col text-right show"
                         [ngClass]="{'expand': field.show}"></div>
                    <div *ngIf="i === 0" class="col"></div>
                </div>
            </div>

            <div class="table-fields inner-container" [ngClass]="field.show ? 'visible' : 'closed'">
                <div class="row" *ngFor="let value of field.values;"
                     [ngClass]="{'background-light': !field.title && i % 2 === 1, 'individual': !field.title}">
                    <div *ngFor="let v of value; let idx = index"
                         [ngClass]="{'text-center': idx > 0, 'd-none': isMobile && idx!==currentIndex+1 && idx!==0}"
                         class="col m-auto">
                        <div [@tableCell]=" toggleAnimation" *ngIf="v.type === 'text'"
                             [ngClass]="{'tooltip-field': idx === 0}">
                            {{ v.text | translate }} </div>
                        <div [@tableCell]=" toggleAnimation" class="sub-text mt-1" *ngIf="v.subText">
                            {{ v.subText | translate }} </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

    <div class="slick-buttons">
        <div class="slide-left">
            <button class="arrow-btn" (click)="scrollLeft()" [disabled]="disableLeft">
                <img class="arrow-img" src="/assets/images/paginator-arrow-colored.svg" alt="arrow-right">
            </button>

        </div>
        <div class="slide-right">
            <button class="arrow-btn" (click)="scrollRight()" [disabled]="disableRight">
                <img class="arrow-img" src="/assets/images/paginator-arrow-colored.svg" alt="arrow-right">
            </button>
        </div>
    </div>
</div>