export class ValidatorPatterns {
    public static readonly RegexPatterns = {
        'number': /^[0-9]+$/,
        'number-range': /^[0-9., -]*$/,
        'multiple-words': /^[\u10D0-\u10F0 a-z-]+$/i,
        // 'phone-number': /^((\+){1}91){1}[1-9]{1}[0-9]{9}$/,
        'phone-number': /^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[- 0-9]*$/,
        'web-address': /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,15}(:[0-9]{1,5})?(\/.*)?$/,
        'email': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'cadastral-number': /^\d+(\.\d+)*$/,
        'tbcAccNumber': /^GE\d{2}TB\d{16}/,
        'latLetters': /^[\w\+\_\- ]+$/i,
        'time': /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/,
        'only-numbersAndLetters': /^[A-Za-z0-9]+$/i,
        'only-lettersAndSpaces': /^[A-Za-zა-ჰ\s]*$/,
    };

    public static onlyNumbersInput(event: KeyboardEvent): boolean {

        let key = event.which || event.key || event.code
        if (!((key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
            if (key != 8 && key != 9 && key != 16) {
                event.preventDefault()
            }
        }
        return (key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key === 8;

    }
}
