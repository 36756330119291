<div class="experience pt-1 white">
    <generic-container textAlign="center" titleKey="WITH_BIG_EXPERIENCE" bold="last">
        <div class="wrap container-fluid">
            <div class="row">
                <div class="col-12 col-sm-4 text-center mb-4 mb-sm-0">
                    <h3 class="font-weight-bold h2 mb-2">
                        {{'EXPERIENCE_1' | translate}}
                    </h3>
                    <h3 class="description">
                        {{'EXPERIENCE_1_DESCRIPTION' | translate}}
                    </h3>
                </div>
                <div class="col-12 col-sm-4 text-center mb-4 mb-sm-0">
                    <h3 class="font-weight-bold h2 mb-2">
                        {{'EXPERIENCE_2' | translate}}
                    </h3>
                    <h3 class="description">
                        {{'EXPERIENCE_2_DESCRIPTION' | translate}}
                    </h3>
                </div>
                <div class="col-12 col-sm-4 text-center">
                    <h3 class="font-weight-bold h2 mb-2">
                        {{'EXPERIENCE_3' | translate}}
                    </h3>
                    <h3 class="description">
                        {{'EXPERIENCE_3_DESCRIPTION' | translate}}
                    </h3>
                </div>
            </div>
        </div>
    </generic-container>
    <div class="experience-bg-wrapper">
        <div class="experience-bg-left">
            <img class="left-oval" src="../../../../assets/images/home/experience/home-experience-oval-left.png" alt="oval">
            <img class="left-matrix" src="../../../../assets/images/home/experience/dots.svg" alt="dots">
        </div>
        <div class="experience-bg-right">
            <img class="right-oval" src="../../../../assets/images/home/experience/home-experience-oval-right.png" alt="oval">
            <img class="right-matrix" src="../../../../assets/images/home/experience/dots.svg" alt="dots">
        </div>
    </div>
</div>