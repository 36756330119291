import { Injectable } from "@angular/core";
import { FilesService } from "./files.service";

@Injectable()
export class FileValidator {

    constructor(private filesService: FilesService) {
    }

    validateFile(formGroupValue, fieldOfActivity, sphere = '') {
        if (sphere === 'geopay') {
            return this.validateGeopay(formGroupValue, fieldOfActivity);
        } else {
            switch (fieldOfActivity) {
                case 1: {
                    return this.validateAgreementsAndInvoice(1, formGroupValue, { license: 'LICENSE', agreement: 'AGREEMENTORINVOICE' });
                }
                case 2: {
                    return this.validateAgreementsAndInvoice(2, formGroupValue, { license: 'LICENSE', agreement: 'AGREEMENTORINVOICE' });
                }
                case 3: {
                    if (formGroupValue.agreementExists) {
                        return this.validateAgreementOrInvoice(3, 'AGREEMENT');
                    } else {
                        return true;
                    }
                }
                case 4: {
                    return this.validateAgreementOrInvoice(4, 'LICENSE');
                }
                case 5: {
                    return this.validateAgreementOrInvoice(5, 'LICENSE')
                }
                case 6: {
                    return true;
                }

            }
        }

    }

    private validateAgreementsAndInvoice(fieldOfActivity, formGroupValue, tags) {
        const licenses = this.filesService.progressInfos.filter(f => f.fieldOfActivity === fieldOfActivity && f.tag === tags.license && f.statusCode === 200);
        const agreements = this.filesService.progressInfos.filter(f => f.fieldOfActivity === fieldOfActivity && f.tag === tags.agreement && f.statusCode === 200);
        const agreementRequired = formGroupValue.agreementOrInvoicesExists;
        const licenseRequired = formGroupValue.licenseIsRequired;
        if (agreementRequired && !licenseRequired) {
            return agreements.length > 0;
        } else if (!agreementRequired && licenseRequired) {
            return licenses.length > 0
        } else if (agreementRequired && licenseRequired) {
            return agreements.length > 0 && licenses.length > 0;
        } else if (!agreementRequired && !licenseRequired) {
            return true;
        }
    }

    validateAgreementOrInvoice(fieldOfActivity, tag) {
        return this.filesService.progressInfos
            .filter(f => f.fieldOfActivity === fieldOfActivity &&
                f.tag === tag && f.statusCode === 200).length > 0;
    }

    private validateGeopay(exists, fieldOfActivity) {
        if (exists) {
            return this.filesService.progressInfos.filter(f => f.fieldOfActivity === fieldOfActivity && f.statusCode === 200).length > 0;
        } else {
            return true;
        }
    }
}