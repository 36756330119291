import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'online-shopping',
	templateUrl: './online-shopping.component.html',
	styleUrls: ['./online-shopping.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnlineShoppingComponent {
	@Input() link: string;
	@Input() disabled: boolean;
}
