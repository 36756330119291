<!--<div >-->
    <div [ngClass]="{'content-order': isOrderPage}"
         class="content position-relative d-flex flex-column m-auto h-100 pt-5 pb-4">
        <div class="img-wrap">
            <a class="product-link" [routerLink]="!isOrderPage ? [product.moreUrl] : []">
                <img class="main {{product.parent}}" src="{{product.img}}" [alt]="product.title | translate">
                <img *ngIf="product.parent === 'ecom'" class="bg {{product.product}}"
                     src="../../../../assets/images/products/ecom/bg-{{product.product}}.svg" [alt]="product.title | translate">
                <div *ngIf="product.parent === 'ecom' && product.product === 'link'"
                     class="new position-absolute font-weight-medium caps">
                    {{'NEW' | translate}}
                </div>
            </a>
        </div>

        <div class="text mb-auto">
            <a [routerLink]="!isOrderPage ? [product.moreUrl] : []">
                <h2 class="content-title mt-2 mb-2 d-block product-link">
                    {{product.title | translate}}
                </h2>
            </a>
            <p class="desc">
                {{product.description | translate}}
            </p>
        </div>
        <div class="buttons">
            <div>
                <div>
                    <a *ngIf="product.disabled" [matTooltip]="'POS_CASHIER_DISABLED' | translate"
                       matTooltipPosition="above"
                       class="disabled custom-button d-inline-block my-1 mx-auto">
                        {{ (isOrderPage ? 'CHOOSE' : product.requestTitle) | translate}}
                    </a>
                    <a *ngIf="!product.disabled" class="custom-button d-inline-block my-1 mx-auto"
                       [routerLink]="[product.requestUrl]">
                        {{ (isOrderPage ? 'CHOOSE' : product.requestTitle) | translate}}
                    </a>
                </div>
                <div *ngIf="product.moreUrl">
                    <a class="link d-inline-block p-2" [routerLink]="[product.moreUrl]">
                        {{'DETAILS' | translate}}
                    </a>
                </div>
            </div>
        </div>
    </div>
<!--</div>-->
