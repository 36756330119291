<generic-container titleKey="VIDEOS_TITLE" textAlign="left">
    <div class="d-flex align-items-center video-labels">
            <div class="label" *ngFor="let label of labels"
                 (click)="changeLabel(label.labelId)"
                 [ngClass]="{'active': activeLabel === label.labelId}">
                <span>{{label.key_code | translate}}</span>
            </div>
    </div>
    <ng-container *ngIf="videos?.length; else videosElse">
        <custom-swiper
                [spaceBetween]="15"
                [data]="videos"
                [maxWidth]="378">
            <swiper>
                <ng-template swiperSlide *ngFor="let video of videos">
                    <app-video [video]="video" [ngClass]="{'empty': video.empty}"></app-video>
                </ng-template>
            </swiper>
        </custom-swiper>
    </ng-container>
</generic-container>

<ng-template #videosElse>
    <div>
        <h1 class="caps text-center">
            {{'NOT_FOUND' | translate}}
        </h1>
    </div>
</ng-template>
